import React, { useState, useEffect, useCallback } from 'react';

import style from './editAvailable.module.css'

import { myFetchGet } from '../../../../services/services.js';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../helpers/sizes';
import { stringCapitalize } from '../../../../helpers/helpers.js';

import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue';

import CardEventModal from '../cardEventModal/CardEventModal.jsx';
import CustomModal from '../../../../components/modal/CustomModal.jsx';
import ZoneAvilitationForm from '../zoneAvilitationForm/ZoneAvilitationForm.jsx';

import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'dayjs/plugin/utc';
import styled from 'styled-components';
import { notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';

const EditAvailable = ({ recordTable, setVisibleEditAvailable, technicianID, avalityOraculoOperator }) => {

	const [disponibilityArray, setDisponibilityArray] = useState([]);
	const [virtualEvent, setVirtualEvent] = useState(null);
	const [sendToForm, setSendToForm] = useState([]);

	const [isVisibleForm, setIsVisibleForm] = useState(false);

	const [currentDate, setCurrentDate] = useState(new Date());
	const [firstDayOfWeek, setFirstDayOfWeek] = useState(null);

	const [zoneForm, setZoneForm] = useState(false);

	const [specialEvent, setSpecialEvent] = useState(0);


	const [selectedEvent, setSelectedEvent] = useState(null);
	const [deleteblock, setDeleteblock] = useState(false);

	const [dateEnd, setDateEnd] = useState(null)
	const [updateblock, setUpdateblock] = useState(false);
	const [createblock, setCreateblock] = useState(false);

	const registerDate = dayjs(recordTable.registration_date, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY")

	const CustomHeader = ({ label, onNavigate }) => {
		return (
			<CustomHeaderCalendar>
				<span className="mid">
					<button onClick={() => onNavigate('PREV')}>{'<'}</button>
					<span className="label">{label}</span>
					<button onClick={() => onNavigate('NEXT')}>{'>'} </button>
				</span>
			</CustomHeaderCalendar >
		)
	}

	const localizer = dayjsLocalizer(dayjs);

	// Funcion para obtener los bloques de disponibilidad
	const editDisponibility = async () => {
		try {
			const res = await myFetchGet(`api/v1/schedule/current_schedule_details/?id=${recordTable.id_unico}`);
			const data = res.data.map(item => {
				return {
					ID: item?.ID,
					title: item?.action === 1 ? "Evento especial" : "Bloque de disponibilidad",
					start: dayjs(item?.datetime_start, "DD-MM-YYYY HH:mm").toDate(),
					end: dayjs(item?.datetime_end, "DD-MM-YYYY HH:mm").toDate(),
					type: item?.action,
					color: "#0060F8",
					opacity: 0.5
				};

			});
			setDisponibilityArray(data);
		} catch (error) {
			console.log(error);
		}
	};


	const events = disponibilityArray?.map((item) => {
		return {
			title: item?.title,
			start: item?.start,
			end: item?.end,
			color: item?.color,
			ID: item?.ID,
			opacity: item?.opacity,
			type: item?.type,
		};
	});

	const minTime = new Date();
	minTime.setHours(7, 0, 0); // set minimum time to 7am

	const maxTime = new Date();
	maxTime.setHours(19, 0, 0); // set maximum time to 7pm

	// captura de eventos nuevos
	const handleSelectSlot = useCallback(
		(event) => {
			onModalOpen()
			setCreateblock(true);
			const selectedDate = dayjs(event?.start).format("DD-MM-YYYY");
			const selectedStart = dayjs(event?.start).format("HH:mm");
			const selectedEnd = dayjs(event?.end).format("HH:mm");
			const new_id = uuidv4();

			setVirtualEvent({
				ID: new_id,
				date: selectedDate,
				start: selectedStart,
				end: selectedEnd,
				action: 0,
			})
		},
		[]
	);

	// apertura de modal
	const onModalOpen = () => setIsVisibleForm(true);

	// Cierre de Modal
	const onModalClose = () => {
		setIsVisibleForm(false);
		setDeleteblock(false);
		setUpdateblock(false);
		setCreateblock(false);
	};

	// funcion parfa navegacion de calendario
	const handleNavigate = date => {
		const firstDay = dayjs(date).startOf('week').toDate();
		setCurrentDate(date);

		const formattedDate = dayjs(firstDay).format("DD-MM-YYYY");
		setFirstDayOfWeek(formattedDate);
	};

	// Estilos para bloques en calendario
	const eventStyleGetter = (event) => ({
		style: {
			backgroundColor: event.color,
			borderRadius: 0,
			color: 'white',
			border: 0,
			cursor: 'pointer',
			opacity: event?.opacity,
		}
	});

	// funcion para capturar tiempo de inicio
	const onStartChange = (_, timeString) => {
		setVirtualEvent({ ...virtualEvent, "start": timeString });
	};

	// funcion para capturar tiempo de fin
	const onEndChange = (_, timeString) => {
		setVirtualEvent({ ...virtualEvent, "end": timeString });
	};

	// conversion de hora de inicio para poder pintar bloque
	const startEvent = dayjs(`${virtualEvent?.date} ${virtualEvent?.start}`, 'DD-MM-YYYY HH:mm').locale('en').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
	// conversion de hora de fin para poder pintar bloque
	const endEvent = dayjs(`${virtualEvent?.date} ${virtualEvent?.end}`, 'DD-MM-YYYY HH:mm').locale('en').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');

	// conversion de tiempo para mostrar el bloque
	const toDateEndEvent = new Date(Date.parse(endEvent));
	const toDateStart = new Date(Date.parse(startEvent));


	// objeto para crear nuevo Evento
	const newEventToCalendar = {
		title: specialEvent === 1 ? "Evento especial" : "Bloque de disponibilidad",
		end: toDateEndEvent,
		start: toDateStart,
		ID: virtualEvent?.ID,
		color: specialEvent === 1 ? "#0412C2" : "#0060F8",
		opacity: 1,
	};

	// funciones para abrir o cerrar formulario
	const onModalZoneClose = () => setZoneForm(false);
	const onModalZoneOpen = () => setZoneForm(true);

	// notificacion para asegurar que hagan algun cambio en calendario
	const notificationCalendar = () => {
		notification.warning({
			style: { fontWeight: 'bold' },
			message: "Selecciona una fecha para continuar",
			placement: 'bottomLeft',
			duration: 2.5,
		});
	};

	// funcion para seleccionar un evento y capturar datos del mismo
	const handleSelectEvent = (event) => {
		onModalOpen();
		const selectedDate = dayjs(event?.start).format("DD-MM-YYYY");
		const selectedStart = dayjs(event?.start).format("HH:mm");
		const selectedEnd = dayjs(event?.end).format("HH:mm");

		setSelectedEvent(event);
		setVirtualEvent({
			ID: event?.ID,
			date: selectedDate,
			start: selectedStart,
			end: selectedEnd,
			type: event?.type,
		});
	};

	// funcion para eliminar evento del calendario
	const handleDeleteEvent = () => {
		setDeleteblock(true);
		setVirtualEvent({ ...virtualEvent, action: 1 });
		if (typeof virtualEvent.ID === "number") {
			setDisponibilityArray(disponibilityArray.filter(event => event.ID !== selectedEvent.ID));
		} else if (typeof virtualEvent.ID === "string") {
			setDisponibilityArray(disponibilityArray.filter(event => event.ID !== selectedEvent.ID));
			setSendToForm(sendToForm.filter(event => event.ID !== selectedEvent.ID))
		}

		setSelectedEvent(null);
	}

	// funcion para actualizar evento del calendario
	const handleUpdate = () => {
		setUpdateblock(true);
		setDisponibilityArray(preEvents => preEvents.map(e => e.ID === selectedEvent.ID ?
			{
				...virtualEvent,
				title: specialEvent === 1 ? "Evento especial" : "Bloque de disponibilidad",
				start: toDateStart,
				end: toDateEndEvent,
				color: specialEvent === 1 ? "#0412C2" : "#0060F8",
			} : e));
	};

	// Funcion para crear el bloque en el estado para enviar a el formulario
	const addBlockTimeArray = () => {
		// si no es actualizar o eliminar puede crear un bloque
		if (!updateblock && !deleteblock) {
			setDisponibilityArray(prevDisponibility => [...prevDisponibility, newEventToCalendar]);
		}

		if (createblock) {
			setSendToForm(prevDisponibility => [...prevDisponibility, {
				"ID": virtualEvent?.ID,
				"date": virtualEvent?.date,
				"start": virtualEvent?.start,
				"end": virtualEvent?.end,
				"type": specialEvent,
				"action": 0,
			}]);
		} else if (deleteblock === true && typeof virtualEvent.ID === "number") {
			setSendToForm(prevDisponibility => [...prevDisponibility, {
				"ID": virtualEvent?.ID,
				"date": virtualEvent?.date,
				"action": virtualEvent?.action,
			}]);
		} else if (updateblock === true) {
			setSendToForm(prevDisponibility => [...prevDisponibility, {
				"ID": virtualEvent?.ID,
				"date": virtualEvent?.date,
				"start": virtualEvent?.start,
				"end": virtualEvent?.end,
				"type": specialEvent,
				"action": 0,
			}]);
		};

		onModalClose()
	};

	// constante para pintar las zonas
	const zone = recordTable.location.length > 0 ? recordTable.location.join(', ') : " - ";

	const continueForm = () => {
		const currentFormData = [...sendToForm]
		const filterData = currentFormData.map(item => {
			if (typeof item.ID === "string") {
				const { ID, ...rest } = item;
				return rest
			} else {
				return item
			}
		})
		setSendToForm(filterData)
		onModalZoneOpen();
	}

	// disparar la funcion editDisponibility
	useEffect(() => {
		editDisponibility()
	}, []);

	// disparar la funcion handleSelectSlot
	useEffect(() => {
		handleNavigate()
	}, [setCurrentDate]);

	// dispara funcion addBlockTimeArray y onModalClose cuando tengamos cambios de la action del objeto
	useEffect(() => {
		if (deleteblock && virtualEvent.action) {
			addBlockTimeArray();
			onModalClose();
		}
	}, [deleteblock, virtualEvent])


	// dispara funcion addBlockTimeArray y onModalClose cuando tengamos la opcion de updateblock
	useEffect(() => {
		if (updateblock) {
			setSelectedEvent(null);
			addBlockTimeArray();
			onModalClose();
		}
	}, [updateblock])

	// converison de fecha para el fomulario
	useEffect(() => {
		if (recordTable?.end_date) {
			const date_end = dayjs(recordTable?.end_date, "YYYY-MM-DD").format("DD-MM-YYYY");
			setDateEnd(date_end);
		}

	}, [])


	return (
		<>
			<div>
				<div className={style.left}>
					<button className={style.arrow_back} onClick={() => setVisibleEditAvailable(false)}>
						<i className={`fa-solid fa-chevron-left ${style.arrow}`}></i>
						<SubtitleBlue marginBottom="3px" value="Regresar" />
					</button>
				</div>
				<div className={style.availability_information}>
					<SubtitleBlue value="Información general sobre la disponibilidad" marginBottom='0px' />
					<p className={style.registration_date}>Fecha de registro <b>{registerDate}</b></p>
				</div>

				<div className={style.description_availability}>
					<section className={style.section_description}>
						<span className={style.container_blue}>
							{recordTable?.end_date === null ? "Indefinida" : "Definida"}
						</span>
						<span className={style.container_column}>
							<p className={style.title}>ID del registro</p>
							<p className={style.id_register}>{recordTable?.ID}</p>
						</span>
						<span className={style.container_column}>
							<p className={style.title}>Zonas de cobertura</p>
							<SubtitleBlue value={zone} marginBottom='0px' />
						</span>
					</section>

					<span className={style.container_button}>
						<button className={style.button_cancel} onClick={() => setVisibleEditAvailable(false)}>Cancelar</button>
						<button className={style.button_continue} onClick={sendToForm.length === 0 ? notificationCalendar : continueForm}>Continuar</button>
					</span>
				</div>

			</div>
			<ContainerCalendar>
				<Calendar
					localizer={localizer}
					events={events}
					eventPropGetter={eventStyleGetter}
					startAccessor="start"
					endAccessor="end"
					style={{ height: "98vh" }}
					scrollToTime={new Date(0, 0, 0, 7, 0, 0)}
					views={["", "week", ""]}
					defaultView="week"
					culture='es'
					components={{
						toolbar: CustomHeader,
					}}
					tooltipAccessor=""
					onSelectSlot={handleSelectSlot}
					selectable
					defaultDate={currentDate}
					onNavigate={handleNavigate}
					onSelectEvent={handleSelectEvent}
					formats={{
						timeGutterFormat: "h:mm a",
						dayHeaderFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, "dddd D [de] MMMM", culture)),
						dayRangeHeaderFormat: ({ start, end }) => (
							`${stringCapitalize(dayjs(start).format('MMM D'))} - ${stringCapitalize(dayjs(end).format('MMM D'))}`
						),

						dayFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, 'ddd DD/MM', culture)),
					}}
				/>

				{isVisibleForm && (
					<CustomModal>
						<CardEventModal
							virtualEvent={virtualEvent}
							onStartChange={onStartChange}
							onEndChange={onEndChange}
							onModalClose={onModalClose}
							addBlockTimeArray={addBlockTimeArray}
							setSpecialEvent={setSpecialEvent}
							specialEvent={specialEvent}
							deleteblock={selectedEvent}
							handleDeleteEvent={handleDeleteEvent}
							updateblock={updateblock}
							handleUpdate={handleUpdate}
							edit={true}
						/>
					</CustomModal>
				)}

				{zoneForm && (
					<CustomModal onClick={onModalZoneClose}>
						<ZoneAvilitationForm
							onModalZoneClose={onModalZoneClose}
							technicianID={technicianID}
							sendToForm={sendToForm}
							disponibilityID={recordTable?.id_unico}
							data={recordTable}
							dateEnd={dateEnd}
							avalityOraculoOperator={avalityOraculoOperator}
							repetition={recordTable?.repetition}
						/>
					</CustomModal>
				)}
			</ContainerCalendar>

		</>
	)
}

export default EditAvailable

const CustomHeaderCalendar = styled.div`
	width: 100%;
	background: white;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
    padding-left: 33.41px; 
    padding-right: 54px; 

	button{
		border: none;
		outline: none;
		background: white;
	}
	.arrow_back{
		display: flex;
		align-items: center;
		background: white;
	}

	.left,
	.mid,
	.right{
		flex: 1;
	}
	.left{
		background: white;
	}
	.ant-select-selector{
		background: white;
		border: 1px solid #E0E0E0;
		border-radius: 5px;
		height: 40px;
	}

	// ----------------------------------

	.mid{
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mid .label{
		margin: 0 16px;
		color: #5A607F;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize18};
	}

	.mid button{
		background: white;
		color: #2B80FF;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize18};
	}
	

	// ----------------------------------

	.right{
		background: white;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.right .right__bn{
		width: ${smallSizes.availability.widtButton};
		height: ${smallSizes.availability.heightButton};
		border: none;
		outline: none;
		background: #DDE4EB;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #0060FF;
		border-radius: 3px;
		font-size: ${smallSizes.fontSize.fontSize12};
		color: #001737;
	}

	//-------------------------------------------

	@media screen and (min-width: ${devices.smallDesk}){
		.mid .label{
			font-size: ${smallSizes.fontSize.fontSize18};
		}
		
		.mid button{
			font-size: ${smallSizes.fontSize.fontSize18};
		}

		.right .right__bn{
			width: ${smallSizes.availability.widtButton};
		height: ${smallSizes.availability.heightButton};
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.mid .label{
			font-size: ${mediumSizes.fontSize.fontSize18};
		}
		
		.mid button{
			font-size: ${mediumSizes.fontSize.fontSize18};
		}

		.right .right__bn{
			width: ${mediumSizes.availability.widtButton};
			height: ${mediumSizes.availability.heightButton};
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.mid .label{
			font-size: ${largeSizes.fontSize.fontSize18};
		}
		
		.mid button{
			font-size: ${largeSizes.fontSize.fontSize18};
		}

		.right .right__bn{
			width: ${largeSizes.availability.widtButton};
			height: ${largeSizes.availability.heightButton};
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	}
`;

const ContainerCalendar = styled.div`
.rbc-time-slot .rbc-label{
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-button-link {
	font-size: ${smallSizes.fontSize.fontSize15};
}

.rbc-time-header-gutter {
	width: 75.9375px;
    min-width: 75.9375px;
    max-width: 75.9375px;
	background: #F7FAFC;
}

.rbc-day-slot .rbc-event-label {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-day-slot .rbc-event-content {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-time-slot .rbc-label{
	font-size: ${smallSizes.fontSize.fontSize12}
}

@media screen and (min-width: ${devices.smallDesk}){
	.rbc-time-slot .rbc-label{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-button-link {
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${smallSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.rbc-time-slot .rbc-label{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-button-link {
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.rbc-time-slot .rbc-label{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-button-link {
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${largeSizes.fontSize.fontSize12}
	}
}
`