import React from 'react'
import "./index.scss"

const SubtitleBlue = ({ value = "", marginBottom = "11px", onClick = () => { }, pointer = false, color = "#2B80FF" }) =>
	<span
		onClick={onClick}
		className='subtitleBlue'
		style={{
			cursor: pointer === true ? "pointer" : "initial",
			marginBottom,
			color
		}}
	>{value}</span>

export default SubtitleBlue