import React, { useState } from 'react'
import styled from 'styled-components'
import { DatePicker, Divider } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes';
import FilterDashboard from '../../../components/filterDashboard/FilterDashboard';
import { useSelector } from 'react-redux';


export default function HeaderDashboard({
	showBtn,
	generatePDF,
	setStartDate,
	setEndDate,
	totalOrders,
	setFilterCheckTechnicials,
	filterCheckTechnicials,
	setFilterCheckSupervisor,
	filterCheckSupervisor,
	emptyDashboard,
	loadding
}) {


	const [value, setValue] = useState(null);
	const [viewFilter, setViewFilter] = useState(false);

	const rol = useSelector((state) => state?.authState.rol)
	const { RangePicker } = DatePicker;
	const today = moment().format("LLL")
	const todayGet = dayjs().format("DD-MM-YYYY")

	const dateHandler = (dates, dateStrings) => {
		setValue(dates)
		if (dateStrings[0] === "" && dateStrings[1] === "") {
			setStartDate(todayGet)
			setEndDate(todayGet)
		} else {
			setStartDate(dateStrings[0])
			setEndDate(dateStrings[1])
		}
	}



	return (
		<Container>
			<span className='row space-between'>
				<span>
					<span className='title-dashboard'>Tablero</span>
					<span className='sub-text-dashboard'>Resumen del día | Histórico de datos</span>
				</span>
				{emptyDashboard === false && loadding === false && (
					<span>
						<span className='dateActuality'>
							<RangePicker
								className='select'
								placeholder={["Fecha Inicio", "Fecha Fin"]}
								format="DD-MM-YYYY"
								value={value}
								onChange={dateHandler}
								inputReadOnly={true}
							/>
							<span className='text-today'>Comparado con {today} </span>
						</span>
					</span>
				)}

			</span>

			<Divider />

			{emptyDashboard === false && loadding === false && (
				<>
					<section className="row space-between">
						<div className='left'>
							<span className='text-cumplimiento' >Cumplimiento de atendimiento</span>
							<span className="percent">{totalOrders?.map((item) => item.nombre === "Cumplimiento de atendimiento" ? item.cant : null)}%</span>
						</div>

						<section className='container_button'>
							{
								showBtn &&
								<button className="button" onClick={generatePDF} id="pdf_generate_btn" >
									<FilePdfOutlined className="pdf_icon" />
									<span className="export_text">Exportar</span>
								</button>
							}
							{
								rol === "supervisor_isp" && (
									<div style={{ position: "relative" }}>
										<button className="filter_button" onClick={() => setViewFilter(!viewFilter)} >
											<i className="fa-solid fa-filter size_icon"></i>
										</button>
										{viewFilter && (
											<FilterDashboard
												setViewFilter={setViewFilter}
												setFilterCheckTechnicials={setFilterCheckTechnicials}
												setFilterCheckSupervisor={setFilterCheckSupervisor}
												filterCheckTechnicials={filterCheckTechnicials}
												filterCheckSupervisor={filterCheckSupervisor}
											/>
										)}
									</div>
								)
							}


						</section>
					</section>
				</>
			)}






		</Container>
	)
}

const Container = styled.div`

.title-dashboard{
	color: #001737;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize24};
	margin-right: 7px;
}

.sub-text-dashboard{
	color: #001737;
	font-size: ${smallSizes.fontSize.fontSize12};
}


.select{
	height: 40px;
	width: 288px;
}

.row{
	width: 100%;
	display: flex;
	align-items: center;
}
.space-between{
	display: flex;
	justify-content: space-between; 
}
.left{
	display: flex;
	align-items: center;
}
.percent{
	background: #0060FF;
	color: white;
	width: 10%;
	height: 23px;
	font-size: ${smallSizes.fontSize.fontSize12};
	margin-left: 5px;
	border-radius: 3px;
	display: flex;
	justify-content: center; 
	align-items: center;
}
.button{
	width: 99px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	// background: rgb(233, 232, 232);
	background: #DDE4EB;
	color: #131523;
	border: 2px solid #0060FF;
	border-radius: 3px;
	font-weight: 400;
	cursor: pointer;

	:hover{
		background: #0060FF;
		color: white;
	}
}
.pdf_icon{
	font-size: 14px;
}

.export_text{
	font-size: ${smallSizes.fontSize.fontSize12};
	margin-left: 5px;
}

.text-today{
	margin-left: 15px;
	font-size: ${smallSizes.fontSize.fontSize12}
}

.text-cumplimiento{
	color: #1458E8;
	font-size: ${smallSizes.fontSize.fontSize14};
	font-weight: bold;
}

.container_button{
	display: flex;
	gap: 6px;
}

.filter_button{
	width: 30px;
	height: 40px;
	border: none;
	outline: none;
	background: #DDE4EB;
	color: #2B80FF;
	border: 2px solid #0060FF;
	border-radius: 3px;
	font-weight: 400;
	cursor: pointer;

	:hover{
		background: #0060FF;
		color: white;
	}
}

.size_icon{
	font-size: 14px;
}

@media screen and (min-width: ${devices.smallDesk}){

	.title-dashboard{
		font-size: ${smallSizes.fontSize.fontSize24};
	}

	.sub-text-dashboard{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.text-today{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.text-cumplimiento{
		font-size: ${smallSizes.fontSize.fontSize14};;
	}

	.percent{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.export_text{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.size_icon{
		font-size: ${smallSizes.fontSize.fontSize14};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.title-dashboard{
		font-size: ${mediumSizes.fontSize.fontSize24};
	}

	.sub-text-dashboard{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.text-today{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	.text-cumplimiento{
		font-size: ${mediumSizes.fontSize.fontSize14};;
	}

	.percent{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.export_text{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.size_icon{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.title-dashboard{
		font-size: ${largeSizes.fontSize.fontSize24};
	}

	.sub-text-dashboard{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.text-today{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	.text-cumplimiento{
		font-size: ${largeSizes.fontSize.fontSize14};;
	}

	.percent{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.export_text{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.size_icon{
		font-size: ${largeSizes.fontSize.fontSize14};
	}
}
`
