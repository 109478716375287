import { myFetchGet } from "../services/services";

const getOperator = async () => {
	try {
		const res = await myFetchGet("api/v1/userapp/list_operators/");
		return res

	} catch (error) { }
}

export { getOperator }