import React, { useEffect, useState } from 'react'

import style from './viewPlanSummary.module.css'
import { Avatar } from 'antd'
import Br from '../../../../../../../../components/br/Br.jsx'

const ViewPlanSummary = ({ data, showSpecification }) => {

	const { first_name, last_name, document_type_name, document_number, direction, number_service } = data

	const [initialName, setInitialName] = useState("")
	const [selectButton, setSelectButton] = useState(1)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [windowWidthCss, setWindowWidthCss] = useState('55%');

	const services = {
		1: 'Internet',
		2: 'Televisión',
		3: 'Telefonía'
	}

	useEffect(() => {
		if (showSpecification) {
			const firstName = first_name.charAt(0).toUpperCase()
			setInitialName(firstName)
		}
	}, [])

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (data.plan_type_integer === 2 && windowWidth > 1480) {
			setWindowWidthCss('50%')
		}
		if (data.plan_type_integer === 2 && windowWidth > 1550) {
			setWindowWidthCss('48%')
		}
		if (data.plan_type_integer === 2 && windowWidth > 1650) {
			setWindowWidthCss('45%')
		}
		if (data.plan_type_integer === 2 && windowWidth > 1750) {
			setWindowWidthCss('42%')
		}
		if (data.plan_type_integer === 2 && windowWidth > 1850) {
			setWindowWidthCss('39%')
		}
		if (data.plan_type_integer === 2 && windowWidth < 1458) {
			setWindowWidthCss('55%')
		}

	}, [windowWidth])


	return (
		<>
			{!showSpecification ? (
				<div className={style.container_plan}>
					<span className={style.subcontainer_text_plan}>
						<label className={style.text_plan}>Plan contratado</label>
					</span>

					<span className={style.container_specification}>
						<label className={style.title_specification}>Especificaciones técnicas del plan</label>
						<label className={style.text_characteristics}>{data.plan_name || "Sin plan"}</label>
					</span>
				</div>
			) :
				(
					<section className={style.container}>

						<header className={style.header}>
							<section className={style.container_data}>
								<Avatar className={style.avatar}>
									{initialName}
								</Avatar>
								<div className={style.subcontaier_data}>
									<label className={style.text_name}>{`${first_name} ${last_name}`}</label>
									<label className={style.text_document}>{`${document_type_name} ${document_number}`}</label>
									<label className={style.text_title_direction}>Dirección</label>
									<label className={style.text_direction}>{direction}</label>
								</div>
							</section>


							<label className={style.text_number}>{number_service}</label>

						</header>


						<section className={style.container_plan}>
							<span className={style.subcontainer_text_plan}>
								<label className={style.text_plan}>Plan contratado</label>
								<label className={style.text_plan_14}>{data?.plan_name}</label>
								<label className={style.text_plan_blue}>{data?.plan_technology}</label>
							</span>

							<span className={style.container_specification}>
								<label className={style.title_specification}>Especificaciones técnicas del plan</label>
								<label className={style.text_characteristics}>Características técnicas asociadas al plan de servicio a constratar.</label>

								<span className={style.container_button} style={{ width: windowWidthCss }}>
									{Object.keys(services).filter(key => key <= data.plan_type_integer).map((key) => (
										<div className={style.subcontainer_button} key={key}>
											<button
												className={selectButton === parseInt(key) ? style.button_selected : style.button_text}
												onClick={() => setSelectButton(parseInt(key))}
												key={key}
											>
												{services[key]}
											</button>
											{selectButton === parseInt(key) &&
												<div className={style.linea} />
											}

										</div>
									))}
								</span>

								{selectButton === 1 ? (
									<div className={style.container_plan_specification}>
										<span className={style.distribution_subcontainer}>
											<i className={`fa-solid fa-arrow-up ${style.arrow_center}`} />
											<label className={style.text_plan_specification}>{data?.plan_megas_upload} mb</label>
										</span>
										<span className={style.distribution_subcontainer}>
											<i className={`fa-solid fa-arrow-down ${style.arrow_center}`} />
											<label className={style.text_plan_specification}>{data?.plan_megas_download} mb</label>
										</span>
									</div>
								) : selectButton === 2 ? (
									<div className={style.container_television}>
										<label className={style.text_television}>{data?.plan_number_channels} <b>SD</b></label>
									</div>
								) : selectButton === 3 ? (
									<div className={style.container_telephony}>
										<label className={style.text_telephony}>{data?.plan_phone_minutes} min llamadas locales</label>
									</div>
								) : null}
							</span>
						</section>
					</section>
				)}
			<Br />
		</>
	)
}

export default ViewPlanSummary