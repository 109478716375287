/* eslint-disable */
import React, { useEffect, useState } from 'react'
import NormalText from '../../../components/text/NormalText'
import styled from 'styled-components'
import { Select, notification, Table } from 'antd'
import { getTechniciansCategoryDatatable, postTechnicianTypeCategory, techniciansCategory_struct } from '../../../api/technician'
import { getCategory, typeOsTechnician } from '../../../api/category'
import { getOstype } from '../../../api/calendar'
import CategoryEditForm from './CategoryEditForm'
import UseOperator from '../../../hooks/UseOperator'
import { myFetch } from '../../../services/services'
import { useSelector } from 'react-redux'
import { permissionCheck } from '../../../helpers/helpers'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes'
import DatatableRowText from '../../../components/text/datatable/datatableRowText'

import style from './typesServicesOrdensPanel.module.css'
import FormDetail from './formDetail/FormDetail'
import EmptyData from '../../../components/empty/emptyData/EmptyData'

export default function TypesServicesOrdensPanel({ data }) {

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const [ROWS, setROWS] = useState(null)
	const [COLUMNS, setCOLUMNS] = useState(null)
	const [selectItem, setSelectItem] = useState(null)
	const [visibleEditForm, setVisibleEditForm] = useState(false)

	const technicianID = data?.technician?.ID

	const [categories, setCategories] = useState(null)
	const [categorySelected, setCategorySelected] = useState(null)

	const [typeOS, setTypeOS] = useState(null)
	const [typeosSelected, setTypeosSelected] = useState(null)

	const { storedOperatorID } = UseOperator()
	const [typeOsError, setTypeOsError] = useState(false);
	const [categoryError, setCategoryError] = useState(false);

	const [typeOrder, setTypeOrder] = useState(null);

	const [selectCategory, setSelectCategory] = useState(null);
	const [visibleFormDetail, setVisibleFormDetail] = useState(false);

	const [optionsCategory, setOptionsCategory] = useState()

	const [loading, setLoading] = useState(true);
	const [emptyData, setEmptyData] = useState(false);

	const onTypeosChange = (value) => {
		setTypeosSelected(value)
		setOptionsCategory([])
		setCategorySelected(null)
	}

	const onCategoryChange = (value) => {
		setOptionsCategory(value)
		const data = value.map(item => {
			const newItem = {
				id: item,
				add: true,
				type: typeOrder
			}
			return newItem
		})
		setCategorySelected(data)
	}

	const createData = {
		'id': technicianID,
		'changes': categorySelected
	}

	// -----------EDIT------------
	const onEditForm = (value) => {
		setVisibleEditForm(true)
		setSelectItem(value)
	}

	const onTypeOrderChange = (value) => {
		setTypeOrder(value)
		setTypeosSelected(null)
		setCategories(null)
		setOptionsCategory([])
		setCategorySelected(null)
	}

	const onViewCategory = (record) => {
		setSelectCategory(record)
		setVisibleFormDetail(true)
	}

	useEffect(() => {
		technicianID && getTechniciansCategoryDatatable(technicianID).then(res => {

			if (res === undefined) setEmptyData(true)
			else setEmptyData(false)
			setROWS(res)
			setLoading(false)
		})
		technicianID && techniciansCategory_struct().then(res => {
			const max = 2;
			const columnModify = res.reduce((acc, item) => {
				if (item.dataIndex === "order_name") {
					item.onCell = (record) => {
						return {
							onClick: () => {
								onEditForm(record)
							},
							style: { cursor: "pointer" },
							className: "hover-table-class"
						}
					}
				} else if (item.dataIndex === "categories_list") {
					item.render = (category, record) => {
						return (
							<>
								{category.slice(0, max).map(img => (
									<span key={Math.random()} style={{ display: "flex", alignItems: "center" }}>
										<DatatableRowText value={img.name} />
									</span>
								))}

								{category.length > max && (
									<button className={style.button_table}
										onClick={() => onViewCategory(record)}
									>
										Ver más
									</button>
								)}
							</>
						)
					}
				}
				acc.push(item)
				return acc
			}, [])
			setCOLUMNS(columnModify)
		})
	}, [technicianID, storedOperatorID])

	useEffect(() => {
		typeOrder && typeOsTechnician(typeOrder).then(res => setTypeOS(res.data))
	}, [typeOrder])

	useEffect(() => {
		typeosSelected && getCategory(typeosSelected, typeOrder).then(res => setCategories(res.data))
	}, [typeosSelected])


	return (
		<Container>
			<div style={{ paddingLeft: "20.65px", marginBottom: 7, paddingTop: "35.43px" }}>
				<NormalText color="#2B80FF" value="Configuración de tipos de órdenes" bold={true} />
			</div>

			<section className="custom__Table__row__top">
				<div className={style.container_add}>
					<p className={style.add_text}>Tipo de orden</p>
					<Select
						style={{ height: 40, flex: 1 }}
						placeholder="Tipo de Órdenes"
						options={[
							{ value: "os", label: "Orden de servicio" },
							{ value: "ot", label: "Orden de trabajo" }
						]}
						onChange={onTypeOrderChange}
					/>
				</div>
				<div className={style.container_add}>
					<p className={style.add_text}>Nombre de la orden</p>
					<Select
						style={{ height: 40, flex: 1 }}
						placeholder="Órdenes de servicio que realiza"
						onChange={onTypeosChange}
						value={typeosSelected}
						status={typeOsError ? 'error' : ''}
					>
						{typeOS?.map(item =>
							<Select.Option
								key={item?.id}
								value={item?.id}>
								{item?.name}
							</Select.Option>)
						}
					</Select>
				</div>


				<div className={style.container_add}>
					<p className={style.add_text}>Categoría</p>
					<Select
						style={{ height: 40, flex: 1 }}
						placeholder="Categorías"
						mode="multiple"
						onChange={onCategoryChange}
						value={optionsCategory}
						status={categoryError ? 'error' : ''}
					>
						{categories?.map(item =>
							<Select.Option
								key={item?.id}
								value={item?.id}>
								{item?.name}
							</Select.Option>)}
					</Select>
				</div>

				<button
					className="btn"
					onClick={() => {
						if (
							createData.changes !== null
						) {
							postTechnicianTypeCategory(createData);
						} else {
							setTypeOsError(true);
							setCategoryError(true);

							notification.warning({
								style: { fontWeight: 'bold' },
								message: 'Todos los campos son obligatorios',
								placement: 'bottomLeft',
								duration: 1.5,
								onClose: () => {
									setTypeOsError(false);
									setCategoryError(false);
								}
							});

							return;
						}
					}}
				>
					Agregar
				</button>
			</section>



			<div style={{ marginBottom: 30 }}></div>
			{!emptyData && !loading && (
				<Table
					columns={COLUMNS}
					dataSource={ROWS}
				/>
			)}

			{emptyData && loading === false && (
				<EmptyData sectionPage='tipos de órdenes' />
			)}


			{
				visibleEditForm &&
				<CategoryEditForm
					selectItem={selectItem}
					technicianID={technicianID}
					setVisibleEditForm={setVisibleEditForm}
					categories={categories}
					typeOS={typeOS}
				/>
			}

			{
				visibleFormDetail && (
					<FormDetail
						selectCategory={selectCategory}
						setVisibleFormDetail={setVisibleFormDetail}
					/>
				)
			}
		</Container>
	)
}

const Container = styled.div`
  display: flex;
  flex-flow: column;

  .row{
    display: flex;
    height: 40px;
    gap: 6px;
    margin-bottom: 20px;
    padding: 0 1em;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 0 11px;
  }

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    min-height: 40px;
    cursor: pointer;
}
  
  .btn{
    border: none;
    outline: none;
    border: 1px solid #0060FF;
    border-radius: 3px;
    color: #2B80FF;
    font-size: ${smallSizes.fontSize.fontSize12};
    background: #DDE4EB;
    height: ${smallSizes.tipoOS.heightButton};
    width: ${smallSizes.tipoOS.widthButton};
  }
// -------------------------
  // ------custom table-------

.custom__Table{
  height: 40px;
  display: flex;
  align-items: center;
  background:  #F5F6FA;
  font-weight: 400;
  font-size: ${smallSizes.fontSize.fontSize12};
  color: #8898AA;
  padding-left: 25px;
}
.custom__Table .icon__box{
  width: 40px;
  height: 16px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.custom__Table__row{
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 26px;


  // 	primer fila
  :nth-child(odd) {background: white}

  // 	Siguiente fila
  :nth-child(even) {background: #F7FAFC}
}
.custom__Table__row__top{
  display: flex;
  align-items: flex-end;
  padding-left: 20.65px;
  padding-right: 117px;
  background: white;
	gap: .7em;
}

.type_os{
    flex: 1;
    display: flex;
    align-items: center;
}
.type_os__row{
    flex: 1;

}
.cetegory{
    flex: 1;
    // padding-left: 16px;
}
.cetegory__row{
    flex: 1;
    padding-left: 14px;
}

.operation{
    width: 250px; 
    cursor: pointer;
    padding-left: 16px;
}

.operation .btnStyle{
    border: none;
    outline: none;
    color: #2B80FF;
    font-size: ${smallSizes.fontSize.fontSize12};
    font-weight: bold;
    background: transparent;
  }

	.name_row{
		font-weight: bold;
		color: #131523;
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.ant-select-selector {
    width: 100%;
    height: auto !important;
	}

	.ant-select-multiple .ant-select-selection-overflow {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
	}

	.ant-select-multiple .ant-select-selection-item {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
	}

	.ant-select-dropdown .ant-select-item-option {
    display: flex;
    flex-direction: row;
	}

	//----------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
  .btn{
    font-size: ${smallSizes.fontSize.fontSize12};
    height: ${smallSizes.tipoOS.heightButton};
    width: ${smallSizes.tipoOS.widthButton};
  }

.custom__Table{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.name_row{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.operation .btnStyle{
    font-size: ${smallSizes.fontSize.fontSize12};
  }
}

@media screen and (min-width: ${devices.mediumDesk}){
  .btn{
    font-size: ${mediumSizes.fontSize.fontSize12};
    height: ${mediumSizes.tipoOS.heightButton};
    width: ${mediumSizes.tipoOS.widthButton};
  }

.custom__Table{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.name_row{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	.operation .btnStyle{
    font-size: ${mediumSizes.fontSize.fontSize12};
  }
}

@media screen and (min-width: ${devices.largeDesk}){
  .btn{
    font-size: ${largeSizes.fontSize.fontSize12};
    height: ${largeSizes.tipoOS.heightButton};
    width: ${largeSizes.tipoOS.widthButton};
  }

	.custom__Table{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.name_row{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	.operation .btnStyle{
    font-size: ${largeSizes.fontSize.fontSize12};
  }
}
`
const ContainerButton = styled.div`
.btn-datatable-displacement{
	color: #2B80FF;
	background: transparent;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
}

@media screen and (min-width: ${devices.smallDesk}){
	.btn-datatable-displacement{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.btn-datatable-displacement{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.btn-datatable-displacement{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;
