/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Input, Select, Divider, Button, notification, ColorPicker } from 'antd';

// api
import { postingColorData } from '../../../api/ostypeCreate';

// hooks & helpers
import UseOperator from '../../../hooks/UseOperator';
import { checkEmptyKeys, emptyInputsCheck, validarCampos } from '../../../helpers/helpers';

// custom
import Br from "../../../components/br/Br.jsx";
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue';
import LabelDrawer from '../../../components/text/drawer/label';
import MainTitle from '../../../components/text/drawer/mainTitle';
import BottomSection from '../../../components/form/bottomSection';
import Form from '../../../components/form/index.jsx'
import './index.scss'

import style from './formServiceOrders.module.css'
import styled from 'styled-components';

export default function FormServiceOrders({ setIsVisibleForm, loginOperadorID }) {

	const onCloseModal = () => setIsVisibleForm(false)
	const { storedOperatorID } = UseOperator()



	const [newName, setName] = useState(null)
	const [tag, setTag] = useState(null)

	const [colorSelected, setColorSelected] = useState("#1677ff");

	const [incompleteFields, setIncompleteFields] = useState(null)

	// Svg Icon
	const D = "M341.6 29.2L240.1 130.8l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4L482.8 170.4c39-39 39-102.2 0-141.1s-102.2-39-141.1 0zM55.4 323.3c-15 15-23.4 35.4-23.4 56.6v42.4L5.4 462.2c-8.5 12.7-6.8 29.6 4 40.4s27.7 12.5 40.4 4L89.7 480h42.4c21.2 0 41.6-8.4 56.6-23.4L309.4 335.9l-45.3-45.3L143.4 411.3c-3 3-7.1 4.7-11.3 4.7H96V379.9c0-4.2 1.7-8.3 4.7-11.3L221.4 247.9l-45.3-45.3L55.4 323.3z"

	const handlerColor = (color) => {
		setColorSelected(color.toHexString())
	}

	const handlerLabel = ({ target }) => {
		setTag(target.value.trim())
	}

	const handleNameChange = e => {
		setName(e.target.value.trim());
	};

	const data = {
		"name": newName,
		"color": colorSelected,
		"tag": tag,
		"operator": storedOperatorID
	}

	const onCretingType = () => {

		const emptyKeys = checkEmptyKeys(data)
		setIncompleteFields(emptyKeys)

		if (validarCampos(data)) {
			postingColorData(data)

		} else {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Por favor, completa todos los campos.',
				placement: 'bottomLeft',
				duration: 2.5,
			})
			return;
		}
	}

	return (

		<Form>
			<MainTitle value='Crear orden de servicio' onClick={onCloseModal} />

			<SubtitleBlue value="Datos del tipo orden de servicio" />

			<section className="column__type_order_service">
				<LabelDrawer value="Nombre" />
				<Input
					style={{ height: "40px" }}
					onChange={handleNameChange}
					placeholder="Ingrese el Nombre de la orden de servicio"
					status={emptyInputsCheck(incompleteFields, "name") ? "error" : ""}
				/>

				<Br />

				<section className={style.container_color}>
					<div className={style.subcontainer_color}>
						<LabelDrawer value='Color' />
						<ContainerColorPicker>
							<span className={style.colorpicker}>
								<p className={style.text_color}>{colorSelected.toUpperCase()}</p>
								<ColorPicker
									onChangeComplete={handlerColor}
									styles={{
										popupOverlayInner: {
											width: 260,
											background: "white",
										}
									}}
									value={colorSelected}
									arrow={false}
								/>
							</span>
						</ContainerColorPicker>
					</div>

					<div className={style.subcontainer_label}>
						<LabelDrawer value="Etiqueta" />
						<Input
							style={{ height: 40 }}
							onChange={handlerLabel}
							placeholder='Nombre del color'
							status={emptyInputsCheck(incompleteFields, "label") ? "error" : ""}
						/>
					</div>
				</section>

			</section>


			<BottomSection onCancel={onCloseModal} onAcept={onCretingType} />

		</Form>
	)
}

const ContainerColorPicker = styled.div`
.ant-color-picker-trigger{
	border: 0px;
}
`