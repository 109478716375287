/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Input, Select, Switch, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import validator from 'validator';

import styled from 'styled-components';
import Br from '../../components/br/Br';

import CustomModal from '../../components/modal/CustomModal';
import { getUserappStatus, onEditCreatedUser } from '../../api/users';
import SubtitleBlue from '../../components/text/drawer/subtitleBlue';
import LabelDrawer from '../../components/text/drawer/label';
import { onlyNumber, onlyText, validarCampos } from '../../helpers/helpers';
import MainTitle from '../../components/text/drawer/mainTitle';
import ErrorMsg from '../../components/text/notificationText/ErrorMsg';
import Form from '../../components/form/index.jsx';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes';
import BottomSection from '../../components/form/bottomSection';

const FormEditUser = ({ setVisibleEditForm, selectedRowData, groups }) => {
	const [allStatus, setAllStatus] = useState(null)
	const [status, setStatus] = useState(selectedRowData?.status)
	const [visiblePassChange, setVisiblePassChange] = useState(false)

	const [name, setName] = useState(selectedRowData?.nombre)
	const [nameError, setNameError] = useState(false)

	const [surname, setSurname] = useState(selectedRowData?.apellido)
	const [surnameError, setSurNameError] = useState("")

	const [email, setEmail] = useState(selectedRowData?.email)
	const [emailError, setEmailError] = useState("")

	const [pass, setPass] = useState("")
	const [mostrarCambioPass, setMostrarCambioPass] = useState(false)
	const [passConfirm, setPassConfirm] = useState("")
	const [passConfirmError, setPassConfirmError] = useState("")

	const [showPass, setShowPass] = useState(false)
	const [showPassConfirm, setShowPassConfirm] = useState(false)
	const [rol, setRol] = useState(null)

	const [phone, setPhone] = useState(selectedRowData?.phone)
	const [phoneError, setPhoneError] = useState("")

	const { Option } = Select;
	const ID = selectedRowData?.id_unico

	useEffect(() => {
		getUserappStatus().then(res => setAllStatus(res))
	}, [])

	const mostrarPassInputs = (value) => setMostrarCambioPass(!mostrarCambioPass)
	const onRoleChange = (value) => setRol(value)
	const onStatusChange = (value) => setStatus(value)
	const onBlurPassConfirm = () => {
		if (pass !== passConfirm) {
			setPassConfirmError('Las contraseñas no coinciden');
		} else {
			setPassConfirmError('')
		}
	}


	const onPassChange = (e) => setPass(e.target.value);
	const onConfirPassChange = (e) => setPassConfirm(e.target.value)

	const onModalClose = () => setVisibleEditForm(false)

	const onNameChange = ({ target }) => {
		// if (onlyText(e.target.value)) {
		//     setName(e.target.value);
		//     setNameError("")
		// } else {
		//     // setName("");
		//     setNameError('No Esta permitido Carácteres especiales');
		// }

		const inputValidation = /^[a-zA-ZáéíóúÁÉÍÓÚ]+(?:\s[a-zA-ZáéíóúÁÉÍÓÚ]+)*$/;
		const isValidationInput = inputValidation.test(target.value.trim());

		if (isValidationInput) {
			setName(target.value.trim());
			setNameError(false)
		} else {
			// setNameError('No Esta permitido Carácteres especiales');
			setNameError(true);
		}

	}

	// ---------------
	// ---------------
	const onSurnNameChange = (e) => {
		if (onlyText(e.target.value)) {
			setSurname(e.target.value);
			setSurNameError("")
		} else {
			// setSurname("")
			setSurNameError('No Esta permitido Carácteres especiales');
		}
	}

	const phoneOnchange = (e) => {
		if (onlyNumber(e.target.value)) {
			setPhoneError('')
			setPhone(e.target.value)
		} else {
			setPhoneError("Ingresa solo números")
			// setPhone("")
		}
	}

	const validateEmail = () => {
		if (!validator.isEmail(email)) {
			setEmailError('Ingresa un correo electrónico válido');
		} else if (email.split('@').length !== 2) {
			setEmailError('El correo electrónico debe contener solo un símbolo "@"');
		} else {
			setEmailError('');
		}
	};

	// status
	// 0-Activo 1-inActivo

	let userEditData = {
		"name": name,
		"last_name": surname,
		"phone": phone,
		"status": status,
	}

	if (rol) {
		userEditData["groups"] = [rol]
	}
	if (pass) {
		userEditData["password"] = pass
	}
	if (email !== selectedRowData?.email) {
		userEditData["email"] = email
	}

	const editUser = () => {
		if (validarCampos(userEditData)) {
			onEditCreatedUser(ID, userEditData)

		} else {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Por favor, completa todos los campos.',
				placement: 'bottomLeft',
				duration: 2.5,
			})
			return;
		}
	}

	return (
		<CustomModal onClick={onModalClose}>
			<Form>
				<Container>
					<MainTitle onClick={onModalClose} value='Modificar usuario' />

					<SubtitleBlue value="Información persona" />

					<span className="row space-between">
						<div className="left">
							<LabelDrawer value="Nombre" />
							<Input
								placeholder={name}
								onChange={onNameChange}
								// value={name}
								style={{ height: 40 }}
								status={nameError ? 'error' : ''}
								maxLength={15}
							/>
						</div>
						<div className="right">
							<LabelDrawer value="Apellido" />
							<Input
								onChange={onSurnNameChange}
								placeholder={surname}
								// value={surname}
								style={{ height: 40 }}
								status={surnameError ? 'error' : ''}
								maxLength={15}
							/>
						</div>
					</span>

					{/* ------------------errors------------------ */}
					<span className="space-between">
						<div className="createUser_left">
							{nameError && <ErrorMsg value="Solo se admiten letras" />}
						</div>

						<div className="createUser_right">
							{surnameError && <ErrorMsg value="Solo se admiten letras" />}
						</div>
					</span>
					{/* ------------------errors------------------ */}

					<Br />

					<LabelDrawer value="Número de Teléfono" />
					<Input
						// value={phone}
						onChange={phoneOnchange}
						placeholder={phone}
						style={{ height: 40 }}
						status={phoneError ? 'error' : ''}
					/>
					{phoneError && <span className='error__message'>{phoneError}</span>}

					<Br />

					<LabelDrawer value="Status" />
					<Select
						style={{ width: '100%', height: "40px" }}
						placeholder={status === 0 ? "Activo" : "Inactivo"}
						onChange={onStatusChange}
						value={status}
					>
						{allStatus?.map(({ name, ID }) => <Option key={ID} value={ID}>{name}</Option>)}
					</Select>

					<Br />

					<SubtitleBlue value="Autenticación de usuario" />
					<span className='container_input_users'>
						<LabelDrawer value="Correo electrónico" />
						<Input
							className="input"
							// value={email}
							onChange={(e) => setEmail(e.target.value)}
							onBlur={validateEmail}
							placeholder={email}
							style={{ height: 40 }}
							maxLength={40}
							status={emailError ? 'error' : ''}
						/>
						{emailError && <span className='error__message'>{emailError}</span>}
					</span>


					<Br />

					<div className="row">
						<span className='switch_box'>
							<span className='text_password'>Cambiar contraseña</span>
							<b className='font_size_12_users'>No</b>
							<Switch
								style={{ margin: "0 5px" }}
								onChange={mostrarPassInputs}
							/>
							<b className='font_size_12_users' >Si</b>
						</span>
					</div>

					{
						mostrarCambioPass === true
							? (
								<>
									<Br />
									<span className="row space-between" style={{ marginTop: 10 }}>
										<div className="left">
											<LabelDrawer value="Contraseña actual" />
											<Input.Password
												className='font_size_12_users'
												iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
												onChange={onPassChange}
												style={{ height: 40 }}
												status={passConfirmError ? "error" : ""}
												maxLength={20}
												minLength={8}
											/>
										</div>
										<div className="right">
											<LabelDrawer value="Confirmar contraseña" />
											<Input.Password
												className='font_size_12_users'
												onChange={onConfirPassChange}
												onBlur={onBlurPassConfirm}
												iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
												style={{ height: 40 }}
												status={passConfirmError ? "error" : ""}
												maxLength={20}
												minLength={8}
											/>
										</div>
									</span>
									<span className="space-between">
										<div className="createUser_left"></div>
										<div className="createUser_right">{passConfirmError && <span className='error__message'>{passConfirmError}</span>}</div>
									</span>
								</>
							) : null
					}


					<Br />

					<SubtitleBlue value="Permisos y accesos" />
					<span className='container_input_users'>
						<LabelDrawer value="Rol de usuario" />
						<Select
							style={{ width: '100%' }}
							placeholder="Seleccionar"
							onChange={onRoleChange}
						>
							{
								groups?.map(({ ID, name }) => (
									<Option key={ID} value={ID}>{name}</Option>
								))
							}
						</Select>
					</span>

					<BottomSection onAcept={editUser} onCancel={onModalClose} />
				</Container >
			</Form>
		</CustomModal>
	)
}

export default FormEditUser

const Container = styled.div`

p{
    display: inline;
    margin: inherit;
}

.ant-input-wrapper, .ant-input-group{
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}
 .ant-input-group-addon,.ant-input{
    background: white;
 }
 .ant-input, .ant-select, .ant-select-selector,.ant-select-multiple{
    border-radius: 5px !important;
}

.row {
	margin: inherit;
	display: flex;
	align-items: center;
}

.column {
	display: flex;
	flex-flow: column;
}
.align-item-center{
    align-items: center;
}
.row-center{
    justify-content: center;
}
.flex-end{
    justify-content: flex-end;
}
.space-between{
    justify-content: space-between;
}
.column_center{
    justify-content: center;
}

.left, .right {
	width: 49%
}

.tool_tip{
    background: #1c1c1c;
    color: white;
    position: relative;
    max-width: 150px;
    min-height: 40px;
    border-radius: 5px;
    text-align: center;
    filter: drop-shadow(0 3px 5px #ccc);
    margin-top: 11px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tool_tip:after{
  content: "";
  position: absolute;
  background: #1c1c1c;
  top: -5px;
  left: 10px;
  z-index: 0;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}
// ---------------------------------------
// ---------------------------------------

.ant-select:not(.ant-select-customize-input) 
.ant-select-selector {
    position: relative;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    color: #868D96; 
    font-size: 12px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-single:not(.ant-select-customize-input) 
.ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 5px 11px;
    color: #868D96; 
    font-size: ${smallSizes.fontSize.fontSize12};
}
.ant-select-item {
    position: relative;
    display: block;
    min-height: 40px;
    padding: 5px 12px;
    color: #868D96; 
    font-size: 12px;
    font-weight: normal;
    line-height: 22px;
    cursor: pointer;
    
}
.ant-select-single:not(.ant-select-customize-input),
.ant-select-selector
.ant-select-selection-search-input {
    height: 40px;
}
.ant-picker {
    color: #868D96; 
    font-size: 12px;
    padding: 4px 11px 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: white;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px;
}
.ant-select-item-option-content {
    color: #868D96; 
    font-size: 12px;
    padding: 4px 11px 4px;
    background: white;
}

.ant-picker-suffix {
    display: flex;
    flex: none;
    align-self: center;
    margin-left: 4px;
    color: #949594;
    line-height: 1;
    pointer-events: none;
}
.ant-picker-input > input {
    color: #949594;
    font-size: 12px;
    background-color: white;
    border-radius: 5px;
}

.ant-select-multiple > div:nth-child(1) {
  height: 40px
	}
.ant-select-arrow{
    color: #343A40
}
.ant-select-selection-item{
    background: white;
    height: 30px;
    padding: 0 !important;
}

.container_input_users{
	display: flex;
	flex-direction: column;
}

.text_password{
	font-size: ${smallSizes.fontSize.fontSize12};
	color: #131523;
	font-weight: bold;
	margin-right: 30px;
}

.font_size_12_users{
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}){
	.ant-input{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.text_password{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.font_size_12_users{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
    font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
.ant-input{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.text_password{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.font_size_12_users{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
    font-size: ${mediumSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.largeDesk}){
.ant-input{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.text_password{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.font_size_12_users{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
    font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;


