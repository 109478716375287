/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Panel, PanelHeader } from "../../components/panel/panel";
import FormServiceOrders from "./components/FormServiceOrders";
import styled from "styled-components";

import { CheckOutlined, FormOutlined } from '@ant-design/icons'

import UseOperator from "../../hooks/UseOperator";
import { ostype_datatables_struct, deleteOstype, getcurrentAwaitTime } from "../../api/ostypeCreate";
import { InputNumber, Popconfirm, notification, Tooltip, Pagination, Table, Input } from "antd";
import { myFetch } from "../../services/services";
import { useSelector, useDispatch } from "react-redux";
import { useFilter } from "../../hooks/useFilter";
import moment from "moment";
import NormalText from "../../components/text/NormalText";
import { permissionCheck } from "../../helpers/helpers";
import CustomModal from "../../components/modal/CustomModal";
import { useHistory } from "react-router";

import './typesServiceOrders.scss'
import DatatableHeaderText from "../../components/text/datatable/datatableHeaderText";

import style from './typesServiceOrders.module.css'
import { selectTab } from "../../store/tab/tabSlice";
import EmptySearch from "../../components/empty/emptySearch/EmptySearch";
import EmptyData from "../../components/empty/emptyData/EmptyData";
import HeaderTypeOrder from "../../components/panelHeader/TypeOrder/HeaderTypeOrder";

function TypesServiceOrders() {

	const { storedOperatorID } = UseOperator()
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [selectItem, setSelectItem] = useState();
	const [orderDirection, setOrderDirection] = useState("desc");

	const reduxState = useSelector((state) => state?.authState)
	const loginOperadorID = reduxState?.operator_detail?.ID;

	const array = reduxState?.permissions
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const [COLUMNS, setCOLUMNS] = useState([]);
	const [ROWS, setRows] = useState([]);

	const [editMode, setEditMode] = useState(false)
	const [minutes, setMinutes] = useState(0)
	const [currentAwaitSeconds, setCurrentAwaitTime] = useState(0)

	const [loadding, setLoadding] = useState(true);
	const [emptyData, setEmptyData] = useState(false);

	const [viewEmpty, setViewEmpty] = useState(false);

	const history = useHistory();
	const dispatch = useDispatch();

	const { Search } = Input

	const onCloseModal = () => setIsVisibleForm(false)

	const updateTime = async () => {
		const seconds = minutes * 60;
		const secondsToString = seconds?.toString()

		const data = {
			"valor": secondsToString
		};

		try {
			const res = await myFetch(`api/v2/operator-setting/${storedOperatorID}/operator_waiting_time_os_int/`, data, "PATCH")
			notification.success({
				style: { fontWeight: 'bold' },
				message: 'Tiempo de espera Actualizado',
				placement: 'bottomLeft',
				duration: 0.5,
				onClose: () => window.location.reload()
			})
		} catch (error) {
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'Error',
				description: 'No se pudo actualizar el tiempo de espera',
				placement: 'bottomLeft',
				duration: 1,
				// onClose: () => window.location.reload()
			})
		}
	}

	const onChange = (value) => setMinutes(value);

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Tipos de órdenes habilitadas por el operador.</span>
		</ContainerTooltip>
	)



	// --------
	// --------
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [totalSize, setTotalSize] = useState(0);

	//Filtro
	const { filters, setRowsParams, rowsParams, searchData, searchHandler } = useFilter()
	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const ostype_datatables = async () => {

		const filter = {
			...filters,
			operator: loginOperadorID,
			order_field: "ID",
			order_type: orderDirection,
		}
		try {
			const res = await myFetch("api/v1/ostype/datatables/", filter);
			if (res.data.length === 0) setEmptyData(true)
			else setEmptyData(false)

			if (res?.size) { setTotalSize(res?.size) }

			if (res?.data) {
				const rows = res?.data.map((row) => {
					const dateFormat = moment(row?.created).format("DD-MM-YYYY");
					return {
						...row,
						key: row.ID,
						created: dateFormat,
					};
				});
				setRows(rows)
				setLoadding(false)
			}
		} catch (e) { }
	}

	useEffect(() => {
		loginOperadorID && getcurrentAwaitTime(loginOperadorID)
			.then(res => {
				if (res !== undefined) {
					setCurrentAwaitTime(res)
				}
			});

		ostype_datatables_struct().then(res => {
			const columnAction = [
				...res,
				{
					title: <DatatableHeaderText value="Acción" />,
					dataIndex: "Accion",
					key: "Accion",
					width: "150px",
					render: (_, record) => (
						<Popconfirm
							placement="topRight"
							icon={null}
							title="¿Eliminar registro?"
							description={() => {
								return (
									<span>
										Si eliminas este registro, se eliminará de forma permanente de tu historial
									</span>
								);
							}}
							onConfirm={() => deleteOstype(record.id_unico)}
							okText="Eliminar"
							cancelText="Cancelar"
						>
							<button className={style.button_table}>Eliminar</button>
						</Popconfirm>
					)
				}
			];

			const data = columnAction.map((item) =>
				item.dataIndex === "name" ?
					{
						...item,
						onCell: (record) => {

							return {
								onClick: () => {
									dispatch(selectTab({ selectedTab: 0 }))
									history.push("/TypeDetallesOS", { params: record });
								},
								style: { cursor: "pointer" },
								className: "hover-table-class"
							}
						}
					} : item
			);
			setCOLUMNS(data);
		});
	}, [loginOperadorID]);

	useEffect(() => {
		ostype_datatables()
	}, [rowsParams, orderDirection, searchData])

	useEffect(() => {
		if (rolPermission("datatable_view_ostype") === false) {
			history.replace("/dashboard")
		}
	}, [])

	useEffect(() => {
		if (emptyData && loadding === false && searchData) setViewEmpty(true)
		if (!emptyData && loadding === false) setViewEmpty(true)
		if (emptyData && loadding === false && !searchData) setViewEmpty(false)
	}, [loadding, emptyData])

	return (
		<Panel value={isVisibleForm} setValue={setIsVisibleForm}>
			<HeaderTypeOrder
				title={"Tipos de Órdenes de Servicios"}
				subText={"Configuración | Tipos de órdenes de servicio"}
				buttonTypeOrder={true}
				buttonText="Agregar orden de servicio"
				handlerClickHeader={() => setIsVisibleForm(true)}
			>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement="bottom"
					overlayInnerStyle={{
						height: "95px",
						maxHeight: '100px'
					}}
				>
					<i className="fa-solid fa-circle-info circle-tooltip"></i>
				</Tooltip>

			</HeaderTypeOrder>
			<div className="type-services-mainPage-container">

				{viewEmpty && (
					<>
						{
							rolPermission("change_ostype") === true ? (
								<span className="time-await-content">
									<p className="time-await-text">Tiempo de espera al cliente</p>

									{editMode === false ? (
										<button className="button">
											<b style={{ color: "white" }}>{currentAwaitSeconds / 60} : 00 </b>
											<FormOutlined
												style={{ color: "white", paddingLeft: "7px", marginLeft: "7px", borderLeft: "2px solid white" }}
												onClick={() => setEditMode(!editMode)}
											/>
										</button>

									) : (
										<button className="button">

											<InputNumber
												size="small"
												min={1}
												max={60}
												defaultValue={currentAwaitSeconds / 60}
												onChange={onChange}
											/>
											<span style={{ color: "white", fontSize: 16, fontWeight: "500" }}>:00</span>
											<Popconfirm
												placement="topRight"
												icon={""}
												title="Actualizar la espera?"
												description={() => {
													return (
														`Si desea Actualizar el tiempo de espera.`)
												}}
												onConfirm={updateTime}
												okText="Guardar"
												cancelText="Cancelar"
											>
												<CheckOutlined
													style={{ color: "white", paddingLeft: "7px", borderLeft: "2px solid white" }}
												// onClick={updateTime}
												/>
											</Popconfirm>

										</button>
									)}
								</span>
							) : null
						}


						<div className={style.conatiner_filter}>
							<p className={style.text}>Listado de tipos de ordenes de trabajo registradas por el operador.</p>
							<SearchInput>
								<Search
									placeholder="Realizar busqueda"
									allowClear
									onSearch={(value) => {
										searchHandler(value)
										if (value === '') {
											setEmptyData(false)
											setLoadding(true)
										}
									}}
								/>
							</SearchInput>
						</div>
					</>
				)}


				{emptyData === false && loadding === false && (
					<>
						<Table
							columns={COLUMNS}
							dataSource={ROWS}
							pagination={false}
						/>

						<PaginationContainer>
							<div className="row space-between align-center">
								<span className="pagination">
									<NormalText
										bold={false}
										value={`Mostrando del ${rowsParams.start + 1} registros al ${rowsParams.start + rowsParams.offset} de un`}
									/>
									<NormalText
										bold={true}
										value={`total de ${totalSize} registros`}
									/>

								</span>
								<Pagination
									showSizeChanger
									defaultCurrent={paginationSettings[1]}
									current={paginationSettings[0]}
									onChange={modifyPagination}
									total={totalSize}
								/>
							</div>
						</PaginationContainer>
					</>
				)}

				{isVisibleForm &&
					<CustomModal onClick={onCloseModal}>
						<FormServiceOrders
							setIsVisibleForm={setIsVisibleForm}
							loginOperadorID={loginOperadorID}
						/>
					</CustomModal>
				}

				{emptyData && loadding === false && searchData && (
					<EmptySearch sectionPage='tipo de orden de servicio' />
				)}

				{emptyData && loadding === false && !searchData && (
					<EmptyData sectionPage='tipo de orden de servicio' />
				)}


			</div>
		</Panel>
	);
}

export default TypesServiceOrders;

const PaginationContainer = styled.div`
	padding-top: 43.87px;
	padding-right: 41px;
	padding-bottom: 33px;
	padding-left: 42px;
	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}
	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}
	.align-center {
		align-items: center;
	}
	.pagination {
		color: #001737;
	}

`;

const Row = styled.span`
	display: flex;
	width: 100%;

	div, b{
		margin: inherit;
	}
	.edit_input{
		margin: inherit;
		width: 40px;
		height: 23px;
		border: none;
		outline: none;
		background: #0058FF;
		color: white;
		font-weight: 500;
	}
	.minutes_input{
		margin: inherit;
		width: 40px;
		height: 23px;
		border: none;
		outline: none;
		background: #0058FF;
		color: white;
		font-weight: 500;
		font-size: 16px;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	padding: 0;
	}	

	/* Firefox */
	input[type=number] {
	-moz-appearance: textfield;
	}

	.button{
		height: 23px;
		padding: 10px;
		background: #0058FF;
		border: none;
		outline: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`
const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 155px;
	}
`;

const SearchInput = styled.div`
width: 318px;

.ant-input-group{
	height: 40px;
}

.ant-input-search .ant-input-affix-wrapper{
	height: 40px;
}

.ant-input-group .ant-input{
	height: 29px;
	font-size: 12px;
}

.ant-input-search .ant-input-search-button{
	height: 40px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
	border-left: none
}

.ant-input-affix-wrapper:focus-within {
	box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
	border-color: white;
}

.ant-input-affix-wrapper:hover {
	border-color: #d9d9d9;
	border-right: none;
}

.ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary):hover{
	border-color: #d9d9d9;
}

@media (min-width: 1280px) {
	.ant-input-group .ant-input {
		font-size: 12px;
	}
}

@media (min-width: 1366px) {
	.ant-input-group .ant-input {
		font-size: 13.5px;
	}
}

@media (min-width: 1920px) {
	.ant-input-group .ant-input {
		font-size: 15.18px; 
	}
}

`
