/* eslint-disable */
import React, { useEffect, useState } from "react";
import SidebarNav from "./sidebar-nav.jsx";
import styled from "styled-components";
import LogoImg from '../../assets/images/logo/logotipo_text.png'
import { Input, Select } from "antd";
import { setStorage } from "../../helpers/helpers.js";
import { useDispatch, useSelector } from "react-redux";
import { myFetch } from "../../services/services.js";
import { onLogin } from "../../store/auth/authSlice.js";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes.js";
import { getOperator } from "../../api/listOperator.js";

const Sidebar = () => {

	const reduxState = useSelector(state => state?.authState)
	const reduxOperatorId = reduxState?.operator_detail?.ID
	const reduxCountryId = reduxState?.operator_detail?.country


	const [selectedOperator, setSelectedOperator] = useState(null);
	const state = useSelector((state) => state?.authState)
	const [countryID, setCountryID] = useState(null)
	const [operators, setOperators] = useState([])

	const storedOperatorID = localStorage.getItem("operatorID");

	const emptyDefaultOperator = operators[0]?.ID;
	const { Option } = Select

	const dispatch = useDispatch()
	const rol = state?.rol
	const operatorDetail = state?.operator_detail
	const envPath = process.env.REACT_APP_URL_BASE

	const onChange = (value) => {
		setStorage("operatorID", Number(value))

		const countryid = operators?.filter(item => item?.ID === value)
		setCountryID(countryid)
		setStorage("countryID", Number(countryid[0]?.country_id))

		setSelectedOperator(value);
		const selectedOperatorID = countryid[0]?.ID;

		refreshOperator(selectedOperatorID)
	};

	const refreshOperator = async (selectedOperatorID) => {
		try {
			const res = await myFetch(`api/v1/userapp/change_operator/?operator=${selectedOperatorID}`);
			dispatch(
				onLogin({
					email: res?.user_detail?.email,
					token: res?.token,
					rol: res?.rol,
					permissions: res?.permissions,
					operator_detail: res?.operator_detail,
					user_detail: res?.user_detail,
				}))

			window.location.reload()
		} catch (error) { }
	}

	useEffect(() => {
		if (storedOperatorID) {
			setSelectedOperator(Number(storedOperatorID));
		}
		console.log(storedOperatorID)
	}, [storedOperatorID]);

	useEffect(() => {
		if (
			rol === "admin_isp" ||
			rol === "supervisor_isp" ||
			rol === "client_service_isp"
		) {
			setStorage("operatorID", operatorDetail?.ID)
			setStorage("countryID", operatorDetail?.country_id)

		} else {
			// Realiza alguna acción específica para los roles no coincidentes
		}
	}, []);

	useEffect(() => {
		getOperator().then((res) => {
			setOperators(res)
		})
	}, [])


	return (
		<SideBar>
			<div className="sidebar">
				<div className="top_brujula"><img className="img_Logo" src={LogoImg} alt="LOGO" /></div>

				<div className="select_custom">
					<span className="box_select">
						{
							rol === "admin_isp" ||
								rol === "supervisor_isp" ||
								rol === "client_service_isp" ? (
								<div className="box_without" >
									<img src={envPath + operatorDetail?.logo} alt={operatorDetail?.logo} className="img_operator" />
									<div className="container_operator">
										<span className="title_operator font_text_title">Operador</span>
										<span className="text_name_operator font_text_name">{operatorDetail?.name}</span>
									</div>

								</div>
							) : (
								<Select
									value={selectedOperator}
									onChange={onChange}
									style={{ color: "white" }}
									defaultValue={selectedOperator}
								>
									{operators?.map((item, i) => (
										<Option key={item?.ID} value={item?.ID}>
											<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
												<img src={envPath + operators[i]?.logo} alt={item?.name} className="img_operator"
												/>
												<div className="container_operator">
													<span className="title_operator font_text_title">Operador</span>
													<span className="text_name_operator font_text_name">{item?.name}</span>
												</div>
											</div>
										</Option>
									))}
								</Select>
							)}
					</span>
				</div>
				<SubTitle><p>Menu Principal</p></SubTitle>
				<SidebarNav />
			</div>
		</SideBar>
	);
};

export default Sidebar;


const SubTitle = styled.div`
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding:0 16px;

			p{
				color: #f1f1f1;
			padding-bottom: 5px;
			width: 100%;
			border-bottom: 1px solid #0058FF19;
			font-size: ${smallSizes.fontSize.fontSize16};
			font-weight: bold;
	}

@media screen and (min-width: ${devices.smallDesk}){
	p{
		font-size: ${smallSizes.fontSize.fontSize16};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	p{
		font-size: ${mediumSizes.fontSize.fontSize16};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	p{
		font-size: ${largeSizes.fontSize.fontSize16};
	}
}
			`;

const SideBar = styled.div`
height: 100%;

.sidebar {
	position: inherit;
	background-image: linear-gradient(to top, #0060ff 0%, #00388f 100%);
	margin: 0 !important;
	padding: 0 !important;
	height: 100%;
	min-width: 100%;
	font-family: 'Lato', sans-serif;
	display: flex;
	align-items: center;
	flex-flow: column;
}

.sidebar .nav>li.expand.has-submenu-four:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 5px;
	bottom: 197px;
	width: 5px;
	background: white;
	display: block;
}

.sidebar .nav>li.expand.has-submenu-one:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 5px;
	bottom: 55px;
	width: 5px;
	background: white;
	display: block;
}

.sidebar .nav>li.expand.has-submenu-two:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 5px;
	bottom: 105px;
	width: 5px;
	background: white;
	display: block;
}

.nav-item a {
	cursor: pointer;
}

// Navbar
.navbar-white {
	background-color: white;
}

// Sidebar
.new__sidebar {
	.nav>li.active>a i {
		color: var(--secondary-color);
	}
}

// ---------------------------
// ---------------------------

.top_brujula{
	background: transparent;
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-top: 25px;
}

.img_Logo{
	width: ${smallSizes.sidebar.widthImg};
	object-fit: contain;
	margin-bottom: 20px;
}
// ---------------------------
// ---------------------------


.select_custom{
	background: transparent;
	margin-bottom: 2em;	
	width: 100%;
}

.ant-select-selection-placeholder{
	color: white;
	width: 100%;
}

.box_select{
	background: transparent !important;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #D8D8D8 !important;
}

.ant-select-selection-placeholder{color: white}
.ant-select:not(.ant-select-customize-input)
.ant-select-selector {
	width: 100%;
	position: relative;
	background: #00388f !important;
	color: #D8D8D8 !important;
	border: none;
	outline: none;
	border-radius: 3px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	height: ${smallSizes.sidebar.heightSelect};
}

.ant-select-single:not(.ant-select-customize-input),
.ant-select-selector {
	width: 100%;
	height: ${smallSizes.sidebar.heightSelect};
	color: #D8D8D8 !important;
	display: flex;
	align-items: center;
}
.ant-select-single:not(.ant-select-customize-input),
.ant-select-selector,
.ant-select-selection-search-input {
	height: ${smallSizes.sidebar.heightSelect};
	color: #D8D8D8 !important;
	width: 100%;
}
.ant-select{
	color: #D8D8D8 !important;
	z-index: 10;
	width: 100%;
}
.ant-select-arrow{
	color: #D8D8D8 !important;
	margin-right: 10px;
}

.ant-select-selection-item{
	font-size: ${smallSizes.fontSize.fontSize14}
	width: 100%;
}

.ant-select-selector {
		// background: #D8D8D8;
		background-color: rgba(216, 216, 216, 0.11) !important;
		border-radius: 0px;
		color: white;
		border-color: #00388f !important;
		width: 100%;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, :where(.css-dev-only-do-not-override-byeoj0).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 18px;
    display: flex;
}

.container_operator .title_operator{
	color: #FFFFFF;
}

.container_operator .text_name_operator {
	font-weight: bold;
	color: #FFFFFF;
}

.img_operator{
	width: ${smallSizes.sidebar.ImgOperator};
	height: ${smallSizes.sidebar.ImgOperator};
	margin-right: 20px;
	margin-left: 6px;
	border-radius: 100%;
}
.box_select .box_without{
	display: flex;
	align-items: center;
	background: #00388f;
	width: 100%;
	height: ${smallSizes.sidebar.heightSelect};
	padding: 1em;
}

.font_text_title{
	font-size: ${smallSizes.fontSize.fontSize14};
}

.font_text_name{
	font-size: ${smallSizes.fontSize.fontSize18};
}



@media screen and (min-width: ${devices.smallDesk}) {

	.sidebar .nav > li > a {
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.sidebar .nav .sub-menu > li > a {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.img_Logo{
		width: ${smallSizes.sidebar.widthImg};
	}

	.ant-select-selection-placeholder{color: white}
	.ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
		height: ${smallSizes.sidebar.heightSelect};
	}

	.ant-select-single:not(.ant-select-customize-input),
	.ant-select-selector {
		height: ${smallSizes.sidebar.heightSelect};
	}

	.ant-select-single:not(.ant-select-customize-input),
	.ant-select-selector,
	.ant-select-selection-search-input {
		height: ${smallSizes.sidebar.heightSelect};
	}

	.ant-select-selection-item{
		font-size: ${smallSizes.fontSize.fontSize14}
	}

	.img_operator{
		width: ${smallSizes.sidebar.ImgOperator};
		height: ${smallSizes.sidebar.ImgOperator};
	}

	.font_text_title{
		font-size: ${smallSizes.fontSize.fontSize14};
	}
	
	.font_text_name{
		font-size: ${smallSizes.fontSize.fontSize18};
	}

}

@media screen and (min-width: ${devices.mediumDesk}) {

	.sidebar .nav > li > a {
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.sidebar .nav .sub-menu > li > a {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.img_Logo{
		width: ${mediumSizes.sidebar.widthImg};
	}

	.ant-select-selection-placeholder{color: white}
	.ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
		height: ${mediumSizes.sidebar.heightSelect};
	}

	.ant-select-single:not(.ant-select-customize-input),
	.ant-select-selector {
		height: ${mediumSizes.sidebar.heightSelect};
	}
	
	.ant-select-single:not(.ant-select-customize-input),
	.ant-select-selector,
	.ant-select-selection-search-input {
		height: ${mediumSizes.sidebar.heightSelect};
	}

	.ant-select-selection-item{
		font-size: ${mediumSizes.fontSize.fontSize14}
	}

	.img_operator{
		width: ${mediumSizes.sidebar.ImgOperator};
		height: ${mediumSizes.sidebar.ImgOperator};
	}

	.font_text_title{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}
	
	.font_text_name{
		font-size: ${mediumSizes.fontSize.fontSize18};
	}
}

@media screen and (min-width: ${devices.largeDesk}) {

	.sidebar .nav > li > a {
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.sidebar .nav .sub-menu > li > a {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.img_Logo{
		width: ${largeSizes.sidebar.widthImg};
	}

	.ant-select-selection-placeholder{color: white}
	.ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
		height: ${largeSizes.sidebar.heightSelect};
	}

	.ant-select-single:not(.ant-select-customize-input),
	.ant-select-selector {
		height: ${largeSizes.sidebar.heightSelect};
	}
	
	.ant-select-single:not(.ant-select-customize-input),
	.ant-select-selector,
	.ant-select-selection-search-input {
		height: ${largeSizes.sidebar.heightSelect};
	}

	.ant-select-selection-item{
		font-size: ${largeSizes.fontSize.fontSize14}
	}

	.img_operator{
		width: ${largeSizes.sidebar.ImgOperator};
		height: ${largeSizes.sidebar.ImgOperator};
	}

	.font_text_title{
		font-size: ${largeSizes.fontSize.fontSize14};
	}
	
	.font_text_name{
		font-size: ${largeSizes.fontSize.fontSize18};
	}
}

@media screen and (min-width: 1539px){
	.sidebar .nav>li.expand.has-submenu-two:before {
		bottom: 100px;
	}
} 
`;