import React from 'react'

import moment from "moment";
import { myFetch, myFetchGet } from "../services/services";
import { notification } from "antd";
import ColorCircle from "../components/colorCircle";
import NormalText from "../components/text/NormalText";
import { capitalize } from '../helpers/helpers';
import DatatableHeaderText from '../components/text/datatable/datatableHeaderText';
import DatatableRowText from '../components/text/datatable/datatableRowText';

//Aprobada
const green = "#2DCE98"

//Rechazada
const red = "#F53C56"

//Pendiente
const yellow = "#F5C43C"


const getAbsenceColumns = async () => {
    try {
        const data = await myFetchGet("api/v1/absence/datatables_struct");
        const columnsTittles = Object.keys(data.columns);
        const columns = [];
        columnsTittles.map((columnsTittle) => {
            columns.push({
                title: <DatatableHeaderText value={columnsTittle} />,
                dataIndex: data.columns[columnsTittle].field,
                key: data.columns[columnsTittle].field,
                width: columnsTittle === "ID" ? "70px" : "",
                align: "left",
                sorter: columnsTittle === "ID" ? true : null,
                filters:
                    columnsTittle === 'Status' ?
                        [
                            {
                                text: "Aprobada",
                                value: "Aprobada",
                            },
                            {
                                text: "Pendiente",
                                value: "Pendiente",
                            },
                        ] : null,
                onFilter: (value, record) => record.status.indexOf(value) === 0,
                render: columnsTittle === "ID"
                    ? (value) =>
                        <span style={{ display: "flex", alignItems: "center" }}>
                            {/* <button id='table_blue_link'>{value}</button> */}
                            <DatatableRowText value={value} color='#2B80FF' bold={true} />
                        </span>

                    : columnsTittle === "Nombre del Técnico" ?
                        (value) =>
                            <span style={{ display: "flex", alignItems: "center" }}>
                                <NormalText value={capitalize(value)} />
                            </span>
                        : columnsTittle === "Estatus" ?
                            (value) =>
                                <span style={{ display: "flex", alignItems: "baseline" }}>
                                    <ColorCircle color={value === "Aprobada" ? green : value === "Pendiente" ? yellow : red} />
                                    <NormalText value={value} />
                                </span>

                            : (value) => <NormalText value={value} />

            });
        });
        return columns
    } catch (e) { console.log("error", e) }
}

const getAbsenceRows = async () => {
    try {
        const res = await myFetch("api/v1/absence/datatables/");
        if (res?.data) {
            const rows = res?.data?.map((row) => {
                const dateFormat = moment(row.created).format("DD-MM-YYYY");
                return {
                    ...row,
                    key: row.ID,
                    created: dateFormat,
                };
            });
            return rows;
        }
    } catch (e) { console.log(e) }
}

const technician = async () => {
    try {
        const res = await myFetchGet("api/v1/technician/");
        return res
    } catch (e) { console.log(e) }
}
const hoursAvailability = async (currentMonth, currentYear, storedOperatorID, technicianID, goToDisponibilidad) => {
    const url = `api/v1/calendar_technician/?operator=${storedOperatorID}&month=${currentMonth}&year=${currentYear}&technician=${technicianID} `

    try {
        await myFetchGet(url);

        return {
            message: "El técnico posee horas disponibles",
            status: 200,
        }

    } catch (e) {
        notification.warning({
            style: { fontWeight: 'bold' },
            message: "El técnico no posee horas disponibles, cree una en la seccion Disponibilidad",

            placement: 'bottomLeft',
            duration: 3,
            onClose: () => goToDisponibilidad()
        })
        return {
            message: "El técnico no posee horas disponibles",
            status: e.response.status,
        }
    }
}

const getLunchAbsence = async (technicianID, operatorID) => {
    try {
        const res = await myFetchGet(`api/v1/create_absence_calendar/?technician=${technicianID}&operator=${operatorID}`);
        return res?.absences
    } catch (e) { }
}

const postLunchAbsence = async (absenceData) => {
    try {
        await myFetch(`api/v1/create_absence_calendar/`, absenceData);
        notification.success({
            style: { fontWeight: 'bold' },
            message: "Almuerzo creado",
            placement: 'bottomLeft',
            duration: 1.5,
            onClose: () => window.location.reload()
        })
    } catch (e) {
        notification.error({
            style: { fontWeight: 'bold' },
            message: 'Intentelo nuevamente',
            placement: 'bottomLeft',
            duration: 1.5,
            onClose: () => window.location.reload()
        })
    }
}


const patchLunchAbsence = async (ID, absenceData) => {
    try {
        await myFetch(`api/v1/absence/${ID}/`, absenceData, "PATCH");
        notification.success({
            style: { fontWeight: 'bold' },
            message: 'Almuerzo actualizado',
            placement: 'bottomLeft',
            duration: 1.5,
            onClose: () => window.location.reload()
        })
    } catch (e) {
        notification.error({
            style: { fontWeight: 'bold' },
            message: 'Intentelo nuevamente',
            placement: 'bottomLeft',
            duration: 1.5,
        })
    }
}
const deleteLunchAbsence = async (ID) => {
    try {
        await myFetch(`api/v1/absence/${ID}/`, "", "DELETE");
        notification.success({
            style: { fontWeight: 'bold' },
            message: 'Almuerzo borrado',
            placement: 'bottomLeft',
            duration: 1.5,
            onClose: () => window.location.reload()
        })
    } catch (e) {
        notification.error({
            style: { fontWeight: 'bold' },
            message: 'Intentelo nuevamente',
            placement: 'bottomLeft',
            duration: 1.5,
        })
    }
}



export {
    getAbsenceColumns,
    getAbsenceRows,
    technician,
    postLunchAbsence,
    getLunchAbsence,
    patchLunchAbsence,
    deleteLunchAbsence,
    hoursAvailability
} 
