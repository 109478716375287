import React, { useEffect, useState } from 'react'

import style from './scheduling.module.css'
import LabelDrawer from '../../../../../../components/text/drawer/label';
import { DatePicker, Select } from 'antd';
import { emptyInputsCheck } from '../../../../../../helpers/helpers';

import dayjs from 'dayjs';

const { Option } = Select;

const Scheduling = ({
	dateSelected,
	onDateChange,
	selectedHour,
	selectedHoursOverflow,
	onHoursChange,
	availableHours,
	selectedDates,
	rolePermissions,
	date,
	timeStart,
	timeEnd,
	startSelectedOverflow,
	endSelectedOverflow,
	visibleFormOverflow,
	camposIncompletos
}) => {

	const [formattedDate, setFormattedDate] = useState('');

	useEffect(() => {
		if (date) {
			const dateString = dayjs(date, 'DD-MM-YYYY');
			const formattedDateString = dateString.format('DD [de] MMMM YYYY');
			setFormattedDate(formattedDateString);
		}

	}, [date]);
	return (
		<section className={style.container}>

			<header className={style.container_header}>
				<div className={style.circle} />
				<p className={style.text_header}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum sem libero. Morbi mollis eleifend efficitur. Morbi sed ligula semper leo maximus .</p>
			</header>


			<section className='row'>
				<div className="column flex">
					<LabelDrawer value="Fecha" />
					<DatePicker
						inputReadOnly
						placeholder="Seleccionar"
						style={{ width: "97%" }}
						format="DD-MM-YYYY"
						onChange={onDateChange}
						value={dateSelected}
						status={emptyInputsCheck(camposIncompletos, "disponibility_hours") ? "error" : ""}
						// Se habilita fechas por disponibilidad
						disabledDate={(current) => {
							return selectedDates.indexOf(current.format('YYYY-MM-DD')) === -1;
						}}
					/>
				</div>

				<div className="column flex">
					<LabelDrawer value="Horario" />
					<Select
						onChange={onHoursChange}
						placeholder="Seleccionar"
						value={selectedHoursOverflow === null ? selectedHour : selectedHoursOverflow}
						status={emptyInputsCheck(camposIncompletos, "disponibility_hours") ? "error" : ""}
					>
						{availableHours?.map((item, i) =>
							<Option key={i} value={JSON.stringify(item)}>
								{item.hours}
							</Option>)
						}
						{availableHours?.length > 0 && rolePermissions === true
							? <Option style={{ color: '#2B80FF', fontWeight: 'bold' }} value={'overflow'}>Agendar sobre cupo</Option>
							: null
						}

					</Select>
				</div>
			</section>

			{(selectedHour || selectedHoursOverflow) && !visibleFormOverflow && (
				<div className={style.container_summary}>
					<label className={`${style.font_12} ${style.text_summary}`}>Fecha: <label className={style.color2B80FF}>{formattedDate}</label></label>
					<label className={`${style.font_12} ${style.text_summary}`}>Horario: <label className={style.number_summary}>{timeStart || startSelectedOverflow}</label></label>
					<label className={`${style.font_12} ${style.text_summary}`}>Tiempo estimado de culminación: <label className={style.number_summary}>{timeEnd || endSelectedOverflow}</label></label>
				</div>
			)}


		</section>
	)
}

export default Scheduling