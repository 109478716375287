import React from 'react'
import DatatableHeaderText from '../components/text/datatable/datatableHeaderText';
import { myFetch, myFetchGet } from '../services/services';
import DatatableRowText from '../components/text/datatable/datatableRowText';
import ColorCircle from '../components/colorCircle';


const getDatatables_structColumns = async () => {
	try {
		const data = await myFetchGet("api/v1/wo/datatables_struct/");
		const columns = []
		const columnsTittles = Object.keys(data.columns);

		columnsTittles.forEach((columnsTittle) => {
			columns.push({
				sorter: columnsTittle === "ID" ? true : null,
				width:
					columnsTittle === "ID" ? "70px"
						: "",
				dataIndex: data.columns[columnsTittle].field,
				key: data.columns[columnsTittle].field,
				align: 'left',
				title: <DatatableHeaderText value={columnsTittle} />,
				filterMultiple: false,
				filters:
					columnsTittle === 'Estatus' ?
						[
							{
								text: "Agendada",
								value: 0,
							},
							{
								text: "En desplazamiento",
								value: 1,
							},
							{
								text: "En atendimiento",
								value: 2,
							},
							{
								text: "Pausada",
								value: 3,
							},
							{
								text: "Cancelada",
								value: 4,
							},
							{
								text: "Por reagendar",
								value: 5,
							},
							{
								text: "Finalizada",
								value: 6,
							},
						] : null,
				render: columnsTittle === "ID"
					? (value) => <span style={{ display: "flex", alignItems: "center" }}>
						<DatatableRowText value={value} color='#2B80FF' bold={true} />

					</span>
					: columnsTittle === "Creación" ?
						(value) =>
							<span style={{ display: "flex", alignItems: "center" }}>
								<DatatableRowText value={value} />
							</span>
						: columnsTittle === "Agendamiento" ?
							(value) =>
								<span style={{ display: "flex", alignItems: "center" }}>
									<DatatableRowText value={value} />
								</span>
							: columnsTittle === "Tipo" ?
								(value) =>
									<span style={{ display: "flex", alignItems: "baseline" }}>
										<ColorCircle color={value?.color} />
										<DatatableRowText value={value?.name} />
									</span>
								: columnsTittle === "Técnico" ?
									(value) =>
										<span style={{ display: "flex", alignItems: "center" }}>
											<DatatableRowText value={value?.full_name} />
										</span>
									: columnsTittle === "Estatus" ?
										(value) =>
											<span style={{ display: "flex", alignItems: "center" }}>
												<DatatableRowText value={value} color='#5A607F' />
											</span>
										: (value) =>
											<span style={{ display: "flex", alignItems: "center" }}>
												<DatatableRowText value={value} />
											</span>
			})
		})

		return columns;

	} catch (error) { }
}

const datatableOW = async (newFilters) => {
	try {
		const response = myFetch(`api/v1/wo/datatables/`, newFilters)
		return response
	} catch (error) { }
}

export { getDatatables_structColumns, datatableOW }