import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { selectTab } from '../../store/tab/tabSlice';
import style from './workOrdersDetails.module.css'
import { Panel, PanelHeader } from '../../components/panel/panel';
import { Tooltip } from 'antd';
import { TabsPanel } from '../../components/tabs/Tabs';
import GeneralPanel from './components/generalPanel/GeneralPanel';
import DisplacementPanel from './components/displacementPanel/DisplacementPanel';
import GalleryPanel from './components/galleryPanel/GalleryPanel';
import QuestionsPanel from './components/questionsPanel/QuestionsPanel';
import HistoricalPanel from './components/historicalPanel/HistoricalPanel';
import PanelHeaderV2 from '../../components/panelHeader/PanelHeaderV2';
import FormCopyOrderOt from './components/formCopyOrderOt/FormCopyOrderOt';

const WorkOrdersDetails = () => {

	const [viewForm, setViewForm] = useState(false);
	const [viewReschedule, setViewReschedule] = useState(false)

	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const selectedTab = useSelector((state) => state?.tabs?.selectedTab);

	const { ID } = location?.state?.params;

	const handleTabChange = (index) => {
		dispatch(
			selectTab({
				selectedTab: index,
				showTabs: true,
			})
		)
	}

	const tabs = [
		{
			name: "General", component: <GeneralPanel data={location?.state?.params}
				setViewReschedule={setViewReschedule}
				viewReschedule={viewReschedule}
			/>
		},
		{ name: "Desplazamiento", component: <DisplacementPanel data={location?.state?.params} /> },
		{ name: "Galería", component: <GalleryPanel data={location?.state?.params} /> },
		{ name: "Preguntas", component: <QuestionsPanel data={location?.state?.params} /> },
		{ name: "Histórico", component: <HistoricalPanel data={location?.state?.params} /> },
	];

	const tooltipText = (
		<section className={style.container_tooltip}>
			<span className={style.tittle_tooltip}>Información de ayuda</span>
			<span className={style.description_tooltip}>Ficha con datos generales asociados al tipo de orden de trabajo</span>
		</section>
	)

	const handelCopyOrderOt = () => {
		setViewForm(true);
	}

	return (
		<Panel>
			<PanelHeaderV2
				title={`Orden de trabajo # ${ID}`}
				subText={"Orden de trabajo | Listado de órdenes | Detalle"}
				buttonOrderOt={true}
				handlerClickHeader={handelCopyOrderOt}
			>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement='bottom'
					overlayInnerStyle={{
						height: "95px",
						maxHeight: '100px'
					}}
				>
					<i className="fa-solid fa-circle-info circle-tooltip"></i>
				</Tooltip>


			</PanelHeaderV2>

			<TabsPanel selectedTabClassName="is-selected">
				{!viewReschedule && (
					<span className="tablist_type_order">
						{tabs?.map((tab, index) => (
							<span key={index} className={`Tab font-size-tab ${selectedTab === index ? "is-selected" : ""}`} onClick={() => handleTabChange(index)}>{tab?.name}</span>
						))}
					</span>

				)}
				{tabs[selectedTab]?.component}
			</TabsPanel>

			{viewForm && (
				<FormCopyOrderOt setViewForm={setViewForm} data={location?.state?.params} />
			)}
		</Panel>
	)
}

export default WorkOrdersDetails