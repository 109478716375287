/* eslint-disable */
import React, { useState } from 'react'
import { Input, Select, Button, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import validator from 'validator';

// api
import { onCreateNewUser } from '../../../api/users';

// helpers
import { onlyEmail, onlyNumber, onlyText } from '../../../helpers/helpers';

// custom
import Br from '../../../components/br/Br';
import Form from '../../../components/form/index.jsx';
import CustomModal from '../../../components/modal/CustomModal';
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue';
import LabelDrawer from '../../../components/text/drawer/label';
import MainTitle from '../../../components/text/drawer/mainTitle';
import './index.scss';
import BottomSection from '../../../components/form/bottomSection';
import ErrorMsg from '../../../components/text/notificationText/ErrorMsg';

export default function FormCreateUser({ setIsVisibleForm, groups, loginOperadorID }) {
	const [name, setName] = useState("")
	const [nameError, setNameError] = useState("")

	const [surname, setSurname] = useState("")
	const [surnameError, setSurNameError] = useState("")

	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState("")

	const [pass, setPass] = useState("")
	const [passConfirm, setPassConfirm] = useState("")
	const [passConfirmError, setPassConfirmError] = useState("")

	const [showPass, setShowPass] = useState(false)
	const [showPassConfirm, setShowPassConfirm] = useState(false)
	const [rol, setRol] = useState(null)

	const [phone, setPhone] = useState("")
	const [phoneError, setPhoneError] = useState("")

	const [inputStatus, setInputStatus] = useState({
		input1: '',
		input2: '',
		input3: '',
		input4: '',
		input5: '',
		input6: '',
	});

	const onBlurPassConfirm = () => {
		if (pass !== passConfirm) {
			setPassConfirmError('Las contraseñas no coinciden');
		} else {
			setPassConfirmError('')
		}
	}


	const onPassChange = (e) => setPass(e.target.value);
	const onConfirPassChange = (e) => setPassConfirm(e.target.value)

	const handleChange = (value) => setRol(value)
	const onModalClose = () => setIsVisibleForm(false)

	const onNameChange = (e) => {
		if (onlyText(e.target.value)) {
			setName(e.target.value);
			setNameError("")
		} else {
			// setName("");
			setNameError('No Esta permitido Carácteres especiales');
		}
	}
	const onSurnNameChange = (e) => {
		if (onlyText(e.target.value)) {
			setSurname(e.target.value);
			setSurNameError("")
		} else {
			// setSurname("")
			setSurNameError('No Esta permitido Carácteres especiales');
		}
	}

	const phoneOnchange = (e) => {
		if (onlyNumber(e.target.value)) {
			setPhoneError('')
			setPhone(e.target.value)
		} else {
			setPhoneError("Ingresa solo números")
			// setPhone("")
		}
	}

	const validateEmail = () => {
		if (!validator.isEmail(email)) {
			setEmailError('Ingresa un correo electrónico válido');
		} else if (email.split('@').length !== 2) {
			setEmailError('El correo electrónico debe contener solo un símbolo "@"');
		} else {
			setEmailError('');
		}
	};

	let data = {
		"password": pass,
		"email": email,
		"name": name,
		"last_name": surname,
		"phone": phone,
		"status": 0,
		"operator": loginOperadorID
	}

	if (rol) {
		data["groups"] = [rol]
	}

	const sendNewUser = () => {
		if (pass === '' || email === '' || name === '' || surname === '' || phone === '' || passConfirm === '') {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Completa todos los campos',
				placement: 'bottomLeft',
				duration: 2,
				// onClose: () => {
				//     window.location.reload();
				// }
			})

			setInputStatus({
				input1: pass === '' ? 'error' : '',
				input2: email === '' ? 'error' : '',
				input3: name === '' ? 'error' : '',
				input4: surname === '' ? 'error' : '',
				input5: phone === '' ? 'error' : '',
				input6: passConfirm === '' ? 'error' : '',
			});

		} else {
			// Todos los campos están completos, continúa con la lógica de tu aplicación...
			onCreateNewUser(data)
		}

	}

	return (
		<CustomModal onClick={onModalClose}>
			<Form>
				<MainTitle value='Agregar registro de usuario' onClick={onModalClose} />
				<SubtitleBlue value="Información personal" />

				<span className="space-between">
					<div className="createUser_left">
						<LabelDrawer value="Nombre" />
						<Input
							className='input_form_users'
							onChange={onNameChange}
							placeholder='Nombre'
							value={name}
							style={{ height: 40 }}
							status={inputStatus?.input3}
							maxLength={15}
						/>
					</div>
					<div className="createUser_right">
						<LabelDrawer value="Apellido" />
						<Input
							className='input_form_users'
							onChange={onSurnNameChange}
							placeholder='Apellido'
							value={surname}
							style={{ height: 40 }}
							status={inputStatus.input4}
							maxLength={15}
						/>
					</div>
				</span>

				{/* ------------------errors------------------ */}
				<span className="space-between">
					<div className="createUser_left">{nameError && <span className='error__message'>{nameError}</span>}</div>
					<div className="createUser_right">{surnameError && <span className='error__message'>{surnameError}</span>}</div>
				</span>
				{/* ------------------errors------------------ */}

				<Br />
				<span>
					<LabelDrawer value="Número de Teléfono" />
					<Input
						className='input_form_users'
						value={phone}
						onChange={phoneOnchange}
						placeholder='Teléfono'
						style={{ height: 40 }}
						status={inputStatus?.input5}
					/>
					{phoneError && <ErrorMsg value={phoneError} />}
				</span>


				<Br />

				<SubtitleBlue value="Autenticación de usuario" />
				<span>
					<LabelDrawer value="Correo electrónico" />
					<Input
						className='input_form_users'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						onBlur={validateEmail}
						placeholder="correo@example.com"
						style={{ height: 40 }}
						maxLength={40}
						status={inputStatus?.input2}
					/>

					{emailError && <ErrorMsg value={emailError} />}
				</span>


				<Br />

				<span className="space-between">
					<div className="createUser_left">
						<LabelDrawer value="Contraseña" />
						<Input.Password
							className='input_form_users'
							iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							onChange={onPassChange}
							style={{ minHeight: 40 }}
							status={inputStatus.input1}
							maxLength={20}
							minLength={8}
							autoComplete='false'
						/>
					</div>
					<div className="createUser_right">
						<LabelDrawer value="Confirmar contraseña" />
						<Input.Password
							className='input_form_users'
							iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							onChange={onConfirPassChange}
							onBlur={onBlurPassConfirm}
							style={{ height: 40 }}
							status={
								passConfirmError || inputStatus.input6 ? "error" : ""}
							maxLength={20}
							minLength={8}
						/>

					</div>
				</span>
				<span className="space-between">
					<div className="createUser_left"></div>
					<div className="createUser_right">{passConfirmError && <ErrorMsg value={passConfirmError} />}</div>
				</span>

				<Br />

				<SubtitleBlue value="Permisos y accesos" />

				<LabelDrawer value="Rol de usuario" />
				<Select
					style={{ width: '100%', height: 40 }}
					placeholder="Seleccionar"
					onChange={handleChange}
				>
					{
						groups?.map((item) => (
							<Select.Option key={item?.ID} value={item.ID}>{item?.name}</Select.Option>
						))
					}
				</Select>

				<Br />

				<BottomSection onCancel={onModalClose} onAcept={sendNewUser} />
			</Form>
		</CustomModal>
	)
}

