import React from 'react'
import "./index.scss"

const LabelDrawer = ({ value="", required = true }) => (
    <p className='labelDrawer__text'>
        {value} <b className="blue__asterisc" >{required === true ? "*" : ""} </b>
    </p>
)


export default LabelDrawer