import React, { useRef, useState } from 'react'
import Form from '../../../../components/form/index.jsx'
import { updateDocument } from '../../../../api/technician'
import { Input, Popconfirm } from 'antd'
import MainTitle from '../../../../components/text/drawer/mainTitle'
import LabelDrawer from '../../../../components/text/drawer/label'
import Br from '../../../../components/br/Br'
import styled from 'styled-components'
import color from '../../../../helpers/color'
import BottomSection from '../../../../components/form/bottomSection/index.jsx'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../helpers/sizes.js'

const EditDocumentForm = ({ closeEditDocumentForm, selected }) => {
	const [caption, setCaption] = useState(selected?.caption)
	const [photo, setTempPhoto] = useState(selected?.photo)
	const [base64Img, setBase64Img] = useState(null)

	const hidden = { display: "none" }
	const inputFile = useRef()
	const documentID = selected?.ID

	const clearTempImage = () => {
		setTempPhoto(null)
		setBase64Img(null)
	}

	const passingToBase64 = (archivos) => {
		setTempPhoto(archivos[0])
		Array.from(archivos).forEach(archivo => {

			const reader = new FileReader();
			reader.readAsDataURL(archivo);
			reader.onload = function () {
				let arraySplited = []
				const base64 = reader.result;

				arraySplited = base64.split(',');
				setBase64Img(arraySplited[1])
			}
		})
	}

	const postingData = () => {
		const postData = { "caption": caption, "photo_base64": base64Img }
		updateDocument(documentID, postData)
	}

	return (
		<Form>
			<Container>
				<MainTitle value='Subir archivo' onClick={closeEditDocumentForm} />

				<button
					className="uploader_box"
					onClick={() => inputFile.current.click()}
				>
					<i className="fa-solid fa-upload icon_size_button" />
					<span className='subtitle_button'>Haga click o arrastre el archivo a esta área para cargarlo</span>
					<span className='help_button' >Soporte para una carga única o masiva. Prohibido estrictamente</span>
					<span className='help_button' >cargar datos de la empresa u otros archivos de bandas.</span>
					<input type="file" ref={inputFile} onChange={(e) => passingToBase64(e.target.files)} style={hidden} />
				</button>

				{base64Img && <Br />}

				{
					base64Img &&
					<div style={{ display: base64Img === null ? "none" : "flex" }} className='temp_image_box'>
						<img className='temp_image animate__animated animate__fadeIn' src={`data:image/png;base64,${base64Img}`} alt="Temporal selected base64" />
						<p className="animate__animated animate__fadeIn temp_name">{photo?.name}</p>
						<Popconfirm
							placement="topRight"
							icon={""}
							title="¿Eliminar fotografía?"
							description={() => {
								return (
									`Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes`)
							}}
							onConfirm={clearTempImage}
							okText="Eliminar"
							cancelText="Cancelar"
						>
							<i className="fa-solid fa-trash clear_tempImageIcon" ></i>
						</Popconfirm>
					</div>
				}

				<Br />
				<Br />

				<div className="column">
					<LabelDrawer value="Descripciòn" />
					<Input
						className="input"
						style={{ height: "40px" }}
						onChange={(e) => setCaption(e?.target?.value)}
						placeholder={caption}
						value={caption}
					/>
				</div>
				<Br />
				<Br />

				<BottomSection onCancel={closeEditDocumentForm} onAcept={() => postingData()} />
				{/* <div className="bottom_section">
                    <button className="btn_normal" onClick={closeEditDocumentForm}>Cancelar</button>
                    <button className="btn_silver"
                        onClick={() => postingData()}
                    >Guardar</button> */}
				{/* </div> */}
			</Container>
		</Form>
	)
}

export default EditDocumentForm

const Container = styled.div`
background: white;

p{display: inline;margin: inherit}

.row {
	margin: inherit;
	display: flex;
	align-items: center;
}

.column {
	display: flex;
	flex-flow: column;
}
.align-item-center{
    align-items: center;
}
.row-center{
    justify-content: center;
}
.flex-end{
    justify-content: flex-end;
}
.space-between{
    justify-content: space-between;
}
.column_center{
    justify-content: center;
}

.subtitle_button{
    font-size: ${smallSizes.fontSize.fontSize14};
    font-weight: bold;
    color: #2B80FF;
    margin-bottom: 1em;
}

.blue{color: #2B80FF}

.input{
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 4px 11px 4px;
    margin-top: 8px;
  
    :focus{
      border: 1px solid #40a9ff;
    }
    ::placeholder {
      font-weight: bold;
      opacity: 0.5;
    }
}

.uploader_box {
	display: flex;
	flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: none;
  outline: none;
  border-radius: 3px;
  border: 1px dashed silver;
  background: #fafafa;
  padding: 1em;
  cursor: pointer;

  :hover {border: 1px dashed #d9d9d9}
}

.temp_image_box {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  padding: 1em;
  display: flex;
  align-items: center;
}
.temp_image {
  width: 116px;
  height: 76px;
  border-radius: 3px;
  object-fit: cover;
}
.clear_tempImageIcon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #2B80FF;
  border-radius: 2px;
  cursor: pointer;

  :hover {color: grey}
}

.bottom_section{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    flex: 1;
}

  .bottom_section .btn_normal {
    background: transparent;
    z-index: 10;
    border: none;
    outline: none;
    width: 83px;
    height: 34px;
    border: 1px solid #BFCCDA;
    border-radius: 3px;
    cursor: pointer;

    :hover {
        background: silver;
    }
}


.bottom_section .btn_silver {
    border: none;
    outline: none;
    color: #001737;
    z-index: 10;
    width: 83px;
    height: 34px;
    background: #DDE4EB;
    border: 1px solid #0060FF;
    border-radius: 3px;
    cursor: pointer;
}
.bottom_section .btn_silver:hover {
    background: #2B80FF;
    color: white;
    }

.uploader_box .icon_size_button{
	font-size: ${smallSizes.fontSize.fontSize36};
	color: #DDE4EB;
	margin-bottom: 10px;
}

.uploader_box .help_button{
	color: silver;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.temp_image_box .temp_name{
	color: #5A607F;
	padding: 0 10px;
	flex: 1;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.input{
	font-size: ${smallSizes.fontSize.fontSize12};
}

//------------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.subtitle_button{
    font-size: ${smallSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${smallSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.mediumDesk}){
	.subtitle_button{
    font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${mediumSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.largeDesk}){
	.subtitle_button{
    font-size: ${largeSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${largeSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`

