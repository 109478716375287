import React from 'react'
import { myFetch, myFetchGet } from "../services/services";
import DatatableRowText from "../components/text/datatable/datatableRowText";
import ColorCircle from "../components/colorCircle";
import DatatableHeaderText from "../components/text/datatable/datatableHeaderText";
import { notification } from 'antd';
import BlueButton from '../components/buttons/blueButton';
import dayjs from 'dayjs';

const datatableStruct = async () => {
	try {
		const data = await myFetchGet(`api/v1/type_ot/datatables_struct/`)

		const columnsTittles = Object.keys(data.columns);

		const columns = [];
		columnsTittles.forEach((columnsTittle) => {
			columns.push({
				sorter: columnsTittle === "ID" ? true : null,
				width: columnsTittle === "ID" ? "50px" : "",
				title: <DatatableHeaderText value={columnsTittle} />,
				dataIndex: data.columns[columnsTittle].field,
				align: "left",
				key: data.columns[columnsTittle].field,
				render: columnsTittle === "ID"
					? (value) =>
						<span style={{ display: "flex", alignItems: "center" }}>
							<DatatableRowText value={value} />
						</span>

					: columnsTittle === "Nombre de la orden de trabajo" ?
						(value) =>
							<span style={{ display: "flex", alignItems: "center" }}>
								<DatatableRowText color="#2B80FF" value={value} bold={true} />
							</span>

						: columnsTittle === "Color" ?
							(value) =>
								<span style={{ display: "flex", alignItems: "baseline" }}>
									<ColorCircle color={value} />
									<DatatableRowText value={value} />
								</span>

							: (value) =>
								<span style={{ display: "flex", alignItems: "center" }}>
									<DatatableRowText value={value} />
								</span>
			})
		})
		return columns
	} catch (error) { }
}

const deleteOTtype = async (id) => {
	try {
		await myFetch(`api/v1/type_ot/${id}/`, "", "DELETE");
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'El registro se ha borrado con éxito',
			placement: 'bottomLeft',
			onClose: () => window.location.reload()
		});
	} catch (error) { }
}

const createType = async (data) => {
	try {
		await myFetch("api/v1/type_ot/", data)
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Creado con Exito!',
			placement: 'bottomLeft',
			onClose: () => window.location.reload()
		})
	} catch (error) { }
}

const datatableStructCategory = async () => {
	try {
		const data = await myFetchGet(`api/v1/category_ot/datatables_struct/`)
		const columnsTittles = Object.keys(data.columns);
		const columns = [];

		columnsTittles.forEach((columnsTittle) => {

			columns.push({
				title: <DatatableHeaderText value={columnsTittle} />,
				dataIndex: data.columns[columnsTittle].field,
				align: "left",
				key: data.columns[columnsTittle].field,
				...((columnsTittle) => {
					if (columnsTittle === "Categoría") {
						return {
							sorter: (a, b) => a.name.localeCompare(b.name)
						};
					}
					return {};
				})(columnsTittle),
				sorterTooltip: 'Haz clic para ordenar por nombre',
				width:
					columnsTittle === "ID" ? "80px"
						: columnsTittle === "Categoría" ? "200px"
							: columnsTittle === "Duración" ? "100px"
								: "",

				render: columnsTittle === "ID"
					? (value) => <DatatableRowText value={value} />

					: columnsTittle === "Categoría" ?
						(value) =>
							<span style={{ display: "flex", alignItems: "center" }}>
								<DatatableRowText color='#2B80FF' bold={true} value={value} />
							</span>

						: columnsTittle === "Duración" ?
							(value) =>
								<span style={{ display: "flex", alignItems: "center" }}>
									<DatatableRowText value={value} />
									<DatatableRowText value="min." />
								</span>

							: columnsTittle === "Imágenes a solicitar" ?
								(value) => value.map(item => {
									return (
										<span key={Math.random()} style={{ display: "flex", alignItems: "center" }}>
											<DatatableRowText value={item.img_name} />
										</span>
									)
								})

								: columnsTittle === "Preguntas" ?
									(value) => value.map(item => {
										return (
											<span key={Math.random()} style={{ height: 30, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
												<DatatableRowText value={item.question} />
												<DatatableRowText value={item.kind === 1 ? "(Si y No)" : "(Abierta)"} />
											</span>
										)
									})


									: (value) =>

										<span style={{ display: "flex", alignItems: "center" }}>
											<DatatableRowText value={value} />
										</span>
			})
		})
		return columns
	} catch (error) { }
}

const datatableDataCategory = async (idUnico, idOperator) => {
	const filter = {
		// "type_ot": idUnico,
		"offset": 10,
		"start": 0,
		"order_type": 'desc',
		"operator": idOperator,
		"filters": `[[\"type_ot\",\"equal\",\"${idUnico}\"]]`
	}
	try {
		const response = await myFetch("api/v1/category_ot/datatables/", filter)
		const rows = response.data.map(row => {
			const dateFormat = dayjs(row.created).format("DD-MM-YYYY")

			return {
				...row,
				key: row.ID,
				imgs: row.imgs.value,
				questions: row.questions.value,
				created: dateFormat
			}
		})

		return rows
	} catch (error) { }
}

const updateType = async (id, data, goToList) => {
	try {
		await myFetch(`api/v1/type_ot/${id}/`, data, "PATCH")
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Editado con éxito!',
			placement: 'bottomLeft',
			onClose: () => goToList()
		});
	} catch (error) {
		console.log(error)
		console.log(error.response.data.message)
		if (error.response.data.message === " Asegúrese de que este campo no tenga más de 20 caracteres. " ||
			"  Asegúrese de que este campo no tenga más de 20 caracteres. Asegúrese de que este campo no tenga más de 20 caracteres. ") {
			notification.error({
				style: { fontWeight: 'bold' },
				message: ' Asegúrese de que este campo no tenga más de 20 caracteres',
				placement: 'bottomLeft',
			});
		}

	}
}

const createCategoryOt = async (data) => {
	try {
		await myFetch("api/v1/category_ot/", data)
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Creado con éxito!',
			placement: 'bottomLeft',
			duration: 1,
			onClose: () => window.location.reload()
		})
	} catch (error) { }
}

const updateCategoryOt = async (id, data) => {
	try {
		await myFetch(`api/v1/category_ot/${id}/`, data, "PATCH")
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Creado con éxito!',
			placement: 'bottomLeft',
			duration: 1,
			onClose: () => window.location.reload()
		})
	} catch (error) {

	}
}

const getHistoryOtType = async (id) => {
	try {
		return await myFetchGet(`api/v1/type_ot/${id}/history`)
	} catch (error) { }
}

export {
	datatableStruct,
	deleteOTtype,
	createType,
	datatableStructCategory,
	datatableDataCategory,
	updateType,
	createCategoryOt,
	updateCategoryOt,
	getHistoryOtType
}