import dayjs from "dayjs";
import moment from "moment"

export const today = dayjs().format('DD-MM-YYYY');

export const validateLettersAndNumbers = (value) => {
	// No se permite caracteres especiales
	const regex = /^[a-zA-Z0-9]+$/;
	return regex.test(value);
}
export const onlyNumber = (value) => {
	const regex = /^[0-9]+$/
	return regex.test(value);
}

export const onlyText = (value) => {
	// const regex = /^[a-zA-ZñÑ\s]+$/
	const regex = /^[a-zA-ZáéíóúÁÉÍÓÚ]+(?:\s[a-zA-ZáéíóúÁÉÍÓÚ]+)*$/
	return regex.test(value);
}
export const onlyTextAndSpace = (value) => {
	const regex = /^[A-Za-z\s]*$/;
	return regex.test(value) || value === '';
}

export const onlyEmail = (value) => {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return regex.test(value);
}

export const size = {
	mobile: '425px',
	tablet: '768px',
	desktop: '1024px'
}

export const device = {
	mobile: `(min-width: ${size.mobile})`,
	tablet: `(min-width: ${size.tablet})`,
	desktop: `(min-width: ${size.desktop})`
}

export const onlyTextAndNumber = (value) => {
	const regex = /^[a-zA-Z\s]+$/;
	return regex.test(value);
}

export const dateFormat = (value) => moment(value).format("DD-MM-YYYY")

// Retorna la primer letra Capitalizada, de cualquier string
export const stringCapitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const setStorage = (key, value) => localStorage.setItem(key, value);

// Max. y Min. de horas disponibles.
export const minStartTime = moment('07:00', 'HH:mm');
export const maxEndTime = moment('18:00', 'HH:mm');
export const format = "DD-MM-YYYY";
export const hourFormat = 'HH:mm';


// Chequear permisos de rol
export const permissionCheck = (array, value) => {
	return array?.includes(value)
}
export const emptyInputsCheck = (array, value) => {
	return array?.includes(value)
}

// calendar getter
export const eventStyleGetter = (event) => {
	let style = {
		backgroundColor: event.color,
		borderRadius: 0,
		color: 'white',
		border: 0,
		cursor: 'pointer',
	};
	if (event.type === 'current-date-line') {
		style = {
			...style,
			className: "custom__line",
		};
	}
	return {
		style,
	};
};

// TimePicker max - min time
export const minTime = new Date();
minTime.setHours(7, 0, 0); // set minimum time to 7am

export const maxTime = new Date();
maxTime.setHours(19, 0, 0); // set maximum time to 7pm

// if (endDate) {
// 	habilitationData["end_date"] = endDate
// }

export const disabledHours = () => {
	const hours = [];
	for (let i = 0; i < 24; i++) {
		if (i < minStartTime.hour() || i > maxEndTime.hour()) {
			hours.push(i);
		}
	}
	return hours;
};


// deshabilitar dias anteriores a la actual.
export const disableBeforeDate = (current) => {
	// Deshabilitar fechas anteriores a la fecha actual
	return current && current.isBefore(dayjs().startOf('day'));
};
export const disabledAfterDate = (current) => {
	// Deshabilitar fechas posteriores a la fecha actual
	return current && current.isAfter(dayjs().startOf('day'));
};
export const validarCampos = (data) => {
	return Object
		.values(data)
		.every(value => value !== null && value !== undefined && value !== "" && value.length !== 0);
}


export function checkEmptyKeys(data) {
	const keysWithEmptyValues = Object.keys(data).filter(key => {
		const value = data[key];
		return value === undefined || value === null || value === "" || value.length === 0;
	});
	return keysWithEmptyValues
}

export const emptyKeySCheck = (array, value) => {
	return array?.includes(value)
}

export const capitalize = (str) => {
	const words = str.split(' ');
	const capitalizedWords = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});
	return capitalizedWords.join(' ');
}

export const nonEqualArrys = (array1, array2) => {
	const _ = require('lodash');

	if (_.isEqual(array1, array2)) {
		return true
	} else {
		return false
	}
}