import React from 'react'
import './index.scss'

const DatatableRowText = ({ value = "", bold = false, color = "#172B4D", marginBottom = "10px" }) => {
	return (
		<span className='datatableRowText'
			style={{
				fontWeight: bold === true ? "bold" : "400",
				color,
				marginBottom: marginBottom
			}}
		>{value}</span>
	)
}

export default DatatableRowText