import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { myFetchGet } from '../../../../../../services/services'
import { prefixesCountry, validateDocument, validateEmail, validateName, validatePhone } from '../../../../../../helpers/helperOT'

import style from './clientData.module.css'
import LabelDrawer from '../../../../../../components/text/drawer/label'
import { Input, Select } from 'antd'
import { emptyInputsCheck } from '../../../../../../helpers/helpers'

const ClientData = ({
	typeClient,
	setSelectedName,
	selectedName,
	setSelectedLastName,
	selectedLastName,
	setSelectedBusinessName,
	selectedBusinessName,
	setSelectedTypeDocument,
	selectedTypeDocument,
	setSelectedDocument,
	selectedDocument,
	setSelectedNumber,
	selectedNumber,
	setSelectedEmail,
	selectedEmail,
	setSelectedServiceID,
	selectedServiceID,
	setTypeCountryString,
	typeCountryString,
	setPrefixes,
	prefixes,
	setIsValidName,
	isValidName,
	setIsValidLastName,
	isValidLastName,
	setIsValidBusinessName,
	isValidBusinessName,
	setIsValidEmail,
	isValidEmail,
	setIsValidPhone,
	isValidPhone,
	setIsValid,
	isValid,
	camposIncompletos
}) => {

	const [typeCountry, setTypeCountry] = useState([])
	const [isoCountry, setIsoCountry] = useState(null)

	const [documentString, setDocumentString] = useState(null)

	const [textName, setTextName] = useState(null)
	const [textLastName, setTextLastName] = useState(null)
	const [textBusinesstName, setTextBusinesstName] = useState(null)
	const [emailInput, setEmailInput] = useState(null)
	const [phoneInput, setPhoneInput] = useState(null)



	const countryID = useSelector((state) => state.authState.operator_detail.country);

	const isoLower = isoCountry && isoCountry.toLowerCase()

	const handlerInputName = (e) => {
		setSelectedName(e.target.value)
		setTextName(e.target.value)
	}

	const handlerInputLastName = (e) => {
		setSelectedLastName(e.target.value)
		setTextLastName(e.target.value)
	}

	const handlerBusinessName = (e) => {
		setSelectedBusinessName(e.target.value)
		setTextBusinesstName(e.target.value)
	}

	const onTypeDocument = async () => {
		try {
			const response = await myFetchGet(`api/v1/choices_by_country/?country=${countryID}`)
			setTypeCountry(response.data)
			setIsoCountry(response.iso)
		} catch (error) { }
	}

	const handlerSelectTypeDocument = (value) => {
		const { name } = typeCountry.find(item => item.ID === value)
		setTypeCountryString(name.toUpperCase())
		setSelectedTypeDocument(value)
	}

	const handlerInputDocument = (e) => {
		setDocumentString(e.target.value)
		setSelectedDocument(e.target.value)
	}

	const prefixElement = (
		<img
			className='img-phone'
			src={`https://flagcdn.com/20x15/${isoLower}.png`}
			alt=""
		/>
	);

	const handlerInputNumber = (e) => {
		setSelectedNumber(e.target.value)
		setPhoneInput(e.target.value)
	}

	const handlerInputEmail = (e) => {
		setSelectedEmail(e.target.value)
		setEmailInput(e.target.value)
	}

	const handlerInputServiceID = (e) => {
		setSelectedServiceID(e.target.value)
	}


	useEffect(() => {
		countryID && onTypeDocument()
	}, [])

	useEffect(() => {
		const timeOut = setTimeout(() => {
			if (documentString && typeCountryString) {
				const valid = validateDocument(isoCountry, typeCountryString, documentString)
				setIsValid(valid)
			}
		}, 1000)

		return () => clearTimeout(timeOut);
	}, [documentString])

	useEffect(() => {
		isoCountry && setPrefixes(prefixesCountry(isoCountry))
	}, [isoCountry])

	useEffect(() => {
		const timeOut = setTimeout(() => {
			if (textName) {
				const valid = validateName(textName)
				setIsValidName(valid)
			}
			if (textLastName) {
				const valid = validateName(textLastName)
				setIsValidLastName(valid)
			}
			if (textBusinesstName) {
				const valid = validateName(textBusinesstName)
				setIsValidBusinessName(valid)
			}
			if (phoneInput) {
				const valid = validatePhone(phoneInput)
				setIsValidPhone(valid)
			}
			if (emailInput) {
				const valid = validateEmail(emailInput)
				setIsValidEmail(valid)
			}

		}, 500)

		return () => clearTimeout(timeOut);
	}, [textName, textLastName, textBusinesstName, phoneInput, emailInput])

	useEffect(() => {
		if (typeCountry.length > 0) {
			const { name } = typeCountry.find(item => item.ID === selectedTypeDocument)
			setTypeCountryString(name.toUpperCase())
		}
	}, [typeCountry])


	useEffect(() => {
		if (typeClient === '1') {
			setSelectedBusinessName(null)
			setTextBusinesstName(null)
		} else {
			setSelectedName(null)
			setTextName(null)
			setSelectedLastName(null)
			setTextLastName(null)
		}
	}, [typeClient])

	return (
		<section className={style.container}>

			{typeClient === '1' ? (
				<section className={style.container_separate}>
					<div className={style.subcontainer_separate}>
						<LabelDrawer value='Nombre' />
						<Input
							style={{ height: 40 }}
							placeholder='Ingrese el Nombre'
							onChange={handlerInputName}
							value={selectedName}
							status={emptyInputsCheck(camposIncompletos, 'name') ? 'error' : ''}
						/>
						{isValidName === false && <span className={style.message_error}>Ingresa solo texto, sin números</span>}
					</div>

					<div className={style.subcontainer_separate}>
						<LabelDrawer value='Apellido' />
						<Input
							style={{ height: 40 }}
							placeholder='Ingrese el Apellido'
							onChange={handlerInputLastName}
							value={selectedLastName}
							status={emptyInputsCheck(camposIncompletos, 'last_name') ? 'error' : ''}
						/>
						{isValidLastName === false && <span className={style.message_error}>Ingresa solo texto, sin números</span>}
					</div>
				</section>
			) :
				(
					<>
						<div>
							<LabelDrawer value='Razón social' />
							<Input
								style={{ height: 40 }}
								placeholder='Ingrese la razón social'
								value={selectedBusinessName}
								onChange={handlerBusinessName}
								status={emptyInputsCheck(camposIncompletos, 'business_name') ? 'error' : ''}
							/>
						</div>
						{isValidBusinessName === false && <span className={style.message_error}>Ingresa solo texto, sin números</span>}
					</>
				)}


			<section className={style.container_separate}>
				<div className={style.subcontainer_separate}>
					<LabelDrawer value='Documento de identidad' />
					<span className={style.two_input}>
						<Select
							style={{ flex: 1, height: 40 }}
							placeholder='Tipo'
							options={typeCountry.map(item => (
								{ value: item.ID, label: item.name }
							))}
							onChange={handlerSelectTypeDocument}
							defaultValue={selectedTypeDocument}
							status={emptyInputsCheck(camposIncompletos, 'type_document') ? 'error' : ''}
						/>
						<Input
							style={{ height: 40, width: "67%" }}
							placeholder='Ingrese el Documento'
							onChange={handlerInputDocument}
							value={selectedDocument}
							status={emptyInputsCheck(camposIncompletos, 'number_document') ? 'error' : ''}
						/>

					</span>
					{isValid === false && <span className={style.message_error}>La Identificación no cumple con el formato</span>}

				</div>

				<div className={style.subcontainer_separate}>
					<LabelDrawer value='Número de teléfono' />
					<span className={style.two_input}>
						<Input
							style={{ height: 40, width: 85 }}
							disabled
							value={` ${prefixes}`}
							prefix={prefixElement}
						/>
						<Input
							style={{ height: 40, width: "75%" }}
							placeholder='Ingrese el Número'
							onChange={handlerInputNumber}
							value={selectedNumber}
							status={emptyInputsCheck(camposIncompletos, 'number_phone') ? 'error' : ''}
						/>
					</span>
					{isValidPhone === false && <span className={style.message_error}>El número no cumple con el formato</span>}
				</div>
			</section>

			<section className={style.container_separate}>
				<div className={style.subcontainer_separate}>
					<LabelDrawer value='Correo electrónico' />
					<Input
						style={{ height: 40 }}
						placeholder='Ingrese la Correo electrónico'
						onChange={handlerInputEmail}
						value={selectedEmail}
						status={emptyInputsCheck(camposIncompletos, 'email') ? 'error' : ''}
					/>
					{isValidEmail === false && <span className={style.message_error}>Ingresa correo válido</span>}
				</div>

				<div className={style.subcontainer_separate}>
					<LabelDrawer value='ID del servicio' />
					<Input
						style={{ height: 40 }}
						placeholder='Ingrese la ID del servicio'
						onChange={handlerInputServiceID}
						value={selectedServiceID}
						status={emptyInputsCheck(camposIncompletos, 'services_Id') ? 'error' : ''}
					/>
				</div>
			</section>

		</section>
	)
}

export default ClientData