/* eslint-disable */
import React, { useEffect, useState } from 'react'

import LabelDrawer from '../../../../../../components/text/drawer/label'

import { emptyInputsCheck } from '../../../../../../helpers/helpers'

import { myFetchGet } from '../../../../../../services/services'

import { Input } from 'antd'

import { debounce } from "lodash";

import './ManualPlanSearch.scss'

const ManualPlanSearch = ({ setTradename, loginOperadorID, camposIncompletos, showSpecification }) => {

	const [InputValue, setInputValue] = useState('');
	const [loadding, setLoadding] = useState(false);
	const [dataSearch, setDataSearch] = useState([]);
	const [visibleData, setVisibleData] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState('');
	const [dataTransform, setDataTransform] = useState([]);

	// capturar el valor del Input
	const handleInputPlan = ({ target }) => {
		setInputValue(target.value);
	}

	// capturar el resulatado de la petiicon
	const getPlan = async () => {
		try {
			setDataSearch(await myFetchGet(`api/v1/get_plans/${showSpecification === true ? 'oraculo' : 'brujula'}/?operator=${loginOperadorID}&tradename=${InputValue}`));
			setLoadding(true);
		} catch (error) {
			console.log(error);
		}
	}

	//captura la data seleccionada
	const handleSelectedPlan = (data) => {
		setSelectedPlan(data);
	}

	// funcion que captura la tecla cunado se deja de oprimir
	const handleKeyUp = () => {
		handlerSearchPlan();
	};

	// funcion que captura al oprimir tecla
	const handleKeyDown = () => {
		handlerSearchPlan.cancel();
	};

	// funcion con calback para el tiempo
	const handlerSearchPlan = debounce(() => {
		if (InputValue !== '') getPlan();
	}, 750);

	// ejecuta la funcion getplan si tiene un valor diferente a ''
	useEffect(() => {
		if (InputValue === '') {
			setLoadding(false);
			setVisibleData(false);
			setSelectedPlan('');
		}
	}, [InputValue])

	// si tiene alguna data la peticion de planes habilita la data
	useEffect(() => {
		if (dataSearch.length !== 0 && showSpecification === false) {
			setVisibleData(true);
			setDataTransform(dataSearch);
		}
		else {
			setVisibleData(false);
			setTradename(
				{
					tradename: InputValue,
					technology: "technology"
				}
			);
		}

		// transformacion de data cuando llega de oraculo
		if (dataSearch.length !== 0 && showSpecification) {
			const data = dataSearch?.plans.map((item) => {
				return {
					"tradename": item.tradename,
					"technology": item.technology_name
				}
			})
			setDataTransform(data);
			setVisibleData(true);
		}

	}, [dataSearch])

	// se selecciona el plan 
	useEffect(() => {
		if (selectedPlan !== '') {
			setTradename(selectedPlan);
			setVisibleData(false);
		}
	}, [selectedPlan])

	return (
		<>
			<LabelDrawer value='Plan' />
			<Input
				className='input_search_plan'
				placeholder="Búscar un Plan"
				onKeyUp={handleKeyUp}
				onKeyDown={handleKeyDown}
				onChange={handleInputPlan}
				value={selectedPlan.tradename}
				allowClear
				status={emptyInputsCheck(camposIncompletos, "tradename") ? "error" : ""}
			/>
			{loadding && visibleData && (
				<div className='container_search_plan custom_scroll'>
					{dataTransform.map((item, i) =>
					(
						<div className='subcontainer_search_plan' key={i} onClick={() => handleSelectedPlan(item)}>
							<span className='subspan_text'>
								<span className='text_search'>{item?.tradename}</span>
								<span className='text_right_search'>{item?.technology}</span>
							</span>
						</div>
					))}
				</div>
			)}
		</>
	)
}

export default ManualPlanSearch