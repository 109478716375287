import { useState, useMemo } from "react";
import { buildFilters } from "../helpers/filterUtils";


export const useFilter = () => {
	const [osTypeID, setOsTypeID] = useState();
	const [technicianID, setTechnicianID] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [searchData, setSearchData] = useState();
	const [otTypeID, setOtTypeID] = useState();

	const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 });

	const filters = useMemo(
		() => buildFilters(osTypeID, technicianID, startDate, endDate, searchData, rowsParams, otTypeID),
		[osTypeID, technicianID, startDate, endDate, searchData, rowsParams, otTypeID]
	);

	const osTypeHandler = (value) => {
		setOsTypeID(value);
	};

	const technicianHandler = (value) => {
		setTechnicianID(value);
	};

	const dateHandler = (dates, dateStrings) => {
		if (dateStrings[0] === "" && dateStrings[1] === "") {
			setStartDate(undefined);
			setEndDate(undefined);
		} else {
			setStartDate(dateStrings[0]);
			setEndDate(dateStrings[1]);
		}
	};

	const searchHandler = (value) => {
		setSearchData(value === "" ? undefined : value);
	};

	const otTypeHandler = (value) => {
		setOtTypeID(value);
	};

	return {
		osTypeID,
		osTypeHandler,
		technicianID,
		technicianHandler,
		startDate,
		endDate,
		dateHandler,
		searchData,
		searchHandler,
		filters,
		setRowsParams,
		rowsParams,
		otTypeID,
		otTypeHandler
	};
};