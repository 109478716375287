import React, { useEffect, useState, useRef } from 'react'

import { Panel, PanelHeader } from '../../components/panel/panel'
import Routing from './components/routing/Routing';
import SubtitleBlue from '../../components/text/drawer/subtitleBlue';
import LabelDrawer from '../../components/text/drawer/label';

import { myFetchGet } from '../../services/services';
import { getTechnicians } from '../../api/technician';

import { permissionCheck } from '../../helpers/helpers';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes';

import UseOperator from '../../hooks/UseOperator';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DatePicker, Select, Tooltip, notification, Timeline } from 'antd';
import { MapContainer, TileLayer } from 'react-leaflet';
import dayjs from 'dayjs';

import style from './routesReport.module.css'
import "leaflet/dist/leaflet.css";

import { useDraggable } from "react-use-draggable-scroll";



const nowDate = dayjs();
const today = nowDate.format('D MMMM YYYY');

const RoutesReport = () => {

	const [technicians, setTechnicians] = useState([])
	const [technicianID, setTechnicianID] = useState(null)

	const [data, setData] = useState(null);
	const [dateSelected, setDateSelected] = useState("");

	const [dragging, setDragging] = useState(false);

	const history = useHistory();
	const { storedOperatorID } = UseOperator();

	const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
	const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

	const getRouteReportHistory = async () => {
		try {
			const res = await myFetchGet(`api/v1/trip_summary/?technician=${technicianID}&day=${dateSelected}`)
			setData(res)

		} catch (error) {
			if (error?.response?.status === 404) {
				setData(null);
				notification.warning({
					style: { fontWeight: 'bold' },
					message: 'El Técnico no posee registros de rutas en la fecha seleccionada',
					placement: 'bottomLeft',
					duration: 2.5,
				});
			}
		}
	}

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Registro de linea de tiempo de actividades del técnico.</span>
		</ContainerTooltip>
	)

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	// camnbios Juan
	const handlerDateSelected = (value, dateString) => {
		setDateSelected(value.format('DD-MM-YYYY'))
	};

	const handlerTechnicialSelected = (value) => {
		setTechnicianID(value);
	};

	useEffect(() => {
		technicianID && dateSelected && getRouteReportHistory()
	}, [technicianID, dateSelected])

	useEffect(() => {
		storedOperatorID && getTechnicians(storedOperatorID)
			.then(res => setTechnicians(res))
	}, [storedOperatorID])

	useEffect(() => {
		if (rolPermission("datatable_view_tripsummary") === false) {
			history.replace("/dashboard")
		}
	}, [])

	useEffect(() => {
		setDateSelected(nowDate.format('DD-MM-YYYY'));
		notification.warning({
			style: { fontWeight: 'bold' },
			message: 'Por favor, selecciona un técnico',
			placement: 'bottomLeft',
			duration: 2.5,
		});
	}, [])

	const handleDragStart = () => {
		setDragging(true);
	}

	const handleDragEnd = () => {
		setDragging(false);
	}

	return (
		<Panel>
			<PanelHeader noButton={true}>
				<span className={style.title_route_report}>Registro de actividad laboral</span>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement="bottom"
					overlayInnerStyle={{
						height: "95px",
						maxHeight: '100px'
					}}
				>
					<i className={`fa-solid fa-circle-info ${style.circle_tooltip}`}></i>
				</Tooltip>
				<span className={style.sub_text_route_report}>Estadísticas | Reporte de rutas</span>

				<span className={style.date_compare_route_report}>Comparar respecto al {today} </span>
			</PanelHeader>


			<ContainerPrueba>
				<section style={{ display: 'flex' }}>
					<div className={style.container_route}>
						<MapContainer center={[4.6097, -74.0817]} zoom={5} style={{ height: "80vh" }}>
							<TileLayer
								attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							<Routing coordinates={data?.coordinates} />
						</MapContainer>

						<section
							className={`${style.data_route} ${style.scroll_item} ${dragging ? style.dragging : ''}`}
							onMouseDown={handleDragStart}
							onMouseUp={handleDragEnd}
							{...events}
							ref={ref}
						>
							<section style={{ display: "flex", flexDirection: "row" }}>
								<div className={style.sub_container_data}>
									<i className={`fa-solid fa-car ${style.icon}`}></i>

									<span className={style.distance_traveled}>
										<p className={style.title_blue}>{data?.distance_traveled} km</p>
										<p className={style.text_data}>Distancia recorrida</p>
									</span>
								</div>

								<div className={style.sub_container_data}>
									<i className={`fa-solid fa-couch ${style.icon}`}></i>

									<span className={style.distance_traveled}>
										<p className={style.title_blue}>{data?.idle_time}</p>
										<p className={style.text_data}>Tiempo sin actividad</p>
									</span>
								</div>

								<div className={style.sub_container_data}>
									<i className={`fa-solid fa-people-carry-box ${style.icon}`}></i>

									<span className={style.distance_traveled}>
										<p className={style.title_blue}>{data?.route_time}</p>
										<p className={style.text_data}>Tiempo en actividad</p>
									</span>
								</div>

								<div className={style.sub_container_data}>
									<i className={`fa-regular fa-clock ${style.icon}`}></i>

									<span className={style.distance_traveled}>
										<p className={style.title_blue}>{data?.dsp_time}</p>
										<p className={style.text_data}>Tiempo de ruta</p>
									</span>
								</div>

								<div className={style.sub_container_data}>
									<i className={`fa-solid fa-stopwatch ${style.icon}`}></i>

									<span className={style.distance_traveled}>
										<p className={style.title_blue}>{data?.wait_time}</p>
										<p className={style.text_data}>Tiempo de espera</p>
									</span>
								</div>

								<div className={style.sub_container_average}>
									<i className={`fa-solid fa-business-time ${style.icon}`}></i>

									<span className={style.distance_traveled}>
										<p className={style.title_blue}>{data?.os_time}</p>
										<p className={style.text_data}>Promedio de instalación</p>
									</span>
								</div>
							</section>


						</section>

					</div>

					<div className={style.container_history}>
						<SubtitleBlue value='Eventos del día' />

						<span className={style.select_technicials}>
							<span className={style.date_route}>
								<LabelDrawer value='Técnico' />
								<DatePicker
									format="DD MMM YYYY"
									bordered={false}
									inputReadOnly
									allowClear={false}
									defaultValue={nowDate}
									onChange={handlerDateSelected}
								/>
							</span>

							<Select
								placeholder="Selecciona un técnico"
								options={technicians.map(item => ({
									label: `${item.name} ${item.last_name}`,
									value: item.ID,
								}))}
								onChange={handlerTechnicialSelected}
							/>
						</span>

						<div className={`${style.timeline_route} custom_scroll`}>
							<Timeline
								mode='left'
								items={data?.changes.map(item => ({
									// position: 'left',
									color: '#00388B',
									label: (
										<b>{item.date}</b>
									),
									children: (<div>{item.change}</div>),
								}))}
							/>
						</div>

					</div>
				</section>
			</ContainerPrueba>
		</Panel>

	)
}

export default RoutesReport

const ContainerPrueba = styled.div`
.leaflet-right{
	display: none;
}

.leaflet-control{
	position: absolute;
	left: 605px;
	top: 500px;
	display: none;
}

.ant-picker-input > input {
  padding-left: 10px;
}

.ant-picker-suffix {
  position: absolute;
  transform: translateX(-100%);
	color: #959595;
}

.ant-select-single{
	height: 45px;
}

.ant-timeline .ant-timeline-item-head{
	border: 1px solid transparent;
	border-color:#00388B;
}

.ant-timeline .ant-timeline-item-tail{
	border-inline-start: 1px solid #00388B;
}

.ant-timeline{
	width: 90%;
	margin-top: 5px;
	
}

.ant-picker .ant-picker-input > input{
	width: 90px;
}

.ant-picker{
	padding-right:0px;
}

.ant-timeline .ant-timeline-item{
	font-size: ${smallSizes.fontSize.fontSize12};
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label{
	width: calc(40% - 12px);
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom{
	inset-inline-start: 41%;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content{
	inset-inline-start: calc(40% - 4px);
	width: calc(63% - 12px);
}


//-------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.ant-timeline .ant-timeline-item{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.ant-timeline .ant-timeline-item{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.ant-timeline .ant-timeline-item{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`

const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 160px;
	}
`;
