import React from 'react'
import './index.scss'

const BottomSection = ({ onCancel = () => { }, onAcept = () => { }, isButtonDisabled, background, minHeight, textButtonAcept = 'Guardar', disable }) => {
	return (
		<div className='bottom_section_form' style={{ background: background, minHeight: minHeight }}>
			<button className='cancel__btn' onClick={onCancel}>Cancelar</button>

			<button
				onClick={onAcept}
				disabled={isButtonDisabled || disable}
				className={isButtonDisabled === true ? "btn_disable_styles" : disable === true ? "disable_style" : "acept__btn"}
			>
				{isButtonDisabled === true ? "Espere.." : textButtonAcept}
			</button>
		</div>
	)
}

export default BottomSection