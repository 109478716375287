import React from 'react'

import style from './importantInformation.module.css'
import SubtitleBlue from '../../../../../../components/text/drawer/subtitleBlue'
import { Checkbox } from 'antd'

const ImportantInformation = ({ text, textCheckbox, checkbox }) => {

	const handlerCheckBox = (e) => {
		checkbox(e.target.checked)
	}
	return (
		<section className={style.container}>
			<SubtitleBlue value='Información importante' marginBottom='4px' />
			<p className={style.text}>{text}</p>
			<Checkbox className={style.checkbox} onChange={handlerCheckBox}>{textCheckbox}</Checkbox>
		</section>
	)
}

export default ImportantInformation