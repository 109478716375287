import React, { useEffect, useState } from 'react'

import style from './serviceOrderAttention.module.css'
import { Checkbox, Input, Select, Switch } from 'antd';
import LabelDrawer from '../../../../../../components/text/drawer/label';
import { emptyInputsCheck } from '../../../../../../helpers/helpers';

const { TextArea } = Input;

const ServiceOrderAttention = ({
	showTechnician,
	setShowTechnician,
	setRandomTechnican,
	setSupervisorSupport,
	onTechnicianChange,
	selectedTechnicials,
	availableTechnicians,
	setViewComment,
	viewComment,
	onCommentChange,
	camposIncompletos
}) => {

	const [optionTecnicians, setOptionTecnicians] = useState(null)

	useEffect(() => {
		if (availableTechnicians) {
			const datatechnicians = availableTechnicians?.map(item => {
				return {
					value: item?.id,
					label: item?.name
				}
			})
			setOptionTecnicians(datatechnicians)
		}
	}, [availableTechnicians])


	return (
		<section className={style.container}>
			<header className={style.container_header}>
				<div className={style.circle} />
				<p className={style.text_header}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum sem libero. Morbi mollis eleifend efficitur. Morbi sed ligula semper leo maximus .</p>
			</header>

			<section className="switch_container_os">

				<section className="left">
					<div className="switch_box">
						<label className='text-assignment'>¿Asignar de forma aleatoria técnico principal?</label>

						<span>
							<label className={style.font_12}>No</label>
							<Switch style={{ margin: "0 5px" }} onChange={(e) => {
								setShowTechnician(!showTechnician);
								setRandomTechnican(!showTechnician);
							}} />
							<label className={style.font_12}>Si</label>
						</span>
					</div>



				</section>

				<div className="right">
					{
						!showTechnician && (
							<div className="showable_technician">
								<div className="column">
									<span style={{ display: 'flex', justifyContent: 'space-between' }}>
										<LabelDrawer value="Técnico" />
									</span>

									<Select
										onChange={onTechnicianChange}
										placeholder="Seleccionar"
										options={optionTecnicians}
										value={selectedTechnicials}
										status={emptyInputsCheck(camposIncompletos, "technician") ? "error" : ""}
									/>

									<Checkbox className='checkbox_os' onChange={(e) => setSupervisorSupport(e.target.checked)}>Amerita apoyo del supervisor</Checkbox>

								</div>
							</div>
						)
					}
				</div>

			</section>

			<section className={style.second_switch}>
				<div className="switch_box">
					<label className='text-assignment'>¿Desea agregar comentario adicional?</label>

					<span>
						<label className={style.font_12}>No</label>
						<Switch style={{ margin: "0 5px" }} onChange={(checked) => setViewComment(checked)} />
						<label className={style.font_12}>Si</label>
					</span>
				</div>
			</section>

			{viewComment && (
				<TextArea
					rows={4}
					onChange={onCommentChange}
					status={emptyInputsCheck(camposIncompletos, "comentary") ? "error" : ""}
				/>
			)}


		</section>
	)
}

export default ServiceOrderAttention