import React, { useCallback, useEffect, useState } from 'react'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue'

import style from './questionsPanel.module.css'
import { myFetch, myFetchGet } from '../../../../services/services'
import { Input, Switch, notification } from 'antd'
import BottomSection from '../../../../components/form/bottomSection'

import _ from "lodash";

const { TextArea } = Input

const QuestionsPanel = ({ data }) => {
	const { category_id, id_unico } = data

	const [questionsData, setQuestionsData] = useState([])
	const [buttonEdit, setButtonEdit] = useState(true)
	const [onDataCategory, setOnDataCategory] = useState(false)
	const [idQuestions, setIdQuestions] = useState(null)

	const [request, setRequest] = useState(false)

	const [loading, setLoading] = useState(true)

	const [questionsDataInitial, setQuestionsDataInitial] = useState([])

	const bodyRequest = {
		"questions": {
			"value": questionsData
		}
	}

	const orderData = (data) => {
		const sortedData = data.sort((a, b) => {
			if (a.kind === 1 && b.kind === 2) {
				return -1;
			} else if (a.kind === 2 && b.kind === 1) {
				return 1;
			} else if (a.kind === 2 && b.kind === 2) {

				if (a.question === "Comentarios adicionales") {
					return 1;
				} else if (b.question === "Comentarios adicionales") {
					return -1;
				} else {
					return 0;
				}
			} else {
				return 0;
			}
		});
	}

	const getCategory = async (id) => {
		try {
			const response = await myFetchGet(`api/v1/category_ot/${id}/`)
			orderData(response.questions.value)
			setQuestionsData(response.questions.value)
			setOnDataCategory(true)
		} catch (error) { }
	}

	const getResponseQuestions = async (id) => {
		try {
			const response = await myFetchGet(`api/v1/responseot/?ref_ot=${id}`)
			if (response.length === 0) {
				const responseQs = questionsData.map((item) => {
					return {
						...item,
						value: item.kind !== 2 ? false : ''
					};
				});
				setQuestionsData(responseQs)
				setQuestionsDataInitial(responseQs)
				setRequest(true)
			} else {
				setIdQuestions(response[0].ID)

				const responsesQuestion = response.map((item) => {
					return item.questions.value
				})
				setQuestionsData(responsesQuestion[0])
				setQuestionsDataInitial(responsesQuestion[0])
				setRequest(false)
			}

			setLoading(false)
		} catch (error) { }
	}

	const onHandlerEdit = () => {
		setButtonEdit(!buttonEdit)
	}

	const onHandlerSwitch = (index, value) => {
		const response = questionsData.map((item, i) =>
			item.kind === 1 &&
				index === i
				? { ...item, value: value }
				: item
		)
		setQuestionsData(response)
	}

	const onHandlerInputText = (index, { target }) => {
		const response = questionsData.map((item, i) =>
			item.kind === 2 &&
				index === i
				? { ...item, value: target.value }
				: item
		)
		setQuestionsData(response)
	}

	const onHandlerInputTextArea = (index, { target }) => {
		const response = questionsData.map((item, i) =>
			item.kind === 2 && item.question === "Comentarios adicionales" &&
				index === i
				? { ...item, value: target.value }
				: item
		)
		setQuestionsData(response)

	}

	const onHandlerSave = async () => {
		try {
			if (request) {
				bodyRequest["ref_ot"] = id_unico
				await myFetch(`api/v1/responseot/`, bodyRequest)
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'Respuestas Creadas',
					placement: 'bottomLeft',
					duration: 2,
					onClose: () => {
						window.location.reload()
					}
				})
			} else {
				await myFetch(`api/v1/responseot/${idQuestions}/`, bodyRequest, "PATCH")
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'Respuestas actualizadas',
					placement: 'bottomLeft',
					duration: 2,
					onClose: () => {
						window.location.reload()
					}
				})
			}
		} catch (error) { }
	}

	const onHandlerCancel = () => {
		setQuestionsData(questionsDataInitial)
		setButtonEdit(true)
	}

	useEffect(() => {
		getCategory(category_id)
	}, [])

	useEffect(() => {
		if (onDataCategory) getResponseQuestions(id_unico)
	}, [onDataCategory])

	return (
		<section className={style.container}>
			<section className={style.container_question}>
				<div className={style.header}>
					<span className={style.flex_column}>
						<SubtitleBlue value='Preguntas asociadas a la categoría' />
						<label className={`${style.font_12} ${style.color5A607F}`}>Listado de preguntas asociadas a la categoría de la orden de trabajo.</label>
					</span>


					<button
						className={`${style.button_modify} ${style.font_12}`}
						onClick={onHandlerEdit}
					>
						<i className="fa-solid fa-pen-to-square"></i>
						Modificar
					</button>
				</div>

				{!loading && questionsData.map((item, i) => {

					return (

						<div
							key={i}
							className={item.kind === 2 && item.question !== "Comentarios adicionales" ? style.container_questions_kind_two :
								item.question === "Comentarios adicionales" && item.kind === 2 ?
									style.container_comment :
									style.container_questions
							}>

							{item.kind === 1 ? (
								<span className={style.kind_one}>
									<label className={`${style.color131523} ${style.font_14}`}>{item.question}</label>
									<span className={style.container_switch}>
										<label>No</label>
										<Switch
											disabled={buttonEdit}
											checked={item.value}
											onChange={(value) => onHandlerSwitch(i, value)}
										/>
										<label>Si</label>
									</span>

								</span>
							) : item.kind === 2 && item.question !== "Comentarios adicionales" ? (
								<span className={style.kind_two}>
									<label className={`${style.color131523} ${style.font_14}`}>{item.question}</label>
									<Input
										className={style.input} disabled={buttonEdit}
										onChange={(value) => onHandlerInputText(i, value)}
										value={item.value}
									/>
								</span>
							) : (
								<span className={style.kind_two}>
									<label className={`${style.color131523} ${style.font_14} ${style.bold}`}>{item.question}</label>
									<TextArea
										rows={4}
										disabled={buttonEdit}
										value={item.value}
										onChange={(value) => onHandlerInputTextArea(i, value)}
									/>
								</span>
							)}


						</div>
					)
				})}

				{!buttonEdit && (

					<BottomSection background="rgba(221, 228, 235, 0.15)" minHeight={50} onCancel={onHandlerCancel} onAcept={onHandlerSave} />


				)}

			</section>

		</section>
	)
}

export default QuestionsPanel