import React, { useEffect, useState } from 'react'

import style from './displacementPanel.module.css'
import { Table } from 'antd'
import { myFetch, myFetchGet } from '../../../../services/services'
import DatatableRowText from '../../../../components/text/datatable/datatableRowText'
import DatatableHeaderText from '../../../../components/text/datatable/datatableHeaderText'
import UseOperator from '../../../../hooks/UseOperator'
import dayjs from 'dayjs'


const DisplacementPanel = ({ data }) => {

	const [tableColumns, setTableColumns] = useState([]);
	const [tableRows, setTableRows] = useState([]);

	const [emptyData, setEmptyData] = useState(false);

	const { id_unico } = data
	const { storedOperatorID } = UseOperator();

	const filters = {
		"offset": 10,
		"start": 0,
		"order_type": "desc",
		"operator": storedOperatorID,
		"filters": `[[\"wo\",\"equal\",\"${id_unico}\"]]`
	}

	const getStructureColumns = async () => {
		try {
			const response = await myFetchGet("api/v1/wo_displacement/datatables_struct/")
			const columnsTittles = Object.keys(response.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					title: <DatatableHeaderText value={columnsTittle} />,
					width: columnsTittle === 'Fecha' ? '150px' :
						columnsTittle === 'Origen' ? '350px' :
							columnsTittle === 'Destino' ? '350px' : "",
					dataIndex: response.columns[columnsTittle].field,
					key: response.columns[columnsTittle].field,
					align: "left",
					sorter: columnsTittle === 'Fecha' ? true : null,
					render: columnsTittle === 'Fecha'
						? (value) => <DatatableRowText value={value} />
						: columnsTittle === 'Origen' ?
							(value) =>
								<DatatableRowText value={value} color='#007AFF' />
							: columnsTittle === 'Destino' ?
								(value) =>
									<DatatableRowText value={value} color='#007AFF' />
								: columnsTittle === 'Medio' ?
									(value) =>
										<DatatableRowText value={value} />
									: columnsTittle === 'Tiempo' ?
										(value) =>
											<DatatableRowText value={value} />
										: (value) => <DatatableRowText value={value} />

				});
			});
			setTableColumns(columns)
		} catch (error) { }
	}

	const getDatatable = async () => {
		try {
			const res = await myFetch("api/v1/wo_displacement/datatables/", filters)
			if (res.data.length === 0) setEmptyData(true)
			else setEmptyData(false)

			if (res) {

				if (res.data) {
					const rows = res.data.map((row) => {
						const dateFormat = dayjs(row.date).format("DD-MM-YYYY");
						return {
							...row,
							key: row.ID,
							created: row.created,
							date: dateFormat,
						};
					});
					setTableRows(rows);
				}
			}
		} catch (error) { }

	}

	useEffect(() => {
		getStructureColumns()
		getDatatable()
	}, [])


	return (
		<section className={style.container_displacement}>
			<label className={`${style.font_14} ${style.color5A607F} ${style.pdl36_5}`}>Listado de trayectos recorridos registrados desde la aplicación asociado al atendimiento de la orden de trabajo</label>
			<div className={style.container_table}>
				<Table
					columns={tableColumns}
					dataSource={tableRows}
					pagination={false}
				/>
			</div>

		</section>
	)
}

export default DisplacementPanel