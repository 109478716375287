import React from 'react'

const GreyDivider = () => {
  return (
    <div style={{ width: "100%", height: "40px", background: "#f3f3f3", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <hr style={{ width: "100%", height: "1px", background: "#CDD6DC" }} />
      </div>
  )
}

export default GreyDivider

