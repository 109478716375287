
const UseOperator = () => {
	const operatorID = localStorage.getItem('operatorID')
	const countryID = localStorage.getItem('countryID')

	return {
		storedOperatorID: operatorID,
		countryID: countryID
	}

}

export default UseOperator;