import React from 'react'
import styled from 'styled-components';

const BlueResumeUI = ({ children, height = 166, colorBackground = "#E9F4FF", colorBorder = "#B8DAFF" }) => {
	return (
		<Component>
			<div className='BlueResumeUI' style={{ minHeight: height, background: colorBackground, border: `1px solid ${colorBorder}` }}>{children}</div>
		</Component>
	)
};

export default BlueResumeUI
const Component = styled.div`
	.BlueResumeUI{
		color: #004085;
		width: 100%;
		border-radius: 4px;
		padding: 2em;
		display: flex;
		flex-flow: column;
		justify-content: center;
	}
`
