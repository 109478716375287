import React, { useEffect, useState } from 'react'
import { Panel } from '../../components/panel/panel'
import PanelHeaderV2 from '../../components/panelHeader/PanelHeaderV2'
import { DatePicker, Flex, Input, Pagination, Select, Table, Tooltip } from 'antd'

import style from './listWorkOrders.module.css'
import SubtitleBlue from '../../components/text/drawer/subtitleBlue'
import styled from 'styled-components'
import { datatableOW, getDatatables_structColumns } from '../../api/listworkOrders'
import { useFilter } from '../../hooks/useFilter'
import { sorterCallback } from '../../helpers/sorterCallback'
import NormalText from '../../components/text/NormalText'
import { myFetchGet } from '../../services/services'
import { selectTab } from '../../store/tab/tabSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import UseOperator from '../../hooks/UseOperator'
import EmptySearch from '../../components/empty/emptySearch/EmptySearch'
import EmptyData from '../../components/empty/emptyData/EmptyData'

const ListWorkOrders = () => {

	const { RangePicker } = DatePicker
	const { Search } = Input
	const {
		otTypeID,
		technicianID,
		technicianHandler,
		searchHandler,
		dateHandler,
		startDate,
		endtDate,
		searchData,
		filters,
		setRowsParams,
		rowsParams,
		otTypeHandler
	} = useFilter()

	const dispatch = useDispatch();
	const history = useHistory();
	const { storedOperatorID } = UseOperator();

	const [tableColumns, setTableColumns] = useState([]);
	const [tableRows, setTableRows] = useState([]);

	const [orderDirection, setOrderDirection] = useState("desc");

	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [totalSize, setTotalSize] = useState(0);
	const [columnFilterValue, setColumnFilterValue] = useState([]);

	const [optionsTechnicials, setOptionsTechnicials] = useState([])
	const [optionsWorkOrders, setOptionsWorkOrders] = useState([])

	const [loadding, setLoadding] = useState(true);
	const [emptyData, setEmptyData] = useState(false);

	const [viewEmpty, setViewEmpty] = useState(false);

	const tooltipText = (
		<section className={style.container_tooltip}>
			<span className={style.tittle_tooltip}>Información de ayuda</span>
			<span className={style.description_tooltip}>Registro de ordenes de trabajo del operador.</span>
		</section>
	)

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const getTechnicials = async () => {
		try {
			const response = await myFetchGet('api/v1/technician/list_technicians/')
			const data = response.data.map(item =>
			({
				value: item.id,
				label: item.full_name
			}))
			setOptionsTechnicials(data)
		} catch (error) { }
	}

	const getWorkOrders = async () => {
		try {
			const response = await myFetchGet('api/v1/wo/list_work_orders/')
			const data = response.data.map(item => ({
				value: item.id,
				label: item.name
			}))
			setOptionsWorkOrders(data)
		} catch (error) { }
	}

	useEffect(() => {
		getDatatables_structColumns().then(res => {
			const data = res.map(column =>
				column.dataIndex === 'ID' ?
					{
						...column,
						onCell: (record) => {
							return {
								onClick: () => {
									dispatch(selectTab({ selectedTab: 0 }))
									history.push("/workOrdersDetails", { params: record });
								},
								style: { cursor: "pointer" },
								className: "hover-table-class"
							}
						}
					} : column.dataIndex === "status_name" ?
						{
							...column,
							filteredValue: columnFilterValue,
						} : column

			)
			setTableColumns(data)

		})
	}, [rowsParams, columnFilterValue])

	useEffect(() => {
		setLoadding(true)

		let newFilters = {
			...filters,
			"order_field": "ID",
			"order_type": orderDirection,
			"operator": storedOperatorID
		};

		if (columnFilterValue.length === 1 && !newFilters.filters) {
			newFilters = {
				...newFilters,
				filters: `[["status","equal","` + columnFilterValue[0] + `"]]`,
			};
		} else if (
			(columnFilterValue.length === 0 && !newFilters.filters) ||
			(columnFilterValue.length === 0 && newFilters.filters)
		) {
			newFilters = newFilters;
		} else if (columnFilterValue.length === 1 && newFilters.filters) {
			newFilters.filters = newFilters.filters.replace(
				"]",
				`], ` + `["status","equal","` + columnFilterValue[0] + `"]`
			);
		}

		datatableOW(newFilters).then(res => {
			if (res.data.length === 0) setEmptyData(true)
			else setEmptyData(false)

			if (res?.size) setTotalSize(res?.size)
			if (res?.data) {
				const dataSource = res?.data.map((item) => ({
					key: item.ID,
					...item
				}))
				setTableRows(dataSource)
			}
			setLoadding(false)
		})

	}, [otTypeID,
		technicianID,
		startDate,
		endtDate,
		searchData,
		rowsParams,
		orderDirection,
		columnFilterValue
	])

	useEffect(() => {
		getTechnicials()
		getWorkOrders()
	}, [])

	useEffect(() => {
		if (emptyData && loadding === false && searchData) setViewEmpty(true)
		if (!emptyData && loadding === false) setViewEmpty(true)
		if (emptyData && loadding === false && !searchData && columnFilterValue.length === 0) setViewEmpty(false)
	}, [loadding, emptyData, columnFilterValue])



	return (
		<Panel>
			<PanelHeaderV2
				title={"Listado de órdenes de trabajo agendadas"}
				subText={"Orden de trabajo | Listado de órdenes"}
			>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement='bottom'
					overlayInnerStyle={{
						height: "95px",
						maxHeight: '100px'
					}}
				>
					<i className="fa-solid fa-circle-info circle-tooltip"></i>
				</Tooltip>
			</PanelHeaderV2>

			<section className={style.container}>
				{viewEmpty && (
					<section className={style.container_filter}>
						<SubtitleBlue value="Opciones de filtrado" />
						<section className={style.input_filter}>
							<span className={style.flex}>
								<Select
									className={style.select}
									placeholder="Orden de trabajo"
									onChange={otTypeHandler}
									options={optionsWorkOrders}
									allowClear
								/>
								<Select
									className={style.select}
									placeholder="Técnico"
									onChange={technicianHandler}
									options={optionsTechnicials}
									allowClear
								/>
								<RangePicker
									className={style.select}
									placeholder={["Fecha Inicio", "Fecha Fin"]}
									onChange={dateHandler}
									format="DD/MM/YYYY"
									allowClear
								/>
							</span>
							<SearchInput>
								<Search
									placeholder='Realizar búsqueda'
									onSearch={(value) => {
										searchHandler(value)
										if (value === '') {
											setEmptyData(false)
											setLoadding(true)
										}
									}}
									allowClear
								/>
							</SearchInput>

						</section>
					</section>
				)}


				{((emptyData === false && loadding === false) || (loadding === false && columnFilterValue.length !== 0)) && (
					<TableDesing>
						<Table
							columns={tableColumns}
							dataSource={tableRows}
							pagination={false}
							onChange={
								(pagination, filters, sorter, extra) => {
									setOrderDirection(sorterCallback(pagination, filters, sorter, extra))

									if (filters.status_name === null || filters.status_name === undefined) {
										setColumnFilterValue([]);
									} else {
										setColumnFilterValue(filters.status_name);
									}
								}
							}
						/>

						<div className={style.container_pagination}>
							<div>
								<span className={style.pagination}>
									<NormalText
										bold={false}
										value={`Mostrando del ${rowsParams.start + 1} registros al ${rowsParams.start + rowsParams.offset} de un`}
									/>
									<NormalText
										bold={false}
										value={`total de ${totalSize} registros`}
									/>
								</span>
								<SubtitleBlue value='Exportar data' />
							</div>

							<Pagination
								className={style.pagination_fontsize}
								showSizeChanger
								defaultCurrent={paginationSettings[1]}
								current={paginationSettings[0]}
								onChange={modifyPagination}
								total={totalSize}
							/>
						</div>
					</TableDesing>
				)}

				{emptyData && loadding === false && searchData && (
					<EmptySearch sectionPage='orden de trabajo' />
				)}

				{emptyData && loadding === false && !searchData && (
					<EmptyData sectionPage='orden de trabajo' />
				)}


			</section>
		</Panel>
	)
}

export default ListWorkOrders

const TableDesing = styled.div`
.ant-table-wrapper .ant-table-column-title{
	flex: 0;
}

.ant-table-wrapper .ant-table-filter-column{
	justify-content: normal;
	gap: 10px;
}
`

const SearchInput = styled.div`
width: 219px;

.ant-input-group{
	height: 40px;
}

.ant-input-search .ant-input-affix-wrapper{
	height: 40px;
}

.ant-input-group .ant-input{
	height: 29px;
	font-size: 12px;
}

.ant-input-search .ant-input-search-button{
	height: 40px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
	border-left: none
}

.ant-input-affix-wrapper:focus-within {
	box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
	border-color: white;
}

.ant-input-affix-wrapper:hover {
	border-color: #d9d9d9;
	border-right: none;
}

.ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary):hover{
	border-color: #d9d9d9;
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus{
	border-color: #d9d9d9;
}

@media (min-width: 1280px) {
	.ant-input-group .ant-input {
		font-size: 12px;
	}
}

@media (min-width: 1366px) {
	.ant-input-group .ant-input {
		font-size: 13.5px;
	}
}

@media (min-width: 1920px) {
	.ant-input-group .ant-input {
		font-size: 15.18px; 
	}
}

`