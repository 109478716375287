/* eslint-disable */
import React, { useEffect, useState } from 'react'

import { DatePicker, Input, Select, notification, Switch } from 'antd';
import styled from "styled-components";


// api
import { getTechnicianHoliday, onHolidayCreate } from '../../../api/holiday.js';

// custom
import Br from '../../../components/br/Br.jsx';
import LabelDrawer from '../../../components/text/drawer/label/index.jsx';
import MainTitle from '../../../components/text/drawer/mainTitle/index.jsx';
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue/index.jsx';
import Form from '../../../components/form/index.jsx';
import BottomSection from '../../../components/form/bottomSection/index.jsx';
import { validarCampos } from '../../../helpers/helpers.js';
import ErrorMsg from '../../../components/text/notificationText/ErrorMsg.jsx';


import './formStyle.scss'
import style from './newHolidayForm.module.css'

const { Option } = Select;

const NewHolidayForm = ({ setVisible, loginOperadorID }) => {
	const [date, setDate] = useState(null)
	const [holidayName, setHolidayName] = useState(null)
	const [holidayStatus, setHolidayStatus] = useState(0)
	const [holidayType, setHolidayType] = useState(null)
	const [errorRegex, setErrorRegex] = useState(false)

	const [options, setOptions] = useState([]);
	const [switchEnable, setSwitchEnable] = useState(false);

	const [selectedTechnicials, setSelectedTechnicials] = useState(null);


	const onCloseModal = () => setVisible(false)

	const handleChange = ({ target }) => {
		const inputValidation = /^[a-zA-ZáéíóúÁÉÍÓÚ]+(?:\s[a-zA-ZáéíóúÁÉÍÓÚ]+)*$/;
		const isValidationInput = inputValidation.test(target.value.trim());

		if (isValidationInput) {
			setHolidayName(target.value.trim());
			setErrorRegex(false);
		} else {
			setErrorRegex(true);
		}
	};

	// state para status de inputs 
	const [inputStatus, setInputStatus] = useState({ input1: '', input2: '', input3: '', input4: '' });


	const onHolidayChange = (value) => setHolidayType(value)

	// data de envio a peticion post
	let holidayData = {
		"day": date,
		"name": holidayName,
		"status": holidayStatus,
		"holiday_type": holidayType,
		"operator": loginOperadorID,
	}

	// condicion para enviar el campo technicians
	if (switchEnable) {
		holidayData["technicians"] = selectedTechnicials;
	}

	// Funcion para verificar si los campos estan llenos y lanzar peticion post
	const NewHoliday = () => {
		if (
			date === null ||
			holidayName === null ||
			holidayType === null ||
			(selectedTechnicials === null && switchEnable)

		) {
			setInputStatus({
				input1: date === null ? 'error' : '',
				input2: holidayName === null ? 'error' : '',
				input3: holidayType === null ? 'error' : '',
				input4: (selectedTechnicials === null && switchEnable) ? 'error' : '',
			});
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Completa todos los campos',
				placement: 'bottomLeft',
				duration: 2,
			})
			return
		}

		if (validarCampos(holidayData)) {
			onHolidayCreate(holidayData)
			setInputStatus({ input1: "", input2: "", input3: "", input4: "" });
		}
	}

	const dateHandler = (dates, dateStrings) => dateStrings === "" ? setDate("") : setDate(dateStrings);

	// funcion para capturar los datos del select
	const handleSelectTechnicials = (value) => {
		setSelectedTechnicials(value);
	}

	// Se ejecuta peticion get para estraer los tecnicos y mostrar en select
	useEffect(() => {
		loginOperadorID && getTechnicianHoliday(loginOperadorID)
			.then(res => {
				const data = res.map(item => {
					return {
						value: item.ID,
						label: `${item.name} ${item.last_name}`,
					}
				})
				setOptions(data)
			})
	}, [loginOperadorID])

	return (
		<Form>
			<MainTitle value='Registro de día feriado' onClick={onCloseModal} />

			<SubtitleBlue value='Información de la festividad' />

			<div className={style.container_name}>
				<LabelDrawer value='Nombre' />
				<Input
					className='input_font_12_holidays'
					placeholder='Ingrese el nombre de la festividad'
					onChange={handleChange}
					style={{ height: 40 }}
					status={inputStatus?.input2}
				/>
				{errorRegex && <ErrorMsg value="Solo se admiten letras" />}
			</div>

			<Br />

			<div className={style.container_date_type}>
				<section className={style.subcontainer_date}>
					<LabelDrawer value='Fecha' />
					<DatePicker
						placeholder='Seleccione una fecha'
						onChange={dateHandler}
						format='DD-MM-YYYY'
						style={{ height: 40 }}
						inputReadOnly
						status={inputStatus?.input1}
					/>
				</section>

				<section className={style.subcontainer_date}>
					<LabelDrawer value='Tipo' />
					<Select
						onChange={onHolidayChange}
						placeholder="Seleccionar"
						status={inputStatus?.input3}
					>
						<Option key={0} value={1}>Civil</Option>
						<Option key={1} value={0}>Religioso</Option>
					</Select>
				</section>
			</div>

			<Br />

			<section>
				<b className={style.text_hability}>¿Habilitar técnicos?</b>

				<span className='text'>No</span>
				<Switch style={{ margin: "0 5px" }} onChange={(e) => {
					setSwitchEnable(e);
				}} />
				<span className='text'>Si</span>
			</section>

			<Br />

			{switchEnable && (
				<section className={style.container_technicial}>
					<LabelDrawer value='Técnicos' />

					<ContainerSelect>
						<Select
							placeholder="Selecciona un técnico"
							options={options}
							mode='multiple'
							className='select_holiday'
							onChange={handleSelectTechnicials}
							status={inputStatus?.input4}
						/>

					</ContainerSelect>
				</section>
			)}


			<BottomSection onAcept={NewHoliday} onCancel={onCloseModal} />
		</Form>
	)

}

export default NewHolidayForm

const ContainerSelect = styled.div`
.select_holiday {
	min-height: 40px !important;
  display: flex;
}

.select_holiday .ant-select-selector {
	width: 100%;
	height: auto !important;
}
`;