const regexFormats = {
	"CL-RUT": /^\d{1,2}.\d{3}.\d{3}-[\dkK]{1}$/,
	"CL-RUN": /^\d+-[\dkK]{1}$/,
	"CL-PASS": /^[A-Z]{2}\d{7}$/,
	"PE-DNI": /\d{8}-[\d]{1}$/,
	"PE-CE": /\d{9}/,
	"text_name": /^[a-zA-Z\s]+$/,
	"email": /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
	"phone": /^\d{10}$/
}

const prefixes = {
	"CL": "+56",
	"PE": "+51",
	"CO": "+57",
	"VE": "+58",
}

export const validateDocument = (iso, typeCountry, document) => {
	const regex = regexFormats[`${iso}-${typeCountry}`] || regexFormats.default;
	const isValid = regex.test(document);
	return isValid;
}

export const prefixesCountry = (iso) => {
	const prefix = prefixes[iso];
	return prefix;
}

export const validateName = (name) => {
	const validate = regexFormats['text_name'] || regexFormats.default
	const isValid = validate.test(name)
	return isValid
}

export const validateEmail = (email) => {
	const validate = regexFormats['email'] || regexFormats.default
	const isValid = validate.test(email)
	return isValid
}

export const validatePhone = (phone) => {
	const validate = regexFormats['phone'] || regexFormats.default
	const isValid = validate.test(phone)
	return isValid
}

export const eliminarCodigoPais = (numeroTelefono) => {
	const regex = /^\+\d+/;
	return numeroTelefono.replace(regex, "");
}
