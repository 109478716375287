import React from 'react'
import { myFetch, myFetchGet } from "../services/services";
import ColorCircle from '../components/colorCircle';
import { notification } from 'antd';
import DatatableHeaderText from '../components/text/datatable/datatableHeaderText';
import DatatableRowText from '../components/text/datatable/datatableRowText';
import { capitalize } from '../helpers/helpers';

const getUserApp_structColumns = async () => {
	try {
		const data = await myFetchGet("api/v1/userapp/datatables_struct");
		const columnsTittles = Object.keys(data?.columns);
		const columns = [];
		columnsTittles.forEach((columnsTittle) => {

			columns.push({
				sorter: columnsTittle === "ID" ? (a, b) => a?.ID - b?.ID : null,
				width: columnsTittle === "ID" ? "80px" : columnsTittle === "Status" ? "90px" : "",
				dataIndex: data?.columns[columnsTittle].field,
				key: data?.columns[columnsTittle].field,
				title: <DatatableHeaderText value={columnsTittle} />,
				align: "left",
				filterMultiple: false,
				filters:
					columnsTittle === 'Estatus' ?
						[
							{
								text: "Activo",
								value: true,
							},
							{
								text: "Inactivo",
								value: false,
							},
						] : null,
				render:
					columnsTittle === "Estatus" ?
						(value) =>
							<span style={{ display: "flex", alignItems: "baseline" }}>
								<ColorCircle color={value?.status_color} />
								<DatatableRowText value={value?.name} />
							</span>

						: columnsTittle === "Nombre Completo" ?
							(value) =>
								<span style={{ display: "flex", alignItems: "center" }}>
									<DatatableRowText value={capitalize(value)} color='#2B80FF' bold={true} />
								</span>

							: (value) =>
								<span style={{ display: "flex", alignItems: "center" }}>
									<DatatableRowText value={value} />
								</span>
			});
		});
		return columns;

	} catch (e) { }
}

const getGroups = async () => {
	try {
		const res = await myFetchGet("api/v1/groups/")
		return res

	} catch (error) { console.log(error) }
}
const onCreateNewUser = async (data) => {
	try {
		await myFetch('api/v1/userapp/', data)
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Usuario creado con exito!!',
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => window.location.reload()
		});
	}
	catch (e) {
		console.log('error', e);
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'No se ha podido crear el usuario.',
			placement: 'bottomLeft',
			duration: 2.5,

		});
	}
}

const onEditCreatedUser = async (ID, data) => {
	try {
		await myFetch(`api/v1/userapp/${ID}/`, data, "PATCH")
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Registro editado!',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => window.location.reload()
		})
	}
	catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'Se ha presendao un error!',
			placement: 'bottomLeft',
		});
	}
}
const getUserappStatus = async () => {

	try {
		const res = await myFetchGet("api/v1/userapp/choices/")
		return res?.status_user

	} catch (error) { console.log(error) }

}

export {
	getUserApp_structColumns,
	getGroups, onCreateNewUser,
	getUserappStatus,
	onEditCreatedUser
}