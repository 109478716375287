import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';

import CustomModal from '../../modal/CustomModal.jsx';
import { Input, Popconfirm, Select, notification } from 'antd';

import { myFetch } from '../../../services/services.js';
import size from '../../../helpers/sizes.js';

import MainTitle from '../../text/drawer/mainTitle/index.jsx';
import Form from '../../form/index.jsx';
import LabelDrawer from '../../text/drawer/label/index.jsx';
import BottomSection from '../../form/bottomSection/index.jsx';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes.js';
import SubtitleBlue from '../../text/drawer/subtitleBlue/index.jsx';

import style from './editImageForm.module.css'
import Br from '../../br/Br.jsx';


const EditImageForm = (props) => {
	const onModalClose = () => props.setVisibleEditForm(false)
	const [caption, setCaption] = useState(props?.selectedInfo?.name)
	const [photo, setTempPhoto] = useState(props?.selectedInfo?.photo)
	const [base64Img, setBase64Img] = useState(props.type === "OT" ? props.selectedInfo.base64 : null)

	const [visibleSelect, setVisibleSelect] = useState(false)
	const [options, setOptions] = useState(null)

	const [namePhoto, setNamePhoto] = useState(null)

	const [categoryDisable, setCategoryDisable] = useState(false)

	const { ID } = props?.selectedInfo

	const clearTempImage = () => {
		setTempPhoto(null)
		setBase64Img(null)
	}

	const inputFile = useRef()

	const onUploadOspic = async () => {
		const data = {
			"caption": caption,
		}

		const body = {
			"caption_name": caption,
			"updated_image": false,
		}
		if (base64Img && props.type === "OS") data["photo_base64"] = base64Img
		if (base64Img && props.type === "OT") body["photo_base64"] = base64Img



		try {
			if (caption !== null && base64Img !== null) {
				if (!categoryDisable) {
					if (props.type === 'OS') {
						await myFetch(`api/v1/ospic/${ID}/`, data, "PATCH")
						notification.success({
							style: { fontWeight: 'bold' },
							message: 'Actualizado con éxito',
							placement: 'bottomLeft',
							onClose: () => {
								onModalClose();
								window.location.reload();
							}
						});
					}
					if (props.type === 'OT') {
						await myFetch(`api/v1/wo_gallery/${ID}/`, body, "PATCH")
						notification.success({
							style: { fontWeight: 'bold' },
							message: 'Actualizado con éxito',
							placement: 'bottomLeft',
							onClose: () => {
								onModalClose();
								window.location.reload();
							}
						});
					}
				} else {
					notification.warning({
						style: { fontWeight: 'bold' },
						description: 'El máximo de imágenes para esta categoría es de 5',
						placement: 'bottomLeft',
						duration: 2,
					});
				}

			} else {
				notification.warning({
					style: { fontWeight: 'bold' },
					message: "Por favor, completa todos los campos",
					placement: 'bottomLeft',
					duration: 3,
				})
			}


		} catch (error) {
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'Error',
				description: 'No se ha podido actualizar',
				placement: 'bottomLeft',
				duration: 2.5,
				onClose: () => onModalClose()
			});
		}
	}

	const passingToBase64 = (archivos) => {

		setTempPhoto(archivos[0])

		Array.from(archivos).forEach(archivo => {

			const reader = new FileReader();
			reader.readAsDataURL(archivo);

			reader.onload = function () {
				let arraySplited = []
				const base64 = reader.result;

				arraySplited = base64.split(',');
				setBase64Img(arraySplited[1])
			}
		})
	}

	const hidden = { display: "none" }

	const clearImagePhoto = () => {
		setTempPhoto(null)
	}

	useEffect(() => {
		const option = props.listCategory?.map(item => {
			return { label: item.img_name, value: item.img_name }
		})
		setOptions(option)
	}, [])

	useEffect(() => {
		const fileName = photo.split('/').pop();
		setNamePhoto(fileName);
	}, [])

	useEffect(() => {
		setVisibleSelect(props.listCategory?.some(item => item.img_name === caption))
	}, [])

	useEffect(() => {
		if (props.categoryMatch[caption] >= 5) {
			setCategoryDisable(true)
		} else {
			setCategoryDisable(false)
		}
	}, [])


	return (
		<CustomModal onClick={onModalClose}>
			<Form>
				<Container>
					<MainTitle value='Modificar archivo' onClick={onModalClose} />

					<SubtitleBlue value='Información general' />

					{!visibleSelect ? (
						<div className={style.container_inpt_select}>
							<LabelDrawer value='Nombre de la imagen' />
							<Input
								className="input"
								style={{ height: "40px" }}
								placeholder='Ingrese el nombre de la imagen'
								value={caption}
								onChange={e => setCaption(e.target.value)}
							/>
						</div>

					) : (
						<div className={style.container_inpt_select}>
							<LabelDrawer value='Imágenes' />
							<Select
								style={{ height: "40px" }}
								placeholder='Seleccionar'
								options={options}
								onChange={value => setCaption(value)}
								value={caption}
							/>
						</div>
					)}

					<button
						className="uploader_box"
						onClick={() => inputFile.current.click()}
					>
						<i className="fa-solid fa-upload icon_size_button" />
						<span className='subtitle_button'>Haga click o arrastre el archivo a esta área para cargarlo</span>
						<span className='help_button'>Soporte para una carga única o masiva. Prohibido estrictamente</span>
						<span className='help_button'>cargar datos de la empresa u otros archivos de bandas.</span>
						<input type="file" ref={inputFile} onChange={(e) => passingToBase64(e.target.files)} style={hidden} />
					</button>

					<Br />

					{
						base64Img ?
							(<div className='temp_image_box'>
								<img className='temp_image animate__animated animate__fadeIn' src={`data:image/png;base64,${base64Img}`} alt="Temporal selected base64" />
								<p className="animate__animated animate__fadeIn temp_name">{photo?.name}</p>
								<Popconfirm
									placement="topRight"
									icon={""}
									title="¿Eliminar fotografía?"
									description={() => {
										return (
											`Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes`)
									}}
									onConfirm={clearTempImage}
									okText="Eliminar"
									cancelText="Cancelar"
								>
									<i className="fa-solid fa-trash clear_tempImageIcon" ></i>
								</Popconfirm>

							</div>)
							: photo && !base64Img ? (
								<div className='temp_image_box'>
									<img className='temp_image animate__animated animate__fadeIn' src={photo} alt="Temporal selected base64" />
									<p className="animate__animated animate__fadeIn temp_name">{namePhoto}</p>
									<Popconfirm
										placement="topRight"
										icon={""}
										title="¿Eliminar fotografía?"
										description={() => {
											return (
												`Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes`)
										}}
										onConfirm={clearImagePhoto}
										okText="Eliminar"
										cancelText="Cancelar"
									>
										<i className="fa-solid fa-trash clear_tempImageIcon" ></i>
									</Popconfirm>

								</div>
							) : null

					}

					<BottomSection onCancel={onModalClose} onAcept={onUploadOspic} minHeight={205} />


				</Container>
			</Form>
		</CustomModal>
	)
}



export default EditImageForm


const Container = styled.div`

p{
    display: inline;
    margin: inherit;
}

.formularioOSContent{
    width: ${size.drawerForm};
    height: 100%;
    display: flex;
    flex-flow: column;
    background: white;
    padding: 0 28.69px;
    padding-top: 33.43px;
  }

.row {
	margin: inherit;
	display: flex;
	align-items: center;
}

.column {
	display: flex;
	flex-flow: column;
}
.align-item-center{
    align-items: center;
}
.row-center{
    justify-content: center;
}
.flex-end{
    justify-content: flex-end;
}
.space-between{
    justify-content: space-between;
}
.column_center{
    justify-content: center;
}

.subtitle_button{
    font-size: ${smallSizes.fontSize.fontSize14};
    font-weight: bold;
    color: #2B80FF;
    margin-bottom: 1em;
}

.blue{
    color: #2B80FF;
}


.uploader_box {
	display: flex;
	flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: none;
  outline: none;
  border-radius: 3px;
  border: 1px dashed silver;
  background: #fafafa;
  padding: 1em;
  cursor: pointer;

  :hover {
			border: 1px dashed #d9d9d9;
		}
}
.temp_image_box {
  width: 100%;
  // height: 76px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  padding: 1em;
  display: flex;
  align-items: center;
}
.temp_image {
  width: 116px;
  height: 76px;
  border-radius: 3px;
  object-fit: cover;
}
.clear_tempImageIcon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #2B80FF;
  border-radius: 2px;
  cursor: pointer;

		:hover {
			color: grey;
		}
}

.uploader_box .icon_size_button{
	font-size: ${smallSizes.fontSize.fontSize36};
	color: #DDE4EB;
	margin-bottom: 10px;
}

.uploader_box .help_button{
	color: silver;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.temp_image_box .temp_name{
	color: #5A607F;
	padding: 0 10px;
	flex: 1;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.input{
	font-size: ${smallSizes.fontSize.fontSize12};
}

//------------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.subtitle_button{
    font-size: ${smallSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${smallSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.mediumDesk}){
	.subtitle_button{
    font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${mediumSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.largeDesk}){
	.subtitle_button{
    font-size: ${largeSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${largeSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

}

`


