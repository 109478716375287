import React, { useState } from 'react'

import style from './panelHeaderCalendarV2.module.css'
import GreyDivider from '../../greyDivider'

const PanelHeaderCalendarV2 = ({ children, title, subText, onVisibleFormOS, onVisibleFormOT }) => {

	const [open, setOpen] = useState(false);



	return (
		<header className={style.container_header}>
			<section className={style.top}>
				<span className={style.sub_container_header}>
					<h2 className={style.title_header}>{title}</h2>

					{children}

					<span className={style.sub_text_header}>{subText}</span>
				</span>

				{title === "Calendario de agendamiento" ? (
					<div className={style.container_button}>
						<button className={style.button_action} onClick={() => setOpen(!open)}>
							<i className="fa-solid fa-bars"></i>
							Acción
							<i className="fa-solid fa-chevron-down"></i>
						</button>
						{open && (
							<div className={style.container_option}>
								<button className={style.button_option} onClick={() => {
									onVisibleFormOS()
									setOpen(!open)
								}}>
									Agregar orden de servicio
								</button>
								<button className={style.button_option} onClick={() => {
									onVisibleFormOT()
									setOpen(!open)
								}}>
									Agregar orden de trabajo
								</button>
							</div>
						)}

					</div>
				) : (
					<button className={style.button_header}>
						Agregar orden de trabajo
					</button>
				)}

			</section>
			<GreyDivider />
		</header>
	)
}

export default PanelHeaderCalendarV2