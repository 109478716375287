import React from 'react'
import './index.scss'

const BlueButton = ({ value }) => {
    return (
        <span className='my__custom__main__blue__button__styles'>{value}</span>
    )
}


export default BlueButton