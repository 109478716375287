/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import { myFetch } from "../../services/services";
import { getAbsenceColumns } from "../../api/absences";
import styled from "styled-components";
import { Pagination, Table, Tooltip } from "antd";
import { useSelector } from "react-redux";
import NormalText from "../../components/text/NormalText";
import { useFilter } from "../../hooks/useFilter";
import moment from "moment";
import EditAbsenceForm from "./EditAbsenceForm";
import UseOperator from "../../hooks/UseOperator";
import { permissionCheck } from "../../helpers/helpers";
import NewAbsenceForm from "./newAbsenceForm/index.jsx";
import { sorterCallback } from "../../helpers/sorterCallback";
import './absences.scss'
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import { set } from "date-fns";
import EmptyData from "../../components/empty/emptyData/EmptyData.jsx";

export default function Absences() {
	const [visibleNewAbsenceForm, setVisibleNewAbsenceForm] = useState(false);
	const [visibleEditAbsenceForm, setVisibleEditAbsenceForm] = useState(false)

	const [ROWS, setRows] = useState([]);
	const [COLUMNS, setColumns] = useState([]);
	const [selectItem, setSelectItem] = useState(null);
	const [orderDirection, setOrderDirection] = useState("desc");

	const [loading, setLoading] = useState(true);
	const [emptyData, setEmptyData] = useState(false);

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}


	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Solicitudes de permiso de ausencias de los técnicos.</span>
		</ContainerTooltip>
	)

	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [totalSize, setTotalSize] = useState(0);

	//Filtro
	const { filters, rowsParams, setRowsParams } = useFilter()
	const { storedOperatorID } = UseOperator()

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const getAbsenceDatatable = async () => {
		const filter = {
			...filters,
			"operator": storedOperatorID,
			"order_field": "ID",
			"order_type": orderDirection,
		}

		try {
			const res = await myFetch("api/v1/absence/datatables/", filter);

			if (res.data.length === 0) setEmptyData(true)
			else setEmptyData(false)

			if (res?.size) { setTotalSize(res?.size) }
			if (res?.data) {
				const rows = res?.data?.map((row) => {
					const dateFormat = moment(row?.created).format("DD-MM-YYYY");
					return {
						...row,
						key: row?.ID,
						created: dateFormat,
					};
				});
				setRows(rows);
				setLoading(false);
			}
		} catch (e) { console.log(e) }
	}

	useEffect(() => {
		if (rolPermission("datatable_view_absence") === false) {
			history.replace("/dashboard")
		} else {
			getAbsenceColumns().then(res => {
				const data = res.map(item =>
					item.dataIndex === "ID" ?
						{
							...item,
							onCell: (record) => {
								if (rolPermission("change_absence") === true) {
									return {
										onClick: () => {
											setSelectItem(record)
											setVisibleEditAbsenceForm(true)
										},
										style: { cursor: "pointer" },
										className: "hover-table-class"
									};
								}

							}
						} : item
				);
				setColumns(data);
			})
		}

	}, [])

	useEffect(() => {
		storedOperatorID && getAbsenceDatatable()
	}, [rowsParams, storedOperatorID, orderDirection])



	return (
		<Panel value={visibleNewAbsenceForm} setValue={setVisibleNewAbsenceForm}>
			<PanelHeader
				buttonText="Registrar ausencias"
				noButton={rolPermission("add_absence") === true ? false : true}
				typeButton={"more"}
			>
				<span className="title_absence" >Listado de solicitud de ausencias</span>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement="bottom"
					overlayInnerStyle={{
						height: "95px",
						maxHeight: '100px'
					}}
				>
					<i className="fa-solid fa-circle-info circle-tooltip"></i>
				</Tooltip>
				<span className="sub_text_absences">Disponibilidad | Ausencias</span>
			</PanelHeader>



			<div className="absences-mainPage-container">

				{emptyData === false && loading === false && (
					<>
						<p className="single-pText">Listado consolidado de las ausencias solicitadas por los técnicos.</p>
						<Table
							columns={COLUMNS}
							dataSource={ROWS}
							pagination={false}
							onChange={
								(pagination, filters, sorter, extra) => setOrderDirection(sorterCallback(pagination, filters, sorter, extra))
							}
						/>

						<PaginationContainer>
							<div className="row space-between align-center">
								<span className="pagination">
									<NormalText
										bold={false}
										value={`Mostrando del ${rowsParams.start + 1} registros al ${rowsParams.start + rowsParams.offset} de un`}
									/>
									<NormalText
										bold={true}
										value={`total de ${totalSize} registros`}
									/>

								</span>
								<Pagination
									showSizeChanger
									defaultCurrent={paginationSettings[1]}
									current={paginationSettings[0]}
									onChange={modifyPagination}
									total={totalSize}
								/>
							</div>
						</PaginationContainer>
					</>
				)}

				{emptyData && loading === false && (
					<EmptyData sectionPage='ausencia' />
				)}

				{visibleNewAbsenceForm &&
					<NewAbsenceForm
						setIsVisibleForm={setVisibleNewAbsenceForm} />
				}

				{visibleEditAbsenceForm &&
					<EditAbsenceForm
						setIsVisibleForm={setVisibleEditAbsenceForm}
						selectItem={selectItem}
					/>
				}
			</div>
		</Panel>
	);
}


const PaginationContainer = styled.div`
	padding-top: 43.87px;
	padding-right: 41px;
	padding-bottom: 33px;
	padding-left: 42px;
	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}
	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}
	.align-center {
		align-items: center;
	}
	.pagination {
		color: #001737;
	}

	.ant-pagination{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	@media screen and (min-width: ${devices.smallDesk}){
	
	.ant-pagination{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	
	.ant-pagination{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	
	.ant-pagination{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;

const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 150px;
	}
`;