import React from 'react'
import styled from 'styled-components'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes'

// *Nota:
// devices es una const que viene del archivo "sizes", 
// contiene las 3 resoluciones desktop y las que se agreguen

const NormalText = ({ value, bold, size, color }) => {
	let textColor = color || "#172B4D"
	let weight = bold === true ? "bold" : "400"
	return (
		<NormalTextComponent>
			<span
				className='normal-text-media'
				style={{
					fontWeight: weight,
					color: textColor,
					paddingRight: 5
				}}>
				{value}
			</span>
		</NormalTextComponent>
	)
}

export default NormalText

const NormalTextComponent = styled.div`

.normal-text-media{
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}) {
	.normal-text-media{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}) {
	.normal-text-media{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}) {
	.normal-text-media{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`