import { myFetchGet } from "../services/services"

const typeOsTechnician = async (typeOrder) => {
	try {
		return await myFetchGet(`api/v1/technician/all_types_orders/?type=${typeOrder}`)
	} catch (error) {

	}
}

async function getCategory(idType, typeOrder) {
	try {
		return await myFetchGet(`api/v1/technician/all_categories_orders/?id=${idType}&type=${typeOrder}`)
	} catch (error) { }
}

async function getCategories() {
	try { return await myFetchGet(`api/v1/category/`) }
	catch (error) { }
}

export { getCategory, getCategories, typeOsTechnician }