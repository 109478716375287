// tabsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const tabsSlice = createSlice({
    name: 'tabs',
    initialState: {
        selectedTab: 0,
        showTabs: true
    },
    reducers: {
        selectTab: (state, action) => {
            state.selectedTab = action.payload.selectedTab;
            state.showTabs = action.payload.showTabs;
        },
    },
});

export const { selectTab } = tabsSlice.actions;
export default tabsSlice.reducer;