import React from 'react'
import styled from 'styled-components'
import { PlusOutlined, ExclamationOutlined, CheckOutlined, FieldTimeOutlined } from '@ant-design/icons'
import { useClassDashboard } from '../../../hooks/useClassDashboard'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes'


const TopCard = ({
	totalOrders,
	percentageCard,
}) => {

	const card1 = percentageCard?.find(item => item.nombre === "creadas")
	const card2 = percentageCard?.find(item => item.nombre === "por atender")
	const card3 = percentageCard?.find(item => item.nombre === "finalizadas")
	const card4 = percentageCard?.find(item => item.nombre === "canceladas")


	const percentSignoCard1 = Math.abs(card1?.porcentaje)
	const percentSignoCard2 = Math.abs(card2?.porcentaje)
	const percentSignoCard3 = Math.abs(card3?.porcentaje)
	const percentSignoCard4 = Math.abs(card4?.porcentaje)

	const classCard1 = useClassDashboard(card1)
	const classCard2 = useClassDashboard(card2)
	const classCard3 = useClassDashboard(card3)
	const classCard4 = useClassDashboard(card4)

	return (
		<Container>
			<div className="cards_container">
				<div className="card">
					<div className="row space-between responsived">
						<div className="text">
							<p className='card__title__p'>Total de órdenes</p>
							<p className='category-text'>Creadas</p>
							<p className="number">{totalOrders?.map((item) => item.nombre === "Creadas" ? item.cant : null)}</p>
						</div>

						<div className="circleIcon">
							<PlusOutlined className="icon_size" />
						</div>
					</div>
					<div className="row center">
						<p className={classCard1?.textCard}>{percentSignoCard1} %</p>
						<i className={classCard1?.classCard}></i>
						<p className='text-respect'>Respecto al día anterior</p>
					</div>
				</div>


				<div className="card">
					<div className="row space-between responsived">
						<div className="text">
							<p className='card__title__p'>Total de órdenes</p>
							<p className='category-text'>Por atender</p>
							<p className="number">{totalOrders?.map((item) => item.nombre === "Por atender" ? item.cant : null)}</p>
						</div>
						<div className="circleIcon"><FieldTimeOutlined className="icon_size" /></div>
					</div>
					<div className="row center">
						<p className={classCard2?.textCard}>{percentSignoCard2} %</p>
						<i className={classCard2?.classCard}></i>
						<p className='text-respect'>Respecto al día anterior</p>
					</div>
				</div>



				<div className="card">
					<div className="row space-between responsived">
						<div className="text">
							<p className='card__title__p'>Total de órdenes</p>
							<p className='category-text'>Finalizadas</p>
							<p className="number">{totalOrders?.map((item) => item.nombre === "Finalizadas" ? item.cant : null)}</p>
						</div>
						<div className="circleIcon"><CheckOutlined className="icon_size" /></div>
					</div>
					<div className="row center">
						<p className={classCard3?.textCard}>{percentSignoCard3} %</p>
						<i className={classCard3?.classCard}></i>
						<p className='text-respect'>Respecto al día anterior</p>
					</div>
				</div>



				<div className="card">
					<div className="row space-between responsived">
						<div className="text">
							<p className='card__title__p'>Total de órdenes</p>
							<p className='category-text' >Canceladas</p>
							<p className="number">{totalOrders?.map((item) => item.nombre === "Canceladas" ? item.cant : null)}</p>
						</div>
						<div className="circleIcon"><ExclamationOutlined className="icon_size" /></div>
					</div>
					<div className="row center">
						<p className={classCard4?.textCard}>{percentSignoCard4} %</p>
						<i className={classCard4?.classCard}></i>
						<p className='text-respect'>Respecto al día anterior</p>
					</div>
				</div>
			</div>
		</Container>
	)
}

export default TopCard

const Container = styled.div`

  .row{
      display: flex !important;
      margin: 0;
      padding: 0;
  }

  .space-between{
      justify-content: space-between;
  }

  .cards_container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.3em;
    align-self: center;
    margin-top: 2em;
}

.card{
    width: 100%;
    border-radius: 3px;
    border: 1px solid #DDE4EB;
    padding: 2em;
    display: flex;
    justify-content: center;
    flex-flow: column;
}

.text {
  display: flex;
  flex-flow: column;
}

.text .card__title__p{
    color: #5A607F;
    font-size: ${smallSizes.fontSize.fontSize14};
    font-weight: 500;
    width: 100%;
}

.number{
    color: #131523;
    font-size: ${smallSizes.fontSize.fontSize22};
    font-weight: bold;
    margin: .4em 0 !important;
}

.circleIcon{
    width: 80px;
    height: 80px;
    background: #0058FF1A;
    border: 1px solid #0058FF19;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon_size{
    color: #172B4D;
    font-size: 40px ;
}

.percent_up{
	color: #3DD598;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: 400;
}

.arrow_icon_up{ 
	color: #3DD598;
	font-size: ${smallSizes.fontSize.fontSize14};
	margin: 0 14px;
}

.percent_down{
	color: #F0142F;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.arrow_icon_down{ 
	color: #F0142F;
	font-size: ${smallSizes.fontSize.fontSize14};
	margin: 0 14px;
}

.arrow_icon_equal{
	color:#2B80FF;
	font-size: ${smallSizes.fontSize.fontSize14};
	margin: 0 14px;
}

.percent_equal{
	color: #2B80FF;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.text-respect{
  font-size: ${smallSizes.fontSize.fontSize10};
  color: #7E84A3;
}

.category-text{
	font-size: ${smallSizes.fontSize.fontSize14};
	font-weight: bold;
}

@media screen and (min-width: ${devices.smallDesk}){

	.text .card__title__p{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.category-text{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.number{
    font-size: ${smallSizes.fontSize.fontSize22};
	}

	.percent_up{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.arrow_icon_up{ 
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.percent_down{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.arrow_icon_down{ 
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.arrow_icon_equal{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.percent_equal{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.text-respect{
		font-size: ${smallSizes.fontSize.fontSize10};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.text .card__title__p{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.category-text{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.number{
    font-size: ${mediumSizes.fontSize.fontSize22};
	}

	.percent_up{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.arrow_icon_up{ 
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.percent_down{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.arrow_icon_down{ 
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.arrow_icon_equal{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.percent_equal{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.text-respect{
		font-size: ${mediumSizes.fontSize.fontSize10};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.text .card__title__p{
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.category-text{
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.number{
    font-size: ${largeSizes.fontSize.fontSize22};
	}

	.percent_up{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.arrow_icon_up{ 
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.percent_down{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.arrow_icon_down{ 
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.arrow_icon_equal{
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.percent_equal{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.text-respect{
		font-size: ${largeSizes.fontSize.fontSize10};
	}
}
`;
