import React, { useEffect, useState } from 'react'

import style from './workOrderAttention.module.css'
import LabelDrawer from '../../../../../../components/text/drawer/label'
import { Checkbox, Input, Select, Switch } from 'antd'
import { debounce } from "lodash";
import { myFetchGet } from '../../../../../../services/services';
import SearchDirection from '../../../searchDirection/SearchDirection';
import { emptyInputsCheck } from '../../../../../../helpers/helpers';

const { TextArea } = Input

const WorkOrderAttention = ({
	selectedCategoryId,
	setSelectedDirectionId,
	setSelectedTechnicalId,
	setSelectedSupervisorRequeried,
	setSelectedAuxiliariesId,
	setComentAditional,
	setViewAditionalComent,
	viewAditionalComent,
	camposIncompletos,
	selectedTechnicalId,
	setSelectedDirection,
	setGetListTechnician,
	getListTechnician,
	setGetListAuxiliaries,
	getListAuxiliaries,
	selectedAuxiliariesId

}) => {

	const [searchDirection, setSearchDirection] = useState('');
	const [dataDirection, SetDataDirection] = useState([])
	const [loaddingSearchDirection, SetLoaddingSearchDirection] = useState(false)
	const [isVisibleListResultDirection, SetIsVisibleListResultDirection] = useState(false)
	const [directionSelected, setDirectionSelected] = useState('');

	const [getListAuxiliariesFilter, setGetListAuxiliariesFilter] = useState([])

	const onViewAditionalComent = (checked) => {
		setViewAditionalComent(checked)
	}

	const handleKeyUpDirection = () => {
		handlerSearchDirection();
	}

	const handleKeyDownDirection = () => {
		handlerSearchDirection.cancel();
	}

	const handlerSearchDirection = debounce(() => {
		if (searchDirection !== '') getDirection();
	}, 750);

	const onDirectionSearch = ({ target }) => {
		setSearchDirection(target.value);
		SetIsVisibleListResultDirection(true);
		setDirectionSelected(target.value);
	}

	const getDirection = async () => {
		const res = await myFetchGet(`api/v1/direction/search/?generic=${searchDirection}`);
		SetDataDirection(res);
		SetLoaddingSearchDirection(true);
	}

	const handleSelectDirection = (item) => {
		setDirectionSelected(item);
		SetIsVisibleListResultDirection(false);
	}

	const getTechnician = async (categoryId) => {
		try {
			const res = await myFetchGet(`api/v1/schedule/main_category_match_ot/?category=${categoryId}`);
			const listSelect = res.data.map((item) => {
				return { value: item.id, label: item.name }
			})
			setGetListTechnician(listSelect)
		} catch (error) { }
	}

	const getAuxiliaries = async (categoryId) => {
		try {
			const response = await myFetchGet(`api/v1/schedule/auxiliaries_category_match_ot/?category=${categoryId}`);
			const listSelect = response.data.map((item) => {
				return { value: item.id, label: item.name }
			})
			setGetListAuxiliariesFilter(listSelect)
		} catch (error) { }
	}

	const handlerSelectTechnician = (value) => {
		setSelectedTechnicalId(value)
		setSelectedAuxiliariesId(null)
	}

	const handlerCheckSupervisor = (e) => {
		setSelectedSupervisorRequeried(e.target.checked)
	}

	const handlerSelectAuxiliaries = (value) => {
		setSelectedAuxiliariesId(value)
	}

	const handlerTextComentAditional = (e) => {
		setComentAditional(e.target.value)
	}

	useEffect(() => {
		if (searchDirection === '') {
			SetIsVisibleListResultDirection(false);
			SetLoaddingSearchDirection(false);
		}
	}, [searchDirection])


	useEffect(() => {
		selectedCategoryId && getTechnician(selectedCategoryId)
		selectedCategoryId && getAuxiliaries(selectedCategoryId)
	}, [selectedCategoryId])

	useEffect(() => {
		if (directionSelected.id !== undefined) {
			setSelectedDirectionId(directionSelected.id);
			setSelectedDirection(directionSelected);
		}
	}, [directionSelected])

	useEffect(() => {
		if (selectedTechnicalId) {
			const auxiliaries = getListAuxiliariesFilter.filter(item => item.value !== selectedTechnicalId)
			setGetListAuxiliaries(auxiliaries)
		}
	}, [selectedTechnicalId])


	return (
		<section className={style.container}>
			<header className={style.container_header}>
				<div className={style.circle} />
				<p className={style.text_header}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum sem libero. Morbi mollis eleifend efficitur. Morbi sed ligula semper leo maximus .</p>
			</header>

			<div>
				<LabelDrawer value='Buscar dirección' />
				<Input
					style={{ height: 40 }}
					placeholder='Ingrese su dirección'
					onKeyUp={handleKeyUpDirection}
					onKeyDown={handleKeyDownDirection}
					onChange={onDirectionSearch}
					value={directionSelected.full_direction}
					allowClear
					status={emptyInputsCheck(camposIncompletos, 'direction') ? 'error' : ''}
				/>
			</div>

			{isVisibleListResultDirection &&
				<SearchDirection
					data={dataDirection}
					loadding={loaddingSearchDirection}
					handleSelectDirection={handleSelectDirection}
				/>}

			<div className={style.container_select}>
				<LabelDrawer value='Técnico principal' />
				<Select
					style={{ flex: 1, height: 40 }}
					placeholder='Seleccionar'
					options={getListTechnician}
					onChange={handlerSelectTechnician}
					value={selectedTechnicalId}
					status={emptyInputsCheck(camposIncompletos, 'technician') ? 'error' : ''}
				/>
				<Checkbox
					className={style.checkbox}
					onChange={handlerCheckSupervisor}
				>
					Amerita apoyo del supervisor
				</Checkbox>
			</div>

			<div className={style.container_select}>
				<LabelDrawer value='Auxiliares' />
				<Select
					style={{ flex: 1, height: 40 }}
					mode='multiple'
					placeholder='Seleccionar'
					options={getListAuxiliaries}
					onChange={handlerSelectAuxiliaries}
					value={selectedAuxiliariesId}
					status={emptyInputsCheck(camposIncompletos, 'auxiliaries') ? 'error' : ''}
				/>
			</div>

			<div className={style.container_coment_aditional}>
				<p className={style.text_coment_aditional}>¿Desea agrear comentario adicional?</p>
				<span className={style.container_switch}>
					<p className={style.text_switch}>No</p>
					<Switch onChange={onViewAditionalComent} />
					<p className={style.text_switch}>Si</p>
				</span>
			</div>
			{viewAditionalComent && (
				<TextArea
					style={{ height: 100 }}
					placeholder='Ingresar comentario adicional'
					onChange={handlerTextComentAditional}
					status={emptyInputsCheck(camposIncompletos, 'comments') ? 'error' : ''}
				/>
			)}

		</section>
	)
}

export default WorkOrderAttention