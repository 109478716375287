import React from "react";
import styled from "styled-components";
import { Skeleton, Space } from "antd";

export function LoadingForm({ count = 4, buttons = 2, showButtons = true }) {

	const skeletonForm = Array.from({ length: count }, (_, i) => (
		<div className="row space-between" key={i}>
			<section className="column flex">
				<Space direction="vertical" size={"small"} style={{ width: "95%" }}>
					<Skeleton.Input size="small" active={true} />
					<Skeleton.Input size="default" active={true} block={true} />
				</Space>
			</section>
		</div>
	));

	const skeletonFormButtons = Array.from({ length: buttons }, (_, i) => (
		<Skeleton.Button size="large" active={true} key={i} />
	));

	return (
		<Container>
			<Space direction="vertical" size={30} style={{ width: "100%" }}>
				<Skeleton.Input size="small" active={true} />
				{skeletonForm}
				{showButtons ? (
					<div className="row row_end">
						<Space size={"middle"}>{skeletonFormButtons}</Space>
					</div>
				) : null}
			</Space>
		</Container>
	);
};

const Container = styled.div`
	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}

	.row_end {
		justify-content: flex-end;
	}

	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}

	.column {
		display: flex;
		flex-flow: column;
	}

	.flex {
		flex: 1;
	}
`;