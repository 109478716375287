import React, { useEffect, useState } from 'react'
import { Panel } from '../../../../components/panel/panel'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue'

import style from './generalPanel.module.css'
import Avatar from 'antd/es/avatar/avatar'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import styled from 'styled-components'
import WorkDescriptionForm from './components/workDescriptionForm/WorkDescriptionForm'
import EditWorkOrderAttention from './components/editWorkOrderAttention/EditWorkOrderAttention'
import Reschedule from './components/reschedule/Reschedule'
import { useDispatch } from 'react-redux'
import { selectTab } from '../../../../store/tab/tabSlice'

const GeneralPanel = ({ data, setViewReschedule, viewReschedule }) => {

	const dispatch = useDispatch();

	const {
		creation_date,
		type_ot,
		category_name,
		service_date,
		hours_schedule,
		description,
		technician,
		auxiliaries,
		comments,
		status_name,
		direction,
		coordinates,
		supervisor,
		id_unico
	} = data

	const [initialTechnician, setInitialTechnician] = useState("")
	const [coordinatesArray, setCoordinatesArray] = useState([coordinates.lat, coordinates.lng])

	const [viewWorkDescription, setViewWorkDescription] = useState(false)
	const [viewEditWorkOrder, setViewEditWorkOrder] = useState(false)

	const [reschedule, setReschedule] = useState(false)

	const [showReagendarButton, setShowReagendarButton] = useState(true);

	const okBtnStatus = [
		'Agendada',
		'Cancelada',
		'En desplazamiento',
		'Pausada',
		'En espera del cliente',
		'Por reagendar'
	]

	const redCircleIcon = L.divIcon({
		className: `${style.custom_icon}`,
		html: `
		<div class=${style.puntero}>
			<div class=${style.circle_blue}>
				<div class=${style.circle_white}>
					<div class=${style.circle_red}></div>
    </div>
  </div>
</div>
		`,
	});

	const hiddingTabs = () => {
		dispatch(
			selectTab({
				selectedTab: 0,
				showTabs: false,
			})
		)
	};

	const showingTabs = () => {
		dispatch(
			selectTab({
				selectedTab: 0,
				showTabs: true,
			})
		)
	};

	useEffect(() => {
		const InitialChart = technician.full_name.split(" ")[0][0]
		setInitialTechnician(InitialChart)
	}, [])

	useEffect(() => {
		const arrayCoordinates = Object.values(coordinates)
		setCoordinatesArray(arrayCoordinates)
	}, [])

	useEffect(() => {
		if (reschedule) {
			hiddingTabs()
			setViewReschedule(true)
		} else {
			showingTabs()
			setViewReschedule(false)
		}
	}, [reschedule])

	useEffect(() => {
		setShowReagendarButton(
			// si el array contiene el btn status que muestra el btn de reagendar
			//arroja true, y muestra el button, statuses incorrectos, NO.
			okBtnStatus.includes(status_name) ? true : false
		);
	}, [status_name]);

	return (
		<>
			{!viewReschedule && (
				<Panel>
					<section className={style.container_general}>
						<section className={style.divider}>
							<SubtitleBlue value='Detalle orden de trabajo' />
							<span>
								<p className={`${style.text12font} ${style.color5A607F} ${style.bold}`}>Creación : </p>
								<p className={`${style.text12font} ${style.color959595}`}>{creation_date}</p>
							</span>

							<div className={style.containerType}>
								<span className={style.panelType}>
									<p className={style.typeOt}>{type_ot.name}</p>
								</span>

								<span className={style.category}>
									<p className={`${style.text12font} ${style.color172B4D} ${style.bold}`}>Categoría</p>
									<p className={`${style.text12font} ${style.color959595}`}>{category_name}</p>
								</span>
							</div>

							<div className={style.container_date}>
								<span className={style.flex_column}>
									<p className={`${style.text12font} ${style.color959595} ${style.bold}`}>Fecha de atendimiento</p>
									<p className={`${style.text14font} ${style.color172B4D}`}>{service_date}</p>
								</span>
								<span className={style.flex_column}>
									<p className={`${style.text12font} ${style.color959595} ${style.bold}`}>Horario</p>
									<p className={`${style.text14font} ${style.color172B4D}`}>{hours_schedule}</p>
								</span>
							</div>

							<p className={`${style.text12font} ${style.color959595} ${style.bold} ${style.margin22}`}>Descripción del trabajo</p>
							<p className={`${style.text14font} ${style.color172B4D}`}>{description}</p>

							<p className={`${style.detailView} ${style.text14font}`} onClick={() => setViewWorkDescription(true)}>Ver detalle</p>

							<SubtitleBlue value='Atendimiento orden de trabajo' />

							<div className={style.container_detail}>
								<Avatar className={style.avatar_user}>
									{initialTechnician}
								</Avatar>

								<div className={style.description_user}>
									<span className={style.subcontainer_title}>
										<p className={`${style.user_name} ${style.bold}`}>{technician.full_name}</p>
										<i className={`fa-solid fa-pen-to-square ${style.icon_edit}`} onClick={() => setViewEditWorkOrder(true)} />
									</span>

									<p className={style.user_text}>Técnico Principal</p>
									<p className={`${style.user_text} ${style.bold}`}>{supervisor ? "apoyo de supervisor" : "Sin apoyo de supervisor"}</p>
									<p className={`${style.user_text} ${style.bold}`}>{`Auxiliar + ${auxiliaries.length}`}</p>

									{auxiliaries.map((item) => {

										const nameINitial = item.full_name.split(" ")[0][0]

										return (
											<span key={item.id} className={style.container_assistant}>
												<Avatar className={style.avatar_assistant} >{nameINitial}</Avatar>
												<p className={`${style.text12font} ${style.color5A607F}`}>{item.full_name}</p>
											</span>
										)
									})}

									{comments && (
										<>
											<p className={`${style.text12font} ${style.bold} ${style.color5A607F}`}>Comentario adicional</p>
											<p className={`${style.text12font} ${style.color5A607F}`}>{comments}</p>
										</>
									)}

								</div>
							</div>
						</section>

						<section style={{ display: 'flex', width: '50%' }}>
							<ContainerMap>
								<section className={style.container_route}>
									<MapContainer center={coordinatesArray} zoom={18} style={{ height: '75vh' }}>
										<TileLayer
											attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
											url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
										/>
										<Marker position={coordinatesArray} icon={redCircleIcon}>
										</Marker>
									</MapContainer>

									{showReagendarButton && (
										<button className={style.modal_reagendar} onClick={() => setReschedule(true)}>
											<i className="fa-solid fa-calendar-days" />
											<p className={style.text12font}>Reagendar</p>
										</button>
									)}

									<div className={style.modal_status}>
										<p>{status_name}</p>
									</div>

									<section className={style.modal_direccion}>
										<div className={style.container_direction}>
											<span className={style.location}>
												<i className={`fa-solid fa-location-dot ${style.icon_location}`} />
											</span>

											<span className={`${style.flex_column} ${style.span_direction}`}>
												<p className={`${style.text14font} ${style.bold}`}>Dirección</p>
												<p className={style.text12font}>{direction}</p>
											</span>

										</div>
									</section>
								</section>
							</ContainerMap>



						</section>

					</section>
					{viewWorkDescription && (
						<WorkDescriptionForm setViewWorkDescription={setViewWorkDescription} data={data} />
					)}

					{viewEditWorkOrder && (
						<EditWorkOrderAttention setViewEditWorkOrder={setViewEditWorkOrder} data={data} />
					)}

				</Panel >
			)}


			{viewReschedule &&
				<Reschedule
					setReschedule={setReschedule}
					id_unico={id_unico}
					type_ot={type_ot}
				/>
			}

		</>
	)
}

export default GeneralPanel

const ContainerMap = styled.div`
width: 100%;

.leaflet-right{
	display: none;
}

.leaflet-control{
	display: none;
}
`