import React, { useEffect, useState } from 'react'
import Form from '../form/index.jsx'
import MainTitle from '../text/drawer/mainTitle/index.jsx'
import SubtitleBlue from '../text/drawer/subtitleBlue/index.jsx'
import style from './detailForm.module.css'

export const DetailForm = ({
	dataImages,
	setViewImages,
	dataQuestions,
	setViewQuestions,
	viewImages,
	viewQuestions,
	isOrderTypeOT = false
}) => {

	const [images, setImages] = useState([])
	const [questions, setquestions] = useState([])

	const Table = ({ data }) => {
		return (
			<table>
				<thead className={style.header_table}>
					<tr>
						<th className={style.th_one}>Categorías</th>
						<th className={style.th_two}>{viewImages ? "Imágenes" : "Preguntas"}</th>
						{viewQuestions && <th className={style.th_three}></th>}
					</tr>
				</thead>
				<tbody className={style.body_table}>

					{viewImages && data.map(item => (
						<tr key={item.id}>
							<td className={style.colum_category}>{item.category}</td>
							<td >
								{item.images.map((name) => (
									<div key={name} className={style.span_table}>{name}</div>
								))}
							</td>
						</tr>
					))}

					{viewQuestions &&
						data.map(item => (
							<tr key={item.id}>
								<td className={style.colum_category}>{item.category}</td>

								<td >
									{item.questions.map(q => (
										<div key={q.name} className={style.span_table}>{q.name}</div>
									))}
								</td>

								<td >
									{item.questions.map(q => (
										<div key={q.name} className={style.span_table}>
											{q.kind === 1 ? "(Si y No)" : "(Abierta)"}
										</div>
									))}
								</td>

							</tr>
						))
					}
				</tbody>
			</table>
		);
	}

	const oncloseForm = () => {
		if (viewImages) setViewImages(false)
		if (viewQuestions) setViewQuestions(false)
	}

	useEffect(() => {
		if (dataImages) {
			const dataImage = dataImages.imgs.map(item => item.img_name)
			setImages(dataImage)
		}
		if (dataQuestions) {
			const dataQuestion = dataQuestions.questions.map(item => { return { name: item.question, kind: item.kind } })
			setquestions(dataQuestion)
		}
	}, [])

	return (
		<Form>
			<MainTitle
				value={`${viewImages && isOrderTypeOT === false ?
					"Imágenes orden de servicio" :
					viewImages && isOrderTypeOT ?
						"Imágenes orden de trabajo" :
						viewQuestions && isOrderTypeOT ?
							"Preguntas orden de trabajo" :
							"Preguntas orden de servicio"
					}`}
				onClick={() => setViewImages(false)}
			/>

			<SubtitleBlue
				value={`${viewImages && isOrderTypeOT === false ?
					"Formato de visualización de imágenes de orden de servicio" :
					viewImages && isOrderTypeOT ?
						"Formato de visualización de imágenes de orden de trabajo" :
						viewQuestions && isOrderTypeOT ?
							"Formato de visualización de preguntas de orden de trabajo" :
							"Formato de visualización de preguntas de orden de servicio"
					}`}
			/>

			<p className={style.text}>{isOrderTypeOT ?
				"Configuración de Categorías de la orden de trabajo habilitadas para el técnico" :
				"Configuración de Categorías de la orden de servicio habilitadas para el técnico"}
			</p>
			{viewImages && !viewQuestions && (
				<Table
					data={[
						{
							id: dataImages.ID,
							category: dataImages.name,
							images: images
						}
					]}
				/>
			)}

			{viewQuestions && !viewImages && (
				<Table
					data={[
						{
							id: dataQuestions.ID,
							category: dataQuestions.name,
							questions: questions
						}
					]}
				/>
			)}


			<section className={style.container_button}>
				<button className={style.button_close} onClick={oncloseForm}>
					Cerrar detalle
				</button>
			</section>

		</Form>
	)
}
