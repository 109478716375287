import React, { useCallback, useEffect, useState } from 'react'
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/plugin/utc';
import { notification } from 'antd';

import getMessagesES from '../../../../helpers/getMessages.js'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx'

import CustomModal from '../../../../components/modal/CustomModal.jsx';
import ZoneAvilitationForm from '../zoneAvilitationForm/ZoneAvilitationForm.jsx';

import { stringCapitalize } from '../../../../helpers/helpers.js';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../helpers/sizes.js';
import { useSelector } from 'react-redux';
import CardEventModal from '../cardEventModal/CardEventModal.jsx';
import { myFetchGet } from '../../../../services/services.js';

const AvailableCalendar = ({ close, technicianID, avalityOraculoOperator }) => {

	const dataRedux = useSelector((state) => state.authState);
	const localizer = dayjsLocalizer(dayjs);

	const [zoneForm, setZoneForm] = useState(false);
	const [directions, setDirections] = useState(null);

	const [currentDate, setCurrentDate] = useState(new Date());
	const [firstDayOfWeek, setFirstDayOfWeek] = useState(null);

	const [rolePermissions, setRolePermissions] = useState(false);

	// virtual
	const [sendToForm, setSendToForm] = useState([]);
	const [disponibilityArray, setDisponibilityArray] = useState([]);

	// ----------Star event----------------
	const [virtualEvent, setVirtualEvent] = useState(null)
	const [isVisibleForm, setIsVisibleForm] = useState(false);


	const [specialEvent, setSpecialEvent] = useState(0);
	const [dayDateWeek, setDayDateWeek] = useState(null);


	const dataAvailability = async () => {
		try {
			const response = await myFetchGet(`api/v1/create_disponibility/?technician=${technicianID}&date=${dayDateWeek}`);
			const data = response.availabilities.map(item => {
				return {
					title: item?.action === 1 ? "Evento especial" : "Bloque de disponibilidad",
					start: dayjs(item?.datetime_start, "DD-MM-YYYY HH:mm").toDate(),
					end: dayjs(item?.datetime_end, "DD-MM-YYYY HH:mm").toDate(),
					type: item?.action,
					color: "#0060F8",
					opacity: 0.5,
				};
			});
			setDisponibilityArray(data);
		} catch (error) {
			console.log(error);
		}
	}

	const CustomHeader = ({ label, onNavigate }) => {
		return (
			<CustomHeaderCalendar>
				<div className="left">
					<button onClick={close} className='arrow_back'>
						<i className="fa-solid fa-chevron-left" style={{ color: "#2B80FF", marginRight: 10 }}></i>
						<SubtitleBlue marginBottom="3px" value="Regresar" />
					</button>
				</div>

				<span className="mid">
					<button onClick={() => onNavigate('PREV')}>{'<'}</button>
					<span className="label">{label}</span>
					<button onClick={() => onNavigate('NEXT')}>{'>'} </button>
				</span>

				<div className="right">
					<button
						className="right__bn"
						onClick={sendToForm.length === 0 ? notificationCalendar : onModalZoneOpen}
					>
						<span>Continuar Habilitación</span>
					</button>
				</div>
			</CustomHeaderCalendar >
		)
	}

	const handleNavigate = date => {
		const firstDay = dayjs(date).startOf('week').toDate();
		setCurrentDate(date);

		const formattedDate = dayjs(firstDay).format("DD-MM-YYYY");
		setFirstDayOfWeek(formattedDate);
	};

	const minTime = new Date();
	minTime.setHours(7, 0, 0); // set minimum time to 7am

	const maxTime = new Date();
	maxTime.setHours(19, 0, 0); // set maximum time to 7pm

	// -------------Calendar Events-----------------
	const events = disponibilityArray?.map((item) => {
		return {
			title: item?.title,
			start: item?.start,
			end: item?.end,
			type: item?.type,
			color: item?.color,
			opacity: item?.opacity,
		};
	});

	const handleSelectSlot = (event) => {
		onModalOpen()
		const selectedDate = dayjs(event?.start).format("DD-MM-YYYY");
		const selectedStart = dayjs(event?.start).format("HH:mm");
		const selectedEnd = dayjs(event?.end).format("HH:mm");

		setVirtualEvent({
			date: selectedDate,
			start: selectedStart,
			end: selectedEnd,
		})
	};

	const eventStyleGetter = (event) => ({
		style: {
			backgroundColor: event.color,
			borderRadius: 0,
			color: 'white',
			border: 0,
			cursor: 'pointer',
			opacity: event?.opacity,
		}
	});

	const onModalZoneClose = () => setZoneForm(false)
	const onModalZoneOpen = () => setZoneForm(true)

	const onStartChange = (_, timeString) => setVirtualEvent({ ...virtualEvent, "start": timeString })
	const onEndChange = (_, timeString) => setVirtualEvent({ ...virtualEvent, "end": timeString })

	const startEvent = dayjs(`${virtualEvent?.date} ${virtualEvent?.start}`, 'DD-MM-YYYY HH:mm').locale('en').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
	const endEvent = dayjs(`${virtualEvent?.date} ${virtualEvent?.end}`, 'DD-MM-YYYY HH:mm').locale('en').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')


	const toDateEndEvent = new Date(Date.parse(endEvent))
	const toDateStart = new Date(Date.parse(startEvent))

	// 
	const newEventToCalendar = {
		title: specialEvent === 1 ? "Evento especial" : "Bloque de disponibilidad",
		end: toDateEndEvent,
		start: toDateStart,
		color: specialEvent === 1 ? "#0412C2" : "#0060F8"
	}

	const onModalClose = () => setIsVisibleForm(false)
	const onModalOpen = () => setIsVisibleForm(true)

	const notificationCalendar = () => {
		notification.warning({
			style: { fontWeight: 'bold' },
			message: "Selecciona una fecha para continuar",
			placement: 'bottomLeft',
			duration: 2.5,
		});
	};

	// añade bloques creados al Array de Disponibilidades.
	const addBlockTimeArray = () => {
		setDisponibilityArray(prevDisponibility => [...prevDisponibility, newEventToCalendar]);
		setSendToForm(prevDisponibility => [...prevDisponibility, {
			"date": virtualEvent?.date,
			"start": virtualEvent?.start,
			"end": virtualEvent?.end,
			"type": specialEvent,
			"action": 0,
		}]);
		onModalClose()
	}

	useEffect(() => {
		dayDateWeek && dataAvailability();
	}, [dayDateWeek])


	useEffect(() => {
		handleNavigate()
	}, [setCurrentDate])

	useEffect(() => {
		if (dataRedux.rol === 'supervisor_isp' || dataRedux.rol === 'admin_isp' || dataRedux.rol === 'admin') {
			setRolePermissions(true);
		} else {
			setRolePermissions(false);
		}
	}, [dataRedux]);

	useEffect(() => {
		const date = new Date(); // La fecha actual
		let firstDay = new Date(date);
		let dayOfWeek = date.getDay();
		dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Ajustamos para que lunes sea 0
		firstDay.setDate(firstDay.getDate() - dayOfWeek);

		const firstDayWeek = dayjs(firstDay).format("DD-MM-YYYY")
		setDayDateWeek(firstDayWeek)
	}, []);

	return (
		<Container>
			<Calendar
				localizer={localizer}
				events={events}
				eventPropGetter={eventStyleGetter}
				startAccessor="start"
				endAccessor="end"
				style={{ height: "98vh" }}
				scrollToTime={new Date(0, 0, 0, 7, 0, 0)}
				views={["", "week", ""]}
				defaultView="week"
				culture='es'
				components={{
					toolbar: CustomHeader,
				}}
				tooltipAccessor=""
				onSelectSlot={handleSelectSlot}
				selectable
				defaultDate={currentDate}
				onNavigate={(date, view) => {
					if (view === 'week') {
						let firstDay = new Date(date);
						let dayOfWeek = date.getDay();
						dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Ajustamos para que lunes sea 0
						firstDay.setDate(firstDay.getDate() - dayOfWeek);

						const firstDayWeek = dayjs(firstDay).format('DD-MM-YYYY')
						setDayDateWeek(firstDayWeek)
					}
					handleNavigate(date)
				}}
				messages={getMessagesES()}
				formats={{
					timeGutterFormat: "h:mm a",
					dayHeaderFormat: (date, culture, localizer) =>
						stringCapitalize(localizer.format(date, "dddd D [de] MMMM", culture)),
					dayRangeHeaderFormat: ({ start, end }) => (
						`${stringCapitalize(dayjs(start).format('MMM D'))} - ${stringCapitalize(dayjs(end).format('MMM D'))}`
					),

					dayFormat: (date, culture, localizer) =>
						stringCapitalize(localizer.format(date, 'ddd DD/MM', culture)),
				}}
			/>

			{isVisibleForm && (
				<CustomModal>
					<CardEventModal
						virtualEvent={virtualEvent}
						onStartChange={onStartChange}
						onEndChange={onEndChange}
						onModalClose={onModalClose}
						addBlockTimeArray={addBlockTimeArray}
						setSpecialEvent={setSpecialEvent}
						specialEvent={specialEvent}
						deleteblock={false}
					/>
				</CustomModal>
			)}

			{zoneForm && (
				<CustomModal onClick={onModalZoneClose}>
					<ZoneAvilitationForm
						onModalZoneClose={onModalZoneClose}
						technicianID={technicianID}
						sendToForm={sendToForm}
						firstDayOfWeek={firstDayOfWeek}
						avalityOraculoOperator={avalityOraculoOperator}
					/>
				</CustomModal>
			)}
		</Container>
	)
}

export default AvailableCalendar

const Container = styled.div`
width: "100%";

.rbc-event{
  // background: #0060F8;
	padding-left: 10px;
}

.rbc-button-link {
	font-size: ${smallSizes.fontSize.fontSize15};
}

.rbc-day-slot .rbc-event-label {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-day-slot .rbc-event-content {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-time-slot .rbc-label{
	font-size: ${smallSizes.fontSize.fontSize12}
}

@media screen and (min-width: ${devices.smallDesk}){
	.rbc-button-link {
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${smallSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.rbc-button-link {
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.rbc-button-link {
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${largeSizes.fontSize.fontSize12}
	}
}
`

const CardEvent = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card{
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    padding: 20px;
    display : flex;
    flex-flow: column;
    background: white;
  }

  .row{
    display: flex;
    margin: 0 !important;
    padding: 0 !important;
  }
  .flex-end{
    justify-content: flex-end;
  }
  .space-between{
    display: flex;
    justify-content: space-between;
  }

  .left__picker{
    width: 47%;
    background: white;
  }
  .right__picker{
    width: 47%;
    background: white;
  }
  button{
    border: none;
    outline: none;
  }

 .ant-picker-suffix {display: none}

	.btn_normal{
    background: transparent;
    font-size: ${smallSizes.fontSize.fontSize12};
    color: #5A607F;
    z-index:10;
    margin-right: 14px;
    width: ${smallSizes.reagendarButon.widtButton};
    height: ${smallSizes.reagendarButon.heightButton};
    cursor: pointer;
  }

  .btn_silver{
    background: #DDE4EB;
		font-size: ${smallSizes.fontSize.fontSize12};
    color: #001737;
    z-index:10;
    border-radius: 3px;
    width: ${smallSizes.reagendarButon.widtButton};
    height: ${smallSizes.reagendarButon.heightButton};
    cursor: pointer;

    :hover{
      background: #2B80FF;
      color: white;
    }
  }


  .fa-trash{
    color: white;
    margin: 0;
    padding: 0;
    font-size: 11px
  }

	.container_switch{
		display: flex;
    flex-direction: column;
    margin-top: 13.6px;
	}

	.text_specia_event{
		font-size: ${smallSizes.fontSize.fontSize12};
		font-weight: bold;
	}

	.subcontainer_switch{
		display: flex;
		align-items: center;
    margin-top: 8px;
	}

	.text_switch_specia_even{
		font-size: ${smallSizes.fontSize.fontSize12};
		color: #131523;
	}

	.text_card{
		color: #868E96;
		font-size: ${smallSizes.fontSize.fontSize14};
	}
  
	@media screen and (min-width: ${devices.smallDesk}){

		.btn_normal{
			font-size: ${smallSizes.fontSize.fontSize12};
			width: ${smallSizes.reagendarButon.widtButton};
			height: ${smallSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${smallSizes.fontSize.fontSize12};
			width: ${smallSizes.reagendarButon.widtButton};
			height: ${smallSizes.reagendarButon.heightButton};
		}

		.text_card{
			font-size: ${smallSizes.fontSize.fontSize14};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){

		.btn_normal{
			font-size: ${mediumSizes.fontSize.fontSize12};
			width: ${mediumSizes.reagendarButon.widtButton};
			height: ${mediumSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${mediumSizes.fontSize.fontSize12};
			width: ${mediumSizes.reagendarButon.widtButton};
			height: ${mediumSizes.reagendarButon.heightButton};
		}

		.text_card{
			font-size: ${mediumSizes.fontSize.fontSize14};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.input_card_ui{
			font-size: ${largeSizes.fontSize.fontSize10};
		}

		.btn_normal{
			font-size: ${largeSizes.fontSize.fontSize12};
			width: ${largeSizes.reagendarButon.widtButton};
			height: ${largeSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${largeSizes.fontSize.fontSize12};
			width: ${largeSizes.reagendarButon.widtButton};
			height: ${largeSizes.reagendarButon.heightButton};
		}

		.text_card{
			font-size: ${largeSizes.fontSize.fontSize14};
		}
	}
`


const CustomHeaderCalendar = styled.div`
	width: 100%;
	background: white;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
    padding-left: 33.41px; 
    padding-right: 54px; 

	button{
		border: none;
		outline: none;
		background: white;
	}
	.arrow_back{
		display: flex;
		align-items: center;
		background: white;
	}

	.left,
	.mid,
	.right{
		flex: 1;
	}
	.left{
		background: white;
	}
	.ant-select-selector{
		background: white;
		border: 1px solid #E0E0E0;
		border-radius: 5px;
		height: 40px;
	}

	// ----------------------------------

	.mid{
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mid .label{
		margin: 0 16px;
		color: #5A607F;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize18};
	}

	.mid button{
		background: white;
		color: #2B80FF;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize18};
	}
	

	// ----------------------------------

	.right{
		background: white;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.right .right__bn{
		width: ${smallSizes.availability.widtButton};
		height: ${smallSizes.availability.heightButton};
		border: none;
		outline: none;
		background: #DDE4EB;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #0060FF;
		border-radius: 3px;
		font-size: ${smallSizes.fontSize.fontSize12};
		color: #001737;
	}

	//-------------------------------------------

	@media screen and (min-width: ${devices.smallDesk}){
		.mid .label{
			font-size: ${smallSizes.fontSize.fontSize18};
		}
		
		.mid button{
			font-size: ${smallSizes.fontSize.fontSize18};
		}

		.right .right__bn{
			width: ${smallSizes.availability.widtButton};
		height: ${smallSizes.availability.heightButton};
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.mid .label{
			font-size: ${mediumSizes.fontSize.fontSize18};
		}
		
		.mid button{
			font-size: ${mediumSizes.fontSize.fontSize18};
		}

		.right .right__bn{
			width: ${mediumSizes.availability.widtButton};
			height: ${mediumSizes.availability.heightButton};
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.mid .label{
			font-size: ${largeSizes.fontSize.fontSize18};
		}
		
		.mid button{
			font-size: ${largeSizes.fontSize.fontSize18};
		}

		.right .right__bn{
			width: ${largeSizes.availability.widtButton};
			height: ${largeSizes.availability.heightButton};
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	}
`;