import React, { useEffect, useState } from 'react'

import style from './serviceOrderType.module.css'
import LabelDrawer from '../../../../../../components/text/drawer/label'
import { Input, Select, Switch } from 'antd'
import { emptyInputsCheck } from '../../../../../../helpers/helpers'
import ManualPlanSearch from '../manualPlanSearch/ManualPlanSearch'

const ServiceOrderType = ({
	onTypeOSChange,
	typeOSList,
	onCategoryChange,
	category,
	categories,
	selectUserSearch,
	manualRegister,
	loginOperadorID,
	setTradename,
	showSpecification,
	setDurationCategory,
	durationCategory,
	setTypeOption,
	setCategoryOption,
	camposIncompletos
}) => {

	const [optionType, setOptionType] = useState(null)
	const [optionCategory, setOptionCategory] = useState(null)

	useEffect(() => {
		const dataType = typeOSList.map((item) => {
			return {
				value: item.ID,
				label: item.name
			}
		})

		setOptionType(dataType)
		setTypeOption(dataType)
	}, [])

	useEffect(() => {
		if (categories) {
			const dataCategory = categories.map((item) => {
				return {
					value: item.id,
					label: item.name
				}
			})
			setOptionCategory(dataCategory)
			setCategoryOption(dataCategory)
		}
	}, [categories])

	useEffect(() => {
		if (category) {
			const filterDuration = categories.filter((item) => item.id === category)
			setDurationCategory(filterDuration[0].duration)
		}
	}, [category])



	return (
		<section className={style.container}>

			<header className={style.container_header}>
				<div className={style.circle} />
				<p className={style.text_header}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum sem libero. Morbi mollis eleifend efficitur. Morbi sed ligula semper leo maximus .</p>
			</header>

			<section className={style.container_separate}>
				<div className={style.subcontainer_separate}>
					<LabelDrawer value='Tipo de orden' />
					<Select
						style={{ flex: 1, height: 40 }}
						placeholder='Selecciona un tipo de orden'
						options={optionType}
						onChange={onTypeOSChange}
						status={emptyInputsCheck(camposIncompletos, "ostype") ? "error" : ""}
					/>
				</div>

				<div className={style.subcontainer_separate}>
					<LabelDrawer value='Categoría' />
					<Select
						style={{ flex: 1, height: 40 }}
						placeholder='Selecciona una categoria'
						options={optionCategory}
						value={category}
						onChange={onCategoryChange}
						status={emptyInputsCheck(camposIncompletos, "category") ? "error" : ""}
					/>
				</div>
			</section>

			{category && (
				<div>
					<label className={`${style.font_12} ${style.text_duration}`}>Tiempo estimado de duración: <label className={style.color2B80FF}>{durationCategory} min</label></label>
				</div>
			)}




			{selectUserSearch === false && manualRegister &&
				(
					<ManualPlanSearch
						loginOperadorID={loginOperadorID}
						setTradename={setTradename}
						camposIncompletos={camposIncompletos}
						showSpecification={showSpecification}
					/>
				)}
		</section>
	)
}

export default ServiceOrderType