import React, { useEffect, useState } from 'react'

import style from './scheduling.module.css'
import { DatePicker } from 'antd'
import LabelDrawer from '../../../../../../components/text/drawer/label'
import dayjs from 'dayjs'
import { emptyInputsCheck } from '../../../../../../helpers/helpers'

const { RangePicker } = DatePicker;

const Scheduling = ({ setDateTimeInitial, setDateTimeEnd, setDiffHour, camposIncompletos }) => {

	const [date, setDate] = useState(null)
	const [hour, setHour] = useState(null)

	const hadlerSelectedDate = (_, dateString) => {
		setDate(dateString);
	}

	const hadlerSelectedTime = (_, timeString) => {
		setHour(timeString);
	}

	useEffect(() => {

		if (date && hour) {
			const dateDayjs = dayjs(date, 'DD/MM/YYYY')
			const HourInit = dayjs(hour[0], 'HH:mm')
			const HourEnd = dayjs(hour[1], 'HH:mm')
			const dateHourInit = dateDayjs.set('hour', HourInit.get('hour')).set('minute', HourInit.get('minute')).format('YYYY-MM-DD HH:mm')
			const dateHourEnd = dateDayjs.set('hour', HourEnd.get('hour')).set('minute', HourEnd.get('minute')).format('YYYY-MM-DD HH:mm')
			const diff = dayjs(dateHourEnd).diff(dayjs(dateHourInit), 'hour');
			setDateTimeInitial(dateHourInit)
			setDateTimeEnd(dateHourEnd)
			setDiffHour(diff)
		}
	}, [date, hour])



	return (
		<section className={style.container}>
			<header className={style.container_header}>
				<div className={style.circle} />
				<p className={style.text_header}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum sem libero. Morbi mollis eleifend efficitur. Morbi sed ligula semper leo maximus .</p>
			</header>

			<section className={style.container_separate}>
				<div className={style.subcontainer_separate}>
					<LabelDrawer value='Fecha' />
					<DatePicker
						format={'DD/MM/YYYY'}
						onChange={hadlerSelectedDate}
						status={emptyInputsCheck(camposIncompletos, 'date_time_initial') || emptyInputsCheck(camposIncompletos, 'date_time_final') ? 'error' : ''}
					/>
				</div>

				<div className={style.subcontainer_separate}>
					<LabelDrawer value='Horario' />
					<RangePicker
						placeholder={["Start time", "End time"]}
						picker='time'
						format={'HH:mm'}
						onChange={hadlerSelectedTime}
						status={emptyInputsCheck(camposIncompletos, 'date_time_initial') || emptyInputsCheck(camposIncompletos, 'date_time_final') ? 'error' : ''}
					/>
				</div>

			</section>
		</section>
	)
}

export default Scheduling