import React, { useEffect, useState } from 'react'

import FormOverflowTemplate from '../FormOverflowTemplate/FormOverflowTemplate'
import MainTitle from '../../../../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../../../../components/text/drawer/subtitleBlue'
import BottomSection from '../../../../../../components/form/bottomSection'
import Br from '../../../../../../components/br/Br'
import LabelDrawer from '../../../../../../components/text/drawer/label'

import { myFetch, myFetchGet } from '../../../../../../services/services'

import { Checkbox, Select } from 'antd'

import style from './FormOverflow.module.css'

const FormOverflow = ({
	setVisibleFormOverflow,
	nameTechnican,
	randomTechnican,
	dateSelectedForm,
	categorySelected,
	setSelectedHoursOverflow,
	setDisponibilidadIDOverflow,
	setTechnicianOverflow,
	setStartSelectedOverflow,
	startSelectedOverflow,
	setEndSelectedOverflow,
	endSelectedOverflow,
	technicianIDForm
}) => {

	const [DataHours, setDataHours] = useState([])
	const [hoursOverflow, setHoursOverflow] = useState(null)
	const [checkbox, setCheckbox] = useState(false)
	const [idFather, setIdFather] = useState(null)

	const { Option } = Select;

	// peticion para obtener los horarios sobrecupo
	const getHoursOverflow = async () => {
		try {
			const responsive = await myFetchGet(`api/v1/technician/hours_overflow/?category=${categorySelected}&day=${dateSelectedForm}`);
			setDataHours(responsive.data);
			setIdFather(responsive.id_father)
		} catch (error) {
			console.log(error)
		}
	}

	// peticion para crear sobrecupo con tecnico aleatorio
	const CreateOverflowRandom = async () => {
		try {
			const request = await myFetch(`api/v1/technician/creates_overflow/?start_time=${startSelectedOverflow}&end_time=${endSelectedOverflow}&father=${idFather}`)
			setDisponibilidadIDOverflow(request.disponibility)
			setTechnicianOverflow(request.info_tech)
			setVisibleFormOverflow(false)
		} catch (error) {
			console.log(error)
		}
	}

	// peticion para crear sobrecupo con tecnico Manual
	const CreateOverflowManual = async () => {
		try {
			const request = await myFetch(`api/v1/technician/creates_overflow/?start_time=${startSelectedOverflow}&end_time=${endSelectedOverflow}&father=${idFather}&technician=${technicianIDForm}`)
			setDisponibilidadIDOverflow(request.disponibility)
			setTechnicianOverflow(request.info_tech)
			setVisibleFormOverflow(false)
		} catch (error) {
			console.log(error)
		}
	}

	// funcion para convertir los horarios de objeto a un array 
	const convertData = () => {
		const hours = [];

		for (let i = 0; i < DataHours.length; i++) {
			const start = DataHours[i].start_time;
			const end = DataHours[i].end_time;
			hours.push(`${start} a ${end}`);
		}
		return hours;
	}

	// separacion de horas seleccionadas
	const convertStingTwoVarible = (hours) => {
		const [start, end] = hours.split(" a ");
		setStartSelectedOverflow(start);
		setEndSelectedOverflow(end);
	}

	const onHoursChange = (value) => {
		// Se invoca funcion para obtener los valores para peticion de post
		convertStingTwoVarible(value)

		setSelectedHoursOverflow(value)
	}

	// obtener los horarios sobrecupo
	useEffect(() => {
		if (DataHours.length > 0) {
			const data = convertData();
			setHoursOverflow(data)
		}
	}, [DataHours])

	// ejecutar la peticion para los horarios
	useEffect(() => {
		getHoursOverflow();
	}, [])

	return (
		<FormOverflowTemplate>
			<MainTitle
				value='Agendar sobrecupo'
				onClick={() => setVisibleFormOverflow(false)}
			/>

			<SubtitleBlue
				value='Información general de agendamiento'
			/>

			<div className={style.container_information}>
				<div className={style.height_80}>
					<div className={style.box}>
						<span className={style.text_label}>Asignación del técnico:</span>
						<span className={style.text_asig}>{randomTechnican === true ? "Aleatorio" : "Manual"}</span>
					</div>
					{randomTechnican === false ? (
						<div className={style.box}>
							<span className={style.text_label}>Técnico principal:</span>
							<span className={`${style.text_label} ${style.text_bold}`}>{nameTechnican}</span>
						</div>
					) : null}

				</div>
				<div className={style.box_right}>
					<span className={style.text_date}>{dateSelectedForm}</span>
				</div>
			</div>

			<div className={style.container_hours}>
				<LabelDrawer value='Horario' />
				<Select
					placeholder="Seleccionar"
					onChange={onHoursChange}
				>
					{hoursOverflow?.map(item => <Option key={item}>{item}</Option>)}
				</Select>
			</div>

			<Br />

			<div className={style.container_important}>
				<span className={style.text_blue}>
					<SubtitleBlue
						value='Importante'
					/>
				</span>

				<Checkbox onChange={(e) => setCheckbox(e.target.checked)} className={style.checkbox}>Al crear un sobrecupo acepta los riesgos que se corren con el agendamiento de la orden.</Checkbox>

			</div>


			<BottomSection onAcept={randomTechnican === false ? CreateOverflowManual : CreateOverflowRandom} onCancel={() => setVisibleFormOverflow(false)} textButtonAcept='Continuar' disable={!checkbox} />
		</FormOverflowTemplate>
	)
}

export default FormOverflow