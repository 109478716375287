import React from 'react'
import DatatableHeaderText from "../components/text/datatable/datatableHeaderText";
import DatatableRowText from "../components/text/datatable/datatableRowText";
import { myFetch, myFetchGet } from "../services/services"
import { notification } from 'antd';
import ColorCircle from '../components/colorCircle';

const holidays_structColumns = async () => {
	try {
		const res = await myFetchGet("api/v1/holiday/datatables_struct");
		const columnsTittles = Object.keys(res?.columns);
		const columns = [];
		columnsTittles.forEach((columnsTittle) => {
			columns.push({
				sorter: columnsTittle === "ID" ? (a, b) => a?.ID - b?.ID : null,
				width: columnsTittle === "ID" ? "80px" : "200px",
				title: <DatatableHeaderText value={columnsTittle} />,
				dataIndex: res?.columns[columnsTittle].field,
				key: res?.columns[columnsTittle].field,
				render: columnsTittle === "Nombre de la festividad"
					? (value) =>
						<span style={{ display: "flex", alignItems: "center" }}>
							<DatatableRowText value={value} color='#2B80FF' bold={true} />
						</span>
					: columnsTittle === "Estatus" ?
						(value) =>
							<span style={{ display: "flex", alignItems: "center" }}>
								<ColorCircle color={value?.color} />
								<DatatableRowText value={value?.name} marginBottom='0px' />
							</span>
						: (value) =>
							<span style={{ display: "flex", alignItems: "center" }}>
								<DatatableRowText value={value} />
							</span>
			});
		});
		return columns;
	} catch (e) {
		console.log("error", e);
		return [];
	}
};

const onHolidayCreate = async (data) => {
	try {
		await myFetch('api/v1/holiday/', data)
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Feriado creado!',
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => {
				window.location.reload()
			}
		})
	}
	catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'No se ha podido crear el feriado',
			placement: 'bottomLeft',
			duration: 2.5,
			// onClose: () => {
			//     window.location.reload()
			// }
		})
	}
}

const onHolidayUpdate = async (key, holidayUpdated) => {
	try {
		await myFetch(`api/v1/holiday/${key}/`, holidayUpdated, "PATCH")
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'El feriado se ha actualizado',
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => window.location.reload()

		})
	} catch (error) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'Se ha producido un error inesperado',
			placement: 'bottomLeft',
			duration: 2.5,
		})
	}
}

const deleteHoliday = (ID) => {
	try {
		myFetch(`api/v1/holiday/${ID}/`, "", "DELETE")
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'El feriado se ha borrado',
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => window.location.reload()
		})
	} catch (error) { }
}

const getAbsenceChoices = async () => {
	try {
		return await myFetchGet("api/v1/absence/choices/")
	} catch (e) { }
}

const onAbsenceCreate = async (data) => {
	try {
		await myFetch("api/v1/absence/", data)
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Se ha creado la ausencia',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => window.location.reload()
		})
	}
	catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'No se ha creado la ausencia',
			placement: 'bottomLeft',
			duration: 1.5,
			// onClose: () => window.location.reload()
		})
	}
}

const onAbsenceUpdate = async (ID, data) => {
	try {
		await myFetch(`api/v1/absence/${ID}/`, data, "PATCH");
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'La ausencia se ha modificado',
			placement: 'bottomLeft',
			duration: 1.5,
			onClose: () => window.location.reload()
		})
	} catch (e) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'La ausencia no se ha modificado',
			placement: 'bottomLeft',
			duration: 1.5,
			// onClose: () => window.location.reload()
		})
	}
}

const getTechnicianHoliday = async (storedOperatorID) => {
	try {
		const res = await myFetchGet(`api/v1/technician/?operator=${storedOperatorID}`);
		return res
	} catch (e) { }
}

export {
	deleteHoliday,
	holidays_structColumns,
	onAbsenceUpdate,
	onAbsenceCreate,
	onHolidayUpdate,
	onHolidayCreate,
	getAbsenceChoices,
	getTechnicianHoliday
}