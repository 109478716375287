import React, { useEffect, useState } from 'react';
import style from './cardEventModal.module.css'
import NormalText from '../../../../components/text/NormalText';
import { Divider, TimePicker, Switch, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../helpers/sizes';


const CardEventModal = ({
	virtualEvent,
	onStartChange,
	onEndChange,
	onModalClose,
	addBlockTimeArray,
	setSpecialEvent,
	deleteblock,
	handleDeleteEvent,
	handleUpdate,
	edit,
	specialEvent
}) => {

	const [rolePermissions, setRolePermissions] = useState(false);

	const hourFormat = 'HH:mm';
	const dataRedux = useSelector((state) => state.authState);

	const DisabledTime = () => {
		return {
			disabledHours() {
				const hours = [];
				for (let i = 0; i < 24; i++) {
					if (i < 7 || i >= 19) {
						hours.push(i);
					}
				}
				return hours;
			}
		};
	};

	const handlerCheckbox = ({ target }) => {

		if (target.checked) {
			setSpecialEvent(1);
		} else {
			setSpecialEvent(0);
		}
	};

	useEffect(() => {
		if (dataRedux.rol === 'supervisor_isp' || dataRedux.rol === 'admin_isp' || dataRedux.rol === 'admin') {
			setRolePermissions(true);
		} else {
			setRolePermissions(false);
		}
	}, [dataRedux])

	useEffect(() => {
		setSpecialEvent(virtualEvent?.type ? virtualEvent?.type : 0);
	}, [])

	return (
		<div className={style.root_card}>
			<section className={style.card_event}>
				<section className={style.container_delete}>
					<span className={style.text_card}>{edit ? "Detalle de disponibilidad" : rolePermissions ? "Registro de disponibilidad" : "Confirmación"}</span>
					{deleteblock && (
						<span className={style.delete_disponibility} onClick={handleDeleteEvent}>
							<i className="fa-solid fa-trash" style={{ color: "#FFFFFF" }}></i>
						</span>
					)}
				</section>
				{rolePermissions && (
					<NormalText
						size="12px"
						bold={true}
						value="Confirmación de horario"
						color="#2B80FF"
					/>
				)}


				<div className={style.space_between}>
					<section className={style.timePicker}>
						<NormalText
							size="12px"
							value="Inicio"
							color="#131523"
						/>
						<TimePicker
							format={hourFormat}
							inputReadOnly
							placeholder={dayjs(virtualEvent?.start, 'HH:mm')}
							style={{ width: "100%" }}
							defaultValue={dayjs(virtualEvent?.start, 'HH:mm')}
							disabledTime={DisabledTime}
							onChange={onStartChange}
						/>
					</section>

					<section className={style.timePicker}>
						<NormalText
							size="12px"
							value="Fin"
							color="#131523"
						/>
						<TimePicker
							format={hourFormat}
							inputReadOnly
							placeholder={dayjs(virtualEvent?.end, 'HH:mm')}
							style={{ width: "100%" }}
							defaultValue={dayjs(virtualEvent?.end, 'HH:mm')}
							disabledTime={DisabledTime}
							onChange={onEndChange}
						/>
					</section>
				</div>

				{rolePermissions && (
					<section className={style.container_checkbox}>
						<NormalText
							size="12px"
							bold={true}
							value="Evento especial"
							color="#2B80FF"
						/>
						<ContainerCheckbox>
							<Checkbox
								onChange={handlerCheckbox}
								checked={specialEvent === 1 ? true : false}

							>
								La permanencia del bloque solo seria para la fecha y hora indicada</Checkbox>
						</ContainerCheckbox>

					</section>
				)}

				<section className={style.flex_end}>
					<button className={style.btn_normal} onClick={onModalClose} >Cancelar</button>
					<button className={style.btn_silver} onClick={deleteblock ? handleUpdate : addBlockTimeArray} >Continuar</button>
				</section>
			</section>

		</div>
	)
}

export default CardEventModal

const ContainerCheckbox = styled.div`

margin-top:7px;

.ant-checkbox{
	align-self: auto;
}

.ant-checkbox .ant-checkbox-inner{
	top: 4px;
}

.ant-checkbox-wrapper{
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}){
	.ant-checkbox-wrapper{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.ant-checkbox-wrapper{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.ant-checkbox-wrapper{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`