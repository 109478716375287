import { Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'

import EmptyPhotos from '../../../assets/images/Empty/Empty_photos.png'
import { deleteImageOt, onDeleteImage } from '../../../api/imageGallery'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { permissionCheck } from '../../../helpers/helpers'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes'

import style from './customTable.module.css'


const CustomTable = ({ row, onEditForm, type }) => {


	const [confirmationData, setConfirmationData] = useState(row.length > 0 ? false : true)
	const [viewOptions, setViewOptions] = useState(false)
	const [openMenuIndex, setOpenMenuIndex] = useState(null);

	const onDownloadImage = (value) => window.open(value, '_blank')

	const array = useSelector((state) => state?.authState?.permissions)
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	useEffect(() => {
		if (viewOptions === false) {
			setOpenMenuIndex(null)
		}
	}, [viewOptions])



	return (
		<>
			{confirmationData && type === "OS" ? (
				<section>
					<div className={style.container_img}>
						<img src={EmptyPhotos} alt="Empty" />
						<strong className={style.title_empty}>Parece no haber Imágenes</strong>
						<p className={style.text_p}>Para proporcionar una imagen asociada a la orden de servicio,</p>
						<p className={style.text_p}>haga clic en <strong className={style.color_blue}>agregar imagen</strong></p>
					</div>

				</section>

			) : confirmationData && type === "OT" ? (
				<section>
					<div className={style.container_img}>
						<img src={EmptyPhotos} alt="Empty" />
						<strong className={style.title_empty}>Parece no haber Imágenes</strong>
						<p className={style.text_p}>Para proporcionar una imagen asociada a la orden de trabajo,</p>
						<p className={style.text_p}>haga clic en <strong className={style.color_blue}>agregar imagen</strong></p>
					</div>

				</section>
			) : null}

			{!confirmationData && (
				<>
					{row?.map((item, i) =>
					(
						<>
							{item?.photo !== null && (
								<Row key={i}>
									<span className='row__custom__table'>
										<div className='row__date'>
											<span className='gallery-date-created'> {item?.created} </span>
											<span className='text-user'> {item?.user} </span>
										</div>

										<div className='row__photo'>
											<img src={item?.photo} alt="Some desc." />
										</div>

										<div className='row__description'>

											<div className='row_description_text'>
												<label className='description_name'>{item?.name} </label>

												<div className='row_icon_upload' onClick={() => onDownloadImage(item?.photo)}>
													<i className="fa-solid fa-file-arrow-down size_icon_image" />
													<label className='text_icon_upload'>Descargar</label>
												</div>
											</div>
										</div>

										<aside className={style.container_button_options}>
											<button className={style.button}
												onClick={
													() => {
														setViewOptions(!viewOptions)
														setOpenMenuIndex(i)
													}
												}
											>
												<svg width="4" height="16" viewBox="0 0 4 16" fill="none">
													<circle cx="2" cy="2" r="2" fill="#2B80FF" />
													<circle cx="2" cy="8" r="2" fill="#2B80FF" />
													<circle cx="2" cy="14" r="2" fill="#2B80FF" />
												</svg>
											</button>

											{viewOptions && openMenuIndex === i && (
												<div className={style.container_options}>
													<p
														onClick={() => {
															onEditForm(item)
															setViewOptions(!viewOptions)
															setOpenMenuIndex(i)
														}}
														className={style.text_modify}
													>Modificar
													</p>

													<Popconfirm
														placement="topRight"
														icon={""}
														title="¿Eliminar fotografía?"
														description={() => {
															return (
																`Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes`)
														}}
														onConfirm={() => {
															if (type === "OS") onDeleteImage(item?.ID)
															if (type === "OT") deleteImageOt(item?.ID)
														}}
														okText="Eliminar"
														cancelText="Cancelar"
													>
														<p className={style.text_delete}>Eliminar</p>
													</Popconfirm>

												</div>
											)}
										</aside>
									</span>
								</Row>
							)}
						</>
					))}
				</>
			)}
		</>
	)
}

export default CustomTable

const Row = styled.div`
			:nth-child(odd) {background: #F7FAFC}
			:nth-child(even) {background: white}
			margin-top: 32.16px;

			.row__custom__table {
				width: 100%;
			height: 105px;
			display: flex;
			margin-bottom: 14.75px;
			align-items: center;
}

			.row__date{
				width: 220px;
			height: 76px;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			margin-left: 36px;
}

			.row__photo img{
				width: ${smallSizes.detailOsGallery.widthImg};
			height: ${smallSizes.detailOsGallery.heightImg};
			margin: 0 32.25px;
}

			.row__description{
				width: 100%;
			height: 76px;
			display: flex;
			flex-flow: column;
			justify-content: center;
}
			.btn_blue_text{
				color: #2B80FF;
			font-size: ${smallSizes.fontSize.fontSize12};
			font-weight: bold;
			border: none;
			outline: none;
			background: transparent;
			cursor: pointer;
}

			.row__date .gallery-date-created{
				color: #2B80FF;
			font-size: ${smallSizes.fontSize.fontSize15};
			font-weight: bold;
}

			.row__date .text-user{
				color: #5A607F;
			font-size: ${smallSizes.fontSize.fontSize12};
}

			.row__description .row_description_text{
				display: flex;
			flex-flow: column;
			margin-left: 15px;
}

			.row_description_text .description_name{
				color: #5A607F;
			font-size: ${smallSizes.fontSize.fontSize12};
			font-weight: bold;
}

			.row_icon_upload{
				display: flex;
			align-items: center;
}

			.row_icon_upload .size_icon_image{
				color: #2B80FF;
			margin-right: 12px;
			margin-bottom: 3px;
			font-size: ${smallSizes.fontSize.fontSize13};
}

			.row_icon_upload .text_icon_upload{
				color: #2B80FF;
			text-align: center;
			font-weight: bold;
			cursor: pointer;
			font-size: ${smallSizes.fontSize.fontSize12};
}

			.row_container_buttom{
				display: flex;
			margin-right: 84px;
			width: ${smallSizes.detailOsGallery.widthContainerButton};
			justify-content: space-between;
}
			//-------------------------------------------------

			@media screen and (min-width: ${devices.smallDesk}){
	.row__date.gallery - date - created{
				font - size: ${smallSizes.fontSize.fontSize15};
	}

			.row__date .text-user{
				font - size: ${smallSizes.fontSize.fontSize12};
	}

			.row__photo img{
				width: ${smallSizes.detailOsGallery.widthImg};
			height: ${smallSizes.detailOsGallery.heightImg};
	}

			.row_description_text .description_name{
				font - size: ${smallSizes.fontSize.fontSize12};
	}

			.row_icon_upload .text_icon_upload{
				font - size: ${smallSizes.fontSize.fontSize12};
	}

			.row_icon_upload .size_icon_image{
				font - size: ${smallSizes.fontSize.fontSize13};
	}

			.btn_blue_text{
				font - size: ${smallSizes.fontSize.fontSize12};
	}

			.row_container_buttom{
				width: ${smallSizes.detailOsGallery.widthContainerButton};
	}
}

			@media screen and (min-width: ${devices.mediumDesk}){
	.row__date.gallery - date - created{
				font - size: ${mediumSizes.fontSize.fontSize15};
	}

			.row__date .text-user{
				font - size: ${mediumSizes.fontSize.fontSize12};
	}

			.row__photo img{
				width: ${mediumSizes.detailOsGallery.widthImg};
			height: ${mediumSizes.detailOsGallery.heightImg};
	}

			.row_description_text .description_name{
				font - size: ${mediumSizes.fontSize.fontSize12};
	}

			.row_icon_upload .text_icon_upload{
				font - size: ${mediumSizes.fontSize.fontSize12};
	}

			.row_icon_upload .size_icon_image{
				font - size: ${mediumSizes.fontSize.fontSize13};
	}

			.btn_blue_text{
				font - size: ${mediumSizes.fontSize.fontSize12};
	}

			.row_container_buttom{
				width: ${mediumSizes.detailOsGallery.widthContainerButton};
	}
}

			@media screen and (min-width: ${devices.largeDesk}){
	.row__date.gallery - date - created{
				font - size: ${largeSizes.fontSize.fontSize15};
	}

			.row__date .text-user{
				font - size: ${largeSizes.fontSize.fontSize12};
	}

			.row__photo img{
				width: ${largeSizes.detailOsGallery.widthImg};
			height: ${largeSizes.detailOsGallery.heightImg};
	}

			.row_description_text .description_name{
				font - size: ${largeSizes.fontSize.fontSize12};
	}

			.row_icon_upload .text_icon_upload{
				font - size: ${largeSizes.fontSize.fontSize12};
	}

			.row_icon_upload .size_icon_image{
				font - size: ${largeSizes.fontSize.fontSize13};
	}

			.btn_blue_text{
				font - size: ${largeSizes.fontSize.fontSize12};
	}

			.row_container_buttom{
				width: ${largeSizes.detailOsGallery.widthContainerButton};
	}
}
			`