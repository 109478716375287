/* eslint-disable */
import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components';
import { getOstypeHistory } from '../../../api/ostypeCreate';

import { PanelBody, Panel } from '../../../components/panel/panel';
import historyDataRegister from '../../../helpers/historyDataRegister';
import { History } from '../../../components/history/History';
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue';
import LabelDrawer from '../../../components/text/drawer/label';
import Br from '../../../components/br/Br';

export default function HistoryPanel({ data }) {
  const [historyData, setHistoryData] = useState([])

  useEffect(() => {
    data?.id_unico && getOstypeHistory(data?.id_unico).then(res => {
      const data = historyDataRegister(res?.changes)
      setHistoryData(data)
    })
  }, [])



  return (
    <Panel>
        <Container>
          <SubtitleBlue value="Registro de actividad" />
          <LabelDrawer required={false} value="Histórico asociado a los cambios registrados de la orden de servicio." />

          <History data={historyData} />
        </Container>
    </Panel>

  )
}

const Container = styled.div`

width: 100%;
display: flex;
flex-flow: column;
padding: 10px 0 0 32px;

background: white;

.space-between{
    justify-content: space-between;
}
.row__end{
    justify-content: flex-end;
}

.title_blue{
    color: #2B80FF;
    font-size: 14px;
    font-weight: bold;
}

.time_span{
    display: flex;
    margin-right: 1em;
}
.time_span .left{
    .fa-car, .fa-couch, .fa-clock{
        font-size: 14.06px;
        color: #959595;
        margin-top: 5px;
        margin-right: 6px;
    }
}
.time_span .right{
    display: flex;
    flex-flow: column;
    
    .distance_text{
        color: #172B4D;
        font-size: 12px;
        font-weight: 400;
    }
}

// ---------------------------------

// .timeline_box{
//     width: 100%;
//     display: flex;
// }
// .one{
//   height: 61px;
// }

// .two{
//   height: 65px;
// }

// .three{
//   height: 85px;
// }

// .four{
//   height: 110px;
// }

// .five{
//   height: 140px;
// }

// .six{
//   height: 160px;
// }

// .seven{
//   height: 180px;
// }

// .eight{
//   height: 210px;
// }

// .nine{
//   height: 240px;
// }

// .ten{
//   height: 270px;
// }

// .timeline__box .date{
//     display: flex;
//     flex-flow: column;
// }

// .timeline_box .mid{
//     width: 12px;
//     height: 12px;
//     margin: 0 17.65px;
//     background: white;
//     border: 1px solid #00388B;
//     border-radius: 100%;
//     position: relative;

//     :before{
//         position: absolute;
//         content: "";
//         left: 50%;
//         width: 1px;
//         background: #00388B;
//     }
// }

// .line_one{
//   :before{
//         bottom: -50px;
//         height: 50px;
//     }
// }

// .line_two{
//   :before{
//         bottom: -55px;
//         height: 55px;
//     }
// }

// .line_three{
//   :before{
//         bottom: -75px;
//         height: 75px;
//     }
// }

// .line_four{
//   :before{
//         bottom: -95px;
//         height: 95px;
//     }
// }

// .line_five{
//   :before{
//         bottom: -115px;
//         height: 115px;
//     }
// }

// .line_six{
//   :before{
//         bottom: -135px;
//         height: 135px;
//     }
// }

// .line_seven{
//   :before{
//         bottom: -175px;
//         height: 175px;
//     }
// }

// .line_eigth{
//   :before{
//         bottom: -205px;
//         height: 205px;
//     }
// }

// .line_nine{
//   :before{
//         bottom: -235px;
//         height: 235px;
//     }
// }

// .line_ten{
//   :before{
//         bottom: -265px;
//         height: 265px;
//     }
// }
`