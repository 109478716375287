import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../components/modal/CustomModal'
import Form from '../../../../components/form/index.jsx'
import MainTitle from '../../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx'

import style from './formCopyOrderOt.module.css'
import { Avatar, DatePicker, Input, Select, Switch, notification } from 'antd'
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx'
import AdditionalInformation from '../../../serviceOrdersCalendar/components/formCreatedOT/components/additionalInformation/AdditionalInformation.jsx'
import BottomSection from '../../../../components/form/bottomSection/index.jsx'
import ClientData from './components/clientData/ClientData.jsx'
import { myFetchGet, myFetchPost } from '../../../../services/services.js'

import { checkEmptyKeys, emptyInputsCheck, validarCampos } from '../../../../helpers/helpers.js'
import dayjs from 'dayjs'
import { eliminarCodigoPais } from '../../../../helpers/helperOT.js'
import SummaryFormCopy from './components/summaryFormCopy/SummaryFormCopy.jsx'
import ImportantInformation from '../../../serviceOrdersCalendar/components/formCreatedOT/components/importantInformation/ImportantInformation.jsx'
import UseOperator from '../../../../hooks/UseOperator.jsx'

const { TextArea } = Input
const { RangePicker } = DatePicker;

const FormCopyOrderOt = ({ setViewForm, data }) => {

	const {
		type_ot,
		category_name,
		technician,
		comments,
		supervisor_required,
		auxiliaries,
		id_unico,
	} = data
	const { storedOperatorID } = UseOperator()

	const [initialTechnician, setInitialTechnician] = useState("")
	const [imageBase64, setImagesBase64] = useState([]);
	const [viewUpload, setViewUpload] = useState(false)
	const [date, setDate] = useState(null)
	const [hour, setHour] = useState(null)

	const [viewTypeClient, setViewTypeClient] = useState(false)
	const [typeClient, setTypeClient] = useState('0')

	const [selectedName, setSelectedName] = useState(null)
	const [selectedLastName, setSelectedLastName] = useState(null)
	const [selectedBusinessName, setSelectedBusinessName] = useState(null)
	const [selectedTypeDocument, setSelectedTypeDocument] = useState(null)
	const [typeCountryString, setTypeCountryString] = useState(null)
	const [selectedDocument, setSelectedDocument] = useState(null)
	const [selectedNumber, setSelectedNumber] = useState(null)
	const [selectedEmail, setSelectedEmail] = useState(null)
	const [selectedServiceID, setSelectedServiceID] = useState(null)

	const [isValidName, setIsValidName] = useState(null)
	const [isValidLastName, setIsValidLastName] = useState(null)
	const [isValidBusinessName, setIsValidBusinessName] = useState(null)
	const [isValidEmail, setIsValidEmail] = useState(null)
	const [isValidPhone, setIsValidPhone] = useState(null)
	const [isValid, setIsValid] = useState(null)

	const [selectDescription, setSelectDescription] = useState(null)

	const [dateTimeInitial, setDateTimeInitial] = useState(null)
	const [dateTimeEnd, setDateTimeEnd] = useState(null)
	const [diffHour, setDiffHour] = useState(null)

	const [selectedCategoryId, setSelectedCategoryId] = useState(null)

	const [loading, setLoading] = useState(true)

	const [viewSummary, setViewSummary] = useState(false)

	const [disponibilityCheck, setDisponibilityCheck] = useState(false)
	const [checkMaxHour, setCheckMaxHour] = useState(false)
	const [disponibility, setDisponibility] = useState(null)

	const [bodyCreateSummary, setBodyCreateSummary] = useState(null)

	const [dataAuxiliries, setDataAuxiliries] = useState(auxiliaries.map((item) => item.id))
	const [selectedDirectionId, setSelectedDirectionId] = useState(null)

	const [selectedDirection, setSelectedDirection] = useState(null)

	const [prefixes, setPrefixes] = useState(null)

	const [camposIncompletos, setCamposIncompletos] = useState(null)



	const getDataOt = async () => {
		setLoading(true)
		try {
			const response = await myFetchGet(`api/v1/wo/${id_unico}/`)
			setViewTypeClient(response.has_a_client)
			setSelectedDirectionId(response.direction_id)
			setSelectedDirection(response.direction)

			if (response.has_a_client && response.details_client.type_client === "Natural") {
				setSelectedName(response.details_client.first_name)
				setSelectedLastName(response.details_client.last_name)
			} else {
				setSelectedBusinessName(response.details_client.business_name)
			}
			setTypeClient(response.details_client.type_client === "Juridico" ? '0' : '1')
			setSelectedDocument(response.details_client.document_number)
			setSelectedNumber(eliminarCodigoPais(response.details_client.phone))
			setSelectedEmail(response.details_client.email)
			setSelectedServiceID(response.details_client.service_number)
			setSelectedTypeDocument(response.details_client.id_type_document)
			setSelectDescription(response.description)
			setSelectedCategoryId(response.category_ot)

			setLoading(false)
		} catch (error) {

		}
	}


	const onViewTypeClient = (checked) => {
		setViewTypeClient(checked)
		setTypeClient(null)
	}

	const hadlerTypeClient = (value) => {
		setTypeClient(value)
	}

	const hadlerSelectedDate = (_, dateString) => {
		setDate(dateString);
	}

	const hadlerSelectedTime = (_, timeString) => {
		setHour(timeString);
	}

	const body = {
		"type_document": selectedTypeDocument,
		"number_document": selectedDocument,
		"number_phone": selectedNumber,
		"email": selectedEmail,
		"services_Id": selectedServiceID,
		"type_ot": type_ot.id,
		"category_ot": selectedCategoryId,
		"description": selectDescription,
		"direction": selectedDirectionId,
		"technician": technician.id,
		"support_supervisor": supervisor_required,
		"auxiliaries": auxiliaries.map((item) => item.id),
		"date_time_initial": dateTimeInitial,
		"date_time_final": dateTimeEnd,
		"type_client": Number(typeClient),
		"has_a_client": viewTypeClient,
		"operator": storedOperatorID,
		"status": 0,
		"schedule": true,
		"code_area": prefixes
	}

	const openSummary = () => {
		if (typeClient === '1') {
			body["first_name"] = selectedName
			body["last_name"] = selectedLastName
		} else {
			body["business_name"] = selectedBusinessName
		}

		if (comments) {
			body["comments"] = comments
		}

		const clavesVacias = checkEmptyKeys(body)
		setCamposIncompletos(clavesVacias)

		if (!validarCampos(body) ||
			isValidName === false || isValidLastName === false ||
			isValidBusinessName === false || isValidEmail === false ||
			isValidPhone === false || isValid === false
		) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: "Por favor, completa todos los campos",
				placement: 'bottomLeft',
				duration: 3,
			})
		} else {
			setBodyCreateSummary(body)
			setViewSummary(true)
		}
	}

	const verifyDsiponibility = async () => {
		const data = {
			"date_time_initial": dateTimeInitial,
			"date_time_final": dateTimeEnd,
			"id": technician.id,
			"ids": auxiliaries.map((item) => item.id)
		}

		try {
			const response = await myFetchPost(`api/v1/schedule/check_disponibility/`, data)
			if (response.status === 200) {
				setDisponibility(true)
			}
		} catch (error) {
			if (error.response.status === 400) {
				setDisponibility(false)
			}
		}

	}

	useEffect(() => {
		const InitialChart = technician.full_name.split(" ")[0][0]
		setInitialTechnician(InitialChart)
	}, [])

	useEffect(() => {
		getDataOt()
	}, [])

	useEffect(() => {

		if (date && hour) {
			const dateDayjs = dayjs(date, 'DD/MM/YYYY')
			const HourInit = dayjs(hour[0], 'HH:mm')
			const HourEnd = dayjs(hour[1], 'HH:mm')
			const dateHourInit = dateDayjs.set('hour', HourInit.get('hour')).set('minute', HourInit.get('minute')).format('YYYY-MM-DD HH:mm')
			const dateHourEnd = dateDayjs.set('hour', HourEnd.get('hour')).set('minute', HourEnd.get('minute')).format('YYYY-MM-DD HH:mm')
			const diff = dayjs(dateHourEnd).diff(dayjs(dateHourInit), 'hour');
			setDateTimeInitial(dateHourInit)
			setDateTimeEnd(dateHourEnd)
			setDiffHour(diff)
		}
	}, [date, hour])

	useEffect(() => {
		if (technician.id && dateTimeInitial && dateTimeEnd && dataAuxiliries) {
			verifyDsiponibility()
		}

	}, [technician.id, dateTimeInitial, dateTimeEnd, dataAuxiliries])



	return (
		<>
			<CustomModal onClick={() => setViewForm(false)}>
				<Form>
					{!loading && (
						<>
							<MainTitle value='Duplicar orden de trabajo' onClick={() => setViewForm(false)} />


							<SubtitleBlue value='Información general' />

							<span className={style.container_client}>
								<p className={style.text_ot_client}>¿La orden de trabajo está atada a un cliente?</p>
								<span className={style.container_switch}>
									<p className={style.text_switch}>No</p>
									<Switch checked={viewTypeClient} onChange={onViewTypeClient} />
									<p className={style.text_switch}>Si</p>
								</span>
							</span>

							{viewTypeClient && (
								<div className={style.container_type_client}>
									<LabelDrawer value='Tipo cliente' />
									<Select
										style={{ flex: 1, height: 40 }}
										placeholder='Selecciona un tipo de cliente'
										defaultValue={typeClient}
										options={
											[
												{ value: '1', label: 'Natural' },
												{ value: '0', label: 'Jurídico' },
											]
										}
										onChange={hadlerTypeClient}
									/>
								</div>
							)}


							<ClientData
								typeClient={typeClient}
								setSelectedName={setSelectedName}
								selectedName={selectedName}
								setSelectedLastName={setSelectedLastName}
								selectedLastName={selectedLastName}
								setSelectedBusinessName={setSelectedBusinessName}
								selectedBusinessName={selectedBusinessName}
								setSelectedTypeDocument={setSelectedTypeDocument}
								selectedTypeDocument={selectedTypeDocument}
								setSelectedDocument={setSelectedDocument}
								selectedDocument={selectedDocument}
								setSelectedNumber={setSelectedNumber}
								selectedNumber={selectedNumber}
								setSelectedEmail={setSelectedEmail}
								selectedEmail={selectedEmail}
								setSelectedServiceID={setSelectedServiceID}
								selectedServiceID={selectedServiceID}
								setTypeCountryString={setTypeCountryString}
								typeCountryString={typeCountryString}
								setIsValidName={setIsValidName}
								isValidName={isValidName}
								setIsValidLastName={setIsValidLastName}
								isValidLastName={isValidLastName}
								setIsValidBusinessName={setIsValidBusinessName}
								isValidBusinessName={isValidBusinessName}
								setIsValidPhone={setIsValidPhone}
								isValidPhone={isValidPhone}
								setIsValidEmail={setIsValidEmail}
								isValidEmail={isValidEmail}
								setIsValid={setIsValid}
								isValid={isValid}
								setPrefixes={setPrefixes}
								prefixes={prefixes}
								camposIncompletos={camposIncompletos}
							/>

							<SubtitleBlue value='Tipo de orden de trabajo' />

							<div className={style.container_category}>
								<span className={style.type}>
									{type_ot.name}
								</span>

								<div className={style.category_subcontainer}>
									<label className={style.title}>Categoría</label>
									<label className={style.category_text}>{category_name}</label>
								</div>
							</div>

							<div style={{ height: 150 }}>
								<LabelDrawer value='Descripción del trabajo' />
								<TextArea
									rows={4}
									style={{ marginBottom: "22.3px" }}
									placeholder='Ingresar descripción de la orden de trabajo'
									value={selectDescription}
									onChange={(e) => setSelectDescription(e.target.value)}
								/>
							</div>


							<SubtitleBlue value='Atendimiento orden de trabajo' />

							<section className={style.container_user}>
								<Avatar className={style.avatar_user} >
									{initialTechnician}
								</Avatar>

								<div className={style.description_user}>
									<p className={`${style.user_name} ${style.bold}`}>{technician.full_name}</p>
									<p className={style.user_text}>Técnico Principal</p>
									<p className={`${style.user_text} ${style.bold}`}>{`Auxiliar + ${auxiliaries.length}`}</p>
									{auxiliaries.map((item) => {

										const nameINitial = item.full_name.split(" ")[0][0]

										return (
											<span key={item.id} className={style.container_assistant}>
												<Avatar className={style.avatar_assistant} >{nameINitial}</Avatar>
												<p className={style.text_font12_5A607F}>{item.full_name}</p>
											</span>
										)
									})}


									{comments && (
										<>
											<p className={`${style.text12font} ${style.bold} ${style.color5A607F}`}>Comentario adicional</p>
											<p className={`${style.text12font} ${style.color5A607F}`}>{comments}</p>
										</>
									)}


								</div>

								<p className={`${style.text_font12_2B80FF} ${style.bold}`}>
									{supervisor_required ? "apoyo de supervisor" : "Sin apoyo de supervisor"}
								</p>
							</section>

							<section className={style.container_date}>

								<SubtitleBlue value='Agendamiento' />

								<div className={style.container_separate}>
									<div className={style.subcontainer_separate}>
										<LabelDrawer value='Fecha' />
										<DatePicker
											format={'DD/MM/YYYY'}
											onChange={hadlerSelectedDate}
											status={emptyInputsCheck(camposIncompletos, 'date_time_initial') || emptyInputsCheck(camposIncompletos, 'date_time_final') ? 'error' : ''}
										/>
									</div>

									<div className={style.subcontainer_separate}>
										<LabelDrawer value='Horario' />
										<RangePicker
											placeholder={["Start time", "End time"]}
											picker='time'
											format={'HH:mm'}
											onChange={hadlerSelectedTime}
											status={emptyInputsCheck(camposIncompletos, 'date_time_initial') || emptyInputsCheck(camposIncompletos, 'date_time_final') ? 'error' : ''}
										/>
									</div>

								</div>
							</section>

							<AdditionalInformation
								setImagesBase64={setImagesBase64}
								setViewUpload={setViewUpload}
								viewUpload={viewUpload}
							/>

							{disponibility === false && (
								<ImportantInformation
									text={'La orden de trabajo que desea crear esta fuera del rango de disponibilidad del técnico.'}
									textCheckbox={'Aprobar agendamiento'}
									checkbox={setDisponibilityCheck}
								/>
							)}

							{diffHour > 8 && (
								<ImportantInformation
									text={'La orden de trabajo que desea crear se excede de 8 horas laborales.'}
									textCheckbox={'Crear orden de trabajo'}
									checkbox={setCheckMaxHour}
								/>
							)}

							<BottomSection
								textButtonAcept='Continuar'
								onAcept={openSummary}

								onCancel={() => setViewForm(false)}
								disable={diffHour > 8 && !checkMaxHour ? true : disponibility == false && !disponibilityCheck ? true : false}
							/>
						</>
					)}

				</Form>
			</CustomModal>

			{viewSummary &&
				<SummaryFormCopy
					setViewSummary={setViewSummary}
					body={bodyCreateSummary}
					typeCountryString={typeCountryString}
					typeClient={typeClient}
					imageBase64={imageBase64}
					viewUpload={viewUpload}
					selectedDirection={selectedDirection}
				/>
			}
		</>
	)
}

export default FormCopyOrderOt