import React from 'react'
import CustomModal from '../../../../components/modal/CustomModal'
import Form from '../../../../components/form/index.jsx'
import MainTitle from '../../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue'

import style from './formDetail.module.css'
import TableDetail from './tableDetail/TableDetail.jsx'

const FormDetail = ({ selectCategory, setVisibleFormDetail }) => {

	return (
		<CustomModal onClick={() => setVisibleFormDetail(false)}>
			<Form>
				<MainTitle value='Detalle Categorías' onClick={() => setVisibleFormDetail(false)} />
				<SubtitleBlue value='Formato de visualización de orden de servicio' />

				<p className={style.text}>Configuración de Categorías de la orden de servicio habilitadas para el técnico</p>

				<TableDetail
					order_type={selectCategory.order_type}
					data={selectCategory.categories_list}
					ordenName={selectCategory.order_name.name}
				/>

				<section className={style.container_button}>
					<button className={style.button_close} onClick={() => setVisibleFormDetail(false)}>
						Cerrar detalle
					</button>
				</section>
			</Form>
		</CustomModal>
	)
}

export default FormDetail