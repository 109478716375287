
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Panel } from "../../../../components/panel/panel.jsx";
import { Form, Table } from 'antd';
import { getCategoriesColumn, getCategoriesRow } from "../../../../api/ostypeCreate.js";
import SubtitleBlue from "../../../../components/text/drawer/subtitleBlue/index.jsx";
import { permissionCheck } from "../../../../helpers/helpers.js";
import { useSelector } from "react-redux";

import style from "./overallDetailsScreen.module.css";
import ColorCircle from "../../../../components/colorCircle/index.jsx";
import AddCategoryForm from "../../newCategory/index.jsx";
import EditCategoryForm from "../EditCategoryForm.jsx";
import EditServiceOrder from "../editServiceOrderType/EditServiceOrder.jsx";
import DatatableRowText from "../../../../components/text/datatable/datatableRowText/index.jsx";
import { DetailForm } from "../../../../components/detailForm/DetailForm.jsx";
import CustomModal from "../../../../components/modal/CustomModal.jsx";
import EmptyData from "../../../../components/empty/emptyData/EmptyData.jsx";


export default function OverallDetailsScreen({ data }) {

	const [COLUMNS, setCOLUMNS] = useState([]);
	const [ROWS, setROWS] = useState([]);

	const [addCategoryForm, setAddCategoryForm] = useState(false);
	const [editCategoryForm, setEditCategoryForm] = useState(false);
	const [editableData, setEditableData] = useState(null);

	const [editOrderType, setEditOrderType] = useState(false);

	const [viewImages, setViewImages] = useState(false);
	const [viewQuestions, setViewQuestions] = useState(false);

	const [dataImages, setDataImages] = useState({});
	const [dataQuestions, setDataQuestions] = useState({});

	const [loadding, setLoadding] = useState(true);
	const [emptyData, setEmptyData] = useState(false);

	const array = useSelector((state) => state?.authState?.permissions);

	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const onViewImages = (data) => {
		setDataImages(data)
		setViewImages(true)
	}

	const onViewQuestions = (data) => {
		setDataQuestions(data)
		setViewQuestions(true)
	}

	useEffect(() => {
		getCategoriesColumn().then(res => {
			const max = 2;

			const data = res.map(item =>
				item.dataIndex === "name" ?
					{
						...item,
						onCell: (record) => {
							if (rolPermission("add_category")) {
								return {
									onClick: () => {
										setEditableData(record);
										setEditCategoryForm(true);
									},
									style: { cursor: "pointer" },
									className: "hover-table-class"
								}
							}
						}
					} : item
			)

			const imagesColumn = data.find(c => c.dataIndex === "imgs");

			if (imagesColumn) {
				imagesColumn.render = (images, record) => {
					return (
						<>
							{
								images.slice(0, max).map(item => (
									<span key={Math.random()} style={{ display: "flex", alignItems: "center" }}>
										<DatatableRowText marginBottom="0px" value={item.img_name} />
									</span>
								))
							}

							{images.length > max && (
								<button className={style.button_table} onClick={() => {
									onViewImages(record)
								}}>
									Ver más
								</button>
							)}
						</>
					)
				}
			}

			const questionColumn = data.find(c => c.dataIndex === "questions");
			if (questionColumn) {
				questionColumn.render = (questions, record) => {

					return (
						<>
							{
								questions.slice(0, max).map(item => (
									<span key={Math.random()} style={{ display: "flex", alignItems: "center" }}>
										<DatatableRowText marginBottom="0px" value={item.question} />
									</span>
								))
							}

							{questions.length > max && (
								<button className={style.button_table} onClick={() => onViewQuestions(record)}>
									Ver más
								</button>
							)}
						</>
					)
				}
			}

			setCOLUMNS(data)
		})
	}, [])

	useEffect(() => {
		getCategoriesRow(data?.id_unico).then(res => {
			setLoadding(false)
			setROWS(res)
			if (res.length === 0) setEmptyData(true)
			else setEmptyData(false)
		})

	}, [])


	return (
		<Panel>
			<section className={style.container_general}>
				<SubtitleBlue value="Información General" marginBottom="0px" />
				<span className={style.text_date}>Fecha de creación <b>{data?.created}</b></span>

				<section className={style.container_data}>
					<section className={style.container_description}>
						<div className={style.sub_container}>
							<p className={style.text_title}>Color</p>
							<span className={style.description}>
								<ColorCircle color={data?.color} />
								<p className={style.text_color}>{data?.color.toUpperCase()}</p>
							</span>
						</div>

						<div className={style.sub_container}>
							<p className={style.text_title}>Etiqueta</p>
							<p className={style.text_label}>{data?.tag}</p>
						</div>

						<div className={style.sub_container}>
							<p className={style.text_title}>Nombre</p>
							<p className={style.text_name} onClick={() => setEditOrderType(true)}>{data?.name}</p>
						</div>
					</section>
					{rolPermission("add_category") === true ? (
						<button className={style.button} onClick={() => setAddCategoryForm(!addCategoryForm)}>
							<i className={`fa-solid fa-plus ${style.icon_button}`}></i>
							Agregar categoría
						</button>
					) : null}


				</section>

			</section>

			{emptyData === false && loadding === false && (
				<section className={style.conatiner_table}>
					<Table
						columns={COLUMNS}
						dataSource={ROWS}
						pagination={false}
					/>
				</section>
			)}

			{emptyData && loadding === false && (
				<EmptyData sectionPage='categoría orden de servicio' />
			)}


			{addCategoryForm &&
				<AddCategoryForm
					os_type={data?.id_unico}
					addCategoryForm={addCategoryForm}
					setAddCategoryForm={setAddCategoryForm}
				/>
			}

			{editCategoryForm &&
				<EditCategoryForm
					editableData={editableData}
					os_type={data?.id_unico}
					editCategoryForm={editCategoryForm}
					setEditCategoryForm={setEditCategoryForm}
				/>
			}
			{editOrderType &&
				<EditServiceOrder
					setEditOrderType={setEditOrderType}
					data={data}
				/>
			}
			{viewImages && !viewQuestions &&
				(
					<CustomModal onClick={() => setViewImages(false)}>
						<DetailForm
							dataImages={dataImages}
							setViewImages={setViewImages}
							viewImages={viewImages}
						/>
					</CustomModal>
				)
			}

			{
				viewQuestions && !viewImages &&
				(
					<CustomModal onClick={() => setViewQuestions(false)}>
						<DetailForm
							dataQuestions={dataQuestions}
							setViewQuestions={setViewQuestions}
							viewQuestions={viewQuestions}
						/>
					</CustomModal>
				)
			}



		</Panel>
	);
}

const SubCont1 = styled.div`
	background-color: white;
	display: flex;
	flex-flow: column;
	padding: 0 35px 20.19px 30.25px;

	p,h3, span, div, b{
		margin: inherit;
		display: inline;
	}
	p{
		display: inline;
	}
	
	.row {
		margin: inherit;
		display: flex;
		align-items: center;
	}
	.column {
		display: flex;
		flex-flow: column;
	}
	
	.space-between {
		display: flex;
		justify-content: space-between;
	}
	.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.flex-end {
		display: flex;
		justify-content: end;
	}
	.title {
		color: #2B80FF;
		font-size: 14px; 
		font-weight: bold; 
	}
	.b {
		color: #2B80FF;
	}
	.left, .right {
		width: 49%
	}
	.inputContainer {
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 5px;
		border: 1px solid #E0E0E0;
		position: relative;
	}
	.inputContainer .selected_color {
		width: calc(99% - 40px);
		height: 40px;
		padding: 1em;
		display: flex;
		align-items: center;
	}

	.inputContainer .selected_color .circle{
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}
	.inputContainer .colorPickerIcon, .inputContainer .colorPickerIcon svg {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.inputContainer .colorPickerIcon svg {
		width: 15px;
		height: 15px;
	}
	.inputColor {
		position: absolute;
	}
	.btn__ {
		width: 83px;
		height: 40px;
	}
	
	.btn__edit {
		width: 101px;
		height: 40px;
		border: 2px solid #0060FF;
	}
	
`;
