/* eslint-disable */
import React from 'react'
import './SearchDirection.scss'

const SearchDirection = ({ data, loadding, handleSelectDirection }) => {

	return (
		<>
			{loadding && (
				<div className='container_search_direction custom_scroll'>
					{data?.map((item) => (
						<div className='subcontainer_search' key={item?.id} onClick={() => handleSelectDirection(item)}>
							<span className='text_search' >{item?.full_direction}</span>
							<span className='text_right_search'>{item?.name}</span>
						</div>

					))}
				</div>
			)}

		</>

	)
}

export default SearchDirection