const historyDataRegister = (data) => {
  const groupedChanges = {}
  data.forEach(changeArr => {
    changeArr.forEach(change => {
      const { field, date } = change;
      if (!groupedChanges[date]) {
        groupedChanges[date] = [];
      }
      groupedChanges[date].push({ field });
    });
  });

  return groupedChanges
}

export default historyDataRegister