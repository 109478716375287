import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { selectTab } from '../../store/tab/tabSlice';
import HistoryPanelTypesOT from './components/historyPanelTypesOT/HistoryPanelTypesOT.jsx';
import OverallDetailsTypesOT from './components/overallDetailsTypesOT/OverallDetailsTypesOT.jsx';
import { Panel, PanelHeader } from '../../components/panel/panel.jsx';
import { TabsPanel } from '../../components/tabs/Tabs.jsx';
import { Tooltip } from 'antd';

import style from './typesWorkOrdersDetails.module.css'
import dayjs from 'dayjs';

const TypesWorkOrdersDetails = () => {

	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const selectedTab = useSelector((state) => state?.tabs?.selectedTab);

	const { created } = location?.state?.params;
	const creacion = dayjs(created, "DD-MM-YYYY").format('D [de] MMMM [de] YYYY', { locale: 'es' });

	const handleTabChange = (index) => {
		dispatch(
			selectTab({
				selectedTab: index,
				showTabs: true,
			})
		)
	}

	const tabs = [
		{ name: "General", component: <OverallDetailsTypesOT data={location?.state?.params} /> },
		{ name: "Histórico", component: <HistoryPanelTypesOT data={location?.state?.params} /> },
	];

	const tooltipText = (
		<section className={style.container_tooltip}>
			<span className={style.tittle_tooltip}>Información de ayuda</span>
			<span className={style.description_tooltip}>Ficha con datos generales asociados al tipo de orden de trabajo</span>
		</section>
	)

	return (
		<Panel>
			<PanelHeader noButton={true}>
				<span style={{ color: "#001737", fontWeight: "bold", fontSize: "24px", minWidth: "303px" }}>Tipos de órdenes de trabajo</span>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement="bottom"
					overlayInnerStyle={{
						height: "95px",
						maxHeight: '100px'
					}}
				>
					<i className="fa-solid fa-circle-info" style={{ margin: "0 10px", fontSize: "12px", color: "#00388B" }}></i>
				</Tooltip>
				<span style={{ color: "#001737", fontWeight: "400", fontSize: "12px", minWidth: "285px" }}>

					<span>Configuración</span>
					<span style={{ cursor: "pointer", fontWeight: "bold", marginLeft: "4px" }} onClick={() => history.push("/TypesOT")}>| Tipos de órdenes de trabajo | </span>
					<span>Detalle</span>
				</span>

				<span style={{ color: "#001737", fontWeight: "400", fontSize: "12px", width: "100%", textAlign: "right" }}>Fecha de creación {creacion} </span>
			</PanelHeader>

			<TabsPanel selectedTabClassName="is-selected">
				<span className="tablist_type_order">
					{tabs?.map((tab, index) => (
						<span key={index} className={`Tab ${selectedTab === index ? "is-selected" : ""}`} onClick={() => handleTabChange(index)}>{tab?.name}</span>
					))}
				</span>
				{tabs[selectedTab]?.component}
			</TabsPanel>
		</Panel>
	)
}

export default TypesWorkOrdersDetails