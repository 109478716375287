const size = {
	mainTitle: "24px",
	drawerForm: "100%",
	sidebar: "16vw",
	drawerCloseIcon: "14px",
	drawerTitle: "20px",
	paragraph: "14px",
	drawerSubtitle: "14px",
}
export default size

export const devices = {
	smallDesk: "1280px",
	mediumDesk: "1366px",
	largeDesk: "1920px",
};

// Dimensiones de componentes y textos

export const smallSizes = {
	fontSize: {
		fontSize8: '8px',
		fontSize9: '9px',
		fontSize10: '10px',
		fontSize11: '11px',
		fontSize12: '12px',
		fontSize13: '13px',
		fontSize14: '14px',
		fontSize15: '15px',
		fontSize16: '16px',
		fontSize18: '18px',
		fontSize20: '20px',
		fontSize22: '22px',
		fontSize24: '24px',
		fontSize36: '36px',
	},
	sidebar: {
		widthImg: '150px',
		heightSelect: '80px',
		ImgOperator: '49px'
	},
	header: {
		heightHeader: '65px',
		widtDialog: '101.75px',
		heightDialog: '23px',
		leftDialog: '-115px',
		headerAvatar: '35px',
		visibleBoxWidth: '165px',
		visibleBoxBottom: '-80px',
	},
	bottomDashboard: {
		avatar: '22px'
	},
	calendar: {
		widthSelect: '192px'
	},
	detailOrderHeader: {
		widthTitle: '258px',
		widthSubTitle: '270px',
		widthSubOS: '108px',
		widthSubList: '115px',
	},
	detailGeneral: {
		widthButton: '101px',
		heightButton: '40px'
	},
	detailOsGallery: {
		widthImg: '115.5px',
		heightImg: '75.5px',
		widthContainerButton: '240px',
	},
	reagendarButon: {
		widtButton: '73px',
		heightButton: '24px'
	},
	detailGeneralTechnicials: {
		widthButton: '83px',
		heightButton: '34px'
	},
	tipoOS: {
		widthButton: '91px',
		heightButton: '40px'
	},
	lunch: {
		dimension: '20px',
	},
	availability: {
		widtButton: '161px',
		heightButton: '34px'
	},
	documentTechnicials: {
		widtButton: '147px',
		heightButton: '34px',
		widthImg: '115.5px',
		heightImg: '75.5px',
		widthDate: "178px"
	},
};

export const mediumSizes = {
	fontSize: {
		fontSize8: '9px',
		fontSize9: '10.12px',
		fontSize10: '11.25px',
		fontSize11: '12.375px',
		fontSize12: '13.5px',
		fontSize13: '14.62px',
		fontSize14: '15.75px',
		fontSize15: '16.87px',
		fontSize16: '18px',
		fontSize18: '20.25px',
		fontSize20: '22.5px',
		fontSize22: '24.75px',
		fontSize24: '27px',
		fontSize36: '40.5px',
	},
	sidebar: {
		widthImg: '168.75px',
		heightSelect: '90px',
		ImgOperator: '55.125px'
	},
	header: {
		heightHeader: '73.12px',
		widtDialog: '114.46px',
		heightDialog: '25.87px',
		leftDialog: '-129.37px',
		headerAvatar: '39.37px',
		visibleBoxWidth: '185px',
		visibleBoxBottom: '-90px',
	},
	bottomDashboard: {
		avatar: '24.75px'
	},
	calendar: {
		widthSelect: '216px'
	},
	detailOrderHeader: {
		widthTitle: '290.25px',
		widthSubTitle: '303.75px',
		widthSubOS: '121.5px',
		widthSubList: '129.37px'
	},
	detailGeneral: {
		widthButton: '113.62px',
		heightButton: '45px'
	},
	detailOsGallery: {
		widthImg: '129.93px',
		heightImg: '84.93px',
		widthContainerButton: '270px',
	},
	reagendarButon: {
		widtButton: '82.125px',
		heightButton: '27px'
	},
	detailGeneralTechnicials: {
		widthButton: '93.375px',
		heightButton: '38.25px'
	},
	tipoOS: {
		widthButton: '102.37px',
		heightButton: '45px'
	},
	lunch: {
		dimension: '22.5px',
	},
	availability: {
		widtButton: '181.12px',
		heightButton: '38.25px'
	},
	documentTechnicials: {
		widtButton: '165.37px',
		heightButton: '38.25px',
		widthImg: '129.93px',
		heightImg: '84.93px',
		widthDate: "200.25px"
	}
};

export const largeSizes = {
	fontSize: {
		fontSize8: '10.12px',
		fontSize9: '11.39px',
		fontSize10: '12.65px',
		fontSize11: '13.92px',
		fontSize12: '15.18px',
		fontSize13: '16.45px',
		fontSize14: '17.71px',
		fontSize15: '18.98px',
		fontSize16: '20.25px',
		fontSize18: '22.78px',
		fontSize20: '25.31px',
		fontSize22: '27.84px',
		fontSize24: '30.37px',
		fontSize36: '45.56px',
	},
	sidebar: {
		widthImg: '189.84px',
		heightSelect: '101.25px',
		ImgOperator: '62px'

	},
	header: {
		heightHeader: '82.26px',
		widtDialog: '128.77px',
		heightDialog: '29.1px',
		leftDialog: '-145.54px',
		headerAvatar: "44.29px",
		visibleBoxWidth: '208px',
		visibleBoxBottom: '-92px',
	},
	bottomDashboard: {
		avatar: '27.84px'
	},
	calendar: {
		widthSelect: '243px'
	},
	detailOrderHeader: {
		widthTitle: '326.53px',
		widthSubTitle: '341.71px',
		widthSubOS: '136.68px',
		widthSubList: '145.54px'
	},
	detailGeneral: {
		widthButton: '127.82px',
		heightButton: '50.625px'
	},
	detailOsGallery: {
		widthImg: '146.17px',
		heightImg: '95.55px',
		widthContainerButton: '303.75px',
	},
	reagendarButon: {
		widtButton: '92.39px',
		heightButton: '30.375px'
	},
	detailGeneralTechnicials: {
		widthButton: '105.04px',
		heightButton: '43.03px'
	},
	tipoOS: {
		widthButton: '115.17px',
		heightButton: '50.62px'
	},
	lunch: {
		dimension: '25.31px',
	},
	availability: {
		widtButton: '203.76px',
		heightButton: '43.03px'
	},
	documentTechnicials: {
		widtButton: '186.04px',
		heightButton: '43.03px',
		widthImg: '146.17px',
		heightImg: '95.55px',
		widthDate: "225.28px"
	}
};

