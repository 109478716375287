export const buildOsTypeFilter = (osTypeID) => (osTypeID ? `["ostype","equal", "${osTypeID}"]` : "");

export const buildTechnicianFilter = (technicianID) => (technicianID ? `["technician","equal", "${technicianID}"]` : "");

export const buildDateRangeFilter = (startDate, endDate) => {
	const filters = [];
	if (startDate) filters.push(`["created","gt","${startDate} 00:00"]`);
	if (endDate) filters.push(`["created","lte","${endDate} 23:59"]`);
	return filters.join(", ");
};

export const buildOtTypeFilter = (otTypeID) => (otTypeID ? `["ID","equal", "${otTypeID}"]` : "");

export const buildFilters = (osTypeID, technicianID, startDate, endDate, searchData, rowsParams, otTypeID) => {
	const filters = [
		buildOsTypeFilter(osTypeID),
		buildTechnicianFilter(technicianID),
		buildDateRangeFilter(startDate, endDate),
		buildOtTypeFilter(otTypeID)
	].filter(Boolean).join(", ");

	const result = {
		offset: rowsParams.offset,
		start: rowsParams.start,
	};

	if (filters) {
		result.filters = `[${filters}]`;
	}

	if (searchData) {
		result.search = searchData;
	}

	return result;
};