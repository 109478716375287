import React from 'react'
import './index.scss'

const DatatableHeaderText = ({ value = "", bold = false, color = "#8898AA" }) => {
    return (
        <span className='datatableHeaderText'
            style={{
                fontWeight: bold === true ? "bold" : "400",
                color
            }}
        >{value}</span>
    )
}

export default DatatableHeaderText