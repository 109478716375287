import React from 'react';
import { Avatar, Tooltip } from "antd";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import styled from 'styled-components'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes'

const BottomCard = ({
	tooltip_type,
	dataTypeOs,
	percentageCard,
	typeOs,
	tooltip,
	technicalDistribution,
	CustomXAxisTick
}) => {

	const firstLetter = technicalDistribution?.map(item => item?.comuna.charAt(0))


	const getRandomColor = () => {
		const letters = "0123456789ABCDEF";
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	};

	return (
		<Container>
			<div className="bottom_section">
				<div className="recents">

					<p className='title-card'>Tipos de órdenes de servicio</p>
					<Tooltip title={tooltip_type} color="white" arrow={false} placement="bottomLeft">
						<i className="fa-solid fa-circle-info circle-tooltip"></i>
					</Tooltip>

					<div className='div-title-orders'>
						<span className='total-orders'>{dataTypeOs?.map((item) => item.nombre === "Total_OS" ? item.cant : null)} Órdenes</span>
						<span className='percent-bottom'> ({percentageCard?.map((item) => item.nombre === "tipo de Órdenes" ? item.porcentaje : null)}) %</span>
						<span className='text-respect'>Respecto al día anterior</span>
					</div>

					<ResponsiveContainer width='100%' height="73%">
						<BarChart
							width={550}
							height={300}
							data={typeOs}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<XAxis dataKey="nombre"
								axisLine={false}
								tick={<CustomXAxisTick
									maxChars={14}
								/>}
								tickMargin={10}
								tickLine={{ stroke: '#eee', strokeWidth: 1 }}
								interval={0}
								height={50}
							/>
							<YAxis axisLine={false} tickLine={{ stroke: '#eee', strokeWidth: 1 }} domain={[0, 100]} />
							<Bar dataKey="cant" fill="#2B80FF" radius={[0, 0, 10, 10]} background={{ fill: '#eee', radius: 10 }} barSize={10} />
						</BarChart>
					</ResponsiveContainer>

				</div>

				{/* --------distribution of technicians-----------------  */}
				<div className="distribution">
					<span className='title-card'>Distribución de técnicos por zonas</span>
					<Tooltip title={tooltip} color="white" arrow={false} placement="bottom">
						<i className="fa-solid fa-circle-info circle-tooltip"></i>
					</Tooltip>

					<div className='table-zones'>
						<span className='title-table comuna' >Comuna</span>
						<span className='title-table'>Técnicos</span>
						<span className='title-table percent-title'>Porcentaje</span>
					</div>
					<div className='scrollable-container'>
						{technicalDistribution?.map((index, item) => (
							<div key={item} className='operadores' >
								<hr className='line' />
								<div className='table'>
									<div style={{ width: 150 }}>
										<Avatar
											className='avatar-comuna'
											style={{ backgroundColor: getRandomColor() }}
											src={index.foto !== null && index.foto !== undefined ? `data:image/${index.foto.extension};base64, ${index.foto.base64}` : ''}
										>
											{firstLetter[item]}
										</Avatar>
										<span className='name-comuna'>{index.comuna}</span>
									</div>
									<span className='tecnicos-comuna' >{index.tecnicos}</span>
									<span className='percent-comuna'>{index.porcentaje} %</span>
								</div>
							</div >
						))}
						<hr style={{ width: "100%", height: "1px", background: "#E9ECEF" }} />
					</div>


				</div>
			</div>
		</Container>
	)
}

export default BottomCard

const Container = styled.div`

.bottom_section{
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  margin: 1.3em 0;
}

.bottom_section .recents{
    width: calc(60% - 1.3em);
    min-height: 400px;
    background: white;
    padding: 2em;
    border-radius: 3px;
    border: 1px solid #DDE4EB;
}

.title-card{
  font-size: ${smallSizes.fontSize.fontSize15};
  font-weight: bold;
  color:#5A607F;
}

.circle-tooltip{
  margin: 10px;
  font-size: ${smallSizes.fontSize.fontSize12};
  color: #00388B;
}

.div-title-orders{
  width: 95%;
	margin-bottom: 6%;
}

.total-orders{
  color: #001737;
  font-weight: bold;
  font-size: ${smallSizes.fontSize.fontSize20};
  margin-right: 10%;
}

.text-respect{
  font-size: ${smallSizes.fontSize.fontSize10};
  color: #7E84A3;
}

.distribution{
  width: 40%;
  min-height: 400px;
  background: white;
  padding: 2em;
  border-radius: 3px;
  border: 1px solid #DDE4EB;
}
.distribution .operadores{
  display: flex;
  align-items: center;
  margin: 5px 20px 0 20px;
  flex-wrap: wrap;
}

.table-zones{
display: flex;
justify-content: space-between;
width: 86%;
margin: 22px 0 8px 38px;
}

.title-table{
  font-size: ${smallSizes.fontSize.fontSize12};
  color: #7E84A3;
}

.comuna{
  width: 68px;
}

.percent-title{
  width: 58px;
}

.line{
  width: 100%;
  height: 1px;
  background: #E9ECEF;
}

.table{
  display: flex;
  justify-content: space-between;
  width: 100%; 
}

.name-comuna{ 
  font-size: ${smallSizes.fontSize.fontSize12};
  font-weight: bold;
  margin-left: 10px;
}

.icon-size{
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.scrollable-container {
  max-height: 340px; 
  overflow-y: auto;
}

.percent-bottom{
	margin-right: 6%;
	font-size: ${smallSizes.fontSize.fontSize14};
	font-weight: bold;
	color: #3DD598;
}

.avatar-comuna{
	width: ${smallSizes.bottomDashboard.avatar};
	height: ${smallSizes.bottomDashboard.avatar};
	line-height: ${smallSizes.bottomDashboard.avatar};
}

.tecnicos-comuna{
	font-size: ${smallSizes.fontSize.fontSize12};
	margin-right: 55px;
}

.percent-comuna{
	font-size: ${smallSizes.fontSize.fontSize12};
	width: 50px;
}

@media screen and (min-width: ${devices.smallDesk}){

	.title-card{
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.circle-tooltip{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.total-orders{
		font-size: ${smallSizes.fontSize.fontSize20};
	}

	.percent-bottom{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.text-respect{
		font-size: ${smallSizes.fontSize.fontSize10};
	}

	.title-table{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
	
	.avatar-comuna{
		width: ${smallSizes.bottomDashboard.avatar};
		height: ${smallSizes.bottomDashboard.avatar};
		line-height: ${smallSizes.bottomDashboard.avatar};
	}

	.name-comuna{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.tecnicos-comuna{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.percent-comuna{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.title-card{
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.circle-tooltip{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.total-orders{
		font-size: ${mediumSizes.fontSize.fontSize20};
	}

	.percent-bottom{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.text-respect{
		font-size: ${mediumSizes.fontSize.fontSize10};
	}

	.title-table{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.avatar-comuna{
		width: ${mediumSizes.bottomDashboard.avatar};
		height: ${mediumSizes.bottomDashboard.avatar};
		line-height: ${mediumSizes.bottomDashboard.avatar};
	}

	.name-comuna{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.tecnicos-comuna{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.percent-comuna{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.title-card{
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.circle-tooltip{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.total-orders{
		font-size: ${largeSizes.fontSize.fontSize20};
	}

	.percent-bottom{
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.text-respect{
		font-size: ${largeSizes.fontSize.fontSize10};
	}

	.title-table{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.avatar-comuna{
		width: ${largeSizes.bottomDashboard.avatar};
		height: ${largeSizes.bottomDashboard.avatar};
		line-height: ${largeSizes.bottomDashboard.avatar};
	}

	.name-comuna{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.tecnicos-comuna{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.percent-comuna{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`