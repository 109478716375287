import React, { useEffect, useState } from 'react'
import NormalText from '../../../../../components/text/NormalText'
import { Divider, Input, Switch } from 'antd'
import styled from 'styled-components'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../../helpers/sizes'
import { myFetch, myFetchGet } from '../../../../../services/services'

const ResumeReagendarUi = ({
	startTime,
	endTime,
	onModalCloseEdit,
	openConfirmation,
	technician,
	overflow,
	category,
	date,
	technicianID,
	setOverflowavAilability
}) => {

	const [idFather, setIdFather] = useState(null)

	// peticion para obtener los horarios sobrecupo
	const getHoursOverflow = async () => {
		try {
			const responsive = await myFetchGet(`api/v1/technician/hours_overflow/?category=${category}&day=${date}`);
			setIdFather(responsive.id_father)
		} catch (error) {
			console.log(error)
		}
	};

	// peticion para crear sobrecupo con tecnico Manual
	const CreateOverflowManual = async () => {
		try {
			const request = await myFetch(`api/v1/technician/creates_overflow/?start_time=${startTime}&end_time=${endTime}&father=${idFather}&technician=${technicianID}`)
			setOverflowavAilability(request.disponibility)
		} catch (error) {
			console.log(error)
		}
	};

	const onCickContinue = () => {
		openConfirmation();
		CreateOverflowManual();
	}

	useEffect(() => {
		getHoursOverflow()
	}, [])


	return (
		<CardEvent>
			{overflow === false ? (
				<div className="card">
					<div className="row space-between">
						<NormalText value="Confirmación" />
					</div>

					<div style={{ marginBottom: 7 }}></div>
					{
						technician &&
						<NormalText
							bold={true}
							value={technician}
							color="#2B80FF"
						/>
					}

					<div className="row space-between">
						<div className="left__picker">
							<NormalText value="Inicio" color="#131523" />
							<Input className='input_card_ui' style={{ width: "100%" }} value={startTime} />

						</div>
						<div className="right__picker">
							<NormalText value="Fin" color="#131523" />
							<Input className='input_card_ui' style={{ width: "100%" }} value={endTime} />
						</div>
					</div>

					<Divider />

					<div className="row flex-end">
						<button className="btn_normal" onClick={onModalCloseEdit}>Cancelar</button>
						{/* <button className="btn_silver" onClick={patchEvent}>Continuar</button> */}
						<button className="btn_silver" onClick={openConfirmation}>Continuar</button>
					</div>
				</div>
			) : (
				<div className="card">
					<div className="row">
						<i className='fa-solid fa-triangle-exclamation warning_icon' />
						<NormalText value="Bloque de horario no disponible" color={"#868E96"} />
					</div>

					<div className="container_switch">
						<span className='text_overflow' >¿Desea reagendar?</span>
						<div className='subcontainer_switch'>
							<span className='text_switch_overflow'>No</span>
							<Switch
								style={{ margin: "0 5px" }}
								defaultChecked
								onChange={onModalCloseEdit}
							/>
							<span className='text_switch_overflow'>si</span>
						</div>

					</div>

					<div className="container_confirmation">
						<span className='text_confirmation' style={{ color: "#2B80FF" }}>Confirmación de horario</span>

					</div>

					<div className="row space-between">
						<div className="left__picker">
							<NormalText value="Inicio" color="#131523" />
							<Input className='input_card_ui' style={{ width: "100%" }} value={startTime} />

						</div>
						<div className="right__picker">
							<NormalText value="Fin" color="#131523" />
							<Input className='input_card_ui' style={{ width: "100%" }} value={endTime} />
						</div>
					</div>

					<Divider />

					<div className="row flex-end">
						<button className="btn_normal" onClick={onModalCloseEdit}>Cancelar</button>
						{/* <button className="btn_silver" onClick={patchEvent}>Continuar</button> */}
						<button className="btn_silver" onClick={onCickContinue}>Continuar</button>
					</div>
				</div>
			)}

		</CardEvent>
	)
}

export default ResumeReagendarUi

const CardEvent = styled.div`

  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card{
	width: 266px;
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    padding: 20px;
    display : flex;
    flex-flow: column;
    background: white;
  }

  .row{
    display: flex;
    margin: 0 !important;
    padding: 0 !important;
}
	.flex-end{
	display: flex;
    justify-content: flex-end;
  }
  .space-between{
    display: flex;
    justify-content: space-between;
  }

  .left__picker{
    width: 47%;
    background: white;
  }
  .right__picker{
    width: 47%;
    background: white;
  }
  button{
    border: none;
    outline: none;
  }

 .ant-picker-suffix {display: none}

 .btn_normal{
    background: transparent;
    font-size: ${smallSizes.fontSize.fontSize12};
    color: #5A607F;
    z-index:10;
    margin-right: 14px;
    width: ${smallSizes.reagendarButon.widtButton};
    height: ${smallSizes.reagendarButon.heightButton};
    cursor: pointer;
  }

  .btn_silver{
    background: #DDE4EB;
		font-size: ${smallSizes.fontSize.fontSize12};
    color: #001737;
    z-index:10;
    border-radius: 3px;
    width: ${smallSizes.reagendarButon.widtButton};
    height: ${smallSizes.reagendarButon.heightButton};
    cursor: pointer;

    :hover{
      background: #2B80FF;
      color: white;
    }
  }

  .btn_noBorder{
    border: none;
    outline: none;
    background: #F53C56;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fa-trash{
    color: white;
    margin: 0;
    padding: 0;
    font-size: 11px
  }
  

	.input_card_ui{
		font-size: ${smallSizes.fontSize.fontSize10};
	}

	.warning_icon{
		color: #fde230;
		margin-right: 10px;
		font-size: 16px;
	}

	.container_switch{
		display: flex;
    align-items: center;
		margin-top: 27px;
}

	.text_overflow{
		color: #131523;
    font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.text_switch_overflow{
		font-size: ${smallSizes.fontSize.fontSize12};
		color: #131523;
	}

	.subcontainer_switch{
		margin-left: 11px;
	}

	.container_confirmation{
		margin-top: 16px;
		margin-bottom: 6px;
	}

	.text_confirmation{
		color: #2B80FF;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	@media screen and (min-width: ${devices.smallDesk}){
		.input_card_ui{
			font-size: ${smallSizes.fontSize.fontSize10};
		}

		.btn_normal{
			font-size: ${smallSizes.fontSize.fontSize12};
			width: ${smallSizes.reagendarButon.widtButton};
			height: ${smallSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${smallSizes.fontSize.fontSize12};
			width: ${smallSizes.reagendarButon.widtButton};
			height: ${smallSizes.reagendarButon.heightButton};
		}

		.text_overflow{
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	
		.text_switch_overflow{
			font-size: ${smallSizes.fontSize.fontSize12};
		}

		.text_confirmation{
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.input_card_ui{
			font-size: ${mediumSizes.fontSize.fontSize10};
		}

		.btn_normal{
			font-size: ${mediumSizes.fontSize.fontSize12};
			width: ${mediumSizes.reagendarButon.widtButton};
			height: ${mediumSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${mediumSizes.fontSize.fontSize12};
			width: ${mediumSizes.reagendarButon.widtButton};
			height: ${mediumSizes.reagendarButon.heightButton};
		}

		.text_overflow{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	
		.text_switch_overflow{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}

		.text_confirmation{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.input_card_ui{
			font-size: ${largeSizes.fontSize.fontSize10};
		}

		.btn_normal{
			font-size: ${largeSizes.fontSize.fontSize12};
			width: ${largeSizes.reagendarButon.widtButton};
			height: ${largeSizes.reagendarButon.heightButton};
		}

		.btn_silver{
			font-size: ${largeSizes.fontSize.fontSize12};
			width: ${largeSizes.reagendarButon.widtButton};
			height: ${largeSizes.reagendarButon.heightButton};
		}

		.text_overflow{
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	
		.text_switch_overflow{
			font-size: ${largeSizes.fontSize.fontSize12};
		}

		.text_confirmation{
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	}
`