import { useState } from 'react';

const useDisabledButton = (initialState = false) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(initialState);

    const enableButton = () => setIsButtonDisabled(false)
    const disableButton = () => setIsButtonDisabled(true);

    return {
        isButtonDisabled,
        enableButton,
        disableButton,
    };
}

export default useDisabledButton;