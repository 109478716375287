/* eslint-disable */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Table, Tooltip, Pagination } from 'antd';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router";

// api
import { myFetch } from '../../services/services';
import { deleteHoliday, holidays_structColumns } from '../../api/holiday';

// hooks & helpers
import { useFilter } from '../../hooks/useFilter';
import { permissionCheck, stringCapitalize } from '../../helpers/helpers';

// custom
import { Panel, PanelHeader } from '../../components/panel/panel'
import CustomModal from '../../components/modal/CustomModal';
import NewHolidayForm from './newHolidayForm/NewHolidayForm';
import UpdateHolidayForm from './updateHoliday/UpdateHoliday';
import NormalText from '../../components/text/NormalText';
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";


import 'moment/locale/es';
import EmptyData from '../../components/empty/emptyData/EmptyData';
const Holidays = () => {
	const reduxState = useSelector((state) => state?.authState)
	const loginOperadorID = reduxState?.operator_detail?.ID;

	const array = reduxState?.permissions

	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}
	const [ROWS, setRows] = useState([]);

	const [COLUMNS, setColumns] = useState([]);

	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [showUpdateForm, setShowUpdateForm] = useState(false);
	const [selectedRowData, setUpdateSelectedData] = useState([])
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [totalSize, setTotalSize] = useState(0);

	const [loading, setLoading] = useState(true);

	const [emptyData, setEmptyData] = useState(false);

	const history = useHistory();

	const OncloseCreateForm = () => {
		setIsVisibleForm(!isVisibleForm)
	};
	const OncloseUpdateForm = () => {
		setShowUpdateForm(!showUpdateForm)
	};

	const dateFormat = (value) => {
		const formattedDate = moment(value, "DD-MM-YYYY").format("dddd, DD [de] MMMM");
		return formattedDate;
	}

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Feriados admitidos por el operador.</span>
		</ContainerTooltip>
	)

	//Filtro
	const { filters, setRowsParams, rowsParams } = useFilter()

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const getHolidaysDatatables = async () => {
		setLoading(true)
		const filtersData = { ...filters, "operator": loginOperadorID }
		try {
			const res = await myFetch("api/v1/holiday/datatables/", filtersData);

			if (res.data.length === 0) setEmptyData(true)
			else setEmptyData(false)

			if (res?.size) { setTotalSize(res?.size) }

			if (res?.data) {
				const rows = res?.data?.map((row) => {
					const date = dateFormat(row?.date_format)

					return {
						...row,
						key: row?.ID,
						date: stringCapitalize(date)
					}
				});
				setRows(rows);
				setLoading(false)
			}
		} catch (e) {
			setLoading(false)
		}
	}

	useEffect(() => {
		holidays_structColumns()
			.then(res => {
				const data = res.map(item =>
					item.dataIndex === "name" ?
						{
							...item, onCell: (record) => {
								return {
									onClick: () => {
										OncloseUpdateForm()
										handlerUpdateHoliday(record)
									}, style: { cursor: "pointer" },
									className: "hover-table-class"
								};
							}
						} : item
				);
				setColumns(data)
			})
	}, [rowsParams])

	useEffect(() => {
		if (loginOperadorID !== null) {
			getHolidaysDatatables()
		}

	}, [loginOperadorID, rowsParams])


	const handlerUpdateHoliday = (data) => {
		setUpdateSelectedData(data)
	}

	useEffect(() => {
		if (rolPermission("datatable_view_holiday") === false) {
			history.replace("/dashboard")
		}
	}, []);


	return (
		<Panel value={isVisibleForm} setValue={setIsVisibleForm}>
			<ContainerHeader>
				<PanelHeader
					buttonText="Registrar feriado"
					noButton={rolPermission("add_holiday") === true ? false : true}
					typeButton={"more"}
				>
					<span className='title_holidays'>Listado de días feriados</span>
					<Tooltip
						title={tooltipText}
						color='white'
						arrow={false}
						placement="bottom"
						overlayInnerStyle={{
							height: "95px",
							maxHeight: '100px'
						}}
					>
						<i className="fa-solid fa-circle-info circle_tooltip"></i>
					</Tooltip>
					<span className='sub_tex_holidays'>Disponibilidad | Feriados</span>
				</PanelHeader>
			</ContainerHeader>

			{loading === false && emptyData === false && (
				<Content>
					<p className='single_text_holidays'>Listado de días feriados registrados por el operador.</p>

					<Table
						columns={COLUMNS}
						dataSource={ROWS}
						scroll={{ x: 1000 }}
						pagination={false}
					/>


					<PaginationContainer>
						<div className="row space-between align-center">
							<span className="pagination">
								<NormalText
									bold={false}
									value={`Mostrando del ${rowsParams.start + 1} registros al ${rowsParams.start + rowsParams.offset} de un`}
								/>
								<NormalText
									bold={true}
									value={`total de ${totalSize} registros`}
								/>

							</span>
							<Pagination
								showSizeChanger
								defaultCurrent={paginationSettings[1]}
								current={paginationSettings[0]}
								onChange={modifyPagination}
								total={totalSize}
							/>
						</div>
					</PaginationContainer>
				</Content>
			)}

			{emptyData && loading === false && (
				<EmptyData sectionPage='feriado' />
			)}

			{
				isVisibleForm && (
					<CustomModal onClick={OncloseCreateForm}>
						<NewHolidayForm
							visible={isVisibleForm}
							setVisible={setIsVisibleForm}
							loginOperadorID={loginOperadorID}
						/>
					</CustomModal>
				)
			}
			{
				showUpdateForm && (
					<CustomModal onClick={OncloseUpdateForm}>
						<UpdateHolidayForm
							visible={showUpdateForm}
							setVisible={setShowUpdateForm}
							selectedRowData={selectedRowData}
							loginOperadorID={loginOperadorID}
						/>
					</CustomModal>
				)
			}

		</Panel>
	)
}

export default Holidays

const Content = styled.div`
display: flex;
flex-flow: column;
background: white;
padding-top: 34.57px;

.single_text_holidays {
	color: #5A607F;
	font-size: 14px;
	margin: 0 !important;
	padding: 0 !important;
	margin-bottom: 27.56px !important;
	padding-left: 42px !important;
	font-size: ${smallSizes.fontSize.fontSize14}
}

@media screen and (min-width: ${devices.smallDesk}){
	.single_text_holidays {
		font-size: ${smallSizes.fontSize.fontSize14}
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.single_text_holidays {
		font-size: ${mediumSizes.fontSize.fontSize14}
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.single_text_holidays {
		font-size: ${largeSizes.fontSize.fontSize14}
	}
}

`;
const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 150px;
	}
`;
const PaginationContainer = styled.div`
    padding-top: 43.87px;
	padding-right: 41px;
	padding-bottom: 33px;
	padding-left: 42px;

	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}
	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}
	.align-center {
		align-items: center;
	}
	.pagination {
		color: #001737;
	}

	.ant-pagination{
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}){
	
	.ant-pagination{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	
	.ant-pagination{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	
	.ant-pagination{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;

const ContainerButton = styled.div`
    display: flex;
    gap: 40px;

.btn-datatable-displacement{
	color: #2B80FF;
	background: transparent;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
}

@media screen and (min-width: ${devices.smallDesk}){
	.btn-datatable-displacement{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.btn-datatable-displacement{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.btn-datatable-displacement{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;

const ContainerHeader = styled.div`
.title_holidays{
	color: #001737;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize24};
}

.circle_tooltip{
	margin: 0 10px;
	color: #00388B;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.sub_tex_holidays{
	color: #001737;
	font-weight: 400;
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}){

	.title_holidays{
		font-size: ${smallSizes.fontSize.fontSize24};
	}

	.circle_tooltip{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.sub_tex_holidays{
		font-size: ${smallSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.title_holidays{
		font-size: ${mediumSizes.fontSize.fontSize24};
	}

	.circle_tooltip{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.sub_tex_holidays{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.title_holidays{
		font-size: ${largeSizes.fontSize.fontSize24};
	}

	.circle_tooltip{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.sub_tex_holidays{
		font-size: ${largeSizes.fontSize.fontSize12}
	}
}
`;
