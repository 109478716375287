import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../../components/modal/CustomModal'
import Form from '../../../../../../components/form/index.jsx'
import MainTitle from '../../../../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../../../../components/text/drawer/subtitleBlue/index.jsx'

import { Input, Image, Upload, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';

import style from './workDescriptionForm.module.css'
import BottomSection from '../../../../../../components/form/bottomSection/index.jsx'
import { myFetch, myFetchGet } from '../../../../../../services/services.js'

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

const WorkDescriptionForm = ({ setViewWorkDescription, data }) => {

	const { id_unico, duration, type_ot, service_date, hours_schedule, description } = data

	const { TextArea } = Input

	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [namePreview, setNamePreview] = useState('');
	const [fileList, setFileList] = useState([])

	const [valueTextArea, setValueTextArea] = useState(description)

	const [dataImages, setDataImages] = useState([])

	const getImages = async () => {
		try {
			const { response } = await myFetchGet(`api/v1/wo_pic/${id_unico}/get_pics/`)

			const data = response.map((item) => ({
				uid: item.ID,
				name: item.name,
				status: 'done',
				url: `data:image/png;base64,${item.picture_base64}`,
			}))
			setFileList(data)
		} catch (error) {

		}
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setNamePreview(file.name)
		setPreviewOpen(true);
	};

	const handleChange = async ({ fileList: newFileList }) => {
		const updatedFileList = await Promise.all(
			newFileList.map(async (item) => {
				if (item.originFileObj) {
					const base64 = await getBase64(item.originFileObj);
					return { ...item, url: base64 };
				}
				return item;
			})
		);
		setFileList(updatedFileList);
	};

	const onDescription = (e) => setValueTextArea(e.target.value)

	const onUpdateDescription = async () => {
		const body = {
			description: valueTextArea,
			images: dataImages
		}
		try {
			await myFetch(`api/v1/wo/${id_unico}/`, body, 'PATCH')
			notification.success({
				style: { fontWeight: 'bold' },
				message: "Descripción de trabajo actualizada",
				placement: 'bottomLeft',
				duration: 1.5,
				onClose: () => window.location.reload()
			})
		} catch (error) { }

	}

	useEffect(() => {
		getImages()
	}, [])

	useEffect(() => {
		const data = fileList.map((item) => ({
			caption_name: item.name,
			photo_base64: item.url
		})
		)
		setDataImages(data)
	}, [fileList])


	return (
		<CustomModal onClick={() => setViewWorkDescription(false)}>
			<Form>
				<MainTitle value='Descripción de trabajo' onClick={() => setViewWorkDescription(false)} />
				<span className={style.container_subtitle} >
					<SubtitleBlue value='Orden de trabajo' />
					<SubtitleBlue value={`Duración ${duration}`} />
				</span>

				<div className={style.container_detail_order}>
					<span className={style.flex_column}>
						<p className={`${style.text12font} ${style.color959595} ${style.bold}`}>Tipo</p>
						<p className={`${style.text20font} ${style.color172B4D} ${style.bold}`}>{type_ot.name}</p>
					</span>

					<div className={style.subcontainer_detail_order}>
						<span className={style.flex_column}>
							<p className={`${style.text12font} ${style.color959595} ${style.bold}`}>Fecha de atendimiento</p>
							<p className={style.text14font}>{service_date}</p>
						</span>

						<span className={style.flex_column}>
							<p className={`${style.text12font} ${style.color959595} ${style.bold}`}>Horario</p>
							<p className={style.text14font}>{hours_schedule}</p>
						</span>
					</div>

				</div>

				<div className={`${style.flex_column} ${style.margin_top35}`}>
					<p className={`${style.text12font} ${style.color172B4D}`}>Descripción</p>
					<TextArea style={{ height: 90 }} value={valueTextArea} onChange={onDescription} />
				</div>

				<p className={style.text_subtitle}>Imágenes asociadas</p>

				<div className={style.container_upload}>
					<Upload
						beforeUpload={() => false}
						listType="picture-card"
						fileList={fileList}
						onPreview={handlePreview}
						onChange={handleChange}
					>
						{fileList.length >= 4 ? null : (
							<button style={{ border: 0, background: 'none', }} type="button">
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>
									Upload
								</div>
							</button>
						)}

					</Upload>
				</div>

				{previewOpen && (
					<section className={style.modal} >
						<div className={style.container_modal}>
							<span className={style.title_image}>
								<p className={`${style.text12font} ${style.bold} ${style.color172B4D}`}>{namePreview}</p>
								<CloseOutlined
									className={style.icon_close}
									onClick={() => setPreviewOpen(false)}
								/>
							</span>

							<Image width={355} height={354} src={previewImage} preview={false} />
						</div>
					</section>


				)}

				<BottomSection onCancel={() => setViewWorkDescription(false)} onAcept={onUpdateDescription} />

			</Form>
		</CustomModal>
	)
}

export default WorkDescriptionForm