import React, { useState } from 'react'
import styled from 'styled-components'
import { InputNumber, Select, Popconfirm, notification } from 'antd'

// api
import { addNewCategory } from '../../../api/ostypeCreate'

// helpers & custom
import { onlyTextAndNumber, validarCampos } from '../../../helpers/helpers'

// custom
import Br from '../../../components/br/Br'
import LabelDrawer from '../../../components/text/drawer/label/index.jsx'
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue'
import BottomSection from '../../../components/form/bottomSection'
import MainTitle from '../../../components/text/drawer/mainTitle'
import CustomModal from '../../../components/modal/CustomModal'
import Form from '../../../components/form/index.jsx'
import './index.scss'

const AddCategoryForm = ({ os_type, addCategoryForm, setAddCategoryForm }) => {
    const [categoryName, setCategoryName] = useState("")
    const [categoryError, setCategoryError] = useState("")

    const [duration, setDuration] = useState(30)

    const [imgInputValue, setImgsInputValue] = useState("")
    const [imagesArray, setImagesArray] = useState([])

    const [questionInputValue, setInputQuestion] = useState("")
    const [questionArray, setQuestionArray] = useState([])

    const [questionType, setQuestionType] = useState("")

    const closeForm = () => setAddCategoryForm(!addCategoryForm)

    const onNameChange = (e) => {
        const value = e.target.value;
        if (onlyTextAndNumber(value)) {
            setCategoryName(value);
            setCategoryError("")
        } else {
            setCategoryError('*No se permiten Carácteres especiales');
        }
    }

    const onDurationChange = (value) => isNaN(value) ? setDuration(null) : setDuration(value)

    const addImagesArray = () => {
        if (!imgInputValue) {
            return;
        }
        setImagesArray(prevState => [...prevState, {
            "img_name": imgInputValue
        }])
        setImgsInputValue("")
    }
    const deleteImgName = (index) => {
        const newArray = imagesArray.filter((_, order) => order !== index)
        setImagesArray(newArray)
    }

    const addQuestionArray = () => {
        if (!questionInputValue) { return; }
        setQuestionArray(prevState => [...prevState, {
            "kind": questionType,
            "question": questionInputValue,
        }])
        setInputQuestion("")
    }

    const deleteQuestionName = (index) => {
        const newArray = questionArray.filter((_, order) => order !== index)
        setQuestionArray(newArray)
    }

    const onTypeChange = (value) => {
        setQuestionType(value);
    }

    const types = [{ kind: 1, name: "Sí o No" }, { kind: 2, name: "Abierta" }]

    const categoryData = {
        "name": categoryName,
        "duration": duration,
        "imgs": {
            "value": imagesArray
        },
        "questions": {
            "value": questionArray
        },
        "os_type": os_type,
    }

    const sendRequest = () => {
        if (validarCampos(categoryData)) {
            addNewCategory(categoryData)

        } else {
            notification.warning({
                style: { fontWeight: 'bold' },
                message: 'Por favor, completa todos los campos.',
                placement: 'bottomLeft',
                duration: 2.5,
            })
            return;
        }
    }

    return (
        <CustomModal onClick={closeForm}>
            <Form>
                <MainTitle onClick={closeForm} value='Agregar categoría' />
                <SubtitleBlue value="Información de la categoría" />

                <div className="row space-between">
                    <div className="left margin-top">
                        <div className="column">
                            <LabelDrawer value="Nombre" />
                            <input
                                type="text"
                                name='category'
                                className='input'
                                placeholder='Ingresar nombre de la categoría'
                                onChange={onNameChange}
                                value={categoryName}
                            />
                        </div>
                    </div>


                    <div className="right margin-top">
                        <div className="column">
                            <LabelDrawer value="Duración" />
                            <InputNumber
                                min={1}
                                max={240}
                                className='input'
                                defaultValue={60}
                                onChange={onDurationChange}
                                value={duration}
                                type="number"
                                placeholder="00:30 Min."
                            />
                        </div>
                    </div>
                </div>
                {categoryError && <span>{categoryError}</span>}

                {/* ------------------------------ */}

                <Br />

                <div className="column margin-top">
                    <LabelDrawer value="Nombre de imágenes a solicitar" />
                    <div className="row space-between">
                        <input type="text" onChange={e => setImgsInputValue(e.target.value)} value={imgInputValue} className='input' placeholder='Ingresar nombre de la categoría' style={{ width: "calc(97.7% - 91px)" }} />
                        <button className='add_button' onClick={addImagesArray}>Agregar</button>
                    </div>
                </div>

                {/* ------------------------------ */}
                <div className="margin-top"></div>
                <div className="row space-between">

                    {imagesArray !== [] ?
                        imagesArray.map((item, index) => {
                            return (
                                <span className='row space-between' key={index}>
                                    <span style={{ fontSize: "11px", paddingLeft: "20px", margin: "2px 0" }}>{item.img_name}</span>

                                    {/* Boton eliminar Imagen */}
                                    <Popconfirm
                                        placement="topRight"
                                        icon={""}
                                        title="¿Eliminar fotografía?"
                                        description={() => {
                                            return (
                                                `Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes`)
                                        }}
                                        onConfirm={() => deleteImgName(index)}
                                        okText="Eliminar"
                                        cancelText="Cancelar"
                                    >
                                        <button className='delete_noBtn' >Eliminar</button>
                                    </Popconfirm>
                                </span>
                            )
                        })
                        : null
                    }


                </div>

                {/* ------------------------------ */}
                <div className="margin-top"></div>
                <div className="row space-between">
                    <div className="column" style={{ width: "270px" }}>
                        <LabelDrawer value="Pregunta" />
                        <input
                            type="text"
                            className='input'
                            placeholder='Ingrese la pregunta'
                            value={questionInputValue}
                            onChange={(e) => setInputQuestion(e.target.value)}
                        />
                    </div>
                    <div className="column" style={{ width: "233px" }}>
                        <LabelDrawer value="Tipo" />
                        <div className="row space-between">
                            <Select
                                style={{ width: 130 }}
                                value={questionType}
                                onChange={onTypeChange}
                                options={types.map((item) => ({
                                    label: item.name,
                                    value: item.kind,
                                }))}
                            />
                            <button className='add_button' onClick={addQuestionArray}>Agregar</button>
                        </div>
                    </div>
                </div>

                {/* ------------------------------- */}

                <div className="margin-top"></div>
                <div className="row space-between">

                    {questionArray !== [] ?
                        questionArray.map((item, index) => {
                            return (
                                <span className='row space-between' key={index}>
                                    <span style={{ margin: "2px 0" }}>
                                        <span style={{ fontSize: "11px", marginRight: "20px" }}>{item.question}</span>
                                        <button style={{ padding: "2px 0", width: "56px", fontSize: 12, color: "white", background: "#2B80FF", textAlign: "center", border: "none", outline: "none" }}>{item.kind === 1 ? "Si o No" : "Abierta"}</button>
                                    </span>
                                    {/* Boton eliminar pregunta */}

                                    <Popconfirm
                                        placement="topRight"
                                        icon={""}
                                        title="¿Eliminar pregunta?"
                                        description={() => {
                                            return (
                                                `Si eliminas esta pregunta, se eliminará de forma permanente de tu galería de preguntas`)
                                        }}
                                        onConfirm={() => deleteQuestionName(index)}
                                        okText="Eliminar"
                                        cancelText="Cancelar"
                                    >
                                        <button className='delete_noBtn'>Eliminar</button>
                                    </Popconfirm>

                                </span>
                            )
                        })
                        : null
                    }
                </div>

                <Br />

                <BottomSection onCancel={closeForm} onAcept={sendRequest} />
            </Form>
        </CustomModal >
    )
}

export default AddCategoryForm