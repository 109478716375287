import React, { useState } from 'react'
import CustomModal from '../../../../components/modal/CustomModal'
import Form from '../../../../components/form/index.jsx'
import MainTitle from '../../../../components/text/drawer/mainTitle/index.jsx'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx'
import { checkEmptyKeys, emptyInputsCheck, validarCampos } from '../../../../helpers/helpers.js'
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx'
import { ColorPicker, Input, notification } from 'antd'
import Br from '../../../../components/br/Br.jsx'
import styled from 'styled-components'

import style from './editWorkOrdersTypes.module.css'
import BottomSection from '../../../../components/form/bottomSection/index.jsx'
import UseOperator from '../../../../hooks/UseOperator.jsx'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js'
import { updateType } from '../../../../api/otTypeApi.js'

const EditWorkOrdersTypes = ({ setEditOrderType, data }) => {

	const [colorSelected, setColorSelected] = useState(data?.color);
	const [nameSelected, setNameSelected] = useState(data?.name);
	const [labelSelected, setLabelSelected] = useState(data?.label);

	const [incompleteFields, setIncompleteFields] = useState(null)

	const { storedOperatorID } = UseOperator();
	const history = useHistory();

	const goToList = () => history.replace("/TypesOT");

	const handlerName = ({ target }) => {
		setNameSelected(target.value)
	}

	const handlerColor = (color) => {
		setColorSelected(color.toHexString())
	}

	const handlerLabel = ({ target }) => {
		setLabelSelected(target.value)
	}

	const onUpdateType = () => {
		const update = {
			"name": nameSelected,
			"color": colorSelected,
			"label": labelSelected,
			"operator": storedOperatorID
		}

		const emptyKeys = checkEmptyKeys(update)
		setIncompleteFields(emptyKeys)

		if (nameSelected === data?.name && colorSelected === data?.color && labelSelected === data?.label) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'no has hecho ningun cambio',
				placement: 'bottomLeft',
				duration: 2,
			})
			return
		}

		if (validarCampos(update)) {
			updateType(data?.id_unico, update, goToList)
		} else {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Por favor, completa todos los campos.',
				placement: 'bottomLeft',
				duration: 2,
			})
			return
		}
	}

	return (
		<CustomModal onClick={() => setEditOrderType(false)}>
			<Form>
				<MainTitle value='Modificar orden de trabajo' onClick={() => setEditOrderType(false)} />
				<SubtitleBlue value="Datos del tipo de orden de trabajo" />

				<div>
					<LabelDrawer value="Nombre" />
					<Input
						style={{ height: "40px" }}
						placeholder="Ingrese el Nombre de la orden de trabajo"
						value={nameSelected}
						onChange={handlerName}
						status={emptyInputsCheck(incompleteFields, "name") ? "error" : ""}
					/>
				</div>

				<Br />

				<section className={style.container_color}>
					<div className={style.subcontainer_color}>
						<LabelDrawer value='Color' />
						<ContainerColorPicker>
							<span className={style.colorpicker}>
								<p className={style.text_color}>{colorSelected.toUpperCase()}</p>
								<ColorPicker
									onChangeComplete={handlerColor}
									styles={{
										popupOverlayInner: {
											width: 260,
											background: "white",
										}
									}}
									value={colorSelected}
									arrow={false}
								/>
							</span>
						</ContainerColorPicker>
					</div>

					<div className={style.subcontainer_label}>
						<LabelDrawer value="Etiqueta" />
						<Input
							style={{ height: 40 }}
							value={labelSelected}
							onChange={handlerLabel}
							placeholder='Nombre del color'
							status={emptyInputsCheck(incompleteFields, "label") ? "error" : ""}
						/>
					</div>
				</section>

				<BottomSection onCancel={() => setEditOrderType(false)} onAcept={onUpdateType} />

			</Form>
		</CustomModal>
	)
}

export default EditWorkOrdersTypes

const ContainerColorPicker = styled.div`
.ant-color-picker-trigger{
	border: 0px;
}
`