import React, { useState } from 'react'
import './index.scss'

const SingleButton = ({
    onClick = () => { },
    value = "",
    minWidth = "100px",
    height = "40px",

    color = "#001737",
    hoverColor = "white",

    background = "#DDE4EB",
    hoverBackground = "#2C91FC",

    border = "1px solid #BFCCDA",
    hoverBorder = "1px solid #f1f1f1",

    iconClassName = ""
}) => {

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <button
            onClick={onClick}
            className='my__custom__single__button__styles'
            style={{
                minWidth,
                height,
                background: isHovered ? hoverBackground : background,
                color: isHovered ? hoverColor : color,
                border: isHovered ? hoverBorder : border,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <span className='button__text__content'>
                {iconClassName && <i className={iconClassName}></i>}
                {" " + value}
            </span>
        </button>
    )
}

export default SingleButton