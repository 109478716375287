/* eslint-disable */
import React, { useEffect, useState } from 'react'

import LabelDrawer from '../../../../../../components/text/drawer/label';
import Br from '../../../../../../components/br/Br';
import SearchDirection from '../../../searchDirection/SearchDirection';

import { emptyInputsCheck, onlyNumber, onlyTextAndSpace } from '../../../../../../helpers/helpers';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../../../helpers/sizes';

import { myFetchGet } from '../../../../../../services/services'

import { Collapse, Input, Radio, Select } from 'antd'
import styled from 'styled-components'
import { debounce } from "lodash";

import { RightOutlined } from '@ant-design/icons';
import { prefixesCountry, validateDocument, validateEmail, validateName, validatePhone } from '../../../../../../helpers/helperOT';
import { useSelector } from 'react-redux';


export default function InputSearch(
	{
		loginOperadorID,
		setFiltreValue,
		filtreValue,
		dataUser,
		idTypes,
		setManualRegister,
		setName,
		setLast_name,
		setDocumentType,
		setDocumentNumber,
		setPhone,
		setEmail,
		setIdServices,
		setAddress,
		camposIncompletos,
		setSelectUserSearch,
		category,
		setAvailableTechnicians,
		setSelectedDirectionId,
		setSelectedDirectionName,
		setTypeCountryString,
		typeCountryString,
		setIsValidName,
		isValidName,
		setIsValidLastName,
		isValidLastName,
		setIsValidEmail,
		isValidEmail,
		setIsValidPhone,
		isValidPhone,
		setIsValidDocument,
		isValidDocument,
	}
) {

	const countryID = useSelector((state) => state.authState.operator_detail.country);

	const [isVisibleListResult, setIsVisibleListResult] = useState(false)
	const [inputValue, setInputValue] = useState("")
	const [valueList, setValueList] = useState([])

	const [showManualUI, setShowManualUI] = useState(false)

	const { Option } = Select;
	const [error, setError] = useState(false)

	const [fullName, setFullName] = useState()

	// ------------Validations:-------------
	// -------------------------------------

	const [idNumber, setIdNumber] = useState(null);
	const [searchDirection, setSearchDirection] = useState('');

	const [isVisibleListResultDirection, SetIsVisibleListResultDirection] = useState(false)
	const [dataDirection, SetDataDirection] = useState([])
	const [loaddingSearchDirection, SetLoaddingSearchDirection] = useState(false)
	const [directionSelected, setDirectionSelected] = useState('');

	const [dataUserSelected, setDataUserSelected] = useState('');

	const [textName, setTextName] = useState(null)
	const [textLastName, setTextLastName] = useState(null)
	const [emailInput, setEmailInput] = useState(null)
	const [phoneInput, setPhoneInput] = useState(null)
	const [documentString, setDocumentString] = useState(null)

	const [typeCountry, setTypeCountry] = useState([])
	const [isoCountry, setIsoCountry] = useState(null)

	const [prefixes, setPrefixes] = useState(null)

	const onNameChange = (e) => {
		setName(e.target.value)
		setTextName(e.target.value)
	}
	const onSurnameChange = (e) => {
		setLast_name(e.target.value)
		setTextLastName(e.target.value)
	}

	const phoneOnchange = (e) => {
		setPhone(e.target.value)
		setPhoneInput(e.target.value)
	}

	const handlerInputEmail = (e) => {
		setEmail(e.target.value)
		setEmailInput(e.target.value)
	}

	// -------------------------------------
	// -------------------------------------


	const namePlaceholder = "Ingresar Nombre o Apellido"
	const docPlaceholder = "Ingresar Documento de Indentidad"
	const idServicePlaceholder = "Ingresar ID de Servicio"


	const onChangeCheck = (e) => setFiltreValue(e)

	const onManualRegister = () => {
		setIsVisibleListResult(false)
		setShowManualUI(true)
		setManualRegister(true)
	}

	const getUser = async () => {
		if (inputValue !== "") {
			try {
				const res = await myFetchGet(`api/v1/get_all_users/?operator=${loginOperadorID}&tipo_busqueda=${filtreValue}&valor_busqueda=${inputValue}`);
				setValueList(res?.users)
			} catch (e) {
				setIsVisibleListResult(false)
			}
		} else { setValueList([]) }
	}

	function handleSelecteValue(user) {
		// Nombre a visualizar en el Input
		setFullName(user?.first_name + " " + user?.last_name)
		// Data de usuario seleccionado
		dataUser(user);
		setIsVisibleListResult(false);
		// Visualizacion data de usuario seleccionado 
		setSelectUserSearch(true);
		// data usuario para peticion de disponibilidad
		setDataUserSelected(user);
	}

	// -------------------------------
	// -------------------------------
	// const ISO = "CL"
	const ISO = idTypes?.iso
	const isoFlag = ISO?.toLowerCase()

	const handleSelectChange = (value) => {

		// number
		const filtered = idTypes?.data?.filter(item => item.name === value)
		setTypeCountryString(filtered[0].name.toUpperCase())
		setDocumentType(filtered[0].ID)

	};

	const onIdentificationNumber = (e) => {
		const value = e.target.value
		setIdNumber(value)
		setDocumentNumber(value)
		setDocumentString(value)
	}



	const onDirectionSearch = ({ target }) => {

		setSearchDirection(target.value);
		SetIsVisibleListResultDirection(true);

		setDirectionSelected(target.value);
	}


	const getDirection = async () => {
		const res = await myFetchGet(`api/v1/direction/search/?generic=${searchDirection}`);
		SetDataDirection(res);
		SetLoaddingSearchDirection(true);
	}

	const handleKeyUp = () => {
		handlerSearchUser();
	};

	const handleKeyDown = () => {
		handlerSearchUser.cancel();
	};

	const handlerSearchUser = debounce(() => {
		getUser();
	}, 750);

	const onInputChange = (e) => {

		if (e.target.value === '') {
			setIsVisibleListResult(false);
			setSelectUserSearch(false);
		} else {
			setIsVisibleListResult(true)
		}
		const value = setInputValue(e.target.value)
		setFullName(value)
	}

	const handleSelectDirection = (item) => {
		setDirectionSelected(item);
		SetIsVisibleListResultDirection(false);
	}

	const handleKeyUpDirection = () => {
		handlerSearchDirection();
	}

	const handleKeyDownDirection = () => {
		handlerSearchDirection.cancel();
	}

	const handlerSearchDirection = debounce(() => {
		if (searchDirection !== '') getDirection();
	}, 750);

	const onTypeDocument = async () => {
		try {
			const response = await myFetchGet(`api/v1/choices_by_country/?country=${countryID}`)
			setTypeCountry(response.data)
			setIsoCountry(response.iso)
		} catch (error) { }
	}


	useEffect(() => {
		if (searchDirection === '') {
			SetIsVisibleListResultDirection(false);
			SetLoaddingSearchDirection(false);
		}
	}, [searchDirection])

	useEffect(() => {
		if (directionSelected.id !== undefined) {
			setSelectedDirectionId(directionSelected.id);
			setSelectedDirectionName(directionSelected.name);
		} else if (dataUserSelected.direction_id !== null && category !== null) {
			setSelectedDirectionId(dataUserSelected.direction_id);
			setSelectedDirectionName(directionSelected.name);
		}

	}, [directionSelected, dataUserSelected])

	useEffect(() => {
		const timeOut = setTimeout(() => {
			if (textName) {
				const valid = validateName(textName)
				setIsValidName(valid)
			}
			if (textLastName) {
				const valid = validateName(textLastName)
				setIsValidLastName(valid)
			}
			if (phoneInput) {
				const valid = validatePhone(phoneInput)
				setIsValidPhone(valid)
			}
			if (emailInput) {
				const valid = validateEmail(emailInput)
				setIsValidEmail(valid)
			}
		}, 500)

		return () => clearTimeout(timeOut);
	}, [textName, textLastName, phoneInput, emailInput])

	useEffect(() => {
		const timeOut = setTimeout(() => {
			console.log(documentString, typeCountryString, isoCountry)
			if (documentString && typeCountryString) {
				const valid = validateDocument(isoCountry, typeCountryString, documentString)
				console.log(valid)
				setIsValidDocument(valid)
			}
		}, 1000)

		return () => clearTimeout(timeOut);
	}, [documentString])

	useEffect(() => {
		isoCountry && setPrefixes(prefixesCountry(isoCountry))
	}, [isoCountry])

	useEffect(() => {
		countryID && onTypeDocument()
	}, [])


	return (
		<Container>
			{
				showManualUI === false
					? (
						<>
							<div className='row space-between'>
								<LabelDrawer value="Buscar cliente" />
								<div className="row">
									<Radio
										onChange={() => onChangeCheck('docIndentidad')}
										checked={filtreValue === 'docIndentidad' ? true : false}
									>Documento de identidad</Radio>

									<Radio
										onChange={() => onChangeCheck('name')}
										checked={filtreValue === 'name' ? true : false}
									>Nombre</Radio>

									<Radio
										onChange={() => onChangeCheck('idService')}
										checked={filtreValue === 'idService' ? true : false}
									>ID del servicio</Radio>
								</div>
							</div>
							<Input
								className='select-input-search'
								allowClear
								placeholder={
									filtreValue === "name" ? namePlaceholder
										: filtreValue === "docIndentidad" ? docPlaceholder
											: idServicePlaceholder
								}
								onKeyUp={handleKeyUp}
								onKeyDown={handleKeyDown}
								onChange={onInputChange}
								value={fullName}
								status={emptyInputsCheck(camposIncompletos, "user_id") ? "error" : ""}
							/>
						</>
					)
					: (
						<ContainerCollapse>
							<Collapse
								bordered={false}
								defaultActiveKey={['1']}
								expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} style={{ color: "#2B80FF" }} />}
								ghost
								items={[
									{
										key: '1',
										label: <p className="text_blue">Datos personales</p>,
										children:
											<ManualUI>

												<header className="container_header">
													<div className="circle" />
													<p className="text_header">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum sem libero. Morbi mollis eleifend efficitur. Morbi sed ligula semper leo maximus .</p>
												</header>

												{/* --------------Nombre y Apellido-------------- */}
												<span className="row space-between">
													<div className="left">
														<LabelDrawer value="Nombre" />
														<Input
															style={{ height: "40px" }}
															className="input"
															type="text"
															onChange={onNameChange}
															placeholder='Ingrese Nombre'
															status={emptyInputsCheck(camposIncompletos, "first_name") ? "error" : ""}

														/>
													</div>
													<div className="right">
														<LabelDrawer value="Apellido" />
														<Input
															style={{ height: "40px" }}
															className="input"
															type="text"
															onChange={onSurnameChange}
															placeholder='Ingrese Apellido'
															status={emptyInputsCheck(camposIncompletos, "last_name") ? "error" : ""}
														/>
													</div>
												</span>

												{/* ---ERRORS--- */}
												<span className="row space-between">
													<div className="left">{isValidName === false && <span className='error__message'>Ingresa solo texto, sin números</span>} </div>
													<div className="right">{isValidLastName === false && <span className='error__message'>Ingresa solo texto, sin números</span>} </div>
												</span>


												{/* --------------DNI y Telefono-------------- */}

												<Br />
												<span className="row space-between">
													<div className="left">
														<div className="column">
															<LabelDrawer value="Documento de identidad" />
															<div className="row space-between">
																<Select
																	onChange={handleSelectChange}
																	style={{ height: "40px" }}
																	className="identityDocSelect"
																	placeholder="Tipo"
																	status={emptyInputsCheck(camposIncompletos, "document_type") ? "error" : ""}
																>
																	{idTypes?.data?.map(item =>
																		<Option key={item.ID} value={item?.name}>
																			{item?.name}
																		</Option>)}
																</Select>

																<span className='column' style={{ width: "calc(97% - 70px)" }}>
																	<Input
																		id="idNumber"
																		onChange={onIdentificationNumber}
																		maxLength="12"
																		className="input__number"
																		value={idNumber}
																		placeholder="Ingrese número de documento"
																		status={emptyInputsCheck(camposIncompletos, "document_number") ? "error" : ""}
																	/>
																</span>
															</div>
														</div>

													</div>
													<div className="right">
														<div className="column">
															<LabelDrawer value="Número de teléfono" />

															<div className="row space-between">
																<span
																	className='phoneFlagsSelect'
																	style={{ marginBottom: error ? 12 : 0 }}
																>
																	{
																		isoFlag && (
																			<img
																				className='img-phone'
																				src={`https://flagcdn.com/20x15/${isoFlag}.png`}
																				alt=""
																			/>
																		)
																	}

																	{
																		prefixes && (
																			<span className='prefixes-phone'>
																				{prefixes}
																			</span>
																		)
																	}
																</span>
																<Input
																	onChange={phoneOnchange}
																	className="input__phoneNumber"
																	style={{ height: 40 }}
																	placeholder="Ingrese número de teléfono"
																	status={emptyInputsCheck(camposIncompletos, "phone") ? "error" : ""}
																/>
															</div>

														</div>
													</div>
												</span>

												{/* ---ERRORS--- */}
												<span className="row space-between">
													<div className="left">{isValidDocument === false && <span className='error__message'>La Identificación no cumple con el formato</span>} </div>
													<div className="right">{isValidPhone === false && <span className='error__message'>El número no cumple con el formato</span>} </div>
												</span>

												<Br />

												{/* --------------Correo y ID de servicio-------------- */}
												<span className="row space-between" style={{ marginTop: 9 }}>
													<div className="left">
														<LabelDrawer value="Correo electrónico" />
														<Input
															placeholder='Example@gmail.com'
															style={{ height: "40px" }}
															className="input"
															type="text"
															onChange={handlerInputEmail}
															status={emptyInputsCheck(camposIncompletos, "email") ? "error" : ""}
														/>
													</div>
													<div className="right">
														<LabelDrawer value="ID del servicio" />
														<Input
															style={{ height: "40px" }}
															className="input"
															type="text"
															onChange={(e) => setIdServices(e.target.value)}
															maxLength={12}
															placeholder='Ingrese Id de servicio'
															status={emptyInputsCheck(camposIncompletos, "service_number") ? "error" : ""}
														/>
													</div>
												</span>

												<span className="row space-between">
													<div className="left">{isValidEmail === false && <span className='error__message'>Ingresa correo válido</span>} </div>
												</span>

												<Br />
												{/* --------------Buscar Direccion-------------- */}
												<span className='column'>
													<LabelDrawer value="Buscar dirección" />
													<Input
														placeholder='Ingrese la dirección a consultar'
														style={{ height: 40 }}
														onKeyUp={handleKeyUpDirection}
														onKeyDown={handleKeyDownDirection}
														onChange={onDirectionSearch}
														value={directionSelected.full_direction}
														allowClear
														status={emptyInputsCheck(camposIncompletos, "direction") ? "error" : ""}
													/>
												</span>
												{isVisibleListResultDirection &&
													<SearchDirection
														data={dataDirection}
														loadding={loaddingSearchDirection}
														handleSelectDirection={handleSelectDirection}
													/>}

												<Br />
												{/* --------------Direccion-------------- */}
												<span className='column'>
													<LabelDrawer value="Complemento dirección" />
													<Input
														placeholder='Ingrese descripción'
														maxLength={100}
														style={{ height: 65 }}
														onChange={(e) => setAddress(e.target.value)}
														status={emptyInputsCheck(camposIncompletos, "direction_text") ? "error" : ""}
													/>
												</span>

											</ManualUI>
									}
								]}
							/>

						</ContainerCollapse>
					)
			}


			{
				isVisibleListResult &&
				(
					<SubContInputSearch>
						<div className='container_search_users custom_scroll'>
							{
								valueList?.map((item) =>
								(
									<div className='subcontainer_search_users' onClick={() => handleSelecteValue(item)} key={item?.ID}>
										<span className='subspan_text'>
											<span className='text_search'>{item?.document_type_name} {item?.document_number} </span>
											<span className='text_mid_search'>{item?.first_name} {item?.last_name}</span>
											<span className='text_right_search'>{item?.number_service}</span>
										</span>

										<span className='prueba'>{item?.direction}</span>

									</div>
								)
								)}
							<button className='manual_register' onClick={onManualRegister}>Registro manual de cliente</button>
						</div>
					</SubContInputSearch>
				)
			}
		</Container>
	)
}
const Container = styled.div`

.row{
	margin: 0 !important;
	align-items: center;
	margin-top: 5px;
}

.row_center{
	justify-content: center;
}
.row_end{
	justify-content: flex-end;
}
.space-between{
	justify-content: space-between;
	margin: 0 !important;
}
.subtitled{
	font-size: 14px;
	font-weight: bold;
	color: #2B80FF;
}
.blue{
	color: #2B80FF;
}

.ant-radio-wrapper{
	font-size: ${smallSizes.fontSize.fontSize12};
}

.select-input-search{
	width: 100%;
	height: 40px;
	margin-top: 7px;
	border-radius: 5px;
	// margin-bottom: 30px;
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}){

	.ant-radio-wrapper{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.select-input-search{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.ant-radio-wrapper{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.select-input-search{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.ant-radio-wrapper{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.select-input-search{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`

const SubContInputSearch = styled.div`


.container_search_users{
	width: 100%;
	background: white;
	border-radius: 3px;
	border: 1px solid #E0E0E0;
	padding: 15px;
	overflow-y: auto;
	max-height: 200px;
	height: auto;
	// position: reative;
}

.container_search_users .subcontainer_search_users{
	cursor: pointer;
	// display: flex;
	margin-bottom: 11px;
	// justify-content: space-between;
	width: 70%
}

.container_search_users .subcontainer_search_users:hover {
	color: #2B80FF;
	font-weight: bold;
}

.subcontainer_search_users .subspan_text{
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0;
}

.subcontainer_search_users .text_search{
	font-size: 12px;
	color: #131523;
	width: 40%;
}

.subcontainer_search_users:hover .text_search {
	color: #2B80FF;
	font-weight: bold;
}

.subcontainer_search_users .text_mid_search{
	width: 40%;
	text-align: left;
}

.subcontainer_search_users .text_right_search{
	width: 40%;
	text-align: left;
}

.subcontainer_search_users .prueba{
	margin: 0;
}

.manual_register{
	color: #2B80FF;
	font-size: ${smallSizes.fontSize.fontSize12};
	margin-top: auto;
	padding: 0 !important;
	font-weight: bold;
	width: 190px;
	height: 15px;
	border: none;
	outline: none;
	background: white;
	text-align: left;
}

@media screen and (min-width: ${devices.smallDesk}){

	.manual_register{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.manual_register{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.manual_register{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`

const ManualUI = styled.div`
	
width: 100%;
background: #F9FAFB;
border: 0.4px solid #F9FAFB;
border-radius: 5px;
padding: 23.4px 30.8px 31.9px 20.4px;

.container_header {
	display: flex;
	gap: 19px;
	margin-bottom: 18.4px;
}

.container_header .circle {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #2B80FF;
}

.container_header .text_header {
	font-size: 12px;
	color: #5A607F;
	margin: 0;
	width: 90%;
}

  .row {
		margin: inherit;
		display: flex;
		align-items: center;
    margin-top: 10px;
	}
	.column {
		display: flex;
		flex-flow: column;
    }
  .align-item-center{
    align-items: center;
  }
  .row-center{
    justify-content: center;
  }
 
  .flex-end{
    justify-content: flex-end;
  }
  .space-between{
    justify-content: space-between;
  }
  .column_center{
    justify-content: center;
  }

  .subtitle{
    font-size: 14px;
    font-weight: bold;
    color: #2B80FF;
  }

// input text nomal de ant
.ant-input {
	margin: 0;
	padding: 0;
	width: 100%;
	padding: 4px 11px;
	color: #868D96;
	font-size: ${smallSizes.fontSize.fontSize12};
	background-color: white;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	transition: all 0.3s;
}

.identityDocSelect{
  width: 72px;
  height: 40px;
  background: white;
  border-radius: 5px;
}
.phoneFlagsSelect{
  width: 84px;
  height: 40px;
  background: white;
  border-radius: 5px;
  border: 1px solid #E0E0E0;
  display : flex;
  justify-content: center;
  align-items: center;
}

// select ant;
.irNypR .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #E0E0E0;
}
  

.blue{
    color: #2B80FF;
}
 .select{
    width: 100%;
    height: 40px;
    background: white;
    color: #868D96;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    
    padding: 6px 11px 6px;
  
  }
 .input__number{
    // width: 97%;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    color: #868D96;
    padding-left: 11px;
    background: white;
  }
 .input__phoneNumber{
    width: calc(97% - 84px);
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    color: #868D96;
    padding-left: 11px;
  }
  /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
}
  
.left, .right {
	width: 49%
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 0 11px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
}

.prefixes-phone{
	color: #868D96;
	margin-left: 8px;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.img-phone{
	width: ${smallSizes.fontSize.fontSize12};
	height: ${smallSizes.fontSize.fontSize12};
}


@media screen and (min-width: ${devices.smallDesk}){

	.container_header .circle {
		width: 25px;
		height: 25px;
	}

	.container_header .text_header {
		font-size: 12px;
	}

	.ant-input {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.prefixes-phone{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.img-phone{
		width: ${smallSizes.fontSize.fontSize12};
		height: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.container_header .circle {
		width: 28.12px;
		height: 28.12px;
	}

	.container_header .text_header {
		font-size: 13.5px;
	}

	.ant-input {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.prefixes-phone{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.img-phone{
		width: ${mediumSizes.fontSize.fontSize12};
		height: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.container_header .circle {
		width: 31.64px;
		height: 31.64px;
	}

	.container_header .text_header {
		font-size: 15.18px;
	}
	
	.ant-input {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.prefixes-phone{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.img-phone{
		width: ${largeSizes.fontSize.fontSize12};
		height: ${largeSizes.fontSize.fontSize12};
	}
}
`

const ContainerCollapse = styled.div`

.text_blue{
	color: #2B80FF;
	font-weight: bold;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header{
	padding-left: 0;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
	padding-left: 0;
}

@media(width >=1280px) {
	.text_blue {
		font-size: 14px;
	}
}

@media(width >=1366px) {
	.text_blue {
		font-size: 15.75px;
	}
}

@media(width >=1920px) {
	.text_blue {
		font-size: 17.71px;
	}
}
`