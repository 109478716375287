import React from 'react'
import MainDrawerTitle from '../../MainDrawer'
import { Divider } from 'antd'
import size from '../../../../helpers/sizes'
import color from '../../../../helpers/color'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons';

const MainTitle = ({ value = "", onClick = () => { } }) => {
    return (
        <TitleBox>
            <div className="main__title__content">
                <MainDrawerTitle value={value} />
                <CloseOutlined
                    style={{
                        fontSize: size.drawerCloseIcon,
                        color: color.drawerCloseIcon,
                        marginRight: "14px"
                    }}
                    onClick={onClick}
                />
            </div>
            <Divider />
        </TitleBox>
    )
}

export default MainTitle

// justify-content: space-between;
const TitleBox = styled.div`
.main__title__content {
	margin: inherit;
	display: flex;
	align-items: center;
    justify-content: space-between;
}

`