import React from 'react'
import style from './panelHeaderV2.module.css'
import GreyDivider from '../greyDivider'
import { CopyOutlined } from '@ant-design/icons'

const PanelHeaderV2 = ({ children, title, subText, buttonOrderOt, handlerClickHeader }) => {
	return (
		<header className={style.container_header}>
			<section className={style.top}>
				<span className={style.sub_container_header}>
					<h2 className={style.title_header}>{title}</h2>

					{children}

					<span className={style.sub_text_header}>{subText}</span>
				</span>

				{buttonOrderOt && (
					<button className={style.button_dupli_OT} onClick={handlerClickHeader}>
						<CopyOutlined />
						Duplicar orden de trabajo
					</button>
				)}


			</section>
			<GreyDivider />
		</header>
	)
}

export default PanelHeaderV2