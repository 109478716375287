import { Checkbox, Dropdown, Space, Timeline } from 'antd'
import React, { useEffect, useState } from 'react'
import { DownOutlined } from '@ant-design/icons';


import style from './historicalPanel.module.css'
import { myFetchGet } from '../../../../services/services';
import historyDataRegister from '../../../../helpers/historyDataRegister';
import styled from 'styled-components';

const HistoricalPanel = ({ data }) => {

	const [historyData, setHistoryData] = useState(null)
	const [checked, setChecked] = useState(1);
	const [dataTimeline, setDataTimeline] = useState(null);

	const [loading, setLoading] = useState(true)



	const { id_unico } = data

	const onChange = ({ target }) => {
		const value = target?.value
		setChecked(value)
	}
	const items = [
		{
			label: <Checkbox checked={checked === 1 ? true : false} value={1} onChange={onChange}>Registro de actividad</Checkbox>,
			key: '0',
		},
		{
			label: <Checkbox checked={checked === 2 ? true : false} value={2} onChange={onChange}>Histórico de estado</Checkbox>,
			key: '1',
		},
		{
			type: 'divider',
		},
		{
			label:
				<span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<span style={{ color: "#5A607F", fontSize: "12px" }}>Limpiar</span>
					<button ><span>Ok</span></button>
				</span>,
			key: '3',
		},
	];

	const getHistory = async () => {
		const historyUrl = `api/v1/wo/${id_unico}/history/`
		const statusUrl = `api/v1/wo/${id_unico}/status_history/`
		try {
			const res = await myFetchGet(checked === 1 ? historyUrl : statusUrl)
			setHistoryData(historyDataRegister(res?.changes))
			setLoading(false)
		} catch (error) {
			setHistoryData(null)
			setLoading(false)
		}
	}

	useEffect(() => {
		if (historyData) {
			const key = Object.entries(historyData).map((item, i) => {
				const dataTimeline = {
					label: item[0],
					children: <div>
						{historyData[item[0]].map((change, index) => (
							<div className='container_text' key={index}>
								<span className='text_historical_os'>{change.field} </span>
								<br />
							</div>
						))}
					</div>
				}
				return dataTimeline

			})
			setDataTimeline(key)
		}
	}, [historyData])

	useEffect(() => {
		setLoading(true)
		getHistory()
	}, [checked])


	return (
		<section className={style.container_history}>
			<section className={style.container_dropdown}>

				<Dropdown menu={{ items }} trigger={['click']} className={style.drop_styling}>
					<Space>
						<span className={style.title_dutarution}>
							{checked === 1 ? "Registro de actividad" : "Histórico de estado"}
						</span>
						<DownOutlined style={{ color: '#BBBBBB' }} />
					</Space>
				</Dropdown>
			</section>

			<label className={style.subtitle_history}>Historico asociado a los cambios registrados de la orden de trabajo.</label>

			{!loading && (
				<ContainerTimeline>
					<Timeline mode={"left"}
						items={dataTimeline}
					/>
				</ContainerTimeline>
			)}

		</section>

	)
}

export default HistoricalPanel

const ContainerTimeline = styled.div`

.ant-timeline{
	font-size: 12px;
}

.ant-timeline .ant-timeline-item{
	font-size: 12px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
	width: calc(12% - 12px);;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-tail{
	inset-inline-start: 12%;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-head{
	inset-inline-start: 12%;
}

.ant-timeline .ant-timeline-item-tail{
	border-inline-start: 1px solid #00388B;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
	inset-inline-start: calc(12% - 4px);
}

.ant-timeline .ant-timeline-item-head{
	border: 1px solid #00388B;
}

.ant-timeline .ant-timeline-item-last >.ant-timeline-item-tail {
	display: block;
}

@media(width >=1280px){
	.ant-timeline{
		font-size: 12px;
	}
	
	.ant-timeline .ant-timeline-item{
		font-size: 12px;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-label {
		width: calc(12% - 12px);;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-tail{
		inset-inline-start: 12%;
	}
	
	.ant-timeline.ant-timeline-label .ant-timeline-item-head{
		inset-inline-start: 12%;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
		inset-inline-start: calc(12% - 4px);
	}
}

@media(width >=1366px){
	.ant-timeline{
		font-size: 13.5px;
	}
	
	.ant-timeline .ant-timeline-item{
		font-size: 13.5px;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-label {
		width: calc(13% - 12px);;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-tail{
		inset-inline-start: 13%;
	}
	
	.ant-timeline.ant-timeline-label .ant-timeline-item-head{
		inset-inline-start: 13%;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
		inset-inline-start: calc(13% - 4px);
	}
}

@media(width >=1920px){
	.ant-timeline{
		font-size: 15.18px;
	}
	
	.ant-timeline .ant-timeline-item{
		font-size: 15.18px;
	}
}
`