import React, { useEffect, useState } from "react"
import { Checkbox, Radio, Select } from "antd"

import style from "./customHeader.module.css"

const CustomHeader = ({
	label,
	onNavigate,
	onView,
	view,
	onTechnicianChange,
	technicians,
	technicianID,
	dataOrders,
	setSelectFilterOrders,
	setButtonOT,
	ButtonOT,
	setButtonOS,
	ButtonOS,
	setRadioChecked,
	radioChecked,
	setCheckedValues,
	checkedValues,
	setOpenFilter,
	openFilter
}) => {

	const [viewButtonMore, setViewButtonMore] = useState(true)

	const handlerClearFilter = () => {
		setButtonOT(false);
		setButtonOS(false);
		setOpenFilter(false);
		setSelectFilterOrders(null)
		setRadioChecked(false)
		setCheckedValues([])
	}

	const handlerFilterOt = () => {
		setSelectFilterOrders("wo")
		setButtonOT(!ButtonOT);
		setButtonOS(false);
		setOpenFilter(false)
		setCheckedValues([])
	}

	const handlerFilterOs = () => {
		setSelectFilterOrders("os")
		setButtonOS(!ButtonOS);
		setButtonOT(false);
		setOpenFilter(false)
		setCheckedValues([])
	}

	const handleRadioChange = (e) => {
		setRadioChecked(e.target.checked);
		if (e.target.checked) {
			const allValues = ButtonOS
				? dataOrders.os_types.map(item => item.id)
				: dataOrders.wo_types.map(item => item.id);
			setCheckedValues(allValues);
		} else {
			setCheckedValues([]);
		}
		setOpenFilter(false)
	};

	const handleCheckboxChange = (value, checked) => {
		if (checked) {
			setCheckedValues([...checkedValues, value]);
		} else {
			setCheckedValues(checkedValues.filter(item => item !== value));
		}
		setOpenFilter(false)
	};

	const checkIfAllChecked = () => {
		let allValues = [];

		if (ButtonOS && dataOrders.os_types && Array.isArray(dataOrders.os_types)) {
			allValues = dataOrders.os_types.map((item) => item.id);
		} else if (!ButtonOS && dataOrders.wo_types && Array.isArray(dataOrders.wo_types)) {
			allValues = dataOrders.wo_types.map((item) => item.id);
		}

		if (allValues.length > 0) {
			const isAllChecked = allValues.every((value) => checkedValues.includes(value));
			setRadioChecked(isAllChecked);

		} else {
			setRadioChecked(false);
		}

	};

	useEffect(() => {

		dataOrders && checkIfAllChecked();
	}, [checkedValues, ButtonOS, ButtonOT, dataOrders])

	useEffect(() => {
		if (dataOrders.wo_types?.length > 4 || dataOrders.os_types?.length > 4) setViewButtonMore(true)
		else setViewButtonMore(false)
	}, [])

	return (
		<div className={style.container_header}>
			<span className={style.left_calendar}>

				<Select
					style={{ flex: 1, height: 40 }}
					className={style.select_calendar}
					onChange={onTechnicianChange}
					value={technicianID}
					placeholder="Técnico"
					allowClear
				>

					{technicians?.map(item =>
						<Select.Option key={item?.ID} value={item?.ID}>{item?.name} {item.last_name}</Select.Option>)
					}

				</Select>
				<div className={style.container_button}>
					<button className={style.button_filter} onClick={() => setOpenFilter(!openFilter)}>
						Filtros
						{checkedValues.length > 0 && (
							<span className={style.circle_filter} >{checkedValues.length}</span>
						)}

						<i className="fa-solid fa-filter size_icon"></i>
					</button>

					{openFilter && (
						<div className={style.container_filter}>
							<span className={style.header_filter}>
								<p className={style.title_filter}>Opciones de filtrado</p>
								<p className={style.text_filter} onClick={handlerClearFilter}>Limpiar filtros</p>
							</span>

							<div className={style.contaner_button_filter}>
								<p className={style.text}>Tipos de órdenes</p>
								<span className={style.container_button}>
									<button className={ButtonOT ? style.button_blue : style.button_type_orders} onClick={handlerFilterOt}>
										<i className="fa-solid fa-briefcase"></i>
										Orden de trabajo
									</button>
									<button className={ButtonOS ? style.button_blue : style.button_type_orders} onClick={handlerFilterOs}>
										<i className="fa-solid fa-plug"></i>
										Orden de servicio
									</button>
								</span>
							</div>

							{ButtonOS || ButtonOT ? (

								<section className={style.filter_options}>
									<span className={style.header_options}>
										<p className={style.font12options}>{ButtonOS ? "Órdenes de Servicio" : "Órdenes de trabajo"}</p>
										<Radio className={style.radio} onChange={handleRadioChange} checked={radioChecked}>Ver todos</Radio>
									</span>
									{viewButtonMore ? (
										<div className={style.container_options}>
											{ButtonOS ? (
												dataOrders.os_types?.slice(0, 4).map((item, index) => (
													<span className={style.container_checkbox} key={index}>
														<Checkbox
															className={style.checkbox}
															checked={checkedValues.includes(item.id)}
															onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
														>{item?.name}</Checkbox>
													</span>
												))
											) : dataOrders.wo_types?.slice(0, 4).map((item, index) => (
												<span className={style.container_checkbox} key={index}>
													<Checkbox
														className={style.checkbox}
														checked={checkedValues.includes(item.id)}
														onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
													>{item?.name}</Checkbox>

												</span>
											))}
											<p className={style.button_see_more} onClick={() => setViewButtonMore(false)}>Ver más</p>
										</div>
									) : (
										<div className={style.container_options}>
											{ButtonOS ? (
												dataOrders.os_types?.map((item, index) => (
													<span className={style.container_checkbox} key={index}>
														<Checkbox
															className={style.checkbox}
															checked={checkedValues.includes(item.id)}
															onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
														>{item?.name}</Checkbox>
													</span>
												))
											) : dataOrders.wo_types?.map((item, index) => (
												<span className={style.container_checkbox} key={index}>
													<Checkbox
														className={style.checkbox}
														checked={checkedValues.includes(item.id)}
														onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
													>{item?.name}</Checkbox>

												</span>
											))}
										</div>
									)}

								</section>
							) : null}

						</div>
					)}
				</div>

			</span>

			<div className={style.mid_calendar}>
				<button onClick={() => onNavigate('PREV')}>{'<'}</button>
				<span className={style.label}>{label}</span>
				<button onClick={() => onNavigate('NEXT')}>{'>'} </button>
			</div>

			<div className={style.right_calendar}>
				<span className={view === "day" ? style.date_selected : style.date_btn} onClick={() => onView("day")}>Dia</span>
				<span className={view === "week" ? style.date_selected : style.date_btn} onClick={() => onView("week")}>Semana</span>
				<span className={view === "month" ? style.date_selected : style.date_btn} onClick={() => onView("month")}>Mes</span>
			</div>
		</div>
	)
}

export default CustomHeader