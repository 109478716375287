import React from 'react'
import CustomModal from '../../../../../../../../components/modal/CustomModal'
import Form from '../../../../../../../../components/form/index.jsx'
import MainTitle from '../../../../../../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../../../../../../components/text/drawer/subtitleBlue/index.jsx'


import style from './formReschedule.module.css'
import LabelDrawer from '../../../../../../../../components/text/drawer/label/index.jsx'
import { Input, Select, notification } from 'antd'
import { myFetch } from '../../../../../../../../services/services.js'
import BottomSection from '../../../../../../../../components/form/bottomSection/index.jsx'
import styled from 'styled-components'
import BlueResumeUI from '../../../../../../../../components/BlueResumeUI/BlueResumeUI.jsx'



const FormReschedule = ({ setIsVisibleForm, operator, reagendarEdit, type_ot }) => {

	const { date, wo, technicianID, disponibilidad, start, end, technician } = reagendarEdit

	const { name } = type_ot

	const ReschedulePost = async () => {
		try {
			await myFetch(`api/v1/wo/reschedule_wo/?operator=${operator}&day=${date}&wo=${wo}&technician=${technicianID}&id_disponibility=${disponibilidad}&start=${start}&end=${end}`)
			notification.success({
				style: { fontWeight: 'bold' },
				message: 'Orden de trabajo reagendada satisfactoriamente',
				placement: 'bottomLeft',
				duration: 2.5,
				onClose: () => window.location.reload()
			})
		} catch (error) {
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'Error al reagendar Orden de trabajo',
				placement: 'bottomLeft',
				duration: 2.5,
			})
		}

	}

	return (
		<CustomModal onClick={() => setIsVisibleForm(false)}>
			<Form>
				<MainTitle value='Resumen de agendamiento' onClick={() => setIsVisibleForm(false)} />

				<SubtitleBlue value='Detalle orden de trabajo' />

				<section className={style.container}>
					<div className={style.sub_container}>
						<LabelDrawer value='Fecha' required={false} />
						<Input
							className={style.input}
							value={date}
							placeholder='Fecha'
							disabled
						/>
					</div>

					<div className={style.sub_container}>
						<LabelDrawer value='Horario' required={false} />
						<Input
							className={style.input}
							value={start + ' - ' + end}
							placeholder='Horario'
							disabled
						/>
					</div>


				</section>

				<br />

				<section className={style.container}>
					<div className={style.sub_container}>
						<LabelDrawer value='Tipo de orden de trabajo' required={false} />
						<Input
							className={style.input}
							value={name}
							disabled
						/>
					</div>

					<div className={style.sub_container}>
						<LabelDrawer value='Técnico Principal' />
						<SelectStyled>
							<Input
								className={style.select}
								mode='multiple'
								value={technician}
								disabled
							/>
						</SelectStyled>
					</div>
				</section>

				<br />

				<BlueResumeUI colorBackground='#F7FAFC' colorBorder='#DDE4EB'>
					<SubtitleBlue value='Resumen de solicitud' />
					<div className={style.container_summary}>
						<span className={style.subcontainer}>
							<label className={style.title_summary}>Técnico principal:</label>
							<span className={style.text_summary}>{technician}</span>
						</span>

						<span className={style.subcontainer}>
							<label className={style.title_summary}>Fecha:</label>
							<span className={style.text_summary}>{date}</span>
						</span>

						<span className={style.subcontainer}>
							<label className={style.title_summary}>Horario:</label>
							<span className={style.text_summary}>{start} - {end}</span>
						</span>

					</div>
				</BlueResumeUI>
				<BottomSection onCancel={() => setIsVisibleForm(false)} onAcept={ReschedulePost} />

			</Form>



		</CustomModal>
	)
}

export default FormReschedule

const SelectStyled = styled.div`
.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item {
	align-items: center;
}
`