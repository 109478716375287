import React, { useState } from 'react'
import CustomModal from '../../../../components/modal/CustomModal'
import Form from '../../../../components/form/index.jsx'
import MainTitle from '../../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx'
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx'
import { ColorPicker, Input, notification } from 'antd'
import Br from '../../../../components/br/Br.jsx'

import style from './createOtForm.module.css'
import styled from 'styled-components'
import BottomSection from '../../../../components/form/bottomSection/index.jsx'
import UseOperator from '../../../../hooks/UseOperator.jsx'
import { createType } from '../../../../api/otTypeApi.js'
import { checkEmptyKeys, emptyInputsCheck, validarCampos } from '../../../../helpers/helpers.js'

const CreateOtForm = ({ setViewCreateForm }) => {

	const [colorSelected, setColorSelected] = useState("#1677ff");
	const [nameSelected, setNameSelected] = useState("");
	const [labelSelected, setLabelSelected] = useState("");

	const [incompleteFields, setIncompleteFields] = useState(null)

	const { storedOperatorID } = UseOperator();

	const handlerColor = (color) => {
		setColorSelected(color.toHexString())
	}

	const handlerLabel = ({ target }) => {
		setLabelSelected(target.value.trim())
	}

	const handlerName = ({ target }) => {
		setNameSelected(target.value.trim())
	}

	const createTypeOt = () => {
		const data = {
			"name": nameSelected,
			"color": colorSelected,
			"label": labelSelected,
			"operator": storedOperatorID
		}

		const emptyKeys = checkEmptyKeys(data)
		setIncompleteFields(emptyKeys)

		if (validarCampos(data)) {
			createType(data)
		} else {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Por favor, completa todos los campos.',
				placement: 'bottomLeft',
				duration: 2,
			})
			return;
		}


	}

	return (
		<CustomModal onClick={() => setViewCreateForm(false)}>
			<Form >
				<MainTitle value='Crear orden de trabajo' onClick={() => setViewCreateForm(false)} />

				<SubtitleBlue value="Datos del tipo orden de trabajo" />

				<div>
					<LabelDrawer value="Nombre" />
					<Input
						style={{ height: "40px" }}
						placeholder="Ingrese el Nombre de la orden de trabajo"
						onChange={handlerName}
						status={emptyInputsCheck(incompleteFields, "name") ? "error" : ""}
					/>
				</div>

				<Br />

				<section className={style.container_color}>
					<div className={style.subcontainer_color}>
						<LabelDrawer value='Color' />
						<ContainerColorPicker>
							<span className={style.colorpicker}>
								<p className={style.text_color}>{colorSelected.toUpperCase()}</p>
								<ColorPicker
									onChangeComplete={handlerColor}
									styles={{
										popupOverlayInner: {
											width: 260,
											background: "white",
										}
									}}
									value={colorSelected}
									arrow={false}
								/>
							</span>
						</ContainerColorPicker>
					</div>

					<div className={style.subcontainer_label}>
						<LabelDrawer value="Etiqueta" />
						<Input
							style={{ height: 40 }}
							onChange={handlerLabel}
							placeholder='Nombre del color'
							status={emptyInputsCheck(incompleteFields, "label") ? "error" : ""}
						/>
					</div>
				</section>

				<BottomSection onCancel={() => setViewCreateForm(false)} onAcept={createTypeOt} />
			</Form>
		</CustomModal>
	)
}

export default CreateOtForm

const ContainerColorPicker = styled.div`
.ant-color-picker-trigger{
	border: 0px;
}
`