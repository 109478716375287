import { notification } from "antd"

export const warningMsg = ({ msg = "", duration = 2.5, onClose = () => { } }) => {
    notification.warning({
        style: { fontWeight: 'bold' },
        message: msg,
        placement: 'bottomLeft',
        duration: duration,
        onClose: onClose
    })
}
export const errorMsg = ({ msg = "", duration = 2.5, onClose = () => { } }) => {
    notification.error({
        style: { fontWeight: 'bold' },
        message: msg,
        placement: 'bottomLeft',
        duration: duration,
        onClose: onClose
    })
}
export const successMsg = ({ msg = "", duration = 2.5, onClose = () => { } }) => {
    notification.success({
        style: { fontWeight: 'bold' },
        message: msg,
        placement: 'bottomLeft',
        duration: duration,
        onClose: onClose
    })
}