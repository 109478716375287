/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Button, DatePicker, Divider, Input, Popconfirm, Select, Skeleton, Space, Switch, notification } from 'antd';
import dayjs from 'dayjs';

// api
import { deleteHoliday, getTechnicianHoliday, onHolidayUpdate } from '../../../api/holiday.js';

// hooks & helpers
import { format, validarCampos } from '../../../helpers/helpers.js';

// custom
import Br from '../../../components/br/Br.jsx';
import Form from '../../../components/form/index.jsx';
import MainTitle from '../../../components/text/drawer/mainTitle/index.jsx';
import LabelDrawer from '../../../components/text/drawer/label/index.jsx';
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue/index.jsx';

import style from './updateHoliday.module.css'
import styled from 'styled-components';
import { LoadingForm } from '../../../components/loading/Loading.jsx';


const UpdateHolidayForm = ({ setVisible, selectedRowData, loginOperadorID }) => {
	const { id_unico, date_format, name, status, holiday_type, holiday_type_ID, list_technicians } = selectedRowData;

	const [updatedate, setUpdatedate] = useState(date_format)
	const [updateName, setUpdateName] = useState(name)
	const [updateType, setUpdateType] = useState(holiday_type_ID)
	const [loading, setLoading] = useState(false)

	const [options, setOptions] = useState([]);
	const [defaultData, setDefaultData] = useState([]);

	const [selectedTechnicials, setSelectedTechnicials] = useState(null);

	const [switchEnable, setSwitchEnable] = useState(false);



	const onCloseModal = () => setVisible(false)

	let holidayUpdated = {
		"day": updatedate,
		"name": updateName,
		"holiday_type": updateType,
	}

	const updateHoliday = () => {

		if (switchEnable) {
			holidayUpdated["technicians"] = selectedTechnicials;
		} else {
			holidayUpdated["technicians"] = [];
		}

		if (
			updatedate === date_format &&
			updateName === name &&
			updateType === holiday_type_ID &&
			(switchEnable && (selectedTechnicials === null || selectedTechnicials === defaultData))

		) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'No has hecho ningun cambio',
				placement: 'bottomLeft',
				duration: 2,
			})
			return
		}

		if (validarCampos(holidayUpdated)) {
			onHolidayUpdate(id_unico, holidayUpdated)
		}
	}

	const dateHandler = (_, dateStrings) => setUpdatedate(dateStrings);

	const handleSelectTechnicials = (value) => {
		setSelectedTechnicials(value);
		// setDefaultData(value);
	}

	useEffect(() => {
		const dateString = date_format;
		setUpdatedate(dayjs(dateString, format).format(format))
	}, []);

	useEffect(() => {
		setLoading(true);
		loginOperadorID && getTechnicianHoliday(loginOperadorID)
			.then(res => {
				const data = res.map(item => {
					return {
						value: item.ID,
						label: `${item.name} ${item.last_name}`,
					}
				})
				setOptions(data)
				setLoading(false);
			})
	}, [loginOperadorID]);

	useEffect(() => {
		const dataTechnician = list_technicians.map(item => {
			return item.id
		})
		setDefaultData(dataTechnician)
		setSelectedTechnicials(dataTechnician);
	}, [])

	useEffect(() => {
		if (list_technicians.length > 0) {
			setSwitchEnable(true);
		}
	}, [])


	return (
		<Form>
			{loading === false ? (
				<>
					<MainTitle value="Actualizar Registro de día feriado" onClick={onCloseModal} />

					<SubtitleBlue value='Información de la festividad' />

					<Br />

					<div className={style.container_name}>
						<LabelDrawer value='Nombre' />
						<Input
							className='input_font_12_holidays'
							placeholder={name}
							onChange={e => setUpdateName(e.target.value)}
							style={{ height: 40 }}
						/>
					</div>

					<Br />

					<div className={style.container_date_type}>
						<section className={style.subcontainer_date}>
							<LabelDrawer value='Fecha' />
							<DatePicker
								placeholder={updatedate}
								onChange={dateHandler}
								format={format}
								style={{ height: 40 }}
								inputReadOnly
							/>
						</section>

						<section className={style.subcontainer_date}>
							<LabelDrawer value='Tipo' />
							<Select
								onChange={value => setUpdateType(value)}
								placeholder={holiday_type}
							>
								<Option key={0} value={1}>Civil</Option>
								<Option key={1} value={0}>Religioso</Option>
							</Select>
						</section>
					</div>

					<Br />

					<section>
						<b className={style.text_hability}>¿Habilitar técnicos?</b>

						<span className='text'>No</span>
						<Switch
							checked={switchEnable}
							style={{ margin: "0 5px" }}
							onChange={(e) => {
								setSwitchEnable(e);
							}} />
						<span className='text'>Si</span>
					</section>

					<Br />
					{switchEnable && (
						<section className={style.container_technicial}>
							<LabelDrawer value='Técnicos' />
							<ContainerSelect>
								<div className='prueba'>
									<Select
										placeholder="Selecciona un técnico"
										options={options}
										value={selectedTechnicials}
										mode='multiple'
										className='select_holiday'
										onChange={handleSelectTechnicials}
									/>
								</div>

							</ContainerSelect>
						</section>
					)}


					<section className={style.container_button}>
						<Popconfirm
							placement="topRight"
							icon={""}
							title="¿Eliminar registro?"
							description="Si eliminas este registro, se eliminará de forma permanente de tu historial."
							onConfirm={() => {
								deleteHoliday(selectedRowData?.id_unico)
							}}
						>
							<button className={style.delete_button}>Eliminar feriado</button>
						</Popconfirm>

						<button className={style.save_button} onClick={updateHoliday}>Guardar</button>

					</section>
				</>
			) :
				(
					<LoadingForm />

				)
			}
		</Form>
	)

}

export default UpdateHolidayForm

const ContainerSelect = styled.div`

.select_holiday {
	min-height: 40px !important;
  display: flex;

.ant-select-selector {
	width: 100%;
	height: auto !important;
}
`;