import React, { useState } from 'react'
import CustomModal from '../../../../components/modal/CustomModal'
import Form from '../../../../components/form/index.jsx'
import MainTitle from '../../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue'
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx'
import { Input, Popconfirm, Select, notification } from 'antd'

import style from './newCategory.module.css'
import BottomSection from '../../../../components/form/bottomSection/index.jsx'
import { onlyTextAndNumber } from '../../../../helpers/helpers.js'
import { createCategoryOt } from '../../../../api/otTypeApi.js'

const NewCategory = ({ setAddCategoryForm, addCategoryForm, type_ot }) => {

	const [addImg, setAddImg] = useState("")
	const [imagesArray, setImagesArray] = useState([])

	const [addQuestions, setAddQuestions] = useState("")
	const [questionType, setQuestionType] = useState(null)
	const [questionArray, setQuestionArray] = useState([])

	const [categoryName, setCategoryName] = useState("")

	const [incompleteFields, setIncompleteFields] = useState({ input1: '', input2: '', input3: '' })

	const options = [
		{ value: 1, label: "Sí o No" },
		{ value: 2, label: "Abierta" }
	]

	const onNameChange = (e) => {
		const value = e.target.value
		if (onlyTextAndNumber(value) || value === "") {
			setCategoryName(value)
		}
	}

	const onNameImgChange = (e) => {
		setAddImg(e.target.value)
	}

	const onHandlerAddImg = () => {
		if (addImg !== "") {
			setImagesArray([...imagesArray, {
				"img_name": addImg
			}])
			setAddImg("")
		} else {
			return
		}
	}

	const onHandlerDeleteImg = (index) => {
		const deleteArray = imagesArray.filter((_, order) => order !== index)
		setImagesArray(deleteArray)
	}

	const onNameQuestionChange = (e) => {
		setAddQuestions(e.target.value)
	}

	const onSelectQuestionChange = (value) => {
		setQuestionType(value)
	}

	const onHandlerAddQuestions = () => {
		if (addQuestions !== "" && questionType !== "") {
			setQuestionArray([...questionArray, {
				"kind": questionType,
				"question": addQuestions,
			}])
			setAddQuestions("")
			setQuestionType(null)
		} else {
			return
		}
	}

	const onHandlerDeleteQuestions = (index) => {
		const deleteArray = questionArray.filter((_, order) => order !== index)
		setQuestionArray(deleteArray)
	}

	const onCreateCategory = () => {
		const data = {
			"name": categoryName,
			"imgs": {
				"value": imagesArray
			},
			"questions": {
				"value": questionArray
			},
			"type_ot": type_ot
		}

		if (categoryName !== "" && imagesArray.length > 0 && questionArray.length > 0) {
			createCategoryOt(data)
		} else {
			setIncompleteFields({
				input1: categoryName === "" ? 'error' : '',
				input2: imagesArray.length === 0 ? 'error' : '',
				input3: questionArray.length === 0 ? 'error' : ''
			})
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Por favor, completa todos los campos.',
				placement: 'bottomLeft',
				duration: 2.5,
			})
			return;
		}
	}

	return (
		<CustomModal onClick={() => setAddCategoryForm(!addCategoryForm)}>
			<Form>
				<MainTitle value="Agregar categoría" onClick={() => setAddCategoryForm(!addCategoryForm)} />
				<SubtitleBlue value="Información de la categoría" />

				<div>
					<LabelDrawer value="Nombre" />
					<Input
						style={{ height: "40px" }}
						placeholder="Ingresar nombre de la categoria"
						onChange={onNameChange}
						value={categoryName}
						status={incompleteFields.input1}
					/>
				</div>

				<section className={style.container_imagen}>
					<div className={style.container_input}>
						<p className={style.label_title}>Nombre de imágenes a solicitar</p>
						<Input
							style={{ height: "40px" }}
							placeholder="Ingrese descripcion de imagen a solicitar"
							onChange={onNameImgChange}
							value={addImg}
							status={incompleteFields.input2}
						/>
					</div>
					<button
						className={style.button}
						onClick={onHandlerAddImg}
					>Agregar</button>
				</section>

				{imagesArray.length > 0 ?
					imagesArray.map((img, index) => {
						return (
							<section className={style.container_add_img} key={index}>
								<p className={style.text_label}>{img.img_name}</p>

								<Popconfirm
									placement="topRight"
									icon={""}
									title="¿Eliminar fotografía?"
									description="Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => onHandlerDeleteImg(index)}
								>
									<button className={style.button}>Eliminar</button>
								</Popconfirm>
							</section>
						)
					}) : null
				}


				<section className={style.container_questions}>
					<div className={style.container_input}>
						<p className={style.label_title}>Pregunta</p>
						<Input
							style={{ height: "40px" }}
							placeholder="Ingrese la pregunta"
							onChange={onNameQuestionChange}
							value={addQuestions}
							status={incompleteFields.input3}
						/>
					</div>
					<div className={style.container_select}>
						<p className={style.label_title}>Tipo</p>
						<Select
							style={{ height: "40px" }}
							placeholder="Seleccione el tipo"
							options={options}
							onChange={onSelectQuestionChange}
							value={questionType}
							status={incompleteFields.input3}
						/>
					</div>
					<button className={style.button} onClick={onHandlerAddQuestions}>Agregar</button>
				</section>

				{questionArray.length > 0 ?
					questionArray.map((question, index) => {
						return (
							<section className={style.container_add_questions} key={index}>
								<p className={style.text_label_questions}>{question.question}</p>
								<p className={style.text_label}>{question.kind === 1 ? "Si o No" : "Abierta"}</p>

								<Popconfirm
									placement="topRight"
									icon={""}
									title="¿Eliminar fotografía?"
									description="Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => onHandlerDeleteQuestions(index)}
								>
									<button className={style.button}>Eliminar</button>
								</Popconfirm>
							</section>
						)
					})
					: null}

				<BottomSection onCancel={() => setAddCategoryForm(!addCategoryForm)} onAcept={onCreateCategory} />
			</Form>
		</CustomModal>
	)
}

export default NewCategory