import React, { useEffect, useState } from 'react'
import Form from '../../../components/form/index.jsx'
import CustomModal from '../../../components/modal/CustomModal'
import MainTitle from '../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue/index.jsx'
import LabelDrawer from '../../../components/text/drawer/label/index.jsx'
import { Select, notification } from 'antd'

import style from './categoryEditForm.module.css'
import BottomSection from '../../../components/form/bottomSection/index.jsx'
import { getCategory, typeOsTechnician } from '../../../api/category.js'
import { myFetch } from '../../../services/services.js'
import { nonEqualArrys } from '../../../helpers/helpers.js'


const CategoryEditForm = ({ selectItem, technicianID, setVisibleEditForm }) => {

	const [typeOrderApi, setTypeOrderApi] = useState(selectItem.order_type === "Orden de servicio" ? "os" : "ot")

	const [optionsCategory, setOptionsCategory] = useState(selectItem.categories_list.map((category) => (category.id)))

	const [typeOS, setTypeOS] = useState(null)
	const [categories, setCategories] = useState(null)

	const [categorySelected, setCategorySelected] = useState(selectItem.categories_list.map(category => (
		{
			id: category.id,
			add: true,
			type: typeOrderApi
		}
	)))
	const [errorCategory, setErrorCategory] = useState(false)

	const [dataCategoryList, setDataCategoryList] = useState(selectItem.categories_list.map(category => (
		{
			id: category.id,
			add: true,
			type: typeOrderApi
		}
	)))
	const [statusSelected, setStatusSelected] = useState(selectItem.status.name.toLowerCase())

	const titleForm = selectItem.order_type
	const typeOrder = selectItem.order_name.id
	const status = selectItem.status.name

	const onStatusChange = (value) => {
		setStatusSelected(value)
	}

	const onCategoryChange = (value) => {
		if (value.length > 0) {

			const data = value.map(item => (
				{
					id: item,
					add: true,
					type: typeOrderApi
				})
			)

			setOptionsCategory(value)
			setCategorySelected(data)
			setErrorCategory(false)
		} else if (optionsCategory.length <= 1) {
			setErrorCategory(true)
		}
	}

	const filterArrayChanges = () => {
		const result = []

		categorySelected.forEach(item => {
			result.push({
				id: item.id,
				add: item.add,
				type: item.type
			});
		});

		dataCategoryList.forEach(item => {
			const found = result.find(r => r.id === item.id);
			if (!found) {
				result.push({
					id: item.id,
					add: false,
					type: typeOrderApi
				});
			}
		});

		return result
	}

	const OnUpdateOrder = async () => {
		const result = filterArrayChanges()

		const data = {
			"id": technicianID,
			"status": statusSelected,
			"changes": result
		}

		const arraysNotEqual = nonEqualArrys(dataCategoryList, result)

		try {
			if (arraysNotEqual === false || (selectItem.status.name.toLowerCase() !== statusSelected.toLowerCase())) {
				await myFetch(`api/v1/technician/edit_typs_and_cat/`, data)
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'Tipo de orden modificado',
					placement: 'bottomLeft',
					duration: 2,
					onClose: () => window.location.reload()
				})
			} else {
				notification.warning({
					style: { fontWeight: 'bold' },
					message: 'No modificaste ningun campo',
					placement: 'bottomLeft',
					duration: 2,
				})
			}

		} catch (error) {

		}
	}



	useEffect(() => {
		typeOrderApi && typeOsTechnician(typeOrderApi).then(res => setTypeOS(res.data))
	}, [])

	useEffect(() => {
		getCategory(typeOrder, typeOrderApi).then(res => setCategories(res?.data))
	}, [])

	return (
		<CustomModal onClick={() => setVisibleEditForm(false)}>
			<Form>
				<MainTitle value={`Modificar tipo de ${titleForm}`} onClick={() => setVisibleEditForm(false)} />
				<SubtitleBlue value='Información general' />

				<section className={style.container_first}>
					<div className={style.subcontainer_first}>
						<LabelDrawer value={titleForm} />
						<Select
							style={{ flex: 1 }}
							defaultValue={typeOrder}
							options={typeOS?.map((type) => (
								{
									value: type.id,
									label: type.name,
								})
							)}
							disabled
						/>
					</div>
					<div className={style.subcontainer_first}>
						<LabelDrawer value='Status' />
						<Select
							style={{ flex: 1 }}
							defaultValue={status}
							options={[
								{ value: 'activo', label: 'Activo' },
								{ value: 'inactivo', label: 'Inactivo' }
							]}
							onChange={onStatusChange}
						/>
					</div>
				</section>

				<div className={style.container_second}>
					<LabelDrawer value='Categorías' />
					<Select
						style={{ flex: 1 }}
						mode="multiple"
						value={optionsCategory}
						options={categories?.map(category => (
							{
								value: category.id,
								label: category.name,
							}
						))}
						onChange={onCategoryChange}
						status={errorCategory ? "error" : ''}
					/>
				</div>

				<BottomSection onCancel={() => setVisibleEditForm(false)} onAcept={OnUpdateOrder} />
			</Form>
		</CustomModal>

	)
}

export default CategoryEditForm