import React from 'react'

import EmptyPhotos from '../../../assets/images/Empty/Empty_photos.png'

import style from './emptySearch.module.css'

const EmptySearch = () => {

	return (
		<section className={style.container_empty_search}>
			<div className={style.container_img}>
				<img src={EmptyPhotos} alt="Empty" />
				<strong className={style.title_empty}>No se han encontrado resultados</strong>
				<p className={style.text_p}>Intenta ajustar tu búsqueda para encontrar lo que estás</p>
				<p className={style.text_p}>buscando. <strong className={style.color_blue}>Limpiar buscador</strong></p>
			</div>
		</section>
	)
}

export default EmptySearch