import React, { useEffect, useState } from 'react'
import SubtitleBlue from '../../../../../../components/text/drawer/subtitleBlue'

import style from './additionalInformation.module.css'
import { Switch, Button, Upload, notification } from 'antd'

import { UploadOutlined } from '@ant-design/icons';


const AdditionalInformation = ({ setImagesBase64, setViewUpload, viewUpload }) => {

	const [fileList, setFileList] = useState([]);

	const onViewUpload = (checked) => {
		setViewUpload(checked)
	}

	const props = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			if (fileList.length < 3) {
				setFileList([...fileList, file]);
				return false;
			} else {
				notification.warning({
					style: { fontWeight: 'bold' },
					message: "Solo puede subir 3 imagenes",
					placement: 'bottomLeft',
				})
				return false
			}
		},
		fileList,
		className: style.upload,
	}

	const transformToBase64 = () => {

		const newImages = [];

		for (let i = 0; i < fileList.length; i++) {
			const files = new FileReader();
			files.readAsDataURL(fileList[i]);
			files.onload = function () {
				newImages.push({
					caption_name: fileList[i].name,
					photo_base64: files.result
				})
				if (newImages.length === fileList.length) {
					setImagesBase64(newImages)
				}

			}
		}
	}

	useEffect(() => {
		fileList.length > 0 && transformToBase64()
	}, [fileList])

	return (
		<>
			<SubtitleBlue value='Información adicional' />

			<span className={style.container_client}>
				<p className={style.text_ot_client}>¿Desea agregar imágenes anexas a la orden de trabajo?</p>
				<span className={style.container_switch}>
					<p className={style.text_switch}>No</p>
					<Switch onChange={onViewUpload} />
					<p className={style.text_switch}>Si</p>
				</span>
			</span>
			{viewUpload && (
				<Upload {...props}>
					<Button icon={<UploadOutlined />}>Upload</Button>
				</Upload>


			)}
		</>
	)
}
export default AdditionalInformation