export const useClassDashboard = (cardPercent) => {

  let classCard = "";
  let textCard = "";
  let textSales = "";
  let textAverage = "";

  if (cardPercent?.porcentaje > 0) {
    classCard = "fa-solid fa-arrow-up arrow_icon_up";
    textCard = "percent_up";
    textSales = "percent_sales_up";
    textAverage = "percent-average-up";
    return { classCard, textCard, textSales, textAverage }
  } else if (cardPercent?.porcentaje < 0) {
    classCard = "fa-solid fa-arrow-down arrow_icon_down";
    textCard = "percent_down";
    textSales = "percent_sales_down";
    textAverage = "percent-average-down";
    return { classCard, textCard, textSales, textAverage }
  } else if (cardPercent?.porcentaje === 0) {
    classCard = "fa-solid fa-equals arrow_icon_equal";
    textCard = "percent_equal";
    textSales = "percent_sales_equal";
    textAverage = "percent-average-equal";
    return { classCard, textCard, textSales, textAverage }
  }

}