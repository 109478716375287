import React from 'react'
import styled from 'styled-components'
import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes'

// *Nota:
// devices es una const que viene del archivo "sizes", 
// contiene las 3 resoluciones desktop y las que se agreguen

const MainDrawerTitle = ({ value, size, color }) => {
	let defaultSize = size || "24px"
	let textColor = color || "#5A607F"
	return (
		<NormalTextComponent>
			<span
				className='title-main-drawer'
				style={{ color: textColor }}>
				{value}
			</span>
		</NormalTextComponent>
	)
}

export default MainDrawerTitle

const NormalTextComponent = styled.div`

.title-main-drawer{
	font-size: ${smallSizes.fontSize.fontSize20};
	font-weight: bold;
}

@media screen and (min-width: ${devices.smallDesk}) {

	.title-main-drawer{
		font-size: ${smallSizes.fontSize.fontSize20};
	}
}

@media screen and (min-width: ${devices.mediumDesk}) {

	.title-main-drawer{
		font-size: ${mediumSizes.fontSize.fontSize20};
	}
}

@media screen and (min-width: ${devices.largeDesk}) {

	.title-main-drawer{
		font-size: ${largeSizes.fontSize.fontSize20};
	}
}
`
