import React from 'react'

import style from './customHeader.module.css'

const CustomHeader = ({ label, onNavigate, onView, view, onReagendar }) => {

	return (
		<section className={style.container_header}>
			<div className={style.left}>
				<button className={style.button_go_back} onClick={onReagendar}>
					<i className="fa-solid fa-chevron-left" />
					Regresar
				</button>
			</div>

			<div className={style.mid}>
				<button className={style.button_mid} onClick={() => onNavigate('PREV')}>
					{'<'}
				</button>
				<p className={style.label_mid}>{label}</p>
				<button className={style.button_mid} onClick={() => onNavigate('NEXT')}>
					{'>'}
				</button>
			</div>

			<div className={style.right}>
				<span className={view === "day" ? style.date_selected : style.date_btn} onClick={() => onView("day")}>Dia</span>
				<span className={view === "week" ? style.date_selected : style.date_btn} onClick={() => onView("week")}>Semana</span>
			</div>
		</section>
	)
}

export default CustomHeader