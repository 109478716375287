import React from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import routes from './../../config/page-route.jsx';
import ProtectedRoute from './../../middleweare/ProtectedRoute'
import Config from './../../config'
import styled from 'styled-components';

function setTitle(path, routeArray) {
	var pageTitle;
	for (var i = 0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = `${Config.TITLE} | ${routeArray[i].title}`;
		}
	}
	document.title = (pageTitle) ? pageTitle : Config.TITLE;
}

class Content extends React.Component {
	componentDidMount() {
		setTitle(this.props.history.location.pathname, routes);
	}
	UNSAFE_componentWillMount() {
		this.props.history.listen(() => {
			setTitle(this.props.history.location.pathname, routes);
		});
	}

	render() {
		return (
			<ContentContainer>
				<Switch>
					{routes.map((route, index) =>
						route.private ? (
							<ProtectedRoute
								key={index}
								path={route.path}
								exact={route.exact}
								component={route.component}
							/>
						) : (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								component={route.component}
							/>
						)
					)}
					{/* Default Route */}
					<Route render={() => <Redirect to="/dashboard" />} />
				</Switch>
			</ContentContainer>
		)
	}
}

export default withRouter(Content);

const ContentContainer = styled.div`
	width: 100%;
	background: #F3F3F3;
	height: calc(100vh - 8.48vh);

    display: flex;
    flex-flow: column;
	padding: 30px;
	box-sizing: border-box !important;
	overflow-y: scroll;
`
