import { myFetchGet } from "../services/services";

const dashboardGet = async (operatorID, startDate, endDate, filterCheckTechnicials, filterCheckSupervisor) => {
	try {
		if (filterCheckTechnicials || filterCheckSupervisor) {
			const res = await myFetchGet(
				`api/v1/dashboard/get/?operator=${operatorID}&inicio_fecha=${startDate}&fin_fecha=${endDate}&for_role=${filterCheckTechnicials && filterCheckSupervisor === false
					? "technician" : filterCheckSupervisor && filterCheckTechnicials === false ? "supervises" : null}`
			);
			return res
		} else {
			const res = await myFetchGet(`api/v1/dashboard/get/?operator=${operatorID}&inicio_fecha=${startDate}&fin_fecha=${endDate}`);
			return res
		}
	} catch (e) { console.log(e) }
}

const userApp = async () => {
	const userEmail = localStorage.getItem("email");
	try {
		const res = await myFetchGet(`api/v1/userapp/?email=${userEmail}`);
		return res
	} catch (e) { console.log(e) }
}


export { dashboardGet, userApp }