import React, { useState } from 'react';
import { PlusCircleOutlined, FormOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Loadding from '../loadding/Loadding';
import GreyDivider from '../greyDivider';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes';

const PanelStat = React.createContext();

const Panel = (props) => {
	const [expand] = useState(false);
	const [collapse] = useState(false);
	const [reload] = useState(false);
	const [remove] = useState(false);

	const moreItem = () => {
		props.setValue(!props.value);
	};

	return (
		<PanelStat.Provider value={{ expand, collapse, reload, remove, moreItem }}>
			{!remove && (
				<div className="panel">
					{props.loaddingData ? (
						<Loadding />
					) : (
						<>
							{props.children}
						</>
					)}
				</div>
			)}
		</PanelStat.Provider>
	);
};

const PanelHeader = ({ noButton, buttonText, typeButton, children, render }) => {
	return (
		<PanelHeaderContainer>
			<div className="top">
				<TitlePanelHeader>{children}</TitlePanelHeader>
				{typeButton === 'none' ? (
					render
				) : (
					!noButton && (
						<PanelStat.Consumer>
							{({ moreItem }) => (
								<Button className='button_panel_header' onClick={moreItem}>
									{typeButton === 'more' && <PlusOutlined className="icon" />}
									{typeButton === 'edit' && <FormOutlined />}
									{typeButton === 'more' && buttonText}
								</Button>
							)}
						</PanelStat.Consumer>
					)
				)}
			</div>
			<GreyDivider />
		</PanelHeaderContainer>
	);
};

const PanelSubHeader = ({ children, noButton = false, typeButton }) => {
	return (
		<SubCont1>
			<span className="title">{children}</span>
			{!noButton && (
				<PanelStat.Consumer>
					{({ moreItem }) => (
						<ButtonSubHeader onClick={moreItem}>
							{typeButton === 'edit' && <FormOutlined />}
							{typeButton === 'more' && <PlusCircleOutlined />}
							{typeButton === 'delete' && <CloseOutlined />}
						</ButtonSubHeader>
					)}
				</PanelStat.Consumer>
			)}
		</SubCont1>
	);
};

const PanelBody = ({ children }) => {
	return (
		<PanelStat.Consumer>
			{() => <div className="panel-body">{children}</div>}
		</PanelStat.Consumer>
	);
};

const PanelHeaderContainer = styled.div`
  background: #f3f3f3;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 15vh;

  .top {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    color: #868d96;
    font-size: 12px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 5px 11px;
    color: #868d96;
    font-size: 12px;
  }

  .ant-select-item {
    position: relative;
    display: block;
    min-height: 40px;
    padding: 5px 12px;
    color: #868d96;
    font-size: 12px;
    font-weight: normal;
    line-height: 22px;
    cursor: pointer;
  }

	.button_panel_header{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	@media screen and (min-width: ${devices.smallDesk}){
		.button_panel_header{
			font-size: ${smallSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.button_panel_header{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.button_panel_header{
			font-size: ${largeSizes.fontSize.fontSize12};
		}
	}
`;

const Button = styled.button`
  height: 40px;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001737;
  background: #dde4eb;
  font-weight: bold;
  border: 1px solid #0060ff;
  font-size: 12px;
  border-radius: 3px;

  :hover {
    background: #0060ff;
    color: white;
    .icon {
      color: white;
    }
  }
  .icon {
    font-size: ${smallSizes.fontSize.fontSize12};
    margin-right: 6px;
    color: #0060ff;
  }

@media screen and (min-width: ${devices.smallDesk}){
	.icon {
    font-size: ${smallSizes.fontSize.fontSize12};
  }
}

@media screen and (min-width: ${devices.mediumDesk}){
	.icon {
    font-size: ${mediumSizes.fontSize.fontSize12};
  }
}

@media screen and (min-width: ${devices.largeDesk}){
	.icon {
    font-size: ${largeSizes.fontSize.fontSize12};
  }
}
`;

const ButtonSubHeader = styled(Button)`
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
`;

const TitlePanelHeader = styled.h2`
  font-size: ${smallSizes.fontSize.fontSize24};
  color: #001737;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 0;
  padding: 0;
  flex: 1;

@media screen and (min-width: ${devices.smallDesk}){
	font-size: ${smallSizes.fontSize.fontSize24};
}

@media screen and (min-width: ${devices.mediumDesk}){
	font-size: ${mediumSizes.fontSize.fontSize24};
}

@media screen and (min-width: ${devices.largeDesk}){
	font-size: ${largeSizes.fontSize.fontSize24};
}
`;

const SubCont1 = styled.div`
  flex: 1 1;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;

  .title {
    font-size: 16px;
    flex: 1;
    color: #fff;
  }
`;

export { Panel, PanelHeader, PanelBody, PanelSubHeader };
