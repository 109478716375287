import React from 'react'
import styled from 'styled-components';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes';

export const History = ({ data }) => {

	const lengthClassMap = {
		1: 'one',
		2: 'two',
		3: 'three',
		4: 'four',
		5: 'five',
		6: 'six',
		7: 'seven',
		8: 'eight',
		9: 'nine',
		10: 'ten'
		// Agrega las demás correspondencias hasta 10 si es necesario
	};

	return (
		<Container>
			<>
				{data !== null ?
					Object.entries(data).map((item, i) => {
						const arrayLength = Math.min(item[1].length, 10)
						const lengthClass = lengthClassMap[arrayLength] || 'one';
						return (
							<div className="row__" key={i}>
								<div className={`timeline_box ${lengthClass}`}>
									<div className="date">
										<span className='timeline_text_date'>{item[0]} </span>
									</div>

									<div className={`mid line_${lengthClass}`}></div>

									<span>
										{data[item[0]].map((change, index) => (
											<div className='container_text' key={index}>
												<span className='text_historical_os'>{change.field} </span>
												<br />
											</div>
										))}

									</span>

								</div>
							</div>
						)
					})
					: null
				}
			</>
		</Container>
	)
}

const Container = styled.div`

padding-left: 43px;
margin-top: 27px;

.row__{
    width: 100%;
    display: flex;
    align-items: center;
    
}

// ---------------------------------

.timeline_box{
    width: 100%;
    display: flex;
}
.one{
  height: 61px;
}

.two{
  height: 65px;
}

.three{
  height: 85px;
}

.four{
  height: 110px;
}

.five{
  height: 140px;
}

.six{
  height: 160px;
}

.seven{
  height: 180px;
}

.eight{
  height: 210px;
}

.nine{
  height: 240px;
}

.ten{
  height: 270px;
}

.date{
    display: flex;
    flex-flow: column;
		width: 117px;
}

.timeline_box .mid{
    width: 12px;
    height: 12px;
    margin: 0 17.65px;
    background: white;
    border: 1px solid #00388B;
    border-radius: 100%;
    position: relative;

    :before{
        position: absolute;
        content: "";
        left: 50%;
        width: 1px;
        background: #00388B;
    }
}

.line_one{
  :before{
        bottom: -50px;
        height: 50px;
    }
}

.line_two{
  :before{
        bottom: -55px;
        height: 55px;
    }
}

.line_three{
  :before{
        bottom: -75px;
        height: 75px;
    }
}

.line_four{
  :before{
        bottom: -95px;
        height: 95px;
    }
}

.line_five{
  :before{
        bottom: -115px;
        height: 115px;
    }
}

.line_six{
  :before{
        bottom: -135px;
        height: 135px;
    }
}

.line_seven{
  :before{
        bottom: -175px;
        height: 175px;
    }
}

.line_eigth{
  :before{
        bottom: -205px;
        height: 205px;
    }
}

.line_nine{
  :before{
        bottom: -235px;
        height: 235px;
    }
}

.line_ten{
  :before{
        bottom: -265px;
        height: 265px;
    }
}

.timeline_text_date{
	color: #131523;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.text_historical_os{
	color: #131523;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.container_text{
	padding-top: 5px;
  width: 666px;
}

//-------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.timeline_text_date{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.text_historical_os{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.container_text{
		width: 666px;
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.timeline_text_date{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	.text_historical_os{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.container_text{
		width: 730px;
	}
}

@media screen and (min-width: 1450px){
	.container_text{
		width: 788px;
	}
}

@media screen and (min-width: 1550px){
	.container_text{
		width: 880px;
	}
}

@media screen and (min-width: 1650px){
	.container_text{
		width: 960px;
	}
}

@media screen and (min-width: 1800px){
	.container_text{
		width: 1080px;
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.timeline_text_date{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	.text_historical_os{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.date{
		width: 150px;
}

	.container_text{
		width: 1145px;
	}
}
`
