import React, { useEffect, useState } from 'react'
import { Panel, PanelHeader } from '../../components/panel/panel'

import { Table, Tooltip, Input, Pagination } from 'antd';

import { getGroups, getUserApp_structColumns } from '../../api/users';
import FormEditUser from './FormEditUser';
import styled from "styled-components";
import { useSelector } from 'react-redux';
import { myFetch } from "../../services/services";
import NormalText from '../../components/text/NormalText';
import { useFilter } from '../../hooks/useFilter';
import FormCreateUser from './createUser/index.jsx';
import { permissionCheck } from '../../helpers/helpers';
import { useHistory } from 'react-router-dom';
import { sorterCallback } from '../../helpers/sorterCallback';
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";

import './users.scss'
import EmptySearch from '../../components/empty/emptySearch/EmptySearch.jsx';
import EmptyData from '../../components/empty/emptyData/EmptyData.jsx';

const { Search } = Input;

const Users = () => {
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [ROWS, setRows] = useState([]);
	const [COLUMNS, setColumns] = useState([]);
	const [groups, setGroups] = useState()

	const [visibleEditForm, setVisibleEditForm] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [orderDirection, setOrderDirection] = useState("desc");

	const [loading, setLoading] = useState(true);
	const [emptyData, setEmptyData] = useState(false);

	const [viewEmpty, setViewEmpty] = useState(false);

	const history = useHistory()
	const reduxState = useSelector((state) => state?.authState)
	const loginOperadorID = reduxState?.operator_detail?.ID;

	const [columnFilterValue, setColumnFilterValue] = useState([]);

	const array = reduxState?.permissions
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	useEffect(() => {
		if (rolPermission("datatable_view_userapp") === false) {
			history.replace("/dashboard")
		} else {
			getGroups().then(res => setGroups(res))
			getUserApp_structColumns().then(res => {
				const data = res.map(item =>
					item.dataIndex === "nombre_completo" ?
						{
							...item,
							onCell: (record) => {
								if (rolPermission("change_userapp") === true) {
									return {
										onClick: () => {
											setVisibleEditForm(true)
											setSelectedRowData(record)
										},
										style: { cursor: "pointer" },
										className: "hover-table-class"
									};
								}

							}
						} : item
				);
				setColumns(data);
			})
		}

	}, [])

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Usuarios habilitados por el operador para gestión del sistema.</span>
		</ContainerTooltip>
	)

	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [totalSize, setTotalSize] = useState(0);

	//Filtro
	const { searchData, searchHandler, filters, setRowsParams, rowsParams } = useFilter()

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const getUserapp_Datatables = async () => {

		let newFilters = {
			...filters,
			'operator': loginOperadorID,
			"order_field": "ID",
			"order_type": orderDirection,
		};

		if (columnFilterValue.length === 1 && !newFilters.filters) {
			newFilters = {
				...newFilters,
				filters: `[["is_active","equal","` + columnFilterValue[0] + `"]]`,
			};
		} else if (
			(columnFilterValue.length === 0 && !newFilters.filters) ||
			(columnFilterValue.length === 0 && newFilters.filters)
		) {
			newFilters = newFilters;
		} else if (columnFilterValue.length === 1 && newFilters.filters) {
			newFilters.filters = newFilters.filters.replace(
				"]",
				`], ` + `["is_active","equal","` + columnFilterValue[0] + `"]`
			);
		}

		setRows(null)

		try {
			const res = await myFetch("api/v1/userapp/datatables/", newFilters);

			if (res.data.length === 0) setEmptyData(true)
			else setEmptyData(false)

			if (res?.size) { setTotalSize(res?.size) }
			if (res?.data) {
				const rows = res?.data?.map((row) => {
					return {
						...row,
						key: row.id_unico,
						ID: row.ID,
					}
				});
				setRows(rows)
			}
			setLoading(false)
		} catch (e) { }
	}

	useEffect(() => {
		setLoading(true)
		getUserapp_Datatables()
	}, [
		searchData,
		rowsParams,
		orderDirection,
		columnFilterValue
	])

	useEffect(() => {
		if (emptyData && loading === false && searchData) setViewEmpty(true)
		if (!emptyData && loading === false) setViewEmpty(true)
		if (emptyData && loading === false && !searchData) setViewEmpty(false)
	}, [loading, emptyData])

	return (

		<Panel value={isVisibleForm} setValue={setIsVisibleForm}>
			<PanelHeader
				buttonText="Agregar usuario"
				noButton={rolPermission("add_userapp") === true ? false : true}
				typeButton={"more"}

			>
				<span className='title-list-users'>Listado de usuarios</span>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement="bottom"
					overlayInnerStyle={{
						height: "95px",
						maxHeight: '100px'
					}}
				>
					<i className="fa-solid fa-circle-info circle-tooltip_users"></i>
				</Tooltip>
				<span className='sub-tex-list-users'>Configuración| Usuarios</span>
			</PanelHeader>

			<div className="users-mainPage-container">
				{viewEmpty && (
					<section className='filter-container'>
						<div className="row">
							<div className="input-search-box-content">
								<p className='single-pText'>Listado consolidado de usuarios registrados por el operador.</p>
								<Search
									placeholder="Realizar busqueda"
									allowClear
									style={{ width: '40%' }}
									onSearch={(value) => {
										searchHandler(value)
										if (value === '') {
											setEmptyData(false)
											setLoading(true)
										}
									}}
								/>


							</div>
						</div>
					</section>
				)}


				{!emptyData && !loading && (
					<>
						<Table
							columns={COLUMNS}
							dataSource={ROWS}
							pagination={false}
							rowKey={(record) => record?.id_unico}
							onChange={
								(pagination, filters, sorter, extra) => {
									setOrderDirection(sorterCallback(pagination, filters, sorter, extra))

									if (filters.status === undefined) {
										setColumnFilterValue([]);
									} else {
										setColumnFilterValue(filters.status);
									}
								}
							}
						/>

						<PaginationContainer>
							<div className="row space-between align-center">
								<span className="pagination">
									<NormalText
										bold={false}
										value={`Mostrando del ${rowsParams.start + 1} registros al ${rowsParams.start + rowsParams.offset} de un`}
									/>
									<NormalText
										bold={true}
										value={`total de ${totalSize} registros`}
									/>

								</span>
								<Pagination
									showSizeChanger
									defaultCurrent={paginationSettings[1]}
									current={paginationSettings[0]}
									onChange={modifyPagination}
									total={totalSize}
								/>
							</div>
						</PaginationContainer>
					</>
				)}

				{emptyData && !loading && searchData && (
					<EmptySearch sectionPage='usuario' />
				)}

				{emptyData && !loading && !searchData && (
					<EmptyData sectionPage='usuario' />
				)}

				{isVisibleForm && <FormCreateUser setIsVisibleForm={setIsVisibleForm} groups={groups} loginOperadorID={loginOperadorID} />}
				{visibleEditForm && <FormEditUser setVisibleEditForm={setVisibleEditForm} groups={groups} selectedRowData={selectedRowData} />}
			</div>
		</Panel>
	)
}

export default Users

const PaginationContainer = styled.div`
    padding-top: 43.87px;
	padding-right: 41px;
	padding-bottom: 33px;
	padding-left: 42px;
	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}
	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}
	.align-center {
		align-items: center;
	}
	.pagination {
		color: #001737;
	}

		.ant-pagination{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	@media screen and (min-width: ${devices.smallDesk}){
	
	.ant-pagination{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	
	.ant-pagination{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	
	.ant-pagination{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}

`;

const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 200px;
	}
`;