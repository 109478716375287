/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderDashboard from "./components/HeaderDashboard";
import { size } from "../../helpers/helpers";
import { dashboardGet, userApp } from "../../api/dashboard";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import UseOperator from "../../hooks/UseOperator";
import dayjs from 'dayjs';
import TopCard from "./components/TopCard";
import MidCard from "./components/MidCard";
import BottomCard from "./components/BottomCard";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";

import style from './dashboard.module.css'
import EmptyPhotos from '../../assets/images/Empty/emptyDashboard.png'

export default function Dashboard() {
	const [loadding, setLoadding] = useState(true);
	const [userData, setUserData] = useState([]);

	const [showBtn, setShowBtn] = useState(true)
	const [successPdf, setSuccessPdf] = useState(false)

	const [technicalActivity, setTechnicalActivity] = useState([])
	const [technicalDistribution, setTechnicalDistribution] = useState([])
	const [percentageCard, setPercentageCard] = useState([])
	const [technicalRelations, setTechnicalRelations] = useState([])
	const [typeOs, setTypeOs] = useState([])
	const [dataTypeOs, setDataTypeOs] = useState([])
	const [totalOrders, setTotalOrders] = useState([])

	const [startDate, setStartDate] = useState()
	const [endtDate, setEndDate] = useState()

	const [activityRecent, setActivityRecent] = useState();

	const [filterCheckTechnicials, setFilterCheckTechnicials] = useState(false)
	const [filterCheckSupervisor, setFilterCheckSupervisor] = useState(false)

	const [dataDashboard, setDataDashboard] = useState({})

	const [emptyDashboard, setEmptyDashboard] = useState(false)

	const todayGet = dayjs().format("DD-MM-YYYY")

	const { storedOperatorID } = UseOperator()

	async function generatePDF() {
		await hiddingBtn()
		const selectedArea = document.getElementById('selected_pdf_area');
		html2canvas(selectedArea)
			.then((canvas) => {

				const imgData = canvas.toDataURL('image/png');

				const pdf = new jsPDF('l', 'mm', [420, 380]);
				const width = pdf.internal.pageSize.getWidth() - 10;
				const height = pdf.internal.pageSize.getHeight() - 10;
				pdf.addImage(imgData, 'PNG', 10, 10, width, height);
				pdf.save("Brújula_Metrics.pdf");
				setSuccessPdf(true)
			});
	}

	// --------------------------------

	const tooltip = (
		<TooltipContainer>
			<span className="tooltip_title">Información de ayuda</span>
			<span className="tooltip_subtitle">
				Mapa de cobertura de técnicos por zonas.
			</span>
		</TooltipContainer>
	);

	const tooltip_activity = (
		<TooltipContainer>
			<span className="tooltip_title">Información de ayuda</span>
			<span className="tooltip_subtitle">
				Actualización de estado de órdenes de servicio en tiempo real.
			</span>
		</TooltipContainer>
	);

	const tooltip_relation = (
		<TooltipContainer>
			<span className="tooltip_title">Información de ayuda</span>
			<span className="tooltip_subtitle">
				Relación de status de técnicos registrados en la aplicación.
			</span>
		</TooltipContainer>
	);

	const tooltip_type = (
		<TooltipContainer>
			<span className="tooltip_title">Información de ayuda</span>
			<span className="tooltip_subtitle">
				Gráfico de cantidad de órdenes de servicios por tipos.
			</span>
		</TooltipContainer>
	);

	const data_graph = (item) => {
		const data = item?.filter(item => item?.nombre !== "Total_OS");
		setTypeOs(data)
	}

	const data_time = (item) => {
		const mapeo = item?.actividad.map((i) => {
			return {
				dot: (<div className="circle-timeline" style={{ background: i?.color_type_os, color: i?.color_type_os }}> </div>),
				children: (
					<>
						<span className="os_phrase">{i?.os_phrase}</span>
						<div className="container-timeline">
							<span className="container-span-timeline">{i?.phrase}</span>
							<span className="time-other">{i?.other}</span>
						</div>
						<p className="time-timeline">{i?.time}</p>
					</>
				)
			}
		})
		setTechnicalActivity(mapeo)
	}

	// funcion estilos grafica
	const CustomXAxisTick = ({ x, y, payload, maxChars }) => {
		const words = typeof payload.value === 'string' ? payload.value?.split(' ') : []
		let lines = []
		let line = ''

		words.forEach((word) => {
			if ((line + ' ' + word).length > maxChars) {
				lines.push(line)
				line = ''
			}
			line += (line === '') ? word : ' ' + word
		})
		lines.push(line)

		return (
			<g transform={`translate(${x},${y})`}>
				{lines.map((line, index) => (
					<GraphStyle key={index} x={0} y={index * 16} dy={10} textAnchor="middle" >{line}</GraphStyle>
				))}
			</g>
		);
	};

	const hiddingBtn = () => {
		setShowBtn(false)
	}

	useEffect(() => {
		userApp()
			.then(res => {
				setUserData(res);
			})
	}, [])

	useEffect(() => {
		setLoadding(true)
		if (storedOperatorID !== null && storedOperatorID !== NaN && storedOperatorID !== undefined) {
			if (filterCheckTechnicials === false && filterCheckSupervisor === false && (startDate !== "" || endtDate !== "")) {
				dashboardGet(storedOperatorID, startDate || todayGet, endtDate || todayGet, filterCheckTechnicials, filterCheckSupervisor)
					.then(res => {
						setDataDashboard(res)
					})
			} else if (filterCheckTechnicials && filterCheckSupervisor === false && (startDate !== "" || endtDate !== "")) {
				dashboardGet(storedOperatorID, startDate || todayGet, endtDate || todayGet, filterCheckTechnicials, filterCheckSupervisor)
					.then(res => {
						setDataDashboard(res)
					})
			} else if (filterCheckSupervisor && filterCheckTechnicials === false && (startDate !== "" || endtDate !== "")) {
				dashboardGet(storedOperatorID, startDate || todayGet, endtDate || todayGet, filterCheckTechnicials, filterCheckSupervisor)
					.then(res => {
						setDataDashboard(res)
					})
			} else {
				setLoadding(false)
			}
		}
	}, [storedOperatorID, startDate, endtDate, filterCheckTechnicials, filterCheckSupervisor])

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowBtn(true)
		}, 500);

		return () => clearTimeout(timer)

	}, [showBtn])

	useEffect(() => {
		if (dataDashboard) {
			data_time(dataDashboard?.actividad_reciente_tecnicos)
			setActivityRecent(dataDashboard?.actividad_reciente_tecnicos)
			setTechnicalDistribution(dataDashboard?.distribucion_tecnicos_por_zonas)
			setPercentageCard(dataDashboard?.porcentajes)
			setTechnicalRelations(dataDashboard?.relacion_de_tecnicos)
			setDataTypeOs(dataDashboard?.tipos_os)
			data_graph(dataDashboard?.tipos_os)
			setTotalOrders(dataDashboard?.total_ordenes)
		}
	}, [dataDashboard])

	useEffect(() => {
		if (dataDashboard.actividad_reciente_tecnicos) {
			if (dataDashboard.actividad_reciente_tecnicos.actividad.length === 0) {
				setEmptyDashboard(true)
			} else {
				setLoadding(false)
			}
		}
	}, [dataDashboard])


	return (
		<div id="selected_pdf_area">
			<Container>
				<HeaderDashboard
					showBtn={showBtn}
					generatePDF={generatePDF}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					totalOrders={totalOrders}
					setFilterCheckTechnicials={setFilterCheckTechnicials}
					setFilterCheckSupervisor={setFilterCheckSupervisor}
					filterCheckTechnicials={filterCheckTechnicials}
					filterCheckSupervisor={filterCheckSupervisor}
					emptyDashboard={emptyDashboard}
					loadding={loadding}
				/>

				{emptyDashboard && (
					<section className={style.container_empty}>
						<div className={style.container_img}>
							<img src={EmptyPhotos} alt="Empty" />
							<strong className={style.title_empty}>Comience a construir su tablero</strong>
							<p className={style.text_p}>Antes de que podamos crear cualquier gráfico o estadística, </p>
							<p className={style.text_p}>primero necesitaremos obtener algunos datos aquí.</p>
						</div>

					</section>

				)}

				{loadding === false && emptyDashboard === false ? (
					<>
						<TopCard
							totalOrders={totalOrders}
							percentageCard={percentageCard}
						/>
						<MidCard
							tooltip_activity={tooltip_activity}
							tooltip_relation={tooltip_relation}
							activityRecent={activityRecent}
							technicalActivity={technicalActivity}
							technicalRelations={technicalRelations}
						/>
						<BottomCard
							tooltip={tooltip}
							tooltip_type={tooltip_type}
							dataTypeOs={dataTypeOs}
							percentageCard={percentageCard}
							typeOs={typeOs}
							technicalDistribution={technicalDistribution}
							CustomXAxisTick={CustomXAxisTick}
						/>
					</>
				) : null
				}

			</Container >
		</div >
	);

}


const Container = styled.div`

display: flex;
flex-flow: column;
min-height: 100vh;
position: relative;

h1, p {
	margin: 0 !important;
	padding: 0 !important;
}

.column{
	flex-flow: column;
}
.row{
	display: flex;
	margin: 0 !important;
	padding: 0 !important;
}
.center{
	align-items: center;
}

.time-other{
	color: #131523;
	font-weight: bold;
	margin-left: 20%;
}

.circle-timeline {
	background-color: blue;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 1px solid #999;
}

.container-timeline{
	display: flex;
}

.container-span-timeline{
	width: 50%;
	font-size: ${smallSizes.fontSize.fontSize12};
	color: #495463;
}

.time-timeline{
	color: #5E6875;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.os_phrase{
	color: #131523;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: 300;
}

@media screen and (min-width: ${devices.smallDesk}){

	.container-span-timeline{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.time-timeline{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.os_phrase{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.container-span-timeline{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.time-timeline{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	.os_phrase{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.container-span-timeline{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.time-timeline{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	.os_phrase{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}

// -----------RESPONSIVE---------
    @media( max-width: 1440px){
        .cards_container .card .circleIcon{ width: 60px;height: 60px}
        .cards_container .card .circleIcon .icon_size{font-size: 30px}
    }
    @media( max-width: 1220px){
        .cards_container .card .responsived{flex-flow: column-reverse;}
        .cards_container .card .circleIcon{ margin-bottom: 1em}
        .mid_section .distribution{width: 100%}
    }
    @media( max-width:${size.desktop}){
        .cards_container{grid-template-columns: repeat(2, 1fr)}
        .mid_section, .bottom_section{ flex-flow: column; justify-content: center; align-items: center}
        .mid_section .recents,.bottom_section .left {width: 100%}
        .mid_section .donut_graph,.bottom_section .right {width: 100%}
        .cards_container .card .responsived{flex-flow: row;}
        .cards_container .card .circleIcon{ margin-bottom: 0}
    }
    @media( max-width:${size.tablet}){
        .cards_container{grid-template-columns: repeat(2, 1fr)}
    }
    @media( max-width:${size.mobile}){
        .cards_container{grid-template-columns: repeat(1, 1fr)}
    }
`;

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    .tooltip_title {
        color: #868E96;
        font-size: 14px;
        margin-bottom: 15px;
    }
    .tooltip_subtitle {
        color: #172B4D;
        font-size: 12px;
    }
`;

const GraphStyle = styled.text`
	font-size: ${smallSizes.fontSize.fontSize9};

	@media screen and (min-width: ${devices.smallDesk}){

		font-size: ${smallSizes.fontSize.fontSize9};
	}

	@media screen and (min-width: ${devices.mediumDesk}){

		font-size: ${mediumSizes.fontSize.fontSize9};
	}

	@media screen and (min-width: ${devices.largeDesk}){

		font-size: ${largeSizes.fontSize.fontSize9};
	}
`;