import React, { useEffect, useState } from 'react'
import { Panel } from '../../../../components/panel/panel'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue'
import LabelDrawer from '../../../../components/text/drawer/label'
import style from './historyPanelTypesOT.module.css'
import { myFetchGet } from '../../../../services/services'
import { History } from '../../../../components/history/History'
import historyDataRegister from '../../../../helpers/historyDataRegister'
import { getHistoryOtType } from '../../../../api/otTypeApi'

const HistoryPanelTypesOT = ({ data }) => {

	const [historyData, setHistoryData] = useState([])

	useEffect(() => {
		data.id_unico && getHistoryOtType(data.id_unico).then(res => {
			const data = historyDataRegister(res.changes)
			setHistoryData(data)
		})
	}, [])

	return (
		<Panel>
			<section className={style.container_top}>
				<SubtitleBlue value="Registro de actividad" />
				<LabelDrawer required={false} value="Histórico asociado a los cambios registrados de la orden de servicio." />
				<History data={historyData} />
			</section>
		</Panel>
	)
}

export default HistoryPanelTypesOT