import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../../components/modal/CustomModal'
import Form from '../../../../../../components/form/index.jsx'
import MainTitle from '../../../../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../../../../components/text/drawer/subtitleBlue'

import style from './summaryForm.module.css'
import { Avatar } from 'antd'
import BottomSection from '../../../../../../components/form/bottomSection/index.jsx'
import { postNewServiceOrder } from '../../../../../../api/calendar.js'
import useDisabledButton from '../../../../../../hooks/useDisabledButton.jsx'
import ViewPlanSummary from './components/viewPlanSummary/ViewPlanSummary.jsx'

const SummaryForm = ({ setViewSummary, bodyCreateSummary, titularUser, showSpecification, selectUserSearch, manualRegister }) => {

	const {
		first_name,
		last_name,
		document_type,
		document_type_name,
		document_number,
		phone,
		email,
		service_number,
		direction,
		direction_text,
		direction_text_complemento,
		operator,
		tradename,
		technology,
		date,
		name_ostype,
		name_category,
		duration,
		technician,
		technician_name,
		comentary,
		supervisor_required,
		ostype,
		category,
		disponibility_hours,
		status,
		operator_id,
		disponibily_code
	} = bodyCreateSummary

	const [initialName, setInitialName] = useState("")
	const { enableButton, disableButton, isButtonDisabled } = useDisabledButton()


	const onServiceOrderCreate = async () => {

		const body = {
			"technician": technician,
			"status": status,
			"ostype": ostype,
			"category": category,
			"operator": operator,
			"disponibility_hours": disponibility_hours,
			"supervisor_required": supervisor_required,
			"disponibily_code": disponibily_code
		}

		if (showSpecification && !manualRegister) {
			body['user_id'] = titularUser.user_id;
			body['plan_id'] = titularUser.plan_id;
			body['direction'] = titularUser.direction_id;
			body['technology'] = titularUser.plan_technology;
		}

		if (!showSpecification && !manualRegister) {
			body['user_id'] = titularUser.brujula_user_id;
			body['tradename'] = titularUser.plan_id || "Sin plan";
			body['direction'] = titularUser.direction_id;
			body['direction_text'] = titularUser.direction;
			body['technology'] = titularUser.plan_technology || "technology";
		}

		if (manualRegister) {
			body['first_name'] = first_name;
			body['last_name'] = last_name;
			body['document_type'] = document_type;
			body['document_number'] = document_number;
			body['phone'] = phone;
			body['email'] = email;
			body['service_number'] = service_number;
			body['direction'] = direction;
			body['direction_text'] = direction_text_complemento;
			body['operator_id'] = operator_id;
			body['tradename'] = tradename;
			body['technology'] = technology;
		}

		if (duration) {
			body['duration'] = duration
		}

		if (comentary) {
			body['comentary'] = comentary
		}

		disableButton()
		postNewServiceOrder(body).then(res => {
			if (res === "error") {
				enableButton()
			}
		})
	}


	useEffect(() => {
		if (!selectUserSearch) {
			const firstName = first_name.charAt(0).toUpperCase()
			setInitialName(firstName)
		}

	}, [])

	return (
		<CustomModal onClick={() => setViewSummary(false)}>
			<Form widthForm='38%'>

				<MainTitle value='Resumen de solicitud' onClick={() => setViewSummary(false)} />
				<SubtitleBlue value='Información del cliente' />

				{selectUserSearch &&
					< ViewPlanSummary
						data={titularUser}
						showSpecification={showSpecification}
					/>
				}

				{!selectUserSearch &&
					<section className={style.container_client}>
						<header className={style.header}>
							<section className={style.container_data}>
								<Avatar className={style.avatar} >
									{initialName}
								</Avatar>
								<div className={style.subcontaier_data}>
									<label className={style.text_name}>{`${first_name} ${last_name}`}</label>
									<label className={style.text_document}>{`${document_type_name} ${document_number}`}</label>
									<label className={style.text_title_direction}>Dirección</label>
									<label className={style.text_direction}>{`${direction_text} ${direction_text_complemento}`}</label>
								</div>
							</section>


							<label className={style.text_number}>{service_number}</label>

						</header>
					</section>
				}


				<SubtitleBlue value='Orden de servicio' marginBottom='3.4px' />

				<section className={style.container_order}>

					<span >
						<p className={style.text_date_title}>Fecha de agendamiento </p>
						<p className={style.text_date}>{date}</p>
					</span>

					<div className={style.container_type_category}>
						<span className={style.container_type}>
							<p className={style.type_order}>{name_ostype}</p>
						</span>

						<span className={style.column}>
							<p className={style.text_category_title}>Categoría</p>
							<p className={style.text_category}>{name_category}</p>
						</span>
					</div>

					<label className={style.text_duration}>Tiempo estimado de duración : <label className={style.color2B80FF}>{duration} min</label></label>
				</section>

				<SubtitleBlue value='Atendimiento orden de servicio' />

				<section className={style.container_user}>
					<Avatar className={style.avatar_user} >
						C
					</Avatar>

					<div className={style.description_user}>
						<p className={`${style.user_name} ${style.bold}`}>{technician_name}</p>
						<p className={style.user_text}>Técnico Principal</p>

						{comentary && (
							<>
								<p className={`${style.text_font12_5A607F} ${style.bold}`}>Comentario adicional</p>
								<p className={style.text_font12_959595}>{comentary}</p>
							</>
						)}



					</div>

					<p className={`${style.text_font12_2B80FF} ${style.bold}`}>
						{supervisor_required === false ? "Sin apoyo de supervisor" : "Apoyo de supervisor"}
					</p>
				</section>

				<BottomSection
					onCancel={() => setViewSummary(false)}
					onAcept={onServiceOrderCreate}
					isButtonDisabled={isButtonDisabled}
				/>


			</Form>
		</CustomModal>
	)
}

export default SummaryForm