const Menu = [
	{
		path: '/dashboard',
		title: 'Tablero',
	},
	{
		path: '/calendarServiceOrders',
		title: 'Calendario',
	},
	{
		path: '/listServiceOrders',
		title: 'Órdenes de servicio',
	},
	{
		path: '/listWorkOrders',
		title: 'Órdenes de trabajo',
	},
	{
		path: "#",
		title: 'Otras opciones',
	},
	{
		path: '/Disponibilidad',
		title: 'Disponibilidad',
		icon: "fa-solid fa-clock",
		children: [
			{
				path: '/technicians',
				title: 'Listado de Técnicos',
			},

			{
				path: '/availability',
				title: 'Disponibilidad actual',
			},

			{
				path: '/absences',
				title: 'Ausencias',
			},
			{
				path: '/holidays',
				title: 'Feriados',
			},

		]

	},
	{
		path: '/Reportes',
		title: 'Reportes',
		icon: "fa-solid fa-chart-pie",
		children: [
			{
				path: '/routesReport',
				title: 'Reporte de rutas'
			},
		]
	},
	{
		path: '/Configuración',
		title: 'Configuración',
		icon: "fa-solid fa-gear",
		children: [
			{ path: '/users', title: 'Usuarios' },
			{ path: '/TypesOS', title: 'Órdenes de servicios' },
			{ path: '/TypesOT', title: 'Órdenes de trabajo' },
		]
	},

	{
		path: '/Configuración',
		title: 'Configuración',
		icon: "fa-solid fa-gear",
		children: [
			{ path: '/TypesOS', title: 'Órdenes de servicios' },
			{ path: '/TypesOT', title: 'Órdenes de trabajo' },
		]
	},
]

export default Menu;
