import React, { useState } from 'react'
import styled from 'styled-components'
import CustomModal from '../../../components/modal/CustomModal'
import { CloseOutlined } from '@ant-design/icons'
import { Divider, InputNumber, Select, Popconfirm, notification, Input } from 'antd'
import { onlyTextAndNumber } from '../../../helpers/helpers'
import Br from '../../../components/br/Br'
import { myFetch } from '../../../services/services'
import size from '../../../helpers/sizes'
import color from '../../../helpers/color'
import LabelDrawer from '../../../components/text/drawer/label'
import Form from '../../../components/form/index.jsx';
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue'
import MainTitle from '../../../components/text/drawer/mainTitle'
import BlueButton from '../../../components/buttons/blueButton'
import SingleButton from '../../../components/buttons/singleButton'

const EditCategoryForm = ({ editCategoryForm, setEditCategoryForm, editableData }) => {

	const [categoryName, setCategoryName] = useState(editableData.name)
	const [categoryError, setCategoryError] = useState("")

	const [duration, setDuration] = useState(editableData.duration)

	const closeForm = () => setEditCategoryForm(!editCategoryForm)
	const types = [{ kind: 1, name: "Sí o No" }, { kind: 2, name: "Abierta" }]

	const onNameChange = (e) => {
		const value = e.target.value;
		if (onlyTextAndNumber(value)) {
			setCategoryName(value);
			setCategoryError("")
		} else {
			setCategoryError('*No se permiten Carácteres especiales');
		}
	}

	const onDurationChange = (value) => isNaN(value) ? setDuration(null) : setDuration(value)

	// ----------------------
	// ----------------------
	// ----------------------
	// ----------------------
	const [imagesArray, setImagesArray] = useState(editableData?.imgs)
	const [imgInputValue, setImgsInputValue] = useState("")

	const addImagesArray = () => {
		if (!imgInputValue) {
			return;
		}
		setImagesArray([...imagesArray, {
			"img_name": imgInputValue
		}])
		setImgsInputValue("")
	}

	const handleEdit = (index, newImgName) => {
		let newArray = [...imagesArray];
		newArray[index] = newImgName;

		setImagesArray(newArray);
	};

	const handleDelete = (index) => {
		const newArray = [...imagesArray];
		newArray.splice(index, 1);
		setImagesArray(newArray);
	};

	function ImageNameItem({ imgName, index, handleEdit, handleDelete }) {
		const [isEditing, setIsEditing] = useState(false);
		const [newImageName, setNewImageName] = useState(imgName);

		const handleEditClick = () => {
			setIsEditing(true);
		};

		const handleSaveClick = () => {
			handleEdit(index, newImageName);
			setIsEditing(false);
		};

		const handleDeleteClick = () => {
			handleDelete(index);
		};

		const handleInputChange = (e) => {
			const value = e.target.value
			setNewImageName({ img_name: value })
		};
		const cancelEdit = () => {
			setIsEditing(false);
		}

		return (
			<>
				{isEditing === true ? (
					<span className='row space-between' style={{ marginTop: 5 }}>
						<input
							type="text"
							name="name"
							value={newImageName.name}
							onChange={handleInputChange}
							className="input_imageName_edit"
						/>
						<button className='delete_noBtn' onClick={cancelEdit}>Cancelar</button>
						<button className='delete_noBtn' onClick={handleSaveClick}>Guardar</button>
					</span>
				) : (
					<span className='row space-between' key={index}>
						<span style={{ fontSize: "11px", paddingLeft: "20px", margin: "2px 0" }}>{imgName.img_name}</span>
						<span>
							<button className='delete_noBtn' onClick={handleEditClick}>Modificar</button>
							<Popconfirm
								placement="topRight"
								icon={""}
								title="¿Eliminar Pregunta?"
								description={() => {
									return (
										`Si eliminas esta pregunta, se eliminará de forma permanente de tu galería de imágenes`)
								}}
								onConfirm={handleDeleteClick}
								okText="Eliminar"
								cancelText="Cancelar"
							>
								<button className='delete_noBtn'>Eliminar</button>
							</Popconfirm>
						</span>
					</span>
				)}
			</>
		);
	}

	function ImagesNameList({ imagesArray, handleEdit, handleDelete }) {
		return (
			<>
				{imagesArray?.map((imgName, index) =>
					<ImageNameItem
						key={index}
						index={index}
						imgName={imgName}
						handleEdit={handleEdit}
						handleDelete={handleDelete}
					/>
				)}
			</>
		);
	}

	// ------------------------
	// ------------------------
	// ------------------------
	// ------------------------
	const [questionArray, setQuestionArray] = useState(editableData?.questions)
	const [questionInputValue, setInputQuestion] = useState("")
	const [questionType, setQuestionType] = useState(editableData?.kind)

	const addQuestionArray = () => {
		if (!questionInputValue) {
			return;
		}
		setQuestionArray([...questionArray, {
			kind: questionType,
			question: questionInputValue,
		}])
		setInputQuestion("")
	}

	const handleQuestEdit = (index, newValue) => {
		let newArray = [...questionArray];
		newArray[index] = newValue;

		setQuestionArray(newArray);
	};

	const handleQuestDelete = (index) => {
		const newArray = [...questionArray];
		newArray.splice(index, 1);
		setQuestionArray(newArray);
	};
	const onTypeChange = (value) => setQuestionType(value)

	function QuestionItem({ item, index, handleQuestEdit, handleQuestDelete }) {
		const [isEditing, setIsEditing] = useState(false);
		const [newQuestion, setNewQuestion] = useState(item?.question);
		const [questionType, setQuestionType] = useState(item?.kind)

		const handleEditClick = () => setIsEditing(true)
		const handleDeleteClick = () => handleQuestDelete(index)

		const handleSaveQuestClick = () => {
			handleQuestEdit(index, {
				kind: questionType,
				question: newQuestion,
			});
		};


		const onEditInputChange = (e) => {
			e.preventDefault()
			const value = e.target.value
			setNewQuestion(value)
		};
		const onTypeChange = (value) => setQuestionType(value)
		const cancelEdit = () => setIsEditing(false)

		return (
			<>
				{isEditing === true ? (
					<div className="row space-between">
						<div className="column" style={{ width: "270px" }}>
							<LabelDrawer value='Pregunta' />
							<Input
								type="text"
								className='input'
								placeholder={item?.question}
								value={newQuestion}
								onChange={onEditInputChange}
							/>
						</div>

						<div className="column" style={{ width: "233px" }}>
							<LabelDrawer value='Tipo' />
							<div className="row space-between">
								<Select
									style={{ width: 110 }}
									value={questionType === 2 ? "abierta" : "Si o no"}
									onChange={onTypeChange}
									options={types?.map((item) => ({
										label: item?.name,
										value: item?.kind,
									}))}
								/>
								<button className='delete_noBtn' onClick={cancelEdit}>Cancelar</button>
								<button className='delete_noBtn' onClick={handleSaveQuestClick}>Guardar</button>
							</div>
						</div>
					</div>
				) : (
					<span className='row space-between' key={index}>
						<span style={{ fontSize: "11px", paddingLeft: "20px", margin: "2px 0", width: "50%" }}>{item?.question}</span>
						<BlueButton value={item.kind === 1 ? "Si o No" : "Abierta"} />
						<span>
							<button className='delete_noBtn' onClick={handleEditClick}>Modificar</button>
							<Popconfirm
								placement="topRight"
								icon={""}
								title="¿Eliminar Pregunta?"
								description={() => {
									return (
										`Si eliminas esta pregunta, se eliminará de forma permanente de tu galería de imágenes`)
								}}
								onConfirm={handleDeleteClick}
								okText="Eliminar"
								cancelText="Cancelar"
							>
								<button className='delete_noBtn'>Eliminar</button>
							</Popconfirm>
						</span>
					</span>
				)}
			</>
		);
	}

	function QuestionList({ questionArray, handleQuestEdit, handleQuestDelete }) {
		return (
			<>
				{questionArray?.map((item, index) =>
					<QuestionItem
						key={index}
						index={index}
						item={item}
						handleQuestEdit={handleQuestEdit}
						handleQuestDelete={handleQuestDelete}
					/>
				)}
			</>
		);
	}

	const data = {
		"name": categoryName,
		"duration": duration,
		"imgs": { "value": imagesArray },
		"questions": { "value": questionArray },
	}

	// ------------------------
	// ------------------------

	const sendPatch = async () => {
		try {
			const res = await myFetch(`api/v1/category/${editableData.ID}/`, data, "PATCH")
			notification.success({
				style: { fontWeight: 'bold' },
				message: 'Editado con éxito',
				placement: 'bottomLeft',
				duration: 3,
				onClose: () => window.location.reload()
			});
		} catch (error) {
			console.log(error)
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'Error',
				description: 'No se pudo editar',
				placement: 'bottomLeft',
				duration: 3,
				onClose: () => window.location.reload()
			});
		}
		// window.location.reload()
		// closeForm()
	}

	return (
		<CustomModal onClick={closeForm}>
			<Form>
				<Container>
					<MainTitle onClick={closeForm} value='Modificar categoría' />

					<SubtitleBlue value="Información de la categoría" />

					<div className="row space-between">
						<div className="left margin-top">
							<div className="column">
								<LabelDrawer value='Nombre' />
								<input
									type="text"
									name='category'
									className='input'
									placeholder='Ingresar nombre de la categoría'
									onChange={onNameChange}
									value={categoryName}
								/>
							</div>
						</div>


						<div className="right margin-top">
							<div className="column">
								<LabelDrawer value='Duración' />
								<InputNumber
									min={1}
									max={240}
									className='input'
									defaultValue={60}
									onChange={onDurationChange}
									value={duration}
									type="number"
									placeholder="00:30 Min."
								/>
							</div>
						</div>
					</div>
					{categoryError && <span>{categoryError}</span>}

					{/* ------------------------------ */}

					<div className="margin-top"></div>

					<LabelDrawer value='Nombre de imágenes a solicitar' />

					<div className="row space-between">
						<input
							type="text"
							style={{ width: "calc(97.7% - 91px)" }}
							value={imgInputValue} className='input'
							onChange={e => setImgsInputValue(e.target.value)}
							placeholder='Ingresar nombre de la categoría'
						/>
						<SingleButton onClick={addImagesArray} value='Agregar' />
					</div>

					<div className="margin-top"></div>
					<ImagesNameList
						imagesArray={imagesArray}
						handleEdit={handleEdit}
						handleDelete={handleDelete}
					/>

					{/* ------------------------------ */}
					{/* ------------------------------ */}
					{/* ------------------------------ */}
					{/* -----------QUESTION----------- */}
					{/* ------------------------------ */}
					{/* ------------------------------ */}
					{/* ------------------------------ */}
					<div className="margin-top"></div>
					<div className="row space-between">
						<div className="column" style={{ width: "calc(98% - 233px) " }}>
							<LabelDrawer value='Pregunta' />
							<input
								type="text"
								className='input'
								placeholder='Ingrese la pregunta'
								value={questionInputValue}
								onChange={(e) => setInputQuestion(e.target.value)}
							/>
						</div>

						<div className="column" style={{ width: "233px" }}>
							<LabelDrawer value='Tipo' />
							<div className="row space-between">
								<Select
									style={{ width: 110 }}
									value={questionType}
									onChange={onTypeChange}
									options={types?.map((item) => ({
										label: item?.name,
										value: item?.kind,
									}))}
								/>

								<SingleButton
									onClick={addQuestionArray}
									value='Agregar'
								/>

							</div>
						</div>
					</div>


					<div className="margin-top"></div>
					<QuestionList
						questionArray={questionArray}
						handleQuestEdit={handleQuestEdit}
						handleQuestDelete={handleQuestDelete}
					/>

					{/* ------------------------------- */}

					<Br />
					<Br />

					<div className="row flex-end" style={{ alignItems: "flex-end", flex: 1 }}>
						<div className="row space-between" style={{ width: "180px" }}>
							<button className="btn__" onClick={closeForm}>Cancelar</button>
							<button className="btn__save" onClick={sendPatch}>Guardar</button>
						</div>
					</div>

				</Container>
			</Form>
		</CustomModal >
	)
}

export default EditCategoryForm

const Container = styled.div`

.row{
	width: 100%;
	display: flex;
	align-items: center;
    margin: 0 !important;
	padding: 0 !important;
}
.column{
	width: 100%;
	display: flex;
    flex-flow: column;
}
.row_center{
    justify-content: center;
  }
.flex-end{
    justify-content: flex-end;
}
.space-between{
	justify-content: space-between;
}
.column-between{
	align-items: space-between;
}
.blue{
    color: #2B80FF;
    display: inline;
}

.subtitled{
    font-size: 14px;
    font-weight: bold;
    color: ${color.requiredIconBlue};
}
.margin-top{
    margin-top: 17px;
}

// ----------------------------------------

.left{
    width: 49%;
}
.right{
    width: 49%;
}

// ----------------------------------------

.input{
    width: 100%;
    height: 40px;
    background: white;
    outline: none;
    border: none;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    padding: 4px 11px 4px;
    color: #868D96;

    ::placeholder {
      font-weight: bold;
      opacity: 0.5;
      color: #868D96;
    }
    
    :focus{
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        border-right-width: 1px;
    }
}

.input_imageName_edit{
    width: calc(91.7% - 91px);
    height: 40px;
    background: white;
    outline: none;
    border: none;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    padding: 4px 11px 4px;
    color: #868D96;

    ::placeholder {
      font-weight: bold;
      opacity: 0.5;
      color: #868D96;
    }
    
    :focus{
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        border-right-width: 1px;
    }
}
  
.ant-select-selector{
    height: 40px;
    border-radius: 5px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.ant-select-selection-item{
    padding-top: 5px !important;
}
.ant-input-number-sm input {
    height: 22px;
    padding: 10px 7px 0 7px;
    color: #868D96;
    font-size: 12px
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 0 11px;
}
// .add_button{
//     width: 100px;
//     height: 40px;
//     background: #DDE4EB;
//     border: none;
//     outline: none;

//     border: 1px solid #0060FF;
//     border-radius: 3px;
//     color: #001737;
//     font-size: 13px;
//     text-align: center;

//     :hover{
//         background: #2C91FC;
//         color: white;
//     }
// }
.delete_noBtn{
    font-size: 12px;
    color: #2B80FF;
    font-weight: bold;
    border: none;
    outline: none;
    margin: 2px 4px 2px 0;
    background: white;
    position: inherit;
    position: relative !important;
}
.delete_tooltip{
    width: 197px;
    height: 124px;

    position: absolute;
    top: 20px;
    right: 100px;
    border-radius: 4px;
    background: #00388B;
    color: white;
    padding: 1em;
    display: flex;
    flex-flow: column;
    z-index: 1;
}

.btn__, .btn__save {
	width: 83px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 3px;
}
.btn__{
    background: white;
    border: 1px solid #BFCCDA;
    :hover{
        background: #DDE4EB;
        border: none;
    }
}
.btn__save{
    background:#DDE4EB;
    border: 1px solid #0060FF;

    :hover{
        background: #2B80FF;
        color: white;
    }
}


// ------------------- Media Querys ------------------- 

@media screen and (min-width: 1280px){
		.delete_noBtn {
		font-size: 12px;
	}
}


@media screen and (min-width: 1366px){
	.delete_noBtn {
    font-size: 13.5px;
	}
}


@media screen and (min-width: 1920px){
	.delete_noBtn {
    font-size: 15.18px;
}
}


`