import React from 'react'
import styled from 'styled-components'
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Divider, Timeline, Tooltip, Spin } from "antd";
import { Link } from "react-router-dom";
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes'

const MidCard = ({
	tooltip_activity,
	tooltip_relation,
	activityRecent,
	technicalActivity,
	technicalRelations,
}) => {

	const yellow = "#FFC700";
	const blue = "#0058FF";

	const COLORS = [blue, yellow,];

	return (
		<Container>
			<div className="mid_section">
				<div className="recents">
					<div className="space-between description">
						<div>
							<p className="title-card">Actividad reciente</p>
							<Tooltip title={tooltip_activity} color="white" arrow={false} placement="bottomLeft">
								<i className="fa-solid fa-circle-info circle-tooltip"></i>
							</Tooltip>
						</div>
						<span className='activity'>{activityRecent?.finalizadas} finalizadas, {activityRecent?.en_curso} en curso</span>
					</div>
					<div className='scrollable-timeline custom_scroll'>
						<br />
						<Timeline items={
							technicalActivity
						}
						/>
						<Divider />
					</div>
				</div>


				<div className="donut_graph">
					<span className="title-card">Relación de técnicos</span>
					<Tooltip title={tooltip_relation} color="white" arrow={false} placement="bottomRight">
						<i className="fa-solid fa-circle-info circle-tooltip" ></i>
					</Tooltip>
					<div className='graph'>
						<ResponsiveContainer width='100%' height="100%">
							<PieChart width={400} height={100}>
								<Pie
									data={technicalRelations}
									cx="50%"
									cy="50%"
									labelLine={false}
									innerRadius={60}
									outerRadius={80}
									fill="#8884d8"
									dataKey="cant"
								>
									{technicalRelations?.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
									))}
								</Pie>
							</PieChart>
						</ResponsiveContainer>
					</div>

					<span className="row space-between aling-width">
						<span className='square-blue'></span>
						<span className='text-items-graph'>Activos</span>
						<b>{technicalRelations?.map((item) => item.tipo === "activos" ? item.cant : null)}%</b>
					</span>
					<span className="row space-between aling-width">
						<span className='square-yellow'></span>
						<span className='text-items-graph'>Inactivos</span>
						<b>{technicalRelations?.map((item) => item.tipo === "inactivos" ? item.cant : null)}%</b>
					</span>

					<Divider />
					<Link to='/technicians' className="show_more">Ir al listado de técnicos</Link>


				</div>
			</div>
		</Container>
	)
}

export default MidCard

const Container = styled.div`

.space-between{
  justify-content: space-between; 
}

.mid_section{
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  margin: 1.3em 0;
}

.recents{
    width: calc(60% - 1.3em);
    min-height: 400px;
    background: white;
    padding: 2em;
    border-radius: 3px;
    border: 1px solid #DDE4EB;
}

.donut_graph{
    width: 40%;
    min-height: 400px;
    background: white;
    padding: 2em;
    border-radius: 3px;
    border: 1px solid #DDE4EB;
}

.description{
  width: 100%;
  display: flex;
  align-items: center;
  margin: 4px 10px 20px 0;
}

.title-card{
  font-size: ${smallSizes.fontSize.fontSize15};
  font-weight: bold;
  color:#5A607F;
}

.circle-tooltip{
  margin: 10px;
  font-size: ${smallSizes.fontSize.fontSize12};
  color: #00388B;
}

.activity{
  color: #495463;
  font-size: ${smallSizes.fontSize.fontSize10};
  margin-right: 7%;
}

.graph{
  width: 100%;
  height: 60%;
}

.aling-width{
  align-items:center;
  width: 100%; 
}
.text-items-graph{
  font-size: ${smallSizes.fontSize.fontSize12};
  margin-left: 20px;
  flex: 1;
  color: #131523;
}

.square-blue{
  background: blue;
  width: 12px;
  height: 12px;
}

.square-yellow{
  background: yellow;
  width: 12px;
  height: 12px;
}

.scrollable-timeline {
  max-height: 340px; 
  overflow-y: auto;
}

.show_more{
	color: #0058FF;
	font-size: ${smallSizes.fontSize.fontSize12};
	width: 100%;
	cursor: pointer;
}

@media screen and (min-width: ${devices.smallDesk}){

	.title-card{
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.circle-tooltip{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.activity{
		font-size: ${smallSizes.fontSize.fontSize10};
	}

	.text-items-graph{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.show_more{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.title-card{
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.circle-tooltip{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.activity{
		font-size: ${mediumSizes.fontSize.fontSize10};
	}

	.text-items-graph{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.show_more{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.title-card{
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.circle-tooltip{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.activity{
		font-size: ${largeSizes.fontSize.fontSize10};
	}

	.text-items-graph{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.show_more{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}

`