import React, { useEffect, useState } from 'react'

import EmptyPhotos from '../../../assets/images/Empty/emptyData.png'

import style from './emptyData.module.css'

const EmptyData = ({ sectionPage }) => {

	const [dataView, setDataView] = useState('')

	useEffect(() => {
		if (sectionPage === 'orden de servicio') setDataView('una orden de servicio')
		if (sectionPage === 'orden de trabajo') setDataView('una orden de trabajo')
		if (sectionPage === 'técnico') setDataView('un técnico')
		if (sectionPage === 'tipo de orden de servicio') setDataView('un tipo de orden de servicio')
		if (sectionPage === 'tipo de orden de trabajo') setDataView('un tipo de orden de trabajo')
		if (sectionPage === 'categoría orden de trabajo') setDataView('una Categoría orden de trabajo')
		if (sectionPage === 'categoría orden de servicio') setDataView('una Categoría orden de servicio')
		if (sectionPage === 'feriado') setDataView('un feriado')
		if (sectionPage === 'ausencia') setDataView('una ausencia')
		if (sectionPage === 'usuario') setDataView('un usuario')
		if (sectionPage === 'documento') setDataView('un documento')
	}, [])

	return (
		<section className={style.container_empty}>
			<div className={style.container_img}>
				<img src={EmptyPhotos} alt="Empty" />
				{sectionPage === 'tipos de órdenes' ? (
					<>
						<strong className={style.title_empty}>Configure los tipos de ordenes que realiza el tecnico</strong>
						<p className={style.text_p}>Debe ir agregando los tipos de orden desde el formulario que esta en la parte superior</p>
					</>
				) : (
					<>
						<strong className={style.title_empty}>Comience agregando {dataView}</strong>
						<p className={style.text_p}>Para proporcionar {dataView}, haga clic en <strong className={style.color_blue}>agregar {sectionPage}</strong></p>
					</>
				)}

			</div>

		</section>
	)
}

export default EmptyData