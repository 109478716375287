import React, { useEffect, useState } from 'react'
import { Panel } from '../../components/panel/panel'
import PanelHeaderCalendarV2 from '../../components/panelHeader/Calendar/PanelHeaderCalendarV2'
import { Tooltip } from 'antd'

import style from './serviceOrdersCalendar.module.css'
import ColorCircle from '../../components/colorCircle'

import { checkIfUserGetOraculo, getOScalendarByWeek, getOs, getOstype, getTechnician, getTypeOrder } from "../../api/calendar";
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import CustomModal from '../../components/modal/CustomModal'

import FormularioOS from "./components/formCreatedOS/FormularioOS";
import FormOverflow from "./components/formCreatedOS/components/formOverflow/FormOverflow";
import FormCreatedOT from "./components/formCreatedOT/FormCreatedOT";

import { devices, largeSizes, mediumSizes, smallSizes } from '../../helpers/sizes'
import { permissionCheck, stringCapitalize } from '../../helpers/helpers'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js'
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import CustomHeader from './components/customHeader/CustomHeader'
import { myFetch } from '../../services/services'
import { selectTab } from '../../store/tab/tabSlice'
import UseOperator from '../../hooks/UseOperator'


const ServiceOrdersCalendar = () => {

	const reduxState = useSelector((state) => state?.authState)
	const loginOperadorID = reduxState?.operator_detail?.ID;

	const array = reduxState?.permissions
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const localizer = dayjsLocalizer(dayjs)

	const currentMonth = dayjs().format('MM');
	const currentYear = dayjs().format('YYYY');

	const history = useHistory();

	const { storedOperatorID } = UseOperator()
	const dispatch = useDispatch()

	const [tooltipData, setTooltipData] = useState([])

	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [visibleFormOT, setVisibleFormOT] = useState(false);

	const [loadding, setLoadding] = useState(true)
	const [selectdFiltre, setSelectdFiltre] = useState("name");

	const [osList, setOsList] = useState([]) //*revisar para que se tiene
	const [typeOSList, setTypeOSList] = useState([]);

	const [technicians, setTechnicians] = useState([])

	const [checkIfOraculo, setCheckIfOraculo] = useState(null)
	const [showSpecification, setShowSpecification] = useState(false)

	const [visibleFormOverflow, setVisibleFormOverflow] = useState(false);

	const [nameTechnican, setNameTechnican] = useState("");

	const [randomTechnican, setRandomTechnican] = useState(false);

	const [dateSelectedForm, setDateSelectedForm] = useState(null);
	const [categorySelected, setCategorySelected] = useState(null);
	const [technicianIDForm, setTechnicianIDForm] = useState(null);

	const [selectedHoursOverflow, setSelectedHoursOverflow] = useState(null);

	const [disponibilidadIDOverflow, setDisponibilidadIDOverflow] = useState(null);

	const [technicianOverflow, setTechnicianOverflow] = useState(null);

	const [daySelected, setDaySelected] = useState("")
	const [technicianID, setTechnicianID] = useState(null)

	const [OScalendarWeek, setOScalendarWeek] = useState(null)

	const [firstDayWeekCalendar, setfirstDayWeekCalendar] = useState(null)
	const [monthSelected, setMonthSelected] = useState(null)
	const [yearSelected, setYearSelected] = useState(null)

	const [currentView, setCurrentView] = useState("week");

	const [selectFilterOrders, setSelectFilterOrders] = useState(null);
	const [ButtonOT, setButtonOT] = useState(false);
	const [ButtonOS, setButtonOS] = useState(false);
	const [radioChecked, setRadioChecked] = useState(false);
	const [checkedValues, setCheckedValues] = useState([]);

	const [openFilter, setOpenFilter] = useState(false);

	const [startSelectedOverflow, setStartSelectedOverflow] = useState(null)
	const [endSelectedOverflow, setEndSelectedOverflow] = useState(null)


	const tooltipText = (
		<section className={style.container_tooltip}>
			<span className={style.tittle}>Información de ayuda</span>
			<p className={style.legend}>Leyenda Calendario</p>
			<section className={style.container_legend}>

				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span className={style.container_div} style={{ marginBottom: 12 }}>
						<ColorCircle color={"transparent"} borderCircle="1px solid #B5B5B5" marginRight="8px" />
						<p className={style.title_order}>Ordenes de trabajo</p>
					</span>

					{tooltipData.wo_types?.map((item, i) => (
						<span className={style.container_div} key={i}>
							<ColorCircle color={"transparent"} marginRight="8px" borderCircle={`1px solid ${item?.color}`} />
							<p className={style.title_order}>{item?.name}</p>
						</span>
					))}

				</div>

				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span className={style.container_div} style={{ marginBottom: 12 }}>
						<ColorCircle color={"#B5B5B5"} marginRight="8px" />
						<p className={style.title_order}>Ordenes de servicio</p>
					</span>

					{tooltipData.os_types?.map((item, i) => (
						<span className={style.container_div} key={i}>
							<ColorCircle color={item?.color} marginRight="8px" />
							<p className={style.title_order}>{item?.name}</p>
						</span>
					))}
				</div>

			</section>

		</section>

	)

	const events = OScalendarWeek?.map((item) => {
		return {
			id: item?.order_sequential_id,
			id_unico: item?.order_unique_id,
			title: item?.title,
			start: dayjs(item?.start, "DD-MM-YYYY HH:mm").toDate(),
			end: dayjs(item?.end, "DD-MM-YYYY HH:mm").toDate(),
			color: item?.color,
			type: item?.type,
		}
	})

	const eventStyleGetter = (event) => {
		let style = {}

		if (event.type === "OS") {
			style = {
				style: {
					backgroundColor: event.color,
					color: 'white',
					cursor: 'pointer',
				},
				className: event.id === OScalendarWeek.at(-1).order_sequential_id ? 'rbc-event-custom' : 'rbc-event-default'
			}
		} else {
			style = {
				style: {
					backgroundColor: "transparent",
					color: event.color,
					cursor: 'pointer',
					border: `2px solid ${event.color}`,
				},
			}
		}

		return style
	}


	const handleSelectEvent = (event) => {
		if (event.type === "WO") {
			getOneOT(event.id_unico)
		}

		if (event.type === "OS") {
			getOneOS(event.id_unico)
		}
	}

	const getOneOT = async (id) => {
		const dataFilter = {
			filters: `[[\"ID\",\"equal\", \"${id}\"]]`,
			operator: storedOperatorID,
		}

		const res = await myFetch("api/v1/wo/datatables/", dataFilter)

		dispatch(selectTab({ selectedTab: 0 }))

		history.push("/workOrdersDetails", { params: res.data[0] })
	}

	const getOneOS = async (id) => {
		// variable de data enviada para el filtro de la peticion
		const dataFilter = {
			filters: `[[\"ID\",\"equal\", \"${id}\"]]`,
			operator: storedOperatorID,
		}
		const res = await myFetch("api/v1/os/datatables/", dataFilter)

		// cundo llegue al detalle, comienza en el primer Tab
		dispatch(selectTab({ selectedTab: 0 }))

		// funcion de react-router-dom para direccionar a vista general de Os
		history.push("/serviceOrdersDetails", { params: res.data[0] })
	}

	const handleNavigate = (date, view) => {
		const formatedDate = dayjs(date).format("DD-MM-YYYY")
		const headerDate = dayjs(formatedDate, "DD-MM-YYYY");

		const month = headerDate.format('MM');
		const day = headerDate.format('DD');
		const year = headerDate.format('YYYY');

		setDaySelected(day)
		setMonthSelected(month)
		setYearSelected(year)
	}

	const eventContent = ({ event }) => (
		<div>
			<label className="id_orden_services">#{event?.id} <b>{event.title}</b> </label><br />
			<label className="orden_service_name">{dayjs(event.start).format('h:mm a')} - {dayjs(event.end).format('h:mm a')}</label> <br />
		</div>
	);

	const onTechnicianChange = (value) => setTechnicianID(value);

	const onViewChange = (view) => {
		setCurrentView(view)
	}

	useEffect(() => {
		getTypeOrder().then(res => setTooltipData(res))

	}, [])

	useEffect(() => {

		const options = {
			monthSelected,
			yearSelected,
			technician: technicianID || undefined,
			filter: selectFilterOrders || undefined,
			types: checkedValues.length > 0 ? checkedValues : undefined
		}

		const allHandlres = {
			month: () => getOScalendarByWeek(options).then(res => setOScalendarWeek(res)),
			week: () => getOScalendarByWeek({ ...options, day: firstDayWeekCalendar, week: true }).then(res => setOScalendarWeek(res)),
			day: () => getOScalendarByWeek({ ...options, day: parseInt(daySelected) }).then(res => setOScalendarWeek(res))
		}

		if (monthSelected && yearSelected && allHandlres[currentView]) {
			allHandlres[currentView]()
		}

	}, [monthSelected, yearSelected, currentView, daySelected, firstDayWeekCalendar, technicianID, selectFilterOrders, checkedValues])


	useEffect(() => {
		loginOperadorID && getOs(loginOperadorID).then(res => setOsList(res))
		loginOperadorID && getOstype(loginOperadorID).then(res => setTypeOSList(res))
		loginOperadorID && getTechnician(loginOperadorID).then(res => setTechnicians(res))
		loginOperadorID && checkIfUserGetOraculo(loginOperadorID).then(res => setCheckIfOraculo(res))

		setLoadding(false)
	}, [loginOperadorID])

	useEffect(() => {
		checkIfOraculo && setShowSpecification(checkIfOraculo === 404 ? false : true)
	}, [checkIfOraculo])

	useEffect(() => {
		handleNavigate();
	}, [setDaySelected]);

	useEffect(() => {
		const date = new Date(); // La fecha actual
		let firstDay = new Date(date);
		let dayOfWeek = date.getDay();
		dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Ajustamos para que lunes sea 0
		firstDay.setDate(firstDay.getDate() - dayOfWeek);

		const firstDayWeek = firstDay.getDate()
		const month = firstDay.getMonth() + 1
		const year = firstDay.getFullYear()


		setfirstDayWeekCalendar(firstDayWeek)
		setMonthSelected(month)
		setYearSelected(year)
	}, []);


	return (
		<Panel>
			<PanelHeaderCalendarV2
				title={"Calendario de agendamiento"}
				subText={"Orden de servicio | Calendario"}
				onVisibleFormOS={() => setIsVisibleForm(true)}
				onVisibleFormOT={() => setVisibleFormOT(true)}
			>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement="bottomLeft"
					overlayInnerStyle={{
						minHeight: '100px',
						minWidth: '325px',

					}}
					overlayStyle={{
						maxWidth: 'none'
					}}
				>
					<i className="fa-solid fa-circle-info circle-tooltip"></i>
				</Tooltip>
			</PanelHeaderCalendarV2>

			<Container>
				<Calendar
					localizer={localizer}
					events={events}
					eventPropGetter={eventStyleGetter}
					startAccessor="start"
					endAccessor="end"
					style={{ height: "102vh" }}
					scrollToTime={new Date(0, 0, 0, 7, 0, 0)}
					views={["day", "week", "month"]}
					defaultView={currentView}
					culture='es'
					popup
					toolbar={true}
					onView={onViewChange}
					onNavigate={(date, view) => {

						if (currentView === 'week') {
							let firstDay = new Date(date);
							let dayOfWeek = date.getDay();
							dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Ajustamos para que lunes sea 0
							firstDay.setDate(firstDay.getDate() - dayOfWeek);

							const firstDayWeek = firstDay.getDate()
							const month = firstDay.getMonth() + 1
							const year = firstDay.getFullYear()

							setMonthSelected(month)
							setYearSelected(year)
							setfirstDayWeekCalendar(firstDayWeek)


						} else {
							handleNavigate(date, view);
						}

					}}
					onSelectEvent={handleSelectEvent}
					tooltipAccessor={event => `\nID: ${event.id}\n${event.title}`}
					components={{
						event: eventContent, // se renderiza el componente de estilos para el evento
						toolbar: (props) =>
							<CustomHeader
								{...props}
								technicians={technicians}
								onTechnicianChange={onTechnicianChange}
								technicianID={technicianID}
								dataOrders={tooltipData}
								setSelectFilterOrders={setSelectFilterOrders}
								setButtonOT={setButtonOT}
								ButtonOT={ButtonOT}
								setButtonOS={setButtonOS}
								ButtonOS={ButtonOS}
								setRadioChecked={setRadioChecked}
								radioChecked={radioChecked}
								setCheckedValues={setCheckedValues}
								checkedValues={checkedValues}
								setOpenFilter={setOpenFilter}
								openFilter={openFilter}

							/>, // se renderiza el componente de estilos para el header
					}}
					formats={{
						timeGutterFormat: "h:mm a",
						dayHeaderFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, "dddd D [de] MMMM", culture)),
						dayRangeHeaderFormat: ({ start, end }) => (
							`${stringCapitalize(dayjs(start).format('MMM D'))} - ${stringCapitalize(dayjs(end).format('MMM D'))}`
						),

						dayFormat: (date, culture, localizer) =>
							stringCapitalize(localizer.format(date, 'ddd DD/MM', culture)),

						monthHeaderFormat: (date) =>
							stringCapitalize(dayjs(date).format("MMMM YYYY")),

						eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
							localizer.format(start, "h:mm a", culture) +
							" - " +
							localizer.format(end, "h:mm a", culture),
					}}
				/>

				{
					isVisibleForm && (

						<FormularioOS
							visible={isVisibleForm}
							setVisible={setIsVisibleForm}
							setLoadding={setLoadding}

							setFiltreValue={setSelectdFiltre}
							filtreValue={selectdFiltre}

							typeOSList={typeOSList}
							technicians={technicians}
							loginOperadorID={loginOperadorID}
							showSpecification={showSpecification}

							setVisibleFormOverflow={setVisibleFormOverflow}
							visibleFormOverflow={visibleFormOverflow}

							setNameTechnican={setNameTechnican}
							setRandomTechnican={setRandomTechnican}
							setDateSelectedForm={setDateSelectedForm}
							setCategorySelected={setCategorySelected}
							setTechnicianIDForm={setTechnicianIDForm}

							selectedHoursOverflow={selectedHoursOverflow}
							disponibilidadIDOverflow={disponibilidadIDOverflow}
							technicianOverflow={technicianOverflow}

							setSelectedHoursOverflow={setSelectedHoursOverflow}
							setDisponibilidadIDOverflow={setDisponibilidadIDOverflow}
							setTechnicianOverflow={setTechnicianOverflow}

							startSelectedOverflow={startSelectedOverflow}
							endSelectedOverflow={endSelectedOverflow}
						/>
					)
				}

				{visibleFormOverflow && (
					<CustomModal onClick={() => setVisibleFormOT(false)}>
						<FormOverflow
							setVisibleFormOverflow={setVisibleFormOverflow}
							nameTechnican={nameTechnican}
							randomTechnican={randomTechnican}
							dateSelectedForm={dateSelectedForm}
							categorySelected={categorySelected}
							technicianIDForm={technicianIDForm}

							setSelectedHoursOverflow={setSelectedHoursOverflow}
							setDisponibilidadIDOverflow={setDisponibilidadIDOverflow}
							setTechnicianOverflow={setTechnicianOverflow}

							setStartSelectedOverflow={setStartSelectedOverflow}
							startSelectedOverflow={startSelectedOverflow}
							setEndSelectedOverflow={setEndSelectedOverflow}
							endSelectedOverflow={endSelectedOverflow}
						/>
					</CustomModal>
				)}

				{visibleFormOT &&
					<FormCreatedOT
						setVisibleFormOT={setVisibleFormOT}
						loginOperadorID={loginOperadorID}
					/>
				}

			</Container>

		</Panel>
	)
}

export default ServiceOrdersCalendar

const Container = styled.div`
width: 100%;

.rbc-event-custom{
	border: 2px solid #000 ;
}

.rbc-event-default{
	border: 1px solid white;
}

// -----React-Big-Calendar------
.rbc-header {
    border: none !important;
}
.rbc-time-header-gutter {
	width: 75.9375px;
    min-width: 75.9375px;
    max-width: 75.9375px;
	background: #F7FAFC;
}


// ------VISTA DE MES/OFF------
.rbc-off-range-bg {
    background: white;
}
.rbc-day-bg {
    height: 135px;
}
.rbc-button-link {
  color: #8898AA;
	font-size: ${smallSizes.fontSize.fontSize15};
	margin-top: 8px;
	margin-right: 9px;
}
.rbc-row-segment .rbc-event-content{
	width: 100%;
}

// ------VISTA DE MES/OFF------

.rbc-current-time-indicator {
	border: 1px solid #11CDEF;
	// position: relative;
}
.rbc-event {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	// border-radius: 4px !important;
	height: 27px;
}

.rbc-current-time-indicator::after {
	position: absolute;
	content: "";
	width: 8px;
	height: 8px;
	border-radius: 100%;
	left: -2px;
	bottom: -4px;
	background: #11CDEF;
	margin: 0;
	padding: 0;
	z-index: 10;
}
.rbc-day-slot .rbc-event-label {
	display: none;
    flex: none;
    padding-right: 0;
    width: 100%;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-day-slot .rbc-event-content {
	width: 100%;
	height: 100%;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none;
	word-wrap: break-word;
	border-radius: 4px !important;
	font-size: ${smallSizes.fontSize.fontSize12};
	text-align: left;
	padding-right: 0;
	cursor: pointer;
	margin-top: 11px;

}

.rbc-day-slot .rbc-event-content label{
	cursor: pointer;
}

.rbc-day-slot .rbc-event-content .id_orden_services{
	// margin-bottom: 1px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	margin-bottom: 6px;
	
}

.rbc-day-slot .rbc-event-content .orden_service_name{
	margin-bottom: 1px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
}

.rbc-time-slot .rbc-label{
	font-size: ${smallSizes.fontSize.fontSize12};
	padding: 0 12px;
	color: #8898AA;
}

.rbc-time-column{
	min-height: 148%;
}

.rbc-month-header .rbc-header {
  font-size: ${smallSizes.fontSize.fontSize15};
  color: #8898AA;
}


@media screen and (min-width: ${devices.smallDesk}){

	.rbc-button-link {
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	.rbc-month-header .rbc-header {
		font-size: ${smallSizes.fontSize.fontSize15};
		color: #8898AA;
	}

}

@media screen and (min-width: ${devices.mediumDesk}){

	.rbc-button-link {
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	.rbc-month-header .rbc-header {
		font-size: ${mediumSizes.fontSize.fontSize15};
		color: #8898AA;
	}

}

@media screen and (min-width: ${devices.largeDesk}){

	.rbc-button-link {
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	.rbc-month-header .rbc-header {
		font-size: ${largeSizes.fontSize.fontSize15};
		color: #8898AA;
	}
}
`;