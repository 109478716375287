import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../../components/modal/CustomModal'
import Form from '../../../../../../components/form/index.jsx'
import MainTitle from '../../../../../../components/text/drawer/mainTitle/index.jsx'
import SubtitleBlue from '../../../../../../components/text/drawer/subtitleBlue/index.jsx'
import { Avatar, Checkbox, Table, notification } from 'antd'

import style from './SummaryFormCopy.module.css'
import DatatableHeaderText from '../../../../../../components/text/datatable/datatableHeaderText/index.jsx'
import ColorCircle from '../../../../../../components/colorCircle/index.jsx'
import BottomSection from '../../../../../../components/form/bottomSection/index.jsx'
import dayjs from 'dayjs'
import { myFetch, myFetchGet } from '../../../../../../services/services.js'

const SummaryFormCopy = ({
	setViewSummary,
	body,
	typeCountryString,
	typeClient,
	viewUpload,
	imageBase64,
	selectedDirection
}) => {

	const [initialName, setInitialName] = useState("")
	const [serviceDate, setServiceDate] = useState("")
	const [serviceHour, setServiceHour] = useState("")
	const [typeSelected, setTypeSelected] = useState("")
	const [categorySelected, setCategorySelected] = useState("")
	const [initialTechnician, setInitialTechnician] = useState("")
	const [technicianSelected, setTechnicianSelected] = useState("")
	const [auxiliariesSelected, setAuxiliariesSelected] = useState([])
	const [dataTable, setDataTable] = useState([])
	const [nameClient, setNameClient] = useState("")

	const [getListTechnician, setGetListTechnician] = useState(null)
	const [getListAuxiliaries, setGetListAuxiliaries] = useState(null)
	const [getListType, setGetListType] = useState([])
	const [getListCategory, setGetListCategory] = useState([])

	const [viewDataTable, setViewDataTable] = useState(false)
	const [allowScheduling, setAllowScheduling] = useState(false)

	const columns = [
		{
			title: <DatatableHeaderText value='ID' />,
			dataIndex: "ID",
			key: "ID",
		},
		{
			title: <DatatableHeaderText value='Tipo de orden' />,
			dataIndex: "typeOrder",
			key: "Tipo de orden",
			render: (value) =>
				<span style={{ display: "flex", alignItems: "baseline" }}>
					<ColorCircle color={value.color} />
					{value.name}
				</span>,
		},
		{
			title: <DatatableHeaderText value='Agendamiento' />,
			dataIndex: "Agendamiento",
			key: "Agendamiento",
		}

	]

	const onInformation = async () => {
		try {
			const bodyCreate = {
				"id_technician": body.technician,
				"ids_auxiliaries": body.auxiliaries,
				"date_time_initial": body.date_time_initial,
				"date_time_final": body.date_time_final
			}
			const response = await myFetch(`api/v1/wo/get_orders_that_clash/`, bodyCreate)
			if (response === 'No existen ordenes que choquen con esta.') {
				setViewDataTable(false)
				return
			} else {
				setViewDataTable(true)
				dataDatatable(response.data)
			}

		} catch (error) { }
	}

	const dataDatatable = (data) => {
		const formattedData = data.map(item => ({
			key: item.id,
			ID: item.id,
			typeOrder: item.type_wo,
			Agendamiento: item.scheduling
		}))

		setDataTable(formattedData)
	}

	const getTechnician = async (categoryId) => {
		try {
			const res = await myFetchGet(`api/v1/schedule/main_category_match_ot/?category=${categoryId}`);
			const listSelect = res.data.map((item) => {
				return { value: item.id, label: item.name }
			})
			setGetListTechnician(listSelect)
		} catch (error) { }
	}

	const getAuxiliaries = async (categoryId) => {
		try {
			const response = await myFetchGet(`api/v1/schedule/auxiliaries_category_match_ot/?category=${categoryId}`);
			const listSelect = response.data.map((item) => {
				return { value: item.id, label: item.name }
			})
			setGetListAuxiliaries(listSelect)
		} catch (error) { }
	}

	const getOrderType = async () => {
		try {
			const res = await myFetchGet('api/v1/type_ot/all_types_orders/')
			const listSelect = res.data.map((item) => {
				return { value: item.id, label: item.name }
			})
			setGetListType(listSelect)
		} catch (error) { }
	}

	const getCategory = async (id) => {
		try {
			const response = await myFetchGet(`api/v1/category_ot/all_categories_orders/?id=${id}`)
			const listSelect = response.data.map((item) => {
				return { value: item.id, label: item.name }
			})
			setGetListCategory(listSelect)
		} catch (error) { }
	}

	const createOt = async (body) => {
		const images = {
			images: imageBase64
		}

		try {
			const response = await myFetch(`api/v1/wo/`, body)
			if (!viewUpload && response.ID) {
				notification.success({
					className: "notification",
					message: "Nueva orden de trabajo creada!",
					placement: "bottomLeft",
					duration: 2.5,
					onClose: () => window.location.reload()
				});
			}

			if (viewUpload && response.ID) {
				images["wo"] = response.ID
				await myFetch(`api/v1/wo_pic/`, images)
				notification.success({
					className: "notification",
					message: "Nueva orden de trabajo creada!",
					placement: "bottomLeft",
					duration: 2.5,
					onClose: () => window.location.reload()
				});
			}

		} catch (error) { }
	}

	useEffect(() => {
		if (body.business_name) {
			const separationName = body.business_name.split(" ")
			if (separationName.length > 1) {
				setInitialName(separationName[0].charAt(0) + separationName[1].charAt(0))
				setNameClient(body.business_name)
			} else if (separationName.length === 1) {
				setInitialName(separationName[0].charAt(0))
				setNameClient(body.business_name)
			}
		} else if (body.first_name && body.last_name) {
			const firstName = body.first_name.charAt(0).toUpperCase()
			const lastName = body.last_name.charAt(0).toUpperCase()
			setInitialName(firstName + lastName)
			setNameClient(`${body.first_name} ${body.last_name}`)

		}

	}, [])

	useEffect(() => {
		const date1 = dayjs(body.date_time_initial)
		const date2 = dayjs(body.date_time_final)

		const dateFormat = date1.format("DD [de] MMMM YYYY")

		const hour1 = date1.format("hh:mm A")
		const hour2 = date2.format("hh:mm A")

		setServiceDate(dateFormat)
		setServiceHour(`${hour1} - ${hour2}`)
	}, [])

	useEffect(() => {
		if (getListType && getListCategory) {
			const typeOrder = getListType.find((item) => item.value === body.type_ot)
			const category = getListCategory.find((item) => item.value === body.category_ot)
			setTypeSelected(typeOrder)
			setCategorySelected(category)
		}

	}, [getListType, getListCategory])

	useEffect(() => {
		if (getListTechnician && getListAuxiliaries) {
			const technician = getListTechnician.find((item) => item.value === body.technician)
			const auxiliaries = getListAuxiliaries.filter(obj => body.auxiliaries.includes(obj.value))
			const separationName = technician.label.split(" ")
			setInitialTechnician(separationName[0].charAt(0))

			setTechnicianSelected(technician)
			setAuxiliariesSelected(auxiliaries)
		}

	}, [getListTechnician, getListAuxiliaries])

	useEffect(() => {
		onInformation()
		getTechnician(body.category_ot)
		getAuxiliaries(body.category_ot)
		getOrderType()
		getCategory(body.type_ot)
	}, [])

	return (
		<CustomModal onClick={() => setViewSummary(false)}>
			<Form widthForm='38%'>
				<MainTitle value='Resumen de solicitud' onClick={() => setViewSummary(false)} />
				<SubtitleBlue value='Información del cliente' />

				<section className={style.container_client}>
					<div className={style.group_left}>
						<Avatar size={50} style={{ backgroundColor: "red", marginRight: 13 }} >{initialName.toUpperCase()}</Avatar>

						<div className={style.column}>
							<p className={style.text_client_name}>{nameClient}</p>
							<p className={style.text_client_document}>{`${typeCountryString} ${body.number_document}`} </p>
						</div>
					</div>


					<div className={`${style.column} ${style.column_p_r}`}>
						<p className={style.text_client_type}>{typeClient === "1" ? "Natural" : "Jurídico"}</p>
						<p className={style.text_client_service}>{body.services_Id}</p>
					</div>
				</section>

				<SubtitleBlue value='Orden de trabajo' />

				<section className={style.container_order}>
					<div className={style.date}>
						<span className={style.column}>
							<p className={style.text_date_title}>Fecha de atendimiento</p>
							<p className={style.text_date}>{serviceDate}</p>
						</span>
						<span className={style.column}>
							<p className={style.text_date_title}>Horario</p>
							<p className={style.text_date}>{serviceHour}</p>
						</span>
					</div>

					<div className={style.container_type_category}>
						<span className={style.type_order}>{typeSelected?.label}</span>
						<span className={style.column}>
							<p className={style.text_category_title}>Categoría</p>
							<p className={style.text_category}>{categorySelected?.label}</p>
						</span>
					</div>

					<div className={style.column}>
						<p className={style.title_description}>Descripción del trabajo</p>
						<p className={style.content_description}>{body.description}</p>
					</div>
				</section>

				<SubtitleBlue value='Atendimiento orden de servicio' />

				<section >
					<div className={style.container_address}>
						<Avatar className={style.avatar_address} >
							<i className="fa-solid fa-location-dot"></i>
						</Avatar>

						<div className={style.column}>
							<p className={style.text_address}>Dirección</p>
							<p className={style.text_address_content}>{selectedDirection}</p>
						</div>
					</div>
				</section>

				<section className={style.container_user}>
					<Avatar className={style.avatar_user} >
						{initialTechnician}
					</Avatar>

					<div className={style.description_user}>
						<p className={`${style.user_name} ${style.bold}`}>{technicianSelected.label}</p>
						<p className={style.user_text}>Técnico Principal</p>
						<p className={`${style.user_text} ${style.bold}`}>{`Auxiliar +${auxiliariesSelected.length}`}</p>
						{auxiliariesSelected.map((item) => {

							const nameINitial = item.label.split(" ")[0][0]

							return (
								<span key={item.value} className={style.container_assistant}>
									<Avatar className={style.avatar_assistant} >{nameINitial}</Avatar>
									<p className={style.text_font12_5A607F}>{item.label}</p>
								</span>
							)
						})}

						{body.comments && (
							<>
								<p className={`${style.text_font12_5A607F} ${style.bold}`}>Comentario adicional</p>
								<p className={style.text_font12_959595}>{body.comments}</p>
							</>
						)}

					</div>

					<p className={`${style.text_font12_2B80FF} ${style.bold}`}>
						{body.support_supervisor === false ? "Sin apoyo de supervisor" : "Apoyo de supervisor"}
					</p>
				</section>

				{viewDataTable && (
					<>
						<SubtitleBlue value='Información importante' />

						<p className={`${style.text_font12_959595} ${style.mb_12}`} >La orden de trabajo que desea crearse chocan con las siguientes ordenes:</p>

						<Table columns={columns} dataSource={dataTable} pagination={false} />
						<Checkbox className={style.checkbox} onChange={(e) => setAllowScheduling(e.target.checked)}>Permitir agendar orden de trabajo</Checkbox>
					</>
				)}



				<BottomSection
					onCancel={() => setViewSummary(false)}
					onAcept={() => createOt(body)}
					disable={viewDataTable && !allowScheduling ? true : false}
				/>
			</Form>
		</CustomModal>
	)
}

export default SummaryFormCopy