import React from 'react'
import styled from 'styled-components'

const CustomModal = ({ children, onClick = () => { } }) => {
	return (
		<Modal>
			<span className="left_modal_content" onClick={onClick}></span>
			{children}
		</Modal>
	)
}

export default CustomModal

const Modal = styled.div`
	width: 100vw;
	min-height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	display: flex;

    animation: fadeIn 500ms; 

    @keyframes fadeIn { 0% { opacity: 0 } 100% { opacity: 1}}

    .left_modal_content{
				width: 100%;
				min-height: 100vh;
        background: hsla(0, 0%, 0%, 0.5);
    }
`;