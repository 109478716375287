import React from 'react'

import style from './tableDetail.module.css'

const TableDetail = ({ data, order_type, ordenName }) => {
	return (
		<table>
			<thead className={style.header_table}>
				<tr>
					<th className={style.th_one}>{order_type}</th>
					<th className={style.th_two}>Categoría</th>
				</tr>
			</thead>
			<tbody className={style.body_table}>

				<tr>
					<td className={style.colum_category} >{ordenName}</td>
					<td>
						{data.map(item => (
							<div key={item.id} className={style.span_table}>{item.name}</div>
						))}
					</td>
				</tr>
			</tbody>
		</table>
	)
}

export default TableDetail