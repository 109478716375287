/* eslint-disable */
import React, { useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import OverallDetailsScreen from "./components/overallDetailsScreen/OverallDetailsScreen";
import HistoryPanel from "./components/HistoryPanel";
import { useLocation, useHistory } from "react-router-dom";
import { TabsPanel } from "../../components/tabs/Tabs";
import moment from "moment";
import styled from "styled-components";
import { Tooltip } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { selectTab } from "../../store/tab/tabSlice";

export default function TypesServiceOrdersDetails() {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const selectedTab = useSelector((state) => state?.tabs?.selectedTab);

	const handleTabChange = (index) => {
		dispatch(
			selectTab({
				selectedTab: index,
				showTabs: true,
			})
		)
	}


	const tabs = [
		{ name: "General", component: <OverallDetailsScreen data={location?.state?.params} /> },
		{ name: "Histórico", component: <HistoryPanel data={location?.state?.params} /> },
	];

	const { created } = location?.state?.params;
	const creacion = moment(created).format("LL");

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Ficha con datos generales asociados al tipo de orden de servicio</span>
		</ContainerTooltip>
	)

	return (
		<Panel>
			<PanelHeader noButton={true}>
				<span style={{ color: "#001737", fontWeight: "bold", fontSize: "24px", minWidth: "303px" }}>Tipos de órdenes de servicio</span>
				<Tooltip
					title={tooltipText}
					color='white'
					arrow={false}
					placement="bottom"
					overlayInnerStyle={{
						height: "95px",
						maxHeight: '100px'
					}}
				>
					<i className="fa-solid fa-circle-info" style={{ margin: "0 10px", fontSize: "12px", color: "#00388B" }}></i>
				</Tooltip>
				<span style={{ color: "#001737", fontWeight: "400", fontSize: "12px", minWidth: "285px" }}>

					<span>Configuración</span>
					<span style={{ cursor: "pointer", fontWeight: "bold", marginLeft: "4px" }} onClick={() => history.push("/TypesOS")}>| Tipos de órdenes de servicio | </span>
					<span>Detalle</span>
				</span>

				<span style={{ color: "#001737", fontWeight: "400", fontSize: "12px", width: "100%", textAlign: "right" }}>Fecha de creación {creacion} </span>
			</PanelHeader>
			<TabsPanel selectedTabClassName="is-selected">
				<span className="tablist_type_order">
					{tabs?.map((tab, index) => (
						<span key={index} className={`Tab font-size-tab ${selectedTab === index ? "is-selected" : ""}`} onClick={() => handleTabChange(index)}>{tab?.name}</span>
					))}
				</span>
				{tabs[selectedTab]?.component}
			</TabsPanel>
		</Panel>
	);
}

const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 190px;
	}
`;