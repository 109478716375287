/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Login from './pages/login.jsx';
import Layout from './layout/index.jsx';
import moment from 'moment';

import 'moment/locale/es';
import { useSelector } from 'react-redux';
moment.locale('es');
import { useHistory, useLocation } from "react-router";

const App = () => {

	const [checkingToken, setCheckingToken] = useState(true);

	const { token } = useSelector((state) => state.authState)
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {

		if (token && location.pathname === '/') {

			history.replace('/dashboard');
			setCheckingToken(false);
		} else {
			setCheckingToken(false);
		}

	}, [token])

	return (
		<>
			{checkingToken && null}

			{!checkingToken && (
				token ? <Layout /> : <Login />
			)}

		</>

	)
}

export default App 