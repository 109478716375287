/* eslint-disable */
import React, { useState, useEffect } from "react";
import { availabilityDataTable, availability_structColumns, oraculoAvality } from "../../../api/available_hours.js";
import Br from '../../../components/br/Br.jsx'
import { Table } from "antd";
import styled from "styled-components";
import AvailableCalendar from "./availableCalendar/AvailableCalendar.jsx";
import { useSelector } from "react-redux";
import { permissionCheck } from "../../../helpers/helpers.js";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../../helpers/sizes.js";
import EditAvailable from "./editAvailable/EditAvailable.jsx";

export default function AvailabilityPanel({ data }) {
	const array = useSelector((state) => state?.authState?.permissions)
	const dataRedux = useSelector((state) => state.authState);

	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [ROWS, setRows] = useState([]);
	const [COLUMNS, setColumns] = useState([]);
	const [directions, setDirections] = useState(null)

	const [dataColumn, setDataColumn] = useState([])
	const [visibleEditAvailable, setVisibleEditAvailable] = useState(false)

	const [recordTable, setRecordTable] = useState({})
	const [rolePermissions, setRolePermissions] = useState(false);
	const [avalityOraculoOperator, setAvalityOraculoOperator] = useState(false);

	const technicianID = data?.technician?.ID

	const formClose = () => setIsVisibleForm(!isVisibleForm)

	const editAvailable = (record) => {
		setVisibleEditAvailable(true);
		setRecordTable(record)
	}

	useEffect(() => {
		technicianID && availabilityDataTable(technicianID).then(res => setRows(res))
		technicianID && availability_structColumns().then(res => setDataColumn(res))
		oraculoAvality(dataRedux.operator_detail.ID).then(res => {
			if (res !== 404) setAvalityOraculoOperator(true)
			else setAvalityOraculoOperator(false)
		})
	}, []);

	useEffect(() => {
		const data = dataColumn.map((item) =>
			item.dataIndex === "ID" ?
				{
					...item, onCell: (record) => {
						if (rolePermissions) {
							return {
								onClick: () => {
									editAvailable(record)
								},
								style: { cursor: "pointer" },
								className: "hover-table-class"

							}
						}

					}
				}
				: item
		)
		setColumns(data)
	}, [dataColumn])

	useEffect(() => {
		if (dataRedux.rol === 'supervisor_isp' || dataRedux.rol === 'admin_isp' || dataRedux.rol === 'admin') {
			setRolePermissions(true);
		} else {
			setRolePermissions(false);
		}
	}, [dataRedux])

	return (
		<Container>
			{isVisibleForm === true && visibleEditAvailable === false
				?
				<AvailableCalendar
					close={formClose}
					technicianID={technicianID}
					avalityOraculoOperator={avalityOraculoOperator}
				/>
				: isVisibleForm === false && visibleEditAvailable === true
					?
					<EditAvailable
						recordTable={recordTable}
						setVisibleEditAvailable={setVisibleEditAvailable}
						technicianID={technicianID}
						avalityOraculoOperator={avalityOraculoOperator}
					/>
					:
					(
						<span>
							<span className="header__content">
								<label className="label_header_text">Listado de registros de disponibilidad del técnico.</label>

								{
									rolPermission("add_schedule") === true ? (
										<button className="button" onClick={() => setIsVisibleForm(!isVisibleForm)}>
											<i className="fa-solid fa-plus"></i>
											<span className="export_text">Agregar disponibilidad</span>
										</button>
									) : null
								}
							</span>
							<Br />

							<Table
								columns={COLUMNS}
								dataSource={ROWS}
								pagination={false}
							/>
						</span>
					)

			}
		</Container>
	)
}

const Container = styled.div`
width: 100%;

.row{
	display: flex;
	align-items: center;
}

.space-between{
	display: flex;
	justify-content: space-between;
}
.header__content{
    display: flex;
		align-items: center;
    justify-content: space-between;
    padding-left: 43px;
    padding-right: 69px;
		padding-top: 43.57px;
}

.button{
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	background: #DDE4EB;
	color: #131523;
	border: 1px solid #0060FF;
	border-radius: 3px;
	font-weight: 400;
	cursor: pointer;
	padding: 0 10px;

		:hover{
			background: #0060FF;
			color: white;
		}
  }

  .pdf_icon{
    font-size: ${smallSizes.fontSize.fontSize12};
  }

  .export_text{
    font-size: ${smallSizes.fontSize.fontSize12};
    margin-left: 5px;
  }

	.label_header_text{
		font-size: ${smallSizes.fontSize.fontSize14};
		color: #5A607F;
	}

	@media screen and (min-width: ${devices.smallDesk}){
		.label_header_text{
			font-size: ${smallSizes.fontSize.fontSize14};
		}

		.pdf_icon{
			font-size: ${smallSizes.fontSize.fontSize12};
		}

		.export_text{
			font-size: ${smallSizes.fontSize.fontSize12};;
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.label_header_text{
			font-size: ${mediumSizes.fontSize.fontSize14};
		}

		.pdf_icon{
			font-size: ${mediumSizes.fontSize.fontSize12};
		}

		.export_text{
			font-size: ${mediumSizes.fontSize.fontSize12};;
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.label_header_text{
			font-size: ${largeSizes.fontSize.fontSize14};
		}

		.pdf_icon{
			font-size: ${largeSizes.fontSize.fontSize12};
		}

		.export_text{
			font-size: ${largeSizes.fontSize.fontSize12};;
		}
	}
`

