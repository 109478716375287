/* eslint-disable */
import React, { useState, useEffect } from 'react'

import Form from '../../../../components/form/index.jsx';
import InputSearch from './components/InputSearch/InputSearch.jsx';
import ManualPlanSearch from './components/manualPlanSearch/ManualPlanSearch.jsx';
import ViewPlanSelect from './components/viewPlan/ViewPlanSelect.jsx';
import Br from '../../../../components/br/Br.jsx';
import BlueResumeUI from '../../../../components/BlueResumeUI/BlueResumeUI.jsx';
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx';
import MainTitle from '../../../../components/text/drawer/mainTitle/index.jsx';
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx';
import BottomSection from '../../../../components/form/bottomSection/index.jsx';

import { getCategory } from '../../../../api/category.js';
import { getAvailability, getSegmentedHour } from '../../../../api/available_hours.js';
import { getID_type } from '../../../../api/technician.js';
import { postNewServiceOrder } from '../../../../api/calendar.js';

import color from '../../../../helpers/color.js';
import { checkEmptyKeys, emptyInputsCheck, validarCampos } from '../../../../helpers/helpers.js';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../helpers/sizes.js';
import useDisabledButton from '../../../../hooks/useDisabledButton.jsx';

import { myFetchGet } from '../../../../services/services.js';

import { Switch, DatePicker, Select, notification, Checkbox, Collapse } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ServiceOrderType from './components/serviceOrderType/ServiceOrderType.jsx';

import { RightOutlined } from '@ant-design/icons';

import style from './formularioOS.module.css';
import ServiceOrderAttention from './components/serviceOrderAttention/ServiceOrderAttention.jsx';
import Scheduling from './components/scheduling/Scheduling.jsx';
import SummaryForm from './components/summaryForm/SummaryForm.jsx';
import CustomModal from '../../../../components/modal/CustomModal.jsx';


export default function FormularioOS(
	{
		setVisible,
		filtreValue,
		setFiltreValue,
		typeOSList,
		loginOperadorID,
		showSpecification,
		setVisibleFormOverflow,
		visibleFormOverflow,
		setNameTechnican,
		setRandomTechnican,
		setDateSelectedForm,
		setCategorySelected,
		selectedHoursOverflow,
		disponibilidadIDOverflow,
		technicianOverflow,
		setTechnicianIDForm,
		setSelectedHoursOverflow,
		setDisponibilidadIDOverflow,
		setTechnicianOverflow,
		startSelectedOverflow,
		endSelectedOverflow,
	}) {
	const [idTypes, setIdTypes] = useState([])
	const [OStype, setOStype] = useState(null)

	const [categories, setCategoryList] = useState([])
	const [category, setCategory] = useState(null)

	const [showTechnician, setShowTechnician] = useState(false)

	// selected user or manual user
	const [titularUser, setTitularUser] = useState({})

	const [date, setDate] = useState(null)

	const [availableHours, setAvailableHours] = useState([])
	const [selectedHour, setSelectHour] = useState(null)

	const [disponibilidadID, setDisponibilidadID] = useState(null)

	const [technicianID, setTechnicianID] = useState(null)
	const [selectedTechnicianName, setSelectedTechnicianName] = useState("")

	const [tradename, setTradename] = useState("")

	// --- Manual register ---
	const [manualRegister, setManualRegister] = useState(false)
	const [name, setName] = useState(null)
	const [last_name, setLast_name] = useState(null)
	const [documentType, setDocumentType] = useState(null)

	const [documentNumber, setDocumentNumber] = useState(null)
	const [phone, setPhone] = useState(null)
	const [email, setEmail] = useState(null)
	const [idServices, setIdServices] = useState(null)
	const [address, setAddress] = useState('')

	const [selectUserSearch, setSelectUserSearch] = useState(false)
	const [availableTechnicians, setAvailableTechnicians] = useState([])
	const [selectedDates, setSelectedDates] = useState([]);
	const [selectedDirectionId, setSelectedDirectionId] = useState('');
	const [selectedTechnicials, setSelectedTechnicials] = useState(null);
	const [selectedDirectionName, setSelectedDirectionName] = useState('');

	const [dateSelected, setDateSelected] = useState(null);
	const [supervisorSupport, setSupervisorSupport] = useState(false);
	const [rolePermissions, setRolePermissions] = useState(false);

	const [durationCategory, setDurationCategory] = useState(null);

	const [viewComment, setViewComment] = useState(false)
	const [commentChange, setCommentChange] = useState(null)

	const [timeStart, setTimeStart] = useState(null)
	const [timeEnd, setTimeEnd] = useState(null)

	const [viewSummary, setViewSummary] = useState(false)
	const [bodyCreateSummary, setBodyCreateSummary] = useState(null)

	const [typeOption, setTypeOption] = useState(null)
	const [typeSelectedName, setTypeSelectedName] = useState(null)

	const [categoryOption, setCategoryOption] = useState(null)
	const [categorySelectedName, setCategorySelectedName] = useState(null)

	const [typeCountryString, setTypeCountryString] = useState(null)

	const [camposIncompletos, setCamposIncompletos] = useState(null)

	const [isValidName, setIsValidName] = useState(null)
	const [isValidLastName, setIsValidLastName] = useState(null)
	const [isValidEmail, setIsValidEmail] = useState(null)
	const [isValidPhone, setIsValidPhone] = useState(null)
	const [isValidDocument, setIsValidDocument] = useState(null)

	const [reserveCode, SetReserveCode] = useState(null)


	const dataRedux = useSelector((state) => state.authState)

	const onCloseModal = () => setVisible(false)

	// metodo para obtener el id del tecnico y la data de las fechas
	const onTechnicianChange = (value) => {

		setSelectedTechnicials(value);

		const selectedId = value;
		// filtro por para seleccionar las fechas del tecnico 
		const selectedData = availableTechnicians.find(item => item.id === selectedId);
		// cargar id
		setTechnicianID(selectedId);
		//  cargar fechas
		setSelectedDates(selectedData.dates);
		// carga Nombre de tecnico	
		setSelectedTechnicianName(selectedData.name);
		// datos enviados a form Overflow
		setNameTechnican(selectedData.name);
		setTechnicianIDForm(selectedId)
	}

	const onTypeOSChange = (value) => {
		setOStype(value);
		setCategory(null);

		const selected = typeOption.find(item => item.value === value);
		setTypeSelectedName(selected.label)
	}

	const onCategoryChange = (value) => {
		setCategory(value);
		setCategorySelected(value);

		const selected = categoryOption.find(item => item.value === value); {
			setCategorySelectedName(selected.label)
		}
	}

	const onDateChange = (dates, dateString) => {
		setDateSelected(dates);
		setDate(dateString);
		setDateSelectedForm(dateString);
	}

	const onHoursChange = (value) => {
		if (value === 'overflow') {

			setVisibleFormOverflow(true);
		} else {
			const toJSON = JSON.parse(value)

			setTimeStart(toJSON?.start_time)
			setTimeEnd(toJSON?.end_time)
			setSelectHour(toJSON?.hours)
			getSegmentedHour(toJSON?.disponibilidad, toJSON?.end_time, toJSON?.start_time, date)
				.then(res => {
					if (res?.status === 400) {
						notification.error({
							style: { fontWeight: 'bold' },
							message: res?.data.message,
							placement: 'bottomLeft',
							duration: 3,
						})
					}
					setDisponibilidadID(res?.id_disponibility_created)
					SetReserveCode(res?.reserve_code)
					console.log(res)
				})
		}
	}

	// peticion get para obtener los tecnicos
	const getTechnicials = async (manual) => {
		try {
			const responseTechnicials = await myFetchGet(`api/v1/schedule/category_match/?category=${category}&manual=${manual}`)
			if (responseTechnicials.data) setAvailableTechnicians(responseTechnicials.data);
		} catch (error) { }

	}

	console.log(titularUser)

	const BodyCreateOS = {
		"technician": technicianID || technicianOverflow?.id,
		"technician_name": selectedTechnicianName || technicianOverflow?.full_name,
		"status": 0,
		"ostype": OStype,
		"name_ostype": typeSelectedName,
		"category": category,
		"name_category": categorySelectedName,
		"operator": loginOperadorID,
		"disponibility_hours": disponibilidadID || disponibilidadIDOverflow,
		"supervisor_required": supervisorSupport,
		"date": date,
		"duration": durationCategory,
		"disponibily_code": reserveCode
	}

	const onViewSummary = () => {
		if (manualRegister) {
			BodyCreateOS['first_name'] = name;
			BodyCreateOS['last_name'] = last_name;
			BodyCreateOS['document_type'] = documentType;
			BodyCreateOS['document_type_name'] = typeCountryString;
			BodyCreateOS['document_number'] = documentNumber;
			BodyCreateOS['phone'] = phone;
			BodyCreateOS['email'] = email;
			BodyCreateOS['service_number'] = idServices;
			BodyCreateOS['direction'] = selectedDirectionId;
			BodyCreateOS['direction_text'] = selectedDirectionName;
			BodyCreateOS['direction_text_complemento'] = address;
			BodyCreateOS['operator_id'] = loginOperadorID;
			BodyCreateOS['tradename'] = tradename.tradename;
			BodyCreateOS['technology'] = tradename.technology;
		} else {
			BodyCreateOS['user_id'] = titularUser.user_id || titularUser.brujula_user_id;
		}

		if (viewComment) {
			BodyCreateOS['comentary'] = commentChange
		}

		const clavesVacias = checkEmptyKeys(BodyCreateOS);
		console.log(clavesVacias)
		setCamposIncompletos(clavesVacias)

		if (!validarCampos(BodyCreateOS) ||
			isValidName === false || isValidLastName === false ||
			isValidEmail === false || isValidPhone === false ||
			isValidDocument === false) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: "Por favor, completa todos los campos",
				placement: 'bottomLeft',
				duration: 3,
			})
		} else {
			setBodyCreateSummary(BodyCreateOS)
			setViewSummary(true)
		}


	}

	const onCommentChange = (value) => {
		setCommentChange(value.target.value)
	}

	// effect para obtener las fechas del tecnico y seleccion de tecnico aleatorio
	useEffect(() => {
		if (showTechnician === true) {
			setSelectedDates(availableTechnicians[0].dates)
			// cargar tecnico id
			setTechnicianID(availableTechnicians[0].id)

			// carga Nombre de tecnico	
			setSelectedTechnicianName(availableTechnicians[0].name);
		}
	}, [availableTechnicians])


	// effect para obtener los tecnicos sea forma manual o aleatoria
	useEffect(() => {
		if (showTechnician === false && category) {
			getTechnicials(0);
		} else if (showTechnician === true && category) {
			getTechnicials(1);
		}
	}, [category, showTechnician])


	useEffect(() => {
		const countryID = localStorage.getItem('countryID')

		OStype && getCategory(OStype, "os").then(res => {
			if (res.data) setCategoryList(res.data)
		})
		countryID && getID_type(countryID).then(res => setIdTypes(res))
	}, [OStype])

	// effect para validar disponibilidad
	useEffect(() => {
		if (date === '' || date === null) {
			setAvailableHours([])
			setSelectHour(null)
			setDisponibilidadID(null)
		}
		date && getAvailability(showTechnician, date, category, technicianID).then(res => setAvailableHours(res));
	}, [date, category, technicianID])

	// Valores Iniciales al cambiar tipo de os o categoria
	useEffect(() => {
		setSelectedTechnicials(null);
		setTechnicianID(null);
		setSelectedTechnicianName("");
		setSelectedDates([])
		setSelectHour(null)
		setDisponibilidadID(null)
		setDate(null)
		setDateSelected(null)
		// Reset valores de form overflow
		setSelectedHoursOverflow(null)
		setDisponibilidadIDOverflow(null)
		setTechnicianOverflow(null)
	}, [OStype, category])

	// logica de sobre escribir null a selectedHour y abrir overflow
	useEffect(() => {
		if (visibleFormOverflow === false) {
			setSelectHour(null)
			setTimeStart(null)
			setTimeEnd(null)
		}
	}, [visibleFormOverflow])

	useEffect(() => {
		if (showTechnician === false) {

			//  quitar la seleccion de fecha
			setSelectedDates([])
			setDate(null)
			setDateSelected(null)
			setDateSelectedForm(null)
			// quitar seleccion de horario
			setSelectHour(null)

			// Reset valores de form overflow
			setSelectedHoursOverflow(null)
			setDisponibilidadIDOverflow(null)
			setTechnicianOverflow(null)
		} else {
			// quitar seleccion de tecnico
			setTechnicianID(null);
			setSelectedTechnicianName("");
			setSelectedTechnicials(null);

			//  quitar la seleccion de fecha
			setDateSelectedForm(null)
			setDate(null)
			setDateSelected(null)

			// quitar seleccion de horario
			setSelectHour(null)

			// Reset valores de form overflow
			setSelectedHoursOverflow(null)
			setDisponibilidadIDOverflow(null)
			setTechnicianOverflow(null)
		}

	}, [showTechnician])

	useEffect(() => {
		if (dataRedux.rol !== 'client_service_isp') {
			setRolePermissions(true);
		} else {
			setRolePermissions(false);
		}
	}, [dataRedux])

	return (
		<>
			<CustomModal onClick={() => setVisible(false)}>
				<Form>
					<Container>
						<MainTitle
							value="Agregar orden de servicio"
							onClick={() => setVisible(false)}
						/>
						{!manualRegister &&
							<SubtitleBlue value="Datos personales" />
						}

						<InputSearch
							setFiltreValue={setFiltreValue}
							filtreValue={filtreValue}
							loginOperadorID={loginOperadorID}
							dataUser={setTitularUser}
							idTypes={idTypes}
							// -- manual register----
							setManualRegister={setManualRegister}
							setName={setName}
							setLast_name={setLast_name}
							setDocumentType={setDocumentType}
							setDocumentNumber={setDocumentNumber}
							setPhone={setPhone}
							setEmail={setEmail}
							setIdServices={setIdServices}
							setAddress={setAddress}
							camposIncompletos={camposIncompletos}
							setSelectUserSearch={setSelectUserSearch}
							category={category}

							setSelectedDirectionId={setSelectedDirectionId}
							setSelectedDirectionName={setSelectedDirectionName}
							setTypeCountryString={setTypeCountryString}
							typeCountryString={typeCountryString}

							setIsValidName={setIsValidName}
							isValidName={isValidName}
							setIsValidLastName={setIsValidLastName}
							isValidLastName={isValidLastName}
							setIsValidPhone={setIsValidPhone}
							isValidPhone={isValidPhone}
							setIsValidEmail={setIsValidEmail}
							isValidEmail={isValidEmail}
							setIsValidDocument={setIsValidDocument}
							isValidDocument={isValidDocument}
						/>

						<Br />

						{selectUserSearch &&
							< ViewPlanSelect
								data={titularUser}
								showSpecification={showSpecification}
							/>}


						<ContainerCollapse>
							<Collapse
								bordered={false}
								defaultActiveKey={['1', '2', '3']}
								expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} style={{ color: "#2B80FF" }} />}
								ghost
								items={[
									{
										key: '1',
										label: <p className={style.text_blue}>Tipo de orden de servicio</p>,
										children:
											<ServiceOrderType
												onTypeOSChange={onTypeOSChange}
												typeOSList={typeOSList}
												onCategoryChange={onCategoryChange}
												category={category}
												categories={categories}
												selectUserSearch={selectUserSearch}
												manualRegister={manualRegister}
												loginOperadorID={loginOperadorID}
												setTradename={setTradename}
												showSpecification={showSpecification}
												setDurationCategory={setDurationCategory}
												durationCategory={durationCategory}
												setTypeOption={setTypeOption}
												setCategoryOption={setCategoryOption}
												camposIncompletos={camposIncompletos}
											/>
									},
									{
										key: '2',
										label: <p className={style.text_blue}>Atendimiento de orden de servicio</p>,
										children:
											<ServiceOrderAttention
												setShowTechnician={setShowTechnician}
												showTechnician={showTechnician}
												setRandomTechnican={setRandomTechnican}
												setSupervisorSupport={setSupervisorSupport}
												onTechnicianChange={onTechnicianChange}
												selectedTechnicials={selectedTechnicials}
												availableTechnicians={availableTechnicians}
												setViewComment={setViewComment}
												viewComment={viewComment}
												onCommentChange={onCommentChange}
												camposIncompletos={camposIncompletos}
											/>
									},
									{
										key: '3',
										label: <p className={style.text_blue}>Agendamiento</p>,
										children:
											<Scheduling
												dateSelected={dateSelected}
												onDateChange={onDateChange}
												selectedHour={selectedHour}
												selectedHoursOverflow={selectedHoursOverflow}
												onHoursChange={onHoursChange}
												availableHours={availableHours}
												selectedDates={selectedDates}
												rolePermissions={rolePermissions}
												date={date}
												timeStart={timeStart}
												timeEnd={timeEnd}
												startSelectedOverflow={startSelectedOverflow}
												endSelectedOverflow={endSelectedOverflow}
												visibleFormOverflow={visibleFormOverflow}
												camposIncompletos={camposIncompletos}
											/>
									}
								]}
							/>
						</ContainerCollapse>

						<Br />

						<BottomSection
							onCancel={onCloseModal}
							onAcept={onViewSummary}
							textButtonAcept='Continuar'
						// isButtonDisabled={isButtonDisabled}
						/>
					</Container >
				</Form>
			</CustomModal>

			{viewSummary &&
				<SummaryForm
					setViewSummary={setViewSummary}
					bodyCreateSummary={bodyCreateSummary}
					titularUser={titularUser}
					showSpecification={showSpecification}
					selectUserSearch={selectUserSearch}
					manualRegister={manualRegister}
				/>
			}
		</>
	)
}


const Container = styled.div`

  .row{
    margin: 0 !important;
    align-items: center;
    margin-top: 5px;
  }

  .row_center{
    justify-content: center;
  }

  .row_end{
    justify-content: flex-end;
  }

  .space-between{
    justify-content: space-between;
    margin: 0 !important;
  }

  .column{
    display:flex;
    flex-flow: column;
    margin: 0 !important;
  }

  .column_center{
    justify-content: center;
  }

  .column_end{
    justify-content: flex-end;
  }

  .flex{
    flex: 1
  }

  .switch_container_os{
    width: 100%;
    display: flex;
		margin-top: 25px;
		flex-direction: column;
  }

  .switch_container_os .left{
    width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 18px;
  }

  .switch_container_os .right{
    width: 100%;
  }

	.checkbox_os{
		display: flex;
    align-items: center;
		color: #2B80FF;
		font-size: ${smallSizes.fontSize.fontSize12};
		font-weight: bold;
		margin-top: 11px;
	}

  .switch_box{
    padding-right: 30px;
		display: flex;
  }

	.switch_box .text{
		font-size: ${smallSizes.fontSize.fontSize10}
	}

  .subtitled{
    font-size: 14px;
    font-weight: bold;
    color: ${color.requiredIconBlue};
  }

  .breadcum{
    color: #004085;
    background: #CCE5FF;
    width: 100%;
    height: 166px;
    border: 1px solid #B8DAFF;
    border-radius: 4px;
  }


// ---------------------------------------
// ---------------------------------------

.ant-select:not(.ant-select-customize-input) 
.ant-select-selector {
    position: relative;
    background-color: white !important;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    color: #868D96; 
    font-size: 12px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-single:not(.ant-select-customize-input) 
.ant-select-selector {
    width: 100%;
    height: 40px;
    color: #868D96; 
    font-size: ${smallSizes.fontSize.fontSize12};
}
.ant-select-item {
    position: relative;
    display: block;
    min-height: 40px;
    padding: 5px 12px;
    color: #868D96; 
    font-size: 12px;
    font-weight: normal;
    line-height: 22px;
    cursor: pointer;
    
}
.ant-select-single:not(.ant-select-customize-input),
.ant-select-selector
.ant-select-selection-search-input {
    height: 40px;
}
.ant-picker {
    color: #868D96; 
    font-size: 12px;
    padding: 4px 11px 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: white;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px;
}
.ant-select-item-option-content {
    color: #868D96; 
    font-size: 12px;
    padding: 4px 11px 4px;
    background: white;
}

.ant-picker-suffix {
    display: flex;
    flex: none;
    align-self: center;
    margin-left: 4px;
    color: #949594;
    line-height: 1;
    pointer-events: none;
}
.ant-picker-input > input {
    color: #949594;
    // font-size: 12px;
    background-color: white;
    border-radius: 5px;
}
.margin-bottom{
  margin-bottom: 11px;
}

.ant-select-multiple > div:nth-child(1) {
  height: 40px
	}

.ant-select-multiple .ant-select-selection-item {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
	font-size: ${smallSizes.fontSize.fontSize12};
}


// ---------------------------------------
// ---------------------------------------

.modal_input {
	width: 100%;
	height: 38px;
	background: white;
	border-radius: 5px;
	border: 1px solid #d9d9d9;
	outline: 0;
	padding: 4px 11px 4px;
	margin-top: 0.5em;
	font-size: 12px;

	:focus {
		border-color: #426da9;
		box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
		border-right-width: 1px;
	}

}

.text-assignment{
	color: #131523;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
	margin-right: 17px;
}

.ant-picker .ant-picker-input > input{
	font-size: ${smallSizes.fontSize.fontSize12};
}

.text-resumen{
	font-size: ${smallSizes.fontSize.fontSize12};
	color: #004085;
}

.margin-bottom-6{
	margin-bottom: 6px;
}

.container_text_summary{
	display: flex;
	justify-content: space-between;
	width: 260px;
}

@media screen and (min-width: ${devices.smallDesk}){

	.text-assignment{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.checkbox_os{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.switch_box .text{
		font-size: ${smallSizes.fontSize.fontSize10}
	}

	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
    font-size: ${smallSizes.fontSize.fontSize12};
	}

	.ant-select-multiple .ant-select-selection-item {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.ant-picker .ant-picker-input > input{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.text-resumen{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
	.container_text_summary{
		width: 260px;
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.text-assignment{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.checkbox_os{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.switch_box .text{
		font-size: ${mediumSizes.fontSize.fontSize10}
	}

	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
    font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.ant-select-multiple .ant-select-selection-item {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.ant-picker .ant-picker-input > input{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.text-resumen{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.container_text_summary{
		width: 290px;
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.text-assignment{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.checkbox_os{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.switch_box .text{
		font-size: ${largeSizes.fontSize.fontSize10}
	}

	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
    font-size: ${largeSizes.fontSize.fontSize12};
	}

	.ant-select-multiple .ant-select-selection-item {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.ant-picker .ant-picker-input > input{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.text-resumen{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.container_text_summary{
		width: 325px;
	}
}
`

const ContainerCollapse = styled.div`
.ant-collapse>.ant-collapse-item >.ant-collapse-header{
	padding-left: 0;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
	padding-left: 0;
}
`
