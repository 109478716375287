import React from 'react'
import style from './FormOverflowTemplate.module.css'


const FormOverflowTemplate = ({ children }) => {
	return (
		<div className={style.form_overflow_background}>
			<span className={`${style.form_overflow} animate__animated animate__fadeInRight custom_scroll`}>
				{children}
			</span>
		</div>
	)
}

export default FormOverflowTemplate