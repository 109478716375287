import React, { useEffect, useState } from 'react'

import { myFetch } from '../../services/services'
import { datatableStruct, deleteOTtype } from '../../api/otTypeApi'

import { Panel } from '../../components/panel/panel'
import GreyDivider from '../../components/greyDivider'
import NormalText from '../../components/text/NormalText'
import DatatableHeaderText from '../../components/text/datatable/datatableHeaderText'

import { useFilter } from '../../hooks/useFilter'
import { sorterCallback } from '../../helpers/sorterCallback'

import CreateOtForm from './components/createOtForm/CreateOtForm'

import { Input, Pagination, Popconfirm, Table, Tooltip } from 'antd'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { selectTab } from '../../store/tab/tabSlice'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import style from './typesWorkOrders.module.css'
import EmptySearch from '../../components/empty/emptySearch/EmptySearch'
import EmptyData from '../../components/empty/emptyData/EmptyData'

const TypesWorkOrders = () => {

	const [columnsTable, setColumnsTable] = useState([])
	const [rowsTable, setRowsTable] = useState([])

	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [totalSize, setTotalSize] = useState(0);

	const [orderDirection, setOrderDirection] = useState("desc");

	const [viewCreateForm, setViewCreateForm] = useState(false);

	const [loadding, setLoadding] = useState(true);
	const [emptyData, setEmptyData] = useState(false);

	const [viewEmpty, setViewEmpty] = useState(false);

	const { Search } = Input

	const dispatch = useDispatch();
	const history = useHistory();

	const reduxState = useSelector((state) => state?.authState)
	const loginOperadorID = reduxState?.operator_detail?.ID;

	const { searchData, searchHandler, filters, setRowsParams, rowsParams } = useFilter()

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const tooltipText = (
		<section className={style.container_tooltip}>
			<span className={style.tittle_tooltip}>Información de ayuda</span>
			<span className={style.description_tooltip}>Tipos de órdenes habilitadas por el operador.</span>
		</section>
	)

	const datatableData = async () => {
		const filter = {
			...filters,
			operator: loginOperadorID,
			order_field: "ID",
			order_type: orderDirection,
		}
		try {
			const response = await myFetch(`api/v1/type_ot/datatables/`, filter)
			if (response.data.length === 0) setEmptyData(true)
			else setEmptyData(false)

			if (response.size) {
				setTotalSize(response.size)
				const data = response.data.map((row) => {
					const dataFormtaed = dayjs(row.created).format("DD-MM-YYYY")
					return {
						...row,
						key: row.ID,
						created: dataFormtaed,
					}
				})
				setRowsTable(data)
			}
			setLoadding(false)
		} catch (error) { }
	}

	useEffect(() => {
		datatableStruct().then(res => {
			const actionColumn = [
				...res,
				{
					title: <DatatableHeaderText value='Operación' />,
					dataIndex: 'operation',
					key: 'operation',
					width: "150px",
					render: (_, record) => (
						<Popconfirm
							placement="topRight"
							icon={null}
							title="¿Eliminar registro?"
							description={() => {
								return (
									<span>
										Si eliminas este registro, se eliminará de forma permanente de tu historial
									</span>
								);
							}}
							onConfirm={() => deleteOTtype(record.id_unico)}
							okText="Eliminar"
							cancelText="Cancelar"
						>
							<button className={style.button_table}>Eliminar</button>
						</Popconfirm>
					)
				}
			]

			const data = actionColumn.map((column) =>

				column.dataIndex === "name" ?
					{
						...column,
						onCell: (record) => {
							return {
								onClick: () => {
									dispatch(selectTab({ selectedTab: 0 }))
									history.push("/TypeDetallesOT", { params: record });
								},
								style: { cursor: "pointer" },
								className: "hover-table-class"
							}
						}
					} : column
			)

			setColumnsTable(data)
		})
	}, [])

	useEffect(() => {
		datatableData()
	}, [searchData, rowsParams, orderDirection])

	useEffect(() => {
		if (emptyData && loadding === false && searchData) setViewEmpty(true)
		if (!emptyData && loadding === false) setViewEmpty(true)
		if (emptyData && loadding === false && !searchData) setViewEmpty(false)
	}, [loadding, emptyData])

	return (
		<Panel>
			<header className={style.container_header}>
				<section className={style.top}>
					<span className={style.sub_container_header}>
						<h2 className={style.title_header}>Tipos de órdenes de trabajo</h2>
						<Tooltip
							title={tooltipText}
							color='white'
							arrow={false}
							placement='bottom'
							overlayInnerStyle={{
								height: "95px",
								maxHeight: '100px'
							}}
						>
							<i className="fa-solid fa-circle-info circle-tooltip"></i>
						</Tooltip>

						<span className={style.sub_text_header}>Configuración | Tipos de órdenes de trabajo</span>
					</span>
					<button className={style.button_header} onClick={() => setViewCreateForm(true)}>
						Agregar orden de trabajo
					</button>
				</section>
				<GreyDivider />
			</header>

			<section className={style.container}>
				{viewEmpty && (
					<div className={style.conatiner_filter}>
						<p className={style.text}>Listado de tipos de ordenes de trabajo registradas por el operador.</p>
						<SearchInput>
							<Search
								placeholder="Realizar busqueda"
								allowClear
								onSearch={(value) => {
									searchHandler(value)
									if (value === '') {
										setEmptyData(false)
										setLoadding(true)
									}
								}}
							/>
						</SearchInput>
					</div>
				)}

				{emptyData === false && loadding === false && (
					<>
						<Table
							columns={columnsTable}
							dataSource={rowsTable}
							onChange={(pagination, filters, sorter, extra) => setOrderDirection(sorterCallback(pagination, filters, sorter, extra))}
							pagination={false}
						/>

						<section className={style.container_pagination}>
							<div className={style.sub_container_export}>
								<span className={style.text_pagination}>
									<NormalText
										bold={false}
										value={`Mostrando del ${rowsParams.start + 1} registros al ${rowsParams.start + rowsParams.offset} de un`}
									/>

									<NormalText
										bold={true}
										value={`total de ${5} registros`}
									/>
								</span>
								<span className={style.export}>
									<i className="fa-solid fa-file" style={{ color: "#2B80FF" }}></i>
									<p className={style.text_export}>Exportar data</p>
								</span>
							</div>

							<Pagination
								showSizeChanger
								defaultCurrent={paginationSettings[1]}
								current={paginationSettings[0]}
								onChange={modifyPagination}
								total={totalSize}
							/>
						</section>
					</>
				)}

				{emptyData && loadding === false && searchData && (
					<EmptySearch sectionPage='tipo de orden de trabajo' />
				)}

				{emptyData && loadding === false && !searchData && (
					<EmptyData sectionPage='tipo de orden de trabajo' />
				)}

			</section>

			{viewCreateForm && (
				<CreateOtForm
					setViewCreateForm={setViewCreateForm}
				/>
			)}


		</Panel>
	)
}

export default TypesWorkOrders

const SearchInput = styled.div`
width: 318px;

.ant-input-group{
	height: 40px;
}

.ant-input-search .ant-input-affix-wrapper{
	height: 40px;
}

.ant-input-group .ant-input{
	height: 29px;
	font-size: 12px;
}

.ant-input-search .ant-input-search-button{
	height: 40px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
	border-left: none
}

.ant-input-affix-wrapper:focus-within {
	box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
	border-color: white;
}

.ant-input-affix-wrapper:hover {
	border-color: #d9d9d9;
	border-right: none;
}

.ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary):hover{
	border-color: #d9d9d9;
}

@media (min-width: 1280px) {
	.ant-input-group .ant-input {
		font-size: 12px;
	}
}

@media (min-width: 1366px) {
	.ant-input-group .ant-input {
		font-size: 13.5px;
	}
}

@media (min-width: 1920px) {
	.ant-input-group .ant-input {
		font-size: 15.18px; 
	}
}

`