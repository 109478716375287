import React from 'react'
import ColorCircle from "../../../../components/colorCircle"
import './index.scss'

const TooltipText = ({ tooltipData }) => {
    return (
        <span className="workday__tooltip__container">
            <span className="tittle">Información de ayuda</span>

            {tooltipData?.map((item, i) => {
                return (
                    <div className="row__content" key={i}>
                        <ColorCircle color={item?.color} />
                        <span className="description">{item?.title}</span>
                    </div>
                )
            })}
        </span>
    )
}

export default TooltipText