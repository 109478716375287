import React from 'react'
import './index.scss'
import { warningMsg } from '../../../../helpers/antNotifications'

const CustomHeader = ({ label, onNavigate, onView, view, availabilityEvents, technicianID }) => {
    
    const onNavigationChange = (value) => {
        onNavigate(value)
        if (technicianID === null) {
            warningMsg({ msg: "Por favor, selecciona un técnico" })
        }
    }

    return (
        <span className='workday__header__calendar__container'>
            <div className="customHeader__left">
                <span className='total__title'>Total de horas laborales</span>

                {availabilityEvents?.length === 0
                    ? <span> - </span>
                    : <span className='total__box'>{availabilityEvents?.total_hours}H </span>
                }
            </div>

            <div className="customHeader__mid">
                <button onClick={() => onNavigationChange('PREV')}>{'<'}</button>
                <span className="label">{label}</span>
                <button onClick={() => onNavigationChange('NEXT')}>{'>'} </button>
            </div>

            <div className="customHeader__right">
                <span className={view === "day" ? "date__selected" : "date__btn"} onClick={() => onView("day")}>Dia</span>
                <span className={view === "week" ? "date__selected" : "date__btn"} onClick={() => onView("week")}>Semana</span>
                <span className={view === "month" ? "date__selected" : "date__btn"} onClick={() => onView("month")}>Mes</span>
            </div>

        </span>

    )
}

export default CustomHeader