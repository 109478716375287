import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../components/modal/CustomModal'
import Form from '../../../../components/form/index.jsx'
import MainTitle from '../../../../components/text/drawer/mainTitle/index.jsx'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx'
import { onlyTextAndNumber } from '../../../../helpers/helpers.js'

import style from './editCategory.module.css'
import { Input, Popconfirm, Select, notification } from 'antd'
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx'
import BottomSection from '../../../../components/form/bottomSection/index.jsx'
import { myFetch } from '../../../../services/services.js'
import styled from 'styled-components'
import { updateCategoryOt } from '../../../../api/otTypeApi.js'

const EditCategory = ({ setEditCategoryForm, editDataCategory }) => {

	const [incompleteFields, setIncompleteFields] = useState({ input1: '', input2: '', input3: '' })

	const [categoryName, setCategoryName] = useState(editDataCategory.name)

	const [addImg, setAddImg] = useState("")
	const [imagesArray, setImagesArray] = useState([...editDataCategory.imgs])

	const [addQuestions, setAddQuestions] = useState("")
	const [questionType, setQuestionType] = useState(null)
	const [questionArray, setQuestionArray] = useState(editDataCategory.questions)

	const [viewModifyImage, setViewModifyImage] = useState(false)
	const [keyModifyImage, setKeyModifyImage] = useState(null)
	const [nameEditImg, setNameEditImg] = useState("")

	const [viewModifyQuestions, setViewModifyQuestions] = useState(false)
	const [keyModifyQuestions, setKeyModifyQuestions] = useState(null)

	const [questionTypeEdit, setQuestionTypeEdit] = useState(null)

	const [nameEditQuestions, setNameEditQuestions] = useState("")


	const options = [
		{ value: 1, label: "Sí o No" },
		{ value: 2, label: "Abierta" }
	]

	const onNameChange = (e) => {
		const value = e.target.value
		if (onlyTextAndNumber(value) || value === "") {
			setCategoryName(value)
		}
	}

	const onNameImgChange = (e) => {
		setAddImg(e.target.value)
	}

	const onHandlerAddImg = () => {
		if (addImg !== "") {
			setImagesArray([...imagesArray, {
				"img_name": addImg
			}])
			setAddImg("")
		} else {
			return
		}
	}

	const onHandlerDeleteImg = (index) => {
		const deleteArray = imagesArray.filter((_, order) => order !== index)
		setImagesArray(deleteArray)
	}

	const onNameQuestionChange = (e) => {
		setAddQuestions(e.target.value)
	}

	const onSelectQuestionChange = (value) => {
		setQuestionType(value)
	}

	const onHandlerAddQuestions = () => {
		if (addQuestions !== "" && questionType !== "") {
			setQuestionArray([...questionArray, {
				"kind": questionType,
				"question": addQuestions,
			}])
			setAddQuestions("")
			setQuestionType(null)
		} else {
			return
		}
	}

	const onHandlerDeleteQuestions = (index) => {
		const deleteArray = questionArray.filter((_, order) => order !== index)
		setQuestionArray(deleteArray)
	}

	const onHandlerModifyImg = (index) => {
		setKeyModifyImage(index)
		setViewModifyImage(true)
	}

	const onHandlerEditNameImg = () => {
		let newArray = [...imagesArray]
		newArray[keyModifyImage] = { ...newArray[keyModifyImage], img_name: nameEditImg };

		setImagesArray(newArray)
		setViewModifyImage(false)
	}

	const onHandlerModifyQuestions = (index) => {
		setKeyModifyQuestions(index)
		setViewModifyQuestions(true)
	}

	const onHandlerEditQuestions = () => {
		let newArray = [...questionArray]
		newArray[keyModifyQuestions] = { ...newArray[keyModifyQuestions], question: nameEditQuestions, kind: questionTypeEdit };

		setQuestionArray(newArray)
		setViewModifyQuestions(false)
	}

	const onUpdateCategory = async () => {
		const data = {
			"name": categoryName,
			"imgs": {
				"value": imagesArray
			},
			"questions": {
				"value": questionArray
			}
		}

		if (categoryName !== "" && imagesArray.length > 0 && questionArray.length > 0) {
			updateCategoryOt(editDataCategory.id_unico, data)
		} else {
			setIncompleteFields({
				input1: categoryName === "" ? 'error' : '',
				input2: imagesArray.length === 0 ? 'error' : '',
				input3: questionArray.length === 0 ? 'error' : ''
			})
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Por favor, completa todos los campos.',
				placement: 'bottomLeft',
				duration: 2.5,
			})
			return;
		}



	}

	return (
		<CustomModal onClick={() => setEditCategoryForm(false)}>
			<Form>
				<MainTitle value='Modificar categoría' onClick={() => setEditCategoryForm(false)} />
				<SubtitleBlue value="Información de la categoría" />

				<div>
					<LabelDrawer value="Nombre" />
					<Input
						style={{ height: "40px" }}
						placeholder="Ingresar nombre de la categoria"
						onChange={onNameChange}
						value={categoryName}
						status={incompleteFields.input1}
					/>
				</div>

				<section className={style.container_imagen}>
					<div className={style.container_input}>
						<p className={style.label_title}>Nombre de imágenes a solicitar</p>
						<Input
							style={{ height: "40px" }}
							placeholder="Ingrese descripcion de imagen a solicitar"
							onChange={onNameImgChange}
							value={addImg}
							status={incompleteFields.input2}
						/>
					</div>
					<button
						className={style.button}
						onClick={onHandlerAddImg}
					>Agregar</button>
				</section>

				{imagesArray.length > 0 ?
					imagesArray.map((img, index) => {
						return (
							<>
								{viewModifyImage && keyModifyImage === index ? (
									<section className={style.container_add_img} key={index}>
										<Input
											placeholder="Ingrese descripcion de imagen a solicitar"
											style={{ width: "50%" }}
											onChange={(e) => setNameEditImg(e.target.value)}
										/>
										<span className={style.container_button}>
											<button className={style.button} onClick={onHandlerEditNameImg}>Guardar</button>
											<button className={style.button} onClick={() => setViewModifyImage(false)}>Cancelar</button>
										</span>
									</section>

								) :
									(
										<section className={style.container_add_img} key={index}>
											<p className={style.text_label}>{img.img_name}</p>

											<span className={style.container_button}>
												<button className={style.button} onClick={() => onHandlerModifyImg(index)}>Modificar</button>
												<Popconfirm
													placement="topRight"
													icon={""}
													title="¿Eliminar fotografía?"
													description="Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes"
													okText="Eliminar"
													cancelText="Cancelar"
													onConfirm={() => onHandlerDeleteImg(index)}
												>
													<button className={style.button}>Eliminar</button>
												</Popconfirm>
											</span>

										</section>
									)}
							</>

						)
					}) : null
				}


				<section className={style.container_questions}>
					<div className={style.container_input}>
						<p className={style.label_title}>Pregunta</p>
						<Input
							style={{ height: "40px" }}
							placeholder="Ingrese la pregunta"
							onChange={onNameQuestionChange}
							value={addQuestions}
							status={incompleteFields.input3}
						/>
					</div>
					<div className={style.container_select}>
						<p className={style.label_title}>Tipo</p>
						<Select
							style={{ height: "40px" }}
							placeholder="Seleccione el tipo"
							options={options}
							onChange={onSelectQuestionChange}
							value={questionType}
							status={incompleteFields.input3}
						/>
					</div>


					<button className={style.button} onClick={onHandlerAddQuestions}>Agregar</button>
				</section>

				{questionArray.length > 0 ?
					questionArray.map((question, index) => {
						return (

							<>
								{viewModifyQuestions && keyModifyQuestions === index ? (
									<section className={style.container_add_questions} key={index}>
										<Input
											placeholder="Ingrese la pregunta"
											style={{ width: "40%" }}
											onChange={(e) => setNameEditQuestions(e.target.value)}
										/>
										<ContainerSelect>
											<Select
												className='select_styles'
												placeholder="Seleccione el tipo"
												options={options}
												onChange={(value) => setQuestionTypeEdit(value)}
											/>
										</ContainerSelect>
										<span className={style.container_button}>
											<button className={style.button} onClick={onHandlerEditQuestions}>Guardar</button>
											<button className={style.button} onClick={() => setViewModifyQuestions(false)}>Cancelar</button>
										</span>
									</section>

								) : (
									<section className={style.container_add_questions} key={index}>
										<p className={style.text_label_questions}>{question.question}</p>
										<p className={style.text_label}>{question.kind === 1 ? "Si o No" : "Abierta"}</p>

										<span className={style.container_button}>
											<button className={style.button} onClick={() => onHandlerModifyQuestions(index)}>Modificar</button>
											<Popconfirm
												placement="topRight"
												icon={""}
												title="¿Eliminar fotografía?"
												description="Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes"
												okText="Eliminar"
												cancelText="Cancelar"
												onConfirm={() => onHandlerDeleteQuestions(index)}
											>
												<button className={style.button}>Eliminar</button>
											</Popconfirm>
										</span>


									</section>
								)}

							</>

						)
					})
					: null}

				<BottomSection onCancel={() => setEditCategoryForm(false)} onAcept={onUpdateCategory} />
			</Form>
		</CustomModal>
	)
}

export default EditCategory

const ContainerSelect = styled.div`
width: 25%;
.select_styles .ant-select-selector{
	height: 31.6px;
}

.select_styles .ant-select-selection-item{
	padding-top: 0 !important;
}

`