export const useRol = (data) => {
  let nameRol = ''

  if (data.rol === 'client_service_isp') {
    nameRol = 'Atención al cliente'
  } else if (data.rol === 'multifiber') {
    nameRol = 'Multifiber'
  } else if (data.rol === 'admin') {
    nameRol = 'Administrador'
  } else if (data.rol === 'admin_isp') {
    nameRol = 'Administrador'
  } else if (data.rol === 'supervisor_isp') {
    nameRol = 'Supervisor'
  }

  return nameRol
}
