import React, { useEffect, useState } from 'react'
import { Panel } from '../../../../components/panel/panel'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue'
import style from './overallDetailsTypesOT.module.css'
import ColorCircle from '../../../../components/colorCircle'
import { useSelector } from 'react-redux'
import { permissionCheck } from '../../../../helpers/helpers'
import { Table } from 'antd'
import { datatableDataCategory, datatableStructCategory } from '../../../../api/otTypeApi'
import EditWorkOrdersTypes from '../editWorkOrdersTypes/EditWorkOrdersTypes'
import NewCategory from '../newCategory/NewCategory'
import EditCategory from '../editCategory/EditCategory'
import DatatableRowText from '../../../../components/text/datatable/datatableRowText'
import CustomModal from '../../../../components/modal/CustomModal'
import { DetailForm } from '../../../../components/detailForm/DetailForm'
import EmptyData from '../../../../components/empty/emptyData/EmptyData'
import UseOperator from '../../../../hooks/UseOperator'

const OverallDetailsTypesOT = ({ data }) => {

	const [addCategoryForm, setAddCategoryForm] = useState(false);
	const [editOrderType, setEditOrderType] = useState(false);

	const [COLUMNS, setCOLUMNS] = useState([]);
	const [ROWS, setROWS] = useState([]);

	const [editCategoryForm, setEditCategoryForm] = useState(false);
	const [editDataCategory, setEditDataCategory] = useState(null);

	const [viewImages, setViewImages] = useState(false);
	const [dataImages, setDataImages] = useState({});

	const [viewQuestions, setViewQuestions] = useState(false);
	const [dataQuestions, setDataQuestions] = useState({});

	const [loadding, setLoadding] = useState(true);
	const [emptyData, setEmptyData] = useState(false);


	const array = useSelector((state) => state?.authState?.permissions);

	const { storedOperatorID } = UseOperator();

	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	const onViewImages = (data) => {
		setDataImages(data)
		setViewImages(true)
	}

	const onViewQuestions = (data) => {
		setDataQuestions(data)
		setViewQuestions(true)
	}

	useEffect(() => {
		datatableStructCategory().then(res => {
			const max = 2;
			const data = res.map(item =>
				item.dataIndex === "name" ?
					{
						...item,
						onCell: (record) => {
							if (rolPermission("add_category")) {
								return {
									onClick: () => {
										setEditCategoryForm(true)
										setEditDataCategory(record)
									},
									style: { cursor: "pointer" },
									className: "hover-table-class"
								}
							}
						}
					} : item
			)

			const imagesColumn = data.find(item => item.dataIndex === "imgs")

			if (imagesColumn) {
				imagesColumn.render = (images, record) => {
					return (
						<>
							{
								images.slice(0, max).map(item => (
									<span key={Math.random()} style={{ display: "flex", alignItems: "center" }}>
										<DatatableRowText marginBottom="0px" value={item.img_name} />
									</span>
								))
							}

							{images.length > max && (
								<button className={style.button_table}
									onClick={() => onViewImages(record)}
								>
									Ver más
								</button>
							)}
						</>
					)
				}
			}

			const questionColumn = data.find(c => c.dataIndex === "questions");
			if (questionColumn) {
				questionColumn.render = (questions, record) => {

					return (
						<>
							{
								questions.slice(0, max).map(item => (
									<span key={Math.random()} style={{ display: "flex", alignItems: "center" }}>
										<DatatableRowText marginBottom="0px" value={item.question} />
									</span>
								))
							}

							{questions.length > max && (
								<button className={style.button_table}
									onClick={() => onViewQuestions(record)}
								>
									Ver más
								</button>
							)}
						</>
					)
				}
			}

			setCOLUMNS(data)
		})

	}, [])

	useEffect(() => {
		storedOperatorID && datatableDataCategory(data?.id_unico, storedOperatorID).then(res => {
			setROWS(res)
			setLoadding(false)
			if (res.length === 0) setEmptyData(true)
			else setEmptyData(false)
		})
	}, [])

	return (
		<Panel>
			<section className={style.container_general}>
				<SubtitleBlue value="Información General" marginBottom="0px" />
				<span className={style.text_date}>Fecha de creación <b>{data?.created}</b></span>

				<section className={style.container_data}>
					<section className={style.container_description}>
						<div className={style.sub_container}>
							<p className={style.text_title}>Color</p>
							<span className={style.description}>
								<ColorCircle color={data?.color} />
								<p className={style.text_color}>{data?.color.toUpperCase()}</p>
							</span>
						</div>

						<div className={style.sub_container}>
							<p className={style.text_title}>Etiqueta</p>
							<p className={style.text_label}>{data?.label}</p>
						</div>

						<div className={style.sub_container}>
							<p className={style.text_title}>Nombre</p>
							<p className={style.text_name} onClick={() => setEditOrderType(true)}>{data?.name}</p>
						</div>
					</section>
					{rolPermission("add_categoryot") === true ? (
						<button className={style.button} onClick={() => setAddCategoryForm(!addCategoryForm)}>
							Agregar categoría
						</button>
					) : null}
				</section>
			</section>
			{emptyData === false && loadding === false && (
				<section className={style.conatiner_table}>
					<Table
						columns={COLUMNS}
						dataSource={ROWS}
						pagination={false}
					/>
				</section>
			)}

			{emptyData && loadding === false && (
				<EmptyData sectionPage='categoría orden de trabajo' />
			)}


			{editOrderType &&
				<EditWorkOrdersTypes
					setEditOrderType={setEditOrderType}
					data={data}
				/>
			}

			{
				addCategoryForm &&
				<NewCategory
					setAddCategoryForm={setAddCategoryForm}
					addCategoryForm={addCategoryForm}
					type_ot={data?.id_unico}
				/>
			}

			{
				editCategoryForm &&
				<EditCategory
					setEditCategoryForm={setEditCategoryForm}
					editDataCategory={editDataCategory}
				/>
			}

			{viewImages && !viewQuestions && (
				<CustomModal onClick={() => setViewImages(false)}>
					<DetailForm
						dataImages={dataImages}
						setViewImages={setViewImages}
						viewImages={viewImages}
						isOrderTypeOT={true}
					/>
				</CustomModal>
			)}

			{
				viewQuestions && !viewImages &&
				(
					<CustomModal onClick={() => setViewQuestions(false)}>
						<DetailForm
							dataQuestions={dataQuestions}
							setViewQuestions={setViewQuestions}
							viewQuestions={viewQuestions}
							isOrderTypeOT={true}
						/>
					</CustomModal>
				)
			}


		</Panel>
	)
}

export default OverallDetailsTypesOT