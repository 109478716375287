import React, { useEffect, useState } from 'react'

import Form from '../../../../components/form/index.jsx'
import CustomModal from '../../../../components/modal/CustomModal.jsx'
import MainTitle from '../../../../components/text/drawer/mainTitle/index.jsx'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx'
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx'
import PersonalData from './components/personalData/PersonalData.jsx'
import TypeOrderWork from './components/typeOrderWork/TypeOrderWork.jsx'
import WorkOrderAttention from './components/workOrderAttention/WorkOrderAttention.jsx'
import Scheduling from './components/scheduling/Scheduling.jsx'
import AdditionalInformation from './components/additionalInformation/AdditionalInformation.jsx'
import ImportantInformation from './components/importantInformation/ImportantInformation.jsx'
import BottomSection from '../../../../components/form/bottomSection/index.jsx'

import { Select, Switch, Collapse, notification } from 'antd'
import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components'

import style from './formCreatedOT.module.css'
import { checkEmptyKeys, validarCampos } from '../../../../helpers/helpers.js'
import { myFetchPost } from '../../../../services/services.js'
import SummaryForm from './components/summaryForm/SummaryForm.jsx'
const FormCreatedOT = ({
	setVisibleFormOT,
	loginOperadorID
}) => {

	const [selectedName, setSelectedName] = useState(null)
	const [selectedLastName, setSelectedLastName] = useState(null)
	const [selectedBusinessName, setSelectedBusinessName] = useState(null)
	const [selectedTypeDocument, setSelectedTypeDocument] = useState(null)
	const [typeCountryString, setTypeCountryString] = useState(null)
	const [selectedDocument, setSelectedDocument] = useState(null)
	const [selectedNumber, setSelectedNumber] = useState(null)
	const [selectedEmail, setSelectedEmail] = useState(null)
	const [selectedServiceID, setSelectedServiceID] = useState(null)

	const [selectedCategoryId, setSelectedCategoryId] = useState(null)
	const [selectedTypeId, setSelectedTypeId] = useState(null)
	const [selectedTextDescription, setSelectedTextDescription] = useState(null)

	const [selectedDirectionId, setSelectedDirectionId] = useState(null)
	const [selectedTechnicalId, setSelectedTechnicalId] = useState(null)
	const [selectedSupervisorRequeried, setSelectedSupervisorRequeried] = useState(false)
	const [selectedAuxiliariesId, setSelectedAuxiliariesId] = useState(null)
	const [viewAditionalComent, setViewAditionalComent] = useState(false)
	const [comentAditional, setComentAditional] = useState(null)
	const [getListType, setGetListType] = useState([])
	const [getListCategory, setGetListCategory] = useState([])
	const [selectedDirection, setSelectedDirection] = useState(null)
	const [getListTechnician, setGetListTechnician] = useState([])
	const [getListAuxiliaries, setGetListAuxiliaries] = useState([])

	const [dateTimeInitial, setDateTimeInitial] = useState(null)
	const [dateTimeEnd, setDateTimeEnd] = useState(null)
	const [diffHour, setDiffHour] = useState(null)

	const [camposIncompletos, setCamposIncompletos] = useState(null)

	const [viewUpload, setViewUpload] = useState(false)
	const [viewTypeClient, setViewTypeClient] = useState(false)
	const [imageBase64, setImagesBase64] = useState([]);
	const [typeClient, setTypeClient] = useState(0)

	const [checkMaxHour, setCheckMaxHour] = useState(false)

	const [disponibility, setDisponibility] = useState(null)
	const [disponibilityCheck, setDisponibilityCheck] = useState(false)

	const [viewSummary, setViewSummary] = useState(false)
	const [bodyCreateSummary, setBodyCreateSummary] = useState(null)

	const [isValidName, setIsValidName] = useState(null)
	const [isValidLastName, setIsValidLastName] = useState(null)
	const [isValidBusinessName, setIsValidBusinessName] = useState(null)
	const [isValidEmail, setIsValidEmail] = useState(null)
	const [isValidPhone, setIsValidPhone] = useState(null)
	const [isValid, setIsValid] = useState(null)

	const [prefixes, setPrefixes] = useState(null)

	const onViewTypeClient = (checked) => {
		setViewTypeClient(checked)
		setTypeClient(null)
	}

	const hadlerTypeClient = (value) => {
		setTypeClient(value)
	}

	const verifyDsiponibility = async () => {
		const data = {
			"date_time_initial": dateTimeInitial,
			"date_time_final": dateTimeEnd,
			"id": selectedTechnicalId,
			"ids": selectedAuxiliariesId
		}

		try {
			const response = await myFetchPost(`api/v1/schedule/check_disponibility/`, data)
			if (response.status === 200) {
				setDisponibility(true)
			}
		} catch (error) {
			if (error.response.status === 400) {
				setDisponibility(false)
			}
		}

	}

	const bodyCReatedOt = {
		"operator": loginOperadorID,
		"status": 0,
		"schedule": true,
		"type_document": selectedTypeDocument,
		"number_document": selectedDocument,
		"number_phone": selectedNumber,
		"email": selectedEmail,
		"services_Id": selectedServiceID,
		"type_ot": selectedTypeId,
		"category_ot": selectedCategoryId,
		"description": selectedTextDescription,
		"direction": selectedDirectionId,
		"technician": selectedTechnicalId,
		"support_supervisor": selectedSupervisorRequeried,
		"auxiliaries": selectedAuxiliariesId,
		"date_time_initial": dateTimeInitial,
		"date_time_final": dateTimeEnd,
		"type_client": Number(typeClient),
		"has_a_client": viewTypeClient,
		"code_area": prefixes
	}

	const openSummary = () => {

		if (typeClient === '1') {
			bodyCReatedOt["first_name"] = selectedName
			bodyCReatedOt["last_name"] = selectedLastName
		} else {
			bodyCReatedOt["business_name"] = selectedBusinessName
		}

		if (viewAditionalComent) {
			bodyCReatedOt["comments"] = comentAditional
		}

		const clavesVacias = checkEmptyKeys(bodyCReatedOt)
		setCamposIncompletos(clavesVacias)

		if (!validarCampos(bodyCReatedOt) ||
			isValidName === false || isValidLastName === false ||
			isValidBusinessName === false || isValidEmail === false ||
			isValidPhone === false || isValid === false
		) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: "Por favor, completa todos los campos",
				placement: 'bottomLeft',
				duration: 3,
			})
		} else {
			setBodyCreateSummary(bodyCReatedOt)
			setViewSummary(true)
		}
	}

	useEffect(() => {
		if (selectedTechnicalId && dateTimeInitial && dateTimeEnd && selectedAuxiliariesId) {
			verifyDsiponibility()
		}

	}, [selectedTechnicalId, dateTimeInitial, dateTimeEnd, selectedAuxiliariesId])

	useEffect(() => {
		setSelectedAuxiliariesId(null)
	}, [])


	return (
		<>
			<CustomModal onClick={() => setVisibleFormOT(false)}>
				<Form>
					<MainTitle value='Agregar orden de trabajo' onClick={() => setVisibleFormOT(false)} />
					<SubtitleBlue value='Información general' />

					<span className={style.container_client}>
						<p className={style.text_ot_client}>¿La orden de trabajo está atada a un cliente?</p>
						<span className={style.container_switch}>
							<p className={style.text_switch}>No</p>
							<Switch onChange={onViewTypeClient} />
							<p className={style.text_switch}>Si</p>
						</span>
					</span>

					{viewTypeClient && (
						<div className={style.container_type_client}>
							<LabelDrawer value='Tipo cliente' />
							<Select
								style={{ flex: 1, height: 40 }}
								placeholder='Selecciona un tipo de cliente'
								value={typeClient}
								options={
									[
										{ value: '1', label: 'Natural' },
										{ value: '0', label: 'Jurídico' },
									]
								}
								onChange={hadlerTypeClient}
							/>
						</div>
					)}


					<ContainerCollapse>
						<Collapse
							bordered={false}
							defaultActiveKey={['1', '2', '3', '4']}
							expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} style={{ color: "#2B80FF" }} />}
							ghost
							items={[{
								key: '1',
								label: <p className={style.text_blue}>Datos Personales</p>,
								children:
									<PersonalData
										typeClient={typeClient}
										setSelectedName={setSelectedName}
										setSelectedLastName={setSelectedLastName}
										setSelectedBusinessName={setSelectedBusinessName}
										setSelectedTypeDocument={setSelectedTypeDocument}
										setSelectedDocument={setSelectedDocument}
										setSelectedNumber={setSelectedNumber}
										setSelectedEmail={setSelectedEmail}
										setSelectedServiceID={setSelectedServiceID}
										setTypeCountryString={setTypeCountryString}
										typeCountryString={typeCountryString}
										setIsValidName={setIsValidName}
										isValidName={isValidName}
										setIsValidLastName={setIsValidLastName}
										isValidLastName={isValidLastName}
										setIsValidBusinessName={setIsValidBusinessName}
										isValidBusinessName={isValidBusinessName}
										setIsValidPhone={setIsValidPhone}
										isValidPhone={isValidPhone}
										setIsValidEmail={setIsValidEmail}
										isValidEmail={isValidEmail}
										setIsValid={setIsValid}
										isValid={isValid}
										setPrefixes={setPrefixes}
										prefixes={prefixes}
										camposIncompletos={camposIncompletos}
									/>,
							},
							{
								key: '2',
								label: <p className={style.text_blue}>Tipo de orden de trabajo</p>,
								children:
									<TypeOrderWork
										setSelectedCategoryId={setSelectedCategoryId}
										selectedCategoryId={selectedCategoryId}
										setSelectedTypeId={setSelectedTypeId}
										selectedTypeId={selectedTypeId}
										setSelectedTextDescription={setSelectedTextDescription}
										setGetListType={setGetListType}
										getListType={getListType}
										setGetListCategory={setGetListCategory}
										getListCategory={getListCategory}
										setSelectedTechnicalId={setSelectedTechnicalId}
										setSelectedAuxiliariesId={setSelectedAuxiliariesId}
										camposIncompletos={camposIncompletos}
									/>,
							},
							{
								key: '3',
								label: <p className={style.text_blue}>Atendimiento de orden de trabajo</p>,
								children:
									<WorkOrderAttention
										selectedCategoryId={selectedCategoryId}
										setSelectedDirectionId={setSelectedDirectionId}
										setSelectedTechnicalId={setSelectedTechnicalId}
										setSelectedSupervisorRequeried={setSelectedSupervisorRequeried}
										setSelectedAuxiliariesId={setSelectedAuxiliariesId}
										selectedAuxiliariesId={selectedAuxiliariesId}
										setComentAditional={setComentAditional}
										setViewAditionalComent={setViewAditionalComent}
										viewAditionalComent={viewAditionalComent}
										camposIncompletos={camposIncompletos}
										selectedTechnicalId={selectedTechnicalId}
										setSelectedDirection={setSelectedDirection}
										setGetListTechnician={setGetListTechnician}
										getListTechnician={getListTechnician}
										setGetListAuxiliaries={setGetListAuxiliaries}
										getListAuxiliaries={getListAuxiliaries}
									/>,
							},
							{
								key: '4',
								label: <p className={style.text_blue}>Agendamiento</p>,
								children:
									<Scheduling
										setDateTimeInitial={setDateTimeInitial}
										setDateTimeEnd={setDateTimeEnd}
										setDiffHour={setDiffHour}
										camposIncompletos={camposIncompletos}
									/>,
							}
							]}
						/>

					</ContainerCollapse>

					<AdditionalInformation
						setImagesBase64={setImagesBase64}
						setViewUpload={setViewUpload}
						viewUpload={viewUpload}
					/>
					{disponibility === false && (
						<ImportantInformation
							text={'La orden de trabajo que desea crear esta fuera del rango de disponibilidad del técnico.'}
							textCheckbox={'Aprobar agendamiento'}
							checkbox={setDisponibilityCheck}
						/>
					)}

					{diffHour > 8 && (
						<ImportantInformation
							text={'La orden de trabajo que desea crear se excede de 8 horas laborales.'}
							textCheckbox={'Crear orden de trabajo'}
							checkbox={setCheckMaxHour}
						/>
					)}

					<BottomSection
						textButtonAcept='Continuar'
						onAcept={openSummary}

						onCancel={() => setVisibleFormOT(false)}
						disable={diffHour > 8 && !checkMaxHour ? true : disponibility == false && !disponibilityCheck ? true : false}
					/>

				</Form>
			</CustomModal>

			{viewSummary &&
				<SummaryForm
					setViewSummary={setViewSummary}
					bodyCreateSummary={bodyCreateSummary}
					typeCountryString={typeCountryString}
					typeClient={typeClient}
					getListType={getListType}
					getListCategory={getListCategory}
					selectedDirection={selectedDirection}
					getListTechnician={getListTechnician}
					getListAuxiliaries={getListAuxiliaries}
					imageBase64={imageBase64}
					viewUpload={viewUpload}
				/>
			}
		</>
	)
}

export default FormCreatedOT

const ContainerCollapse = styled.div`
.ant-collapse>.ant-collapse-item >.ant-collapse-header{
	padding-left: 0;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
	padding-left: 0;
}
`