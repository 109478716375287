import React, { useCallback, useEffect, useState } from 'react';

// helper
import { stringCapitalize, format, eventStyleGetter } from '../../../../../helpers/helpers.js';
import getMessagesES from "../../../../../helpers/getMessages.js";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../../../../helpers/sizes.js";

// Api
import { getReagendarEvents } from '../../../../../api/calendar.js';

// custom
import CustomModal from '../../../../../components/modal/CustomModal.jsx';
import ResumeReagendarUi from '../resumeUi/ResumeReagendarUi.jsx';


// external libraries
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from "moment";
import styled from "styled-components";
import dayjs from "dayjs";
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import ReScheduleForm from '../reScheduleForm/ReScheduleForm.jsx';




const RescheduleCalendar = ({ setReagendar, reagendar, ostypeName, storedOperatorID, osID, data }) => {

	const currentDay = dayjs().format(format);
	const localizer = momentLocalizer(moment);

	const dataRedux = useSelector((state) => state.authState)

	const [reagendarData, setReagendarData] = useState(null);
	const [view, setView] = useState("day");

	const [reagendarEdit, setReagendarEdit] = useState();
	const [isVisibleEditForm, setIsVisibleEditForm] = useState(false);

	const [dayDateWeek, setDayDateWeek] = useState(null);
	const [dayDate, setDayDate] = useState(currentDay);
	const [confirmModal, setConfirmModal] = useState(false);

	const [overflowavAilability, setOverflowavAilability] = useState(null);

	const [rolePermissions, setRolePermissions] = useState(false);

	const onModalOpenEdit = () => setIsVisibleEditForm(true);
	const onModalCloseEdit = () => setIsVisibleEditForm(false);
	const openResumeForm = () => setConfirmModal(true);
	const closeResumeForm = () => setConfirmModal(false);

	const handleViewChange = (newView) => setView(newView)

	const handleNavigate = (date) => {
		const dayDateNavigate = dayjs(date).format(format)
		setDayDate(dayDateNavigate)
	}

	const CustomHeader = ({ label, onNavigate, onView, view }) => {
		return (
			<HeaderCalendar>
				<div className="left">
					<button onClick={onReagendar}>
						<i className="fa-solid fa-chevron-left"></i>
						Regresar</button>
				</div>

				<div className="mid">
					<button onClick={() => onNavigate('PREV')}>{'<'}</button>
					<span className="label">{label}</span>
					<button onClick={() => onNavigate('NEXT')}>{'>'} </button>
				</div>

				<div className="right">
					<span className={view === "day" ? "date__selected" : "date__btn"} onClick={() => onView("day")}>Dia</span>
					<span className={view === "week" ? "date__selected" : "date__btn"} onClick={() => onView("week")}>Semana</span>
				</div>
			</HeaderCalendar>
		)
	};

	const events = reagendarData?.map((item) => {
		return {
			title: item?.full_name,
			start: moment(`${item?.date} ${item?.start_time}`, 'DD-MM-YYYY HH:mm').toDate(),
			end: moment(`${item?.date} ${item?.end_time}`, 'DD-MM-YYYY HH:mm').toDate(),
			technicianID: item?.technician_id,
			technician: item?.full_name,
			disponibilidad: item?.disponibilidad,
			date: item?.date,
		}
	});

	const handleSelectEvent = useCallback(
		(event) => {
			onModalOpenEdit()
			const selectedStart = dayjs(event?.start).format('HH:mm')
			const selectedEnd = dayjs(event?.end).format('HH:mm')

			setReagendarEdit({
				"start": selectedStart,
				"end": selectedEnd,
				"technicianID": event?.technicianID,
				"technician": event?.technician,
				"ostype": ostypeName,
				"disponibilidad": event?.disponibilidad,
				"date": event?.date,
				"overflow": false
			})
		},
		[]
	);

	const onReagendar = () => setReagendar(!reagendar)

	const dayRequest = (dateRequest) => {
		getReagendarEvents(storedOperatorID, osID, dateRequest)
			.then(res => { setReagendarData(res) }
			)
	}

	// abrir modal de confirmacion
	const openConfirmation = () => {
		onModalCloseEdit()
		openResumeForm()
	};

	const handleSelectSlot = (event) => {

		// Calcular fin como 1 hora después
		const end = new Date(event.start.getTime() + (data.category_duration * 60 * 1000))

		// Reemplazar horas de event
		event.end = end

		// se toma el dia actual 
		const today = new Date();

		// Se valida que el evento no se pueda realizar cuando sea un dia anterior
		if (event.start <= today) {
			notification.warning({
				style: { fontWeight: 'bold' },
				placement: 'bottomLeft',
				message: "No puedes crear eventos en fechas pasadas!",

			})
			return false
		}

		onModalOpenEdit()
		const selectedStart = dayjs(event?.start).format('HH:mm')
		const selectedEnd = dayjs(event?.end).format('HH:mm')
		const selectedDate = dayjs(event?.start).format('DD-MM-YYYY')

		setReagendarEdit({
			"start": selectedStart,
			"end": selectedEnd,
			"date": selectedDate,
			"ostype": ostypeName,
			"technicianID": data.technician_id,
			"technician": data.technician,
			"overflow": true
		})
	};

	// Redondear al siguiente intervalo de horas 
	const roundToNearestHour = (date) => {

		date.setMinutes(0, 0, 0)
		return date
	};

	// Realizar peticion si es dia o semana
	useEffect(() => {
		if (view === "day") {
			storedOperatorID && reagendar && dayRequest(dayDate)
		}
		if (view === "week") {
			storedOperatorID && reagendar && dayRequest(dayDateWeek)
		}

	}, [view, storedOperatorID, dayDateWeek, dayDate, reagendar])

	// tomar el primer dia de la semana cuando se renderiza componente
	useEffect(() => {
		const date = new Date(); // La fecha actual
		let firstDay = new Date(date);
		let dayOfWeek = date.getDay();
		dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Ajustamos para que lunes sea 0
		firstDay.setDate(firstDay.getDate() - dayOfWeek);

		const firstDayWeek = dayjs(firstDay).format(format)
		setDayDateWeek(firstDayWeek)
	}, []);

	useEffect(() => {
		if (dataRedux.rol === 'supervisor_isp' || dataRedux.rol === 'admin_isp') {
			setRolePermissions(true);
		} else {
			setRolePermissions(false);
		}
	}, [dataRedux])


	return (
		<Container>
			<Calendar
				localizer={localizer}
				events={events}
				startAccessor="start"
				endAccessor="end"
				eventPropGetter={eventStyleGetter}
				defaultView={view}
				style={{ height: "102vh" }}
				scrollToTime={new Date(0, 0, 0, 7, 0, 0)}
				views={["day", "week"]}
				culture='es'
				messages={getMessagesES()}
				formats={{
					// columna izq. hora
					timeGutterFormat: 'h:mm a',

					dayHeaderFormat: (date, culture, localizer) =>
						stringCapitalize(localizer.format(date, "dddd D [de] MMMM", culture)),

					// Dic 13 - Ene 19
					dayRangeHeaderFormat: ({ start, end }) => (
						`${stringCapitalize(moment(start).format('MMM D'))} - ${stringCapitalize(moment(end).format('MMM D'))}`
					),

					// Lun. 13/03
					dayFormat: (date, culture, localizer) =>
						stringCapitalize(localizer.format(date, 'ddd DD/MM', culture)),

					monthHeaderFormat: (date) =>
						stringCapitalize(moment(date).format("MMMM YYYY")),
				}}
				components={{ toolbar: CustomHeader }}
				selectable
				onSelectEvent={handleSelectEvent}
				onSelectSlot={rolePermissions ? handleSelectSlot : null}
				view={view}
				onView={handleViewChange}

				onNavigate={(date, view) => {
					if (view === 'week') {
						let firstDay = new Date(date);
						let dayOfWeek = date.getDay();
						dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Ajustamos para que lunes sea 0
						firstDay.setDate(firstDay.getDate() - dayOfWeek);

						const firstDayWeek = dayjs(firstDay).format(format)
						setDayDateWeek(firstDayWeek)
					}
					handleNavigate(date)
				}}
			/>

			{isVisibleEditForm && (
				<CustomModal>
					<ResumeReagendarUi
						startTime={reagendarEdit?.start}
						endTime={reagendarEdit?.end}

						onModalCloseEdit={onModalCloseEdit}
						openConfirmation={openConfirmation}
						technician={reagendarEdit?.technician}
						overflow={reagendarEdit?.overflow}

						category={data?.category_id}
						date={reagendarEdit?.date}

						technicianID={reagendarEdit?.technicianID}
						setOverflowavAilability={setOverflowavAilability}
					/>
				</CustomModal>
			)}

			{confirmModal && (
				<CustomModal>
					<ReScheduleForm
						storedOperatorID={storedOperatorID}
						osID={osID}
						reagendarEdit={reagendarEdit}
						closeResumeForm={closeResumeForm}
						overflowavAilability={overflowavAilability}
					/>
				</CustomModal>
			)}
		</Container>
	)
}

export default RescheduleCalendar

const Container = styled.div`
padding-bottom: 40px;

.rbc-time-header-gutter {
	width: 75.9375px;
    min-width: 75.9375px;
    max-width: 75.9375px;
	background: #F7FAFC;
	border-left: 1px solid #EAF0F4;
}
`

const HeaderCalendar = styled.div`
	width: 100%;
	height: 60px;
	background: white;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	padding: 25px 63px 5px 30.25px;
	margin-top: 10px;

	button{
		border: none;
		outline: none;
	}

	.left,
	.mid,
	.right{
		flex: 1;
	}
	.left{
		background: white;
	}
	.left button{
		width: 85px;
		height: 21px;
		background: white;
		color: #2B80FF;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize14};
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.left .fa-chevron-left{
		color: #2B80FF;
		font-size: ${smallSizes.fontSize.fontSize14};
	}
	.ant-select-selector{
		background: white;
		border: 1px solid #E0E0E0;
		border-radius: 5px;
		height: 40px;
	}

	// ----------------------------------

	.mid{
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mid .label{
		margin: 0 16px;
		color: #5A607F;
		font-weight: bold;
		font-size: ${smallSizes.fontSize.fontSize18};
	}
	.mid button{
		background: white;
		color: #2B80FF;
		font-weight: bold;
		font-size: 18px;
	}
	

	// ----------------------------------

	.right{
		background: white;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.right .date__btn{
		background: white;
		border-radius: 3px;
		color: #2B80FF;
		font-size: ${smallSizes.fontSize.fontSize12};
		padding: 10px 17px;
		cursor: pointer;
	}
	.date__selected{
		background: #DDE4EB;
		border-radius: 3px;
		color: #2B80FF;
		font-size: ${smallSizes.fontSize.fontSize12};
		padding: 10px 17px;
		cursor: pointer;
	}
	
//------------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.left button{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.left .fa-chevron-left{
		font-size: ${smallSizes.fontSize.fontSize14};
	}

	.mid .label{
		font-size: ${smallSizes.fontSize.fontSize18};
	}

	.right .date__btn{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
	.date__selected{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.mediumDesk}){
	.left button{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.left .fa-chevron-left{
		font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.mid .label{
		font-size: ${mediumSizes.fontSize.fontSize18};
	}

	.right .date__btn{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
	.date__selected{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

}


@media screen and (min-width: ${devices.largeDesk}){
	.left button{
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.left .fa-chevron-left{
		font-size: ${largeSizes.fontSize.fontSize14};
	}

	.mid .label{
		font-size: ${largeSizes.fontSize.fontSize18};
	}

	.right .date__btn{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
	.date__selected{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;