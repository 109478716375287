import React from 'react'
import './index.scss'

const Form = ({ children, widthForm = '42%' }) => {
	return (
		<div className='form_style_background' style={{ width: widthForm }}>
			<span className='form animate__animated animate__fadeInRight custom_scroll' style={{ width: widthForm }}>{children}</span>
		</div>
	)
}
export default Form