import React, { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis } from 'recharts';

import style from './galleryPanel.module.css'
import { myFetchGet } from '../../../../services/services';
import CustomTable from '../../../../components/images/customTable/CustomTable';
import UploadImageForm from '../../../../components/images/uploadImageForm/UploadImageForm';
import EditImageForm from '../../../../components/images/editImageForm/EditImageForm';

const GalleryPanel = ({ data }) => {

	const { id_unico } = data

	const [visibleEditForm, setVisibleEditForm] = useState(false)
	const [visibleUploadForm, setVisibleUploadForm] = useState(false)

	const [ROWS, setROWS] = useState(null)
	const [selectedInfo, setSelectedInfo] = useState(null)

	const [listCategory, setListCategory] = useState(null)

	const [numberRequired, setNumberRequired] = useState(null)
	const [arrayMatch, setArrayMatch] = useState(0)
	const [categoryMatch, setCategoryMatch] = useState({})

	const [loadingtable, setLoadingtable] = useState(true)


	const getGallery = async () => {
		try {
			const response = await myFetchGet(`api/v1/wo_gallery/${id_unico}/get_pics/`)
			const data = response.response.map(item => ({
				ID: item.ID,
				created: item.created,
				user: item.user,
				name: item.name,
				photo: item.picture,
				base64: item.picture_base64,
			}))

			setROWS(data)
			setLoadingtable(false)
		} catch (error) { }
	}

	const getCatgeory = async () => {
		try {
			const response = await myFetchGet(`api/v1/category_ot/${id_unico}/`)
			setListCategory(response.imgs.value)
			setNumberRequired(response.imgs.value.length)
		} catch (error) { }
	}

	const dataGraph = [
		{ name: 'cantidad', uv: arrayMatch },
	];

	const onEditForm = (item) => {
		setSelectedInfo(item)
		setVisibleEditForm(true)
	}

	const openUploadForm = () => setVisibleUploadForm(true)
	const closeUploadForm = () => setVisibleUploadForm(false)

	useEffect(() => {
		getGallery()
		getCatgeory()
	}, [])

	useEffect(() => {
		if (listCategory && ROWS) {

			const coincidencias = {};
			const coincidenciasArray = [];

			listCategory.forEach(item1 => {
				let cantidad = 0;
				ROWS.forEach(item2 => {
					if (item1.img_name.trim() === item2.name.trim()) {
						cantidad++;
					}
				});

				if (cantidad > 0) {
					coincidencias[item1.img_name] = cantidad;
				}
			});

			Object.keys(coincidencias).forEach(nombre => {
				coincidenciasArray.push({
					nombre,
					cantidad: coincidencias[nombre]
				});
			});

			setCategoryMatch(coincidencias);
			setArrayMatch(coincidenciasArray.length);
		}
	}, [listCategory, ROWS])

	return (
		<section>
			<section className={style.container_header}>
				<p className={style.text_gallery_description}>
					Listados de imágenes asociadas a la categoría de la orden de trabajo.
				</p>

				<button
					className={style.button_create}
					onClick={openUploadForm}
				>
					Agregar imagen
				</button>
			</section>

			<section className={style.container_graph}>
				<BarChart width={400} height={30} data={dataGraph} layout="vertical">
					<XAxis type="number" hide domain={[0, numberRequired]} />
					<YAxis dataKey="name" type="category" hide />

					<Bar dataKey="uv" fill="#2B80FF" barSize={7} radius={[10, 10, 10, 10]} background={{ fill: '#eee', radius: 10 }} />
				</BarChart>

				<p className={style.text_p}>{`${arrayMatch} de ${numberRequired} imágenes requeridas cargadas`}</p>
				<p className={style.text_blue}>Máximo 5 imágenes por item</p>
			</section>

			{!loadingtable && (
				<CustomTable
					onEditForm={onEditForm}
					row={ROWS}
					type="OT"
				/>
			)}

			{visibleUploadForm &&
				<UploadImageForm
					ID={id_unico}
					selectedInfo={selectedInfo}
					closeUploadForm={closeUploadForm}
					listCategory={listCategory}
					categoryMatch={categoryMatch}
					type={"OT"}
				/>
			}

			{visibleEditForm &&
				<EditImageForm
					setVisibleEditForm={setVisibleEditForm}
					selectedInfo={selectedInfo}
					listCategory={listCategory}
					categoryMatch={categoryMatch}
					type={"OT"}
				/>
			}

		</section>
	)
}

export default GalleryPanel