import React, { useEffect, useState } from 'react'

import style from './typeOrderWork.module.css'
import LabelDrawer from '../../../../../../components/text/drawer/label'
import { Select, Input } from 'antd'
import { myFetchGet } from '../../../../../../services/services'
import { emptyInputsCheck } from '../../../../../../helpers/helpers'

const { TextArea } = Input

const TypeOrderWork = ({
	setSelectedCategoryId,
	selectedCategoryId,
	setSelectedTypeId,
	selectedTypeId,
	setSelectedTextDescription,
	setGetListType,
	getListType,
	setGetListCategory,
	getListCategory,
	setSelectedTechnicalId,
	setSelectedAuxiliariesId,
	camposIncompletos
}) => {

	const getOrderType = async () => {
		try {
			const res = await myFetchGet('api/v1/type_ot/all_types_orders/')
			const listSelect = res.data.map((item) => {
				return { value: item.id, label: item.name }
			})
			setGetListType(listSelect)
		} catch (error) { }
	}

	const handlerSelectTypeOrder = (value) => {
		setSelectedTypeId(value)
		setSelectedCategoryId(null)
		setSelectedTechnicalId(null)
		setSelectedAuxiliariesId(null)
	}

	const getCategory = async (id) => {
		try {
			const response = await myFetchGet(`api/v1/category_ot/all_categories_orders/?id=${id}`)
			const listSelect = response.data.map((item) => {
				return { value: item.id, label: item.name }
			})
			setGetListCategory(listSelect)
		} catch (error) { }
	}

	const handlerSelectCategory = (value) => {
		setSelectedCategoryId(value)
		setSelectedTechnicalId(null)
		setSelectedAuxiliariesId(null)
	}

	const handlerTextDescription = (e) => {
		setSelectedTextDescription(e.target.value)
	}

	useEffect(() => {
		getOrderType()
	}, [])

	useEffect(() => {
		selectedTypeId && getCategory(selectedTypeId)
	}, [selectedTypeId])


	return (
		<section className={style.container}>

			<header className={style.container_header}>
				<div className={style.circle} />
				<p className={style.text_header}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum sem libero. Morbi mollis eleifend efficitur. Morbi sed ligula semper leo maximus .</p>
			</header>

			<section className={style.container_separate}>
				<div className={style.subcontainer_separate}>
					<LabelDrawer value='Tipo de orden' />
					<Select
						style={{ flex: 1, height: 40 }}
						placeholder='Selecciona un tipo de orden'
						options={getListType}
						onChange={handlerSelectTypeOrder}
						status={emptyInputsCheck(camposIncompletos, 'type_ot') ? 'error' : ''}
					/>
				</div>

				<div className={style.subcontainer_separate}>
					<LabelDrawer value='Categoría' />
					<Select
						style={{ flex: 1, height: 40 }}
						placeholder='Selecciona una categoria'
						options={getListCategory}
						value={selectedCategoryId}
						onChange={handlerSelectCategory}
						status={emptyInputsCheck(camposIncompletos, 'category_ot') ? 'error' : ''}
					/>
				</div>
			</section>

			<div className={style.container_text_area}>
				<LabelDrawer value='Descripción del trabajo' />
				<TextArea
					style={{ height: 100 }}
					placeholder='Ingresar descripción de la orden de trabajo'
					onChange={handlerTextDescription}
					status={emptyInputsCheck(camposIncompletos, 'description') ? 'error' : ''}
				/>
			</div>
		</section>
	)
}

export default TypeOrderWork