import React, { useState } from 'react'
import style from './filterDashboard.module.css'
import { Checkbox } from 'antd'
import styled from 'styled-components'
import { useEffect } from 'react'

const FilterDashboard = ({ setViewFilter, setFilterCheckTechnicials, setFilterCheckSupervisor, filterCheckTechnicials, filterCheckSupervisor }) => {

	const [tecnicialSelect, setTecnicialSelect] = useState(false)
	const [supervisorSelect, setSupervisorSelect] = useState(false)

	const onClearFilter = () => {
		setFilterCheckTechnicials(false)
		setFilterCheckSupervisor(false)
		setTecnicialSelect(false)
		setSupervisorSelect(false)
		setViewFilter(false)
	}

	const onFilterTechnicial = () => {
		setTecnicialSelect(true)
		setSupervisorSelect(false)
	}

	const onFilterSupervisor = () => {
		setSupervisorSelect(true)
		setTecnicialSelect(false)
	}

	const onFilterSelected = () => {
		if (tecnicialSelect && supervisorSelect === false) {
			setFilterCheckTechnicials(true)
			setFilterCheckSupervisor(false)
			setViewFilter(false)
		} else if (supervisorSelect && tecnicialSelect === false) {
			setFilterCheckSupervisor(true)
			setFilterCheckTechnicials(false)
			setViewFilter(false)
		}
	}

	useEffect(() => {
		setSupervisorSelect(filterCheckSupervisor)
		setTecnicialSelect(filterCheckTechnicials)
	}, [])


	return (
		<section className={style.container}>
			<div className={style.filter_container}>
				<label className={style.title}>Opciones de filtrado</label>
				<CheckboxFont>
					<Checkbox checked={tecnicialSelect} onChange={onFilterTechnicial}> Reporte general</Checkbox>
					<Checkbox checked={supervisorSelect} onChange={onFilterSupervisor}> Técnicos bajo mi supervisión</Checkbox>
				</CheckboxFont>
			</div>

			<hr />

			<div className={style.conatiner_bottom}>
				<label className={style.text} onClick={onClearFilter}>Limpiar</label>
				<button className={style.button_ok} onClick={onFilterSelected}>Ok</button>
			</div>

		</section>
	)
}

export default FilterDashboard

const CheckboxFont = styled.div`
.ant-checkbox-wrapper{
	font-size: 12px;
	margin-bottom: 11px;
}

@media screen and (min-width: 1280px){
	.ant-checkbox-wrapper{
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px){
	.ant-checkbox-wrapper{
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px){
	.ant-checkbox-wrapper{
		font-size: 15.18px;
	}
}
`;
