import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        status: 'not-authenticated',
        email: null,
        rol: null,
        permissions: null,
        operator_detail: null,
        user_detail: null,
    },
    reducers: {
        onLogin: (state, action) => {
            state.status = 'authenticated';
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.rol = action.payload.rol;
            state.permissions = action.payload.permissions;
            state.operator_detail = action.payload.operator_detail;
            state.user_detail = action.payload.user_detail;
        },
        onLogout: (state) => {
            state.token = null;
            state.status = 'not-authenticated';
            state.email = null;
            state.rol = null;
            state.permissions = null;
            state.operator_detail = null;
            state.user_detail = null;
        },
    }
});


// Action creators are generated for each case reducer function
export const { onLogin, onLogout } = authSlice.actions;