import React from 'react'
import CustomModal from '../../../../components/modal/CustomModal'
import Form from '../../../../components/form/index.jsx'
import MainTitle from '../../../../components/text/drawer/mainTitle/index.jsx'
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx'
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx'
import { ColorPicker, Input, notification } from 'antd'
import style from './editServiceOrder.module.css'
import styled from 'styled-components'
import { useState } from 'react'
import BottomSection from '../../../../components/form/bottomSection/index.jsx'
import { patchColorData } from '../../../../api/ostypeCreate.js'
import UseOperator from '../../../../hooks/UseOperator.jsx'
import { useHistory } from "react-router";

const EditServiceOrder = ({ data, setEditOrderType }) => {

	const [colorSelected, setColorSelected] = useState(data?.color);
	const [nameSelected, setNameSelected] = useState(data?.name);
	const [labelSelected, setLabelSelected] = useState(data?.tag);

	const { storedOperatorID } = UseOperator();
	const history = useHistory();

	const goToList = () => history.replace("/TypesOS");

	const handlerColor = (color) => {
		setColorSelected(color.toHexString())
	}

	const handlerName = ({ target }) => {

		setNameSelected(target.value)
	}

	const handlerLabe = ({ target }) => {
		setLabelSelected(target.value)
	}

	const onUpdateColor = () => {
		const update = {
			"name": nameSelected,
			"color": colorSelected,
			"tag": labelSelected,
			"operator": storedOperatorID
		}

		if (nameSelected === data?.name && colorSelected === data?.color && labelSelected === data?.tag) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'no has hecho ningun cambio',
				placement: 'bottomLeft',
				duration: 2,
			})
			return
		}

		patchColorData(data?.id_unico, update, goToList)
	}

	return (
		<CustomModal onClick={() => setEditOrderType(false)}>
			<Form>
				<MainTitle value='Modificar orden de servicio' onClick={() => setEditOrderType(false)} />
				<SubtitleBlue value="Datos del tipo de orden de servicio" />

				<section className={style.container_name}>
					<LabelDrawer value="Nombre" />
					<Input style={{ height: 40 }} value={nameSelected} onChange={handlerName} />
				</section>

				<section className={style.container_color}>
					<div className={style.subcontainer_color}>
						<LabelDrawer value="Color" />
						<ContainerColorPicker>
							<span className={style.colorpicker}>
								<p className={style.text_color}>{colorSelected.toUpperCase()}</p>
								<ColorPicker
									onChangeComplete={handlerColor}
									styles={{
										popupOverlayInner: {
											width: 260,
											background: "white",
										}
									}}
									value={colorSelected}
									arrow={false}
								/>
							</span>

						</ContainerColorPicker>

					</div>
					<div className={style.subcontainer_label}>
						<LabelDrawer value="Etiqueta" />
						<Input style={{ height: 40 }} value={labelSelected} onChange={handlerLabe} />
					</div>
				</section>

				<BottomSection onCancel={() => setEditOrderType(false)} onAcept={onUpdateColor} />

			</Form>
		</CustomModal>


	)
}

export default EditServiceOrder

const ContainerColorPicker = styled.div`
.ant-color-picker-trigger{
	border: 0px;
}
`