import React, { useCallback, useEffect, useState } from 'react'

import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs';
import CustomHeader from './components/customHeader/CustomHeader';
import getMessagesES from '../../../../../../helpers/getMessages';
import { format, stringCapitalize } from '../../../../../../helpers/helpers';
import UseOperator from '../../../../../../hooks/UseOperator';
import { myFetchGet } from '../../../../../../services/services';
import ModalConfirm from './components/modalConfirm/ModalConfirm';
import CustomModal from '../../../../../../components/modal/CustomModal';
import FormReschedule from './components/formReschedule/FormReschedule';

const Reschedule = ({ setReschedule, id_unico, type_ot }) => {

	const { storedOperatorID } = UseOperator()
	const localizer = dayjsLocalizer(dayjs);
	const currentDay = dayjs().format(format);

	const [view, setView] = useState("day");
	const [dayDateWeek, setDayDateWeek] = useState(null);
	const [dayDate, setDayDate] = useState(currentDay);
	const [reagendarData, setReagendarData] = useState(null);

	const [isVisibleEditForm, setIsVisibleEditForm] = useState(false);
	const [isVisibleForm, setIsVisibleForm] = useState(false);

	const [reagendarEdit, setReagendarEdit] = useState(null);

	const onReagendar = () => {
		setReschedule(false)
	}

	const handleViewChange = (newView) => setView(newView)

	const handleNavigate = (date) => {
		const dayDateNavigate = dayjs(date).format(format)
		setDayDate(dayDateNavigate)
	}

	const getRescheduleData = async (dateRequest) => {

		const day = dayjs(dateRequest, 'DD-MM-YYYY').date();
		const month = dayjs(dateRequest, 'DD-MM-YYYY').month() + 1;
		const year = dayjs(dateRequest, 'DD-MM-YYYY').year();
		try {
			const response = await myFetchGet(`api/v1/wo/calendar_reschedule_wo?operator=${storedOperatorID}&day=${day}&month=${month}&year=${year}&wo=${id_unico}`);
			setReagendarData(response.data)
		} catch (error) {
			console.error(error)
		}
	}

	const events = reagendarData?.map(item => {
		return {
			title: item?.full_name,
			start: dayjs(`${item?.date} ${item?.start_time}`, 'DD-MM-YYYY HH:mm').toDate(),
			end: dayjs(`${item?.date} ${item?.end_time}`, 'DD-MM-YYYY HH:mm').toDate(),
			technicianID: item?.technician_id,
			technician: item?.full_name,
			disponibilidad: item?.disponibilidad,
			date: item?.date,
		}
	})

	const eventStyleGetter = () => ({
		style: {
			backgroundColor: "#2B80FF",
			borderRadius: 0,
			color: 'white',
			border: 0,
			cursor: 'pointer',
		}
	});

	const handleSelectEvent = useCallback((event) => {
		setIsVisibleEditForm(true)

		const selectedStart = dayjs(event?.start).format('HH:mm')
		const selectedEnd = dayjs(event?.end).format('HH:mm')

		setReagendarEdit({
			"start": selectedStart,
			"end": selectedEnd,
			"technicianID": event?.technicianID,
			"technician": event?.technician,
			"disponibilidad": event?.disponibilidad,
			"date": event?.date,
			"overflow": false,
			"wo": id_unico
		})

	}, [])

	useEffect(() => {
		if (view === "day") {
			storedOperatorID && getRescheduleData(dayDate)
		}
		if (view === "week") {
			storedOperatorID && getRescheduleData(dayDateWeek)
		}

	}, [view, storedOperatorID, dayDateWeek, dayDate])

	useEffect(() => {
		const date = new Date(); // La fecha actual
		let firstDay = new Date(date);
		let dayOfWeek = date.getDay();
		dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Ajustamos para que lunes sea 0
		firstDay.setDate(firstDay.getDate() - dayOfWeek);

		const firstDayWeek = dayjs(firstDay).format(format)
		setDayDateWeek(firstDayWeek)
	}, []);

	return (
		<section>
			<Calendar
				localizer={localizer}
				events={events}
				startAccessor="start"
				endAccessor="end"
				style={{ height: "98vh" }}
				views={["day", "week"]}
				view={view}
				onView={handleViewChange}
				culture='es'
				scrollToTime={new Date(0, 0, 0, 7, 0, 0)}
				messages={getMessagesES()}
				selectable
				components={{ toolbar: (props) => <CustomHeader {...props} onReagendar={onReagendar} /> }}
				eventPropGetter={eventStyleGetter}
				onSelectEvent={handleSelectEvent}
				formats={{
					// columna izq. hora
					timeGutterFormat: 'h:mm a',

					dayHeaderFormat: (date, culture, localizer) =>
						stringCapitalize(localizer.format(date, "dddd D [de] MMMM", culture)),

					// Dic 13 - Ene 19
					dayRangeHeaderFormat: ({ start, end }) => (
						`${stringCapitalize(dayjs(start).format('MMM D'))} - ${stringCapitalize(dayjs(end).format('MMM D'))}`
					),

					// Lun. 13/03
					dayFormat: (date, culture, localizer) =>
						stringCapitalize(localizer.format(date, 'ddd DD/MM', culture)),

					monthHeaderFormat: (date) =>
						stringCapitalize(dayjs(date).format("MMMM YYYY")),
				}}
				onNavigate={
					(date, view) => {
						if (view === "week") {
							let firstDay = new Date(date);
							let dayOfWeek = firstDay.getDay();
							dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
							firstDay.setDate(firstDay.getDate() - dayOfWeek);

							const firstDayWeek = dayjs(firstDay).format(format)
							setDayDateWeek(firstDayWeek)
						}

						handleNavigate(date)
					}
				}
			/>

			{isVisibleEditForm && (
				<CustomModal>
					<ModalConfirm
						setIsVisibleEditForm={setIsVisibleEditForm}
						reagendarEdit={reagendarEdit}
						setIsVisibleForm={setIsVisibleForm}
					/>
				</CustomModal>
			)}

			{isVisibleForm &&
				<FormReschedule
					setIsVisibleForm={setIsVisibleForm}
					operator={storedOperatorID}
					reagendarEdit={reagendarEdit}
					type_ot={type_ot}
				/>
			}


		</section>
	)
}

export default Reschedule