import React from 'react'

import NormalText from '../../../../../../../../components/text/NormalText'

import style from './modalConfirm.module.css'
import { TimePicker } from 'antd'
import dayjs from 'dayjs';

const ModalConfirm = ({ setIsVisibleEditForm, reagendarEdit, setIsVisibleForm }) => {

	const hourFormat = 'HH:mm';

	const DisabledTime = () => {
		return {
			disabledHours() {
				const hours = [];
				for (let i = 0; i < 24; i++) {
					if (i < 7 || i >= 19) {
						hours.push(i);
					}
				}
				return hours;
			}
		};
	};

	return (
		<section className={style.root_card}>
			<section className={style.card_event}>
				<NormalText value="Confirmación" color='#868E96' />

				<NormalText bold={true} value={`Técnico ${reagendarEdit.technician}`} color="#2B80FF" />

				<section className={style.space_between}>
					<div className={style.timePicker}>
						<NormalText
							size="12px"
							value="Inicio"
							color="#131523"
						/>
						<TimePicker
							format={hourFormat}
							inputReadOnly
							placeholder={dayjs(reagendarEdit.start, 'HH:mm')}
							style={{ width: "100%" }}
							defaultValue={dayjs(reagendarEdit.start, 'HH:mm')}
							disabledTime={DisabledTime}
							disabled
						/>
					</div>

					<div className={style.timePicker}>
						<NormalText
							size="12px"
							value="Fin"
							color="#131523"
						/>
						<TimePicker
							format={hourFormat}
							inputReadOnly
							placeholder={dayjs(reagendarEdit.end, 'HH:mm')}
							style={{ width: "100%" }}
							defaultValue={dayjs(reagendarEdit.end, 'HH:mm')}
							disabledTime={DisabledTime}
							disabled
						/>
					</div>
				</section>

				<div className={style.flex_end}>
					<button className={style.btn_normal} onClick={() => setIsVisibleEditForm(false)} >Cancelar</button>
					<button className={style.btn_silver} onClick={() => {
						setIsVisibleForm(true)
						setIsVisibleEditForm(false)
					}} >Continuar</button>
				</div>
			</section>



		</section>
	)
}

export default ModalConfirm