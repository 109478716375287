import React, { useEffect, useState } from 'react'

import Br from '../../../../../../components/br/Br.jsx'

import './ViewPlanSelect.scss'
import style from './viewPlan.module.css'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons';

const ViewPlanSelect = ({ data, showSpecification, }) => {

	const [initialName, setInitialName] = useState("")

	const { first_name, last_name, document_type_name, document_number, direction, number_service } = data

	const [selectButton, setSelectButton] = useState(1)

	const services = {
		1: 'Internet',
		2: 'Televisión',
		3: 'Telefonía'
	}

	useEffect(() => {
		if (showSpecification) {
			const firstName = first_name.charAt(0).toUpperCase()
			setInitialName(firstName)
		}
	}, [])

	return (
		<>
			{!showSpecification ? (
				<div className='view_plan_container'>
					<span className='container_text_plan'>
						<label className='text_plan'>Plan contratado</label>
					</span>

					<span className='container_specification'>
						<label className='title_specification'>Especificaciones técnicas del plan</label>
						<label className='text_characteristics'>{data.plan_name || "Sin plan"}</label>
					</span>
				</div>
			) :
				(
					<section className={style.container}>

						<header className={style.header}>
							<section className={style.container_data}>
								<Avatar className={style.avatar}>
									{initialName}
								</Avatar>
								<div className={style.subcontaier_data}>
									<label className={style.text_name}>{`${first_name} ${last_name}`}</label>
									<label className={style.text_document}>{`${document_type_name} ${document_number}`}</label>
									<label className={style.text_title_direction}>Dirección</label>
									<label className={style.text_direction}>{direction}</label>
								</div>
							</section>


							<label className={style.text_number}>{number_service}</label>

						</header>


						<div className='view_plan_container'>
							<span className='container_text_plan'>
								<label className='text_plan'>Plan contratado</label>
								<label className='text_plan_14'>{data?.plan_name}</label>
								<label className='text_plan_blue'>{data?.plan_technology}</label>
							</span>

							<span className='container_specification'>
								<label className='title_specification'>Especificaciones técnicas del plan</label>
								<label className='text_characteristics'>Características técnicas asociadas al plan de servicio a constratar.</label>

								<span className='container_button' style={{ width: data.plan_type_integer === 2 ? `${data.plan_type_integer * 22}%` : `${data.plan_type_integer * 30}%` }}>
									{Object.keys(services).filter(key => key <= data.plan_type_integer).map((key) => (
										<div className={style.subcontainer_button}>
											<button
												className={selectButton === parseInt(key) ? 'button_selected' : 'button_text'}
												onClick={() => setSelectButton(parseInt(key))}
												key={key}
											>
												{services[key]}
											</button>
											{selectButton === parseInt(key) &&
												<div className={style.linea} />
											}

										</div>
									))}
								</span>

								{selectButton === 1 ? (
									<div className='container_plan_specification'>
										<span className='distribution_subcontainer'>
											<i className='fa-solid fa-arrow-up arrow_center' />
											<label className='text_plan_specification'>{data?.plan_megas_upload} mb</label>
										</span>
										<span className='distribution_subcontainer'>
											<i className='fa-solid fa-arrow-down arrow_center' />
											<label className='text_plan_specification'>{data?.plan_megas_download} mb</label>
										</span>
									</div>
								) : selectButton === 2 ? (
									<div className='container_television'>
										<label className='text_television'>{data?.plan_number_channels} <b>SD</b></label>
										<label className='text_television'>10 <b>HD</b></label>
									</div>
								) : selectButton === 3 ? (
									<div className='container_telephony'>
										<label className='text_telephony'>{data?.plan_phone_minutes} min llamadas locales</label>
									</div>
								) : null}
							</span>
						</div>
					</section>
				)}
			<Br />
		</>
	)
}

export default ViewPlanSelect